var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100vh", "overflow-y": "hidden" } },
    [
      _c("div", { attrs: { id: "three" } }),
      _vm.nameDiv.show
        ? _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                padding: "5px",
                "background-color": "rgba(0,0,0,0.7)",
                "z-index": "100",
                "border-radius": "5px",
                color: "white",
                "font-size": "20px",
              },
              style: {
                left: _vm.nameDiv.left + "px",
                top: _vm.nameDiv.top + "px",
              },
            },
            [_vm._v(" " + _vm._s(_vm.nameDiv.name) + " ")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }