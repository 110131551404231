import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
export default {
  props: {
    // 是否能够拖动
    isDrag: {
      type: Boolean,
      default: false
    },
    // 房源数据
    itemDetail: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 卡片类型显示 0 只显示房号 1 显示房号以及其他
    cardType: {
      type: Number,
      default: 0
    },
    // 头部颜色
    headerColor: {
      type: String,
      default: '#ff8d37'
    },
    // 字体颜色
    fontColor: {
      type: String,
      default: '#ffffff'
    },
    // 配置的颜色
    allColor: {
      type: Object,
      default: null
    },
    // 配置的宽高
    allSize: {
      type: Object,
      default: {}
    },
    // 配置
    ledConfInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    projectRoomInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    houseResLength: {
      type: Number,
      default: 1
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      // addCommas: function (val = 0,type='unit') {
      //     if (val == 0) return '--'
      //     if(type == 'unit'){ //单价
      //         if(this.)
      //     }
      //     while (/(\d+)(\d{3})/.test(val.toString())) {
      //         val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2')
      //     }
      //     return val
      // },
      tabWidth: 100,
      isShowscreen: false,
      SelectId: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['customSetting'])), mapState({
    projectInfo: function projectInfo(state) {
      return state.screen.allDatas.projectInfo;
    },
    // 所有数据 包括右边侧栏
    ledinfo: function ledinfo(state) {
      return state.screen.allDatas.ledInfo;
    },
    // 项目信息
    // floorSort: state => state.screen.allDatas.floorSort,
    height: function height(state) {
      return state.screen.cellHeight;
    },
    width: function width(state) {
      return state.screen.cellWidth;
    },
    waitJson: function waitJson(state) {
      return state.screen.waitJson;
    }
  })),
  mounted: function mounted() {
    var _parent$houseRes;
    this.$emit("onSelectIds", this.itemDetail.id, false);
    // 表头键值
    var key = {
      show_floor: 1,
      show_room_cate: 1,
      show_room_area: 1,
      show_build_area: 1,
      droom_info_pin_an_jie_jia: 1,
      droom_info_pin_biao_zhun_jia: 1,
      droom_info_pin_xian_jia: 1,
      droom_info_pin_yi_ci_jia: 1,
      droom_info_pin_you_hui_jia: 1,
      droom_info_pin_yuan_jia: 1,
      zroom_info_pin_an_jie_jia: 1,
      zroom_info_pin_biao_zhun_jia: 1,
      zroom_info_pin_xian_jia: 1,
      zroom_info_pin_yi_ci_jia: 1,
      zroom_info_pin_you_hui_jia: 1,
      zroom_info_pin_yuan_jia: 1,
      user_info_batch_code: 1
    };
    var tabLength = this.itemDetail.room_max;
    var tabWidth = 80,
      tabHeight = 30,
      floorLength = 1;
    // 如果是单列显示
    if (this.cardType == '0') {
      var length = 0;
      for (var k in this.itemDetail.room) {
        floorLength += 1;
        if (this.itemDetail.room[k] && length < this.itemDetail.room[k].length) {
          length = this.itemDetail.room[k].length;
        }
      }
      tabLength = length;
    }
    // 多列显示
    else {
      // 获取楼层
      for (var _k in this.itemDetail.room) {
        floorLength += 1;
      }
      for (var _k2 in key) {
        if (key[_k2] == this.ledConfInfo[_k2]) {
          tabLength += this.itemDetail.room_max;
        }
      }
    }
    // 拖拽模式
    if (this.isDrag) {
      tabHeight = (document.body.offsetHeight - 140) / floorLength;
    }
    if ((document.body.offsetWidth - 235) / tabLength > 100) {
      tabWidth = (document.body.offsetWidth - 235) / tabLength;
    }
    // 单元格宽高计算完成
    // 如果存在设置的高度
    var __width = this.__width,
      __height = this.__height;
    if (this.itemDetail.height !== '' && this.ledinfo.is_banner == 0) {
      this.isShowscreen = false;
      __height = this.itemDetail.height;
    }
    // 如果默认值存在
    else if (this.height && !isNaN(this.height) && !this.isDrag && this.ledConfInfo.show_floor == 0) {
      this.isShowscreen = true;
      __height = this.height;
    }
    // 如果都不存在使用计算的高度
    else {
      this.isShowscreen = false;
      __height = tabHeight;
    }
    if (this.itemDetail.width !== '' && this.ledinfo.is_banner == 0) {
      this.isShowscreen = false;
      __width = this.itemDetail.width;
    } else if (this.width && !isNaN(this.width) && !this.isDrag && this.ledConfInfo.show_floor == 0) {
      this.isShowscreen = true;
      __width = this.width;
    } else {
      this.isShowscreen = false;
      __width = tabWidth;
    }
    this.$emit("doGetCellWidth", {
      index: this.index,
      tabLength: tabLength,
      id: this.itemDetail.id,
      room_max: this.itemDetail.room_max,
      floor_num: this.itemDetail.floorSort.length
    });
    var parent = this.isDrag ? this.$parent : this.$parent.$parent.$parent.$parent;
    parent.boxWidth += parseInt((this.itemDetail.width || tabWidth) * tabLength + (this.itemDetail.width || tabWidth) * tabLength / ((_parent$houseRes = parent.houseRes) === null || _parent$houseRes === void 0 ? void 0 : _parent$houseRes.length));
  },
  methods: {
    addCommas: function addCommas() {
      var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var type = arguments.length > 1 ? arguments[1] : undefined;
      if (val == 0) return '--';
      if (type == 'unit') {
        //单价
        if (this.waitJson.unit_isround) {
          //取整
          val = Math.round(val);
        }
        while (/(\d+)(\d{3})/.test(val.toString())) {
          val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
        }
        return val;
      } else {
        if (this.waitJson.total_isround) {
          //取整
          val = Math.round(val);
        }
        while (/(\d+)(\d{3})/.test(val.toString())) {
          val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
        }
        return val;
      }
    },
    /**
     * 双击事件
     * @param itemDetail
     * @param column
     * @param name
     * @param key
     */
    ondblClick: function ondblClick(itemDetail, column, name) {
      var key = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "column";
      if (this.isDrag) {
        itemDetail = JSON.parse(JSON.stringify(itemDetail));
        itemDetail.width = +getComputedStyle(document.getElementById("unit-box-" + this.itemDetail.id), false).getPropertyValue('--width');
        itemDetail.height = +getComputedStyle(document.getElementById("unit-box-" + this.itemDetail.id), false).getPropertyValue('--height');
        if (column) {
          if (this.allSize && this.allSize[column]) {
            itemDetail.columnWidth = this.allSize[column].width;
            itemDetail.columnHeight = this.allSize[column].height;
          }
        }
        this.$emit('ondblClickDialog', key, itemDetail.build_name + itemDetail.unit_room + "  " + name + '  配置', itemDetail, column);
      }
    },
    /**
     * 获取颜色配置
     * @param column
     * @param key
     */
    doGetStyle: function doGetStyle(column, key) {
      var background_color = "",
        color = "",
        width = "",
        height = "";
      // 判断当前楼栋单元是否存在颜色配置
      if (this.allColor && this.allColor[this.itemDetail.id]) {
        // 判断是否存在列颜色并且不是非售，已售，边框，并且key存在
        if (this.allColor[this.itemDetail.id][column] && key !== "notForSale_" && key !== "sold_" && key !== "border_" && key && column) {
          background_color = this.allColor[this.itemDetail.id][column][key + "background_color"];
          color = this.allColor[this.itemDetail.id][column][key + "color"];
        } else {
          background_color = this.allColor[this.itemDetail.id][key + "background_color"];
          color = this.allColor[this.itemDetail.id][key + "color"];
        }
      }
      if (key !== "notForSale_" && key !== "sold_" && column && this.allSize && this.allSize[column]) {
        var __width = +this.allSize.width;
        var __height = +this.allSize.height;
        if (document.getElementById("unit-box-" + this.itemDetail.id)) {
          __width = +getComputedStyle(document.getElementById("unit-box-" + this.itemDetail.id), false).getPropertyValue('--width');
          __height = +getComputedStyle(document.getElementById("unit-box-" + this.itemDetail.id), false).getPropertyValue('--height');
        }
        if (__width !== this.allSize[column].width) {
          width = this.allSize[column].width;
        }
        if (__height !== this.allSize[column].height) {
          height = this.allSize[column].height;
        }
      }
      return {
        "backgroundColor": background_color,
        "color": color,
        "--width": width,
        "--height": height
      };
    },
    onSelectIds: function onSelectIds(value) {
      this.$emit("onSelectIds", this.itemDetail.id, value);
    }
  }
};