var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "enter-box" }, [
    _c("div", { staticClass: "ThenNumber-content" }, [
      _c(
        "div",
        { staticClass: "input-content" },
        _vm._l(_vm.number, function (item, index) {
          return _c(
            "div",
            { key: item, staticClass: "input-box", style: _vm.bfb },
            [
              _c("el-input", {
                ref: "input" + item,
                refInFor: true,
                staticClass: "input-style2",
                attrs: {
                  type: "number",
                  disabled: _vm.disabledInput,
                  maxlength: "2",
                  minlength: "1",
                },
                on: {
                  input: function ($event) {
                    return _vm.inputValue(_vm.value[item], item)
                  },
                },
                model: {
                  value: _vm.value[item],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item, $$v)
                  },
                  expression: "value[item]",
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm.code_status
        ? _c("p", { staticClass: "tisp" }, [_vm._v("重复号码，请重新摇号")])
        : _vm._e(),
    ]),
    !_vm.disabledInput
      ? _c("div", { staticClass: "enter-btn", on: { click: _vm.confirm } }, [
          _c("span", [_vm._v("确定")]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }