var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "div",
        {
          staticClass: "setLedActivityEnd",
          staticStyle: {
            width: "100vw",
            height: "100vh",
            overflow: "hidden",
            "user-select": "none",
            position: "absolute",
            "z-index": "1000",
          },
          style: { "background-color": _vm.data.config.background.color },
        },
        [
          _vm.data.type === 1
            ? _c(
                "div",
                {
                  staticStyle: { position: "relative" },
                  style: _vm.doCssPushPX(
                    _vm.data.htmlCss.background,
                    "background"
                  ),
                  on: {
                    mousedown: function ($event) {
                      $event.stopPropagation()
                      return _vm.onCursor_background($event)
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: {
                      position: "absolute",
                      "z-index": "0",
                      width: "100%",
                      height: "100%",
                      "pointer-events": "none",
                    },
                    attrs: {
                      src: _vm.replaceImgshow(_vm.data.config.background.src),
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.data.config.congratulate.show,
                          expression: "data.config.congratulate.show",
                        },
                      ],
                      staticStyle: { position: "absolute" },
                      style: _vm.doCssPushPX(
                        _vm.data.htmlCss.congratulate,
                        "congratulate"
                      ),
                      on: {
                        mousedown: function ($event) {
                          $event.stopPropagation()
                          return _vm.onCursor_congratulate_XY($event)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "100%",
                          "pointer-events": "none",
                        },
                        attrs: {
                          id: "congratulate",
                          src: _vm.replaceImgshow(
                            _vm.data.config.congratulate.src
                          ),
                        },
                      }),
                    ]
                  ),
                  _vm.data.config.projectName.show
                    ? _c(
                        "div",
                        {
                          class: _vm.data.config.projectName.font,
                          staticStyle: { position: "absolute", "z-index": "1" },
                          style: _vm.doCssPushPX(
                            _vm.data.htmlCss.projectName,
                            "projectName"
                          ),
                          on: {
                            mousedown: function ($event) {
                              $event.stopPropagation()
                              return _vm.onCursor_projectName($event)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.project.name) + " ")]
                      )
                    : _vm._e(),
                  (
                    _vm.saleText === 0
                      ? _vm.data.config.saledNum.endShow
                      : _vm.data.config.saledNum.sellOutShow
                  )
                    ? [
                        !_vm.data.config.saledNum.isSeparate
                          ? _c(
                              "div",
                              {
                                class: _vm.data.config.saledNum.font,
                                staticStyle: { position: "absolute" },
                                style: _vm.doCssPushPX(
                                  _vm.data.htmlCss.saledNum,
                                  "saledNum"
                                ),
                                on: {
                                  mousedown: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onCursor_saledNum($event)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.saleText === 0
                                        ? _vm.data.config.saledNum.saleText
                                        : _vm.data.config.saledNum.sellOutText
                                    ) +
                                    _vm._s(_vm.saledNum) +
                                    "套 "
                                ),
                              ]
                            )
                          : [
                              _c(
                                "div",
                                {
                                  class: _vm.data.config.saledNum.font,
                                  staticStyle: { position: "absolute" },
                                  style: _vm.doCssPushPX(
                                    _vm.data.htmlCss.saledNum_text,
                                    "saledNum_text"
                                  ),
                                  on: {
                                    mousedown: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onCursor_saledNum_text($event)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.saleText === 0
                                          ? _vm.data.config.saledNum.saleText
                                          : _vm.data.config.saledNum.sellOutText
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  class: _vm.data.config.saledNum.font,
                                  staticStyle: { position: "absolute" },
                                  style: _vm.doCssPushPX(
                                    _vm.data.htmlCss.saledNum_num,
                                    "saledNum_num"
                                  ),
                                  on: {
                                    mousedown: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onCursor_saledNum_num($event)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.saledNum) + "套 ")]
                              ),
                            ],
                      ]
                    : _vm._e(),
                  (
                    _vm.saleText === 0
                      ? _vm.data.config.saledPrice.endShow
                      : _vm.data.config.saledPrice.sellOutShow
                  )
                    ? [
                        !_vm.data.config.saledPrice.isSeparate
                          ? _c(
                              "div",
                              {
                                class: _vm.data.config.saledPrice.font,
                                staticStyle: { position: "absolute" },
                                style: _vm.doCssPushPX(
                                  _vm.data.htmlCss.saledPrice,
                                  "saledPrice"
                                ),
                                on: {
                                  mousedown: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onCursor_saledPrice($event)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.data.config.saledPrice.saleText
                                    ) +
                                    _vm._s(_vm.saledPrice) +
                                    "元 "
                                ),
                              ]
                            )
                          : [
                              _c(
                                "div",
                                {
                                  class: _vm.data.config.saledPrice.font,
                                  staticStyle: { position: "absolute" },
                                  style: _vm.doCssPushPX(
                                    _vm.data.htmlCss.saledPrice_text,
                                    "saledPrice_text"
                                  ),
                                  on: {
                                    mousedown: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onCursor_saledPrice_text(
                                        $event
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.data.config.saledPrice.saleText
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  class: _vm.data.config.saledPrice.font,
                                  staticStyle: { position: "absolute" },
                                  style: _vm.doCssPushPX(
                                    _vm.data.htmlCss.saledPrice_num,
                                    "saledPrice_num"
                                  ),
                                  on: {
                                    mousedown: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onCursor_saledPrice_num($event)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.saledPrice) + "元 ")]
                              ),
                            ],
                      ]
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticStyle: { position: "absolute" },
                      style: _vm.doCssPushPX(
                        _vm.data.htmlCss.powerBy,
                        "powerBy"
                      ),
                    },
                    [_vm._v(_vm._s(_vm.powerBy) + " ")]
                  ),
                  (
                    _vm.saleText === 0
                      ? _vm.data.config.custom.endShow
                      : _vm.data.config.custom.sellOutShow
                  )
                    ? _c(
                        "div",
                        {
                          class: _vm.data.config.custom.font,
                          staticStyle: { position: "absolute" },
                          style: _vm.doCssPushPX(
                            _vm.data.htmlCss.custom,
                            "custom"
                          ),
                          on: {
                            mousedown: function ($event) {
                              $event.stopPropagation()
                              return _vm.onCursor_custom($event)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.saleText === 0
                                ? _vm.data.config.custom.endText
                                : _vm.data.config.custom.sellOutText
                            ) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.isSet
                    ? [
                        _c("div", {
                          staticClass: "cursor_right_bottom",
                          on: {
                            mousedown: function ($event) {
                              $event.stopPropagation()
                              return _vm.onCursor_right_bottom($event)
                            },
                          },
                        }),
                        _c("div", {
                          staticClass: "cursor_right",
                          on: {
                            mousedown: function ($event) {
                              $event.stopPropagation()
                              return _vm.onCursor_right($event)
                            },
                          },
                        }),
                        _c("div", {
                          staticClass: "cursor_bottom",
                          on: {
                            mousedown: function ($event) {
                              $event.stopPropagation()
                              return _vm.onCursor_bottom($event)
                            },
                          },
                        }),
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.data.config.congratulate.show,
                              expression: "data.config.congratulate.show",
                            },
                          ],
                          staticClass: "cursor_congratulate",
                          style: {
                            left:
                              _vm.data.htmlCss.congratulate.left +
                              _vm.data.htmlCss.congratulate.width -
                              30 +
                              "px",
                            top:
                              _vm.data.htmlCss.congratulate.top +
                              _vm.data.htmlCss.congratulate.width /
                                _vm.congratulateScale -
                              30 +
                              "px",
                          },
                          on: {
                            mousedown: function ($event) {
                              $event.stopPropagation()
                              return _vm.onCursor_congratulate_size($event)
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm.data.type === 2
            ? _c(
                "div",
                {
                  staticStyle: { position: "relative" },
                  style: _vm.doCssPushPX(
                    _vm.data.htmlCss.background,
                    "background"
                  ),
                  on: {
                    mousedown: function ($event) {
                      $event.stopPropagation()
                      return _vm.onCursor_background($event)
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: {
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      "z-index": "0",
                      "pointer-events": "none",
                    },
                    attrs: {
                      src: _vm.replaceImgshow(_vm.data.config.background.src),
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.data.config.congratulate.show,
                          expression: "data.config.congratulate.show",
                        },
                      ],
                      staticStyle: { position: "absolute" },
                      style: _vm.doCssPushPX(
                        _vm.data.htmlCss.congratulate,
                        "congratulate"
                      ),
                      on: {
                        mousedown: function ($event) {
                          $event.stopPropagation()
                          return _vm.onCursor_congratulate_XY($event)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "100%",
                          "pointer-events": "none",
                        },
                        attrs: {
                          id: "congratulate",
                          src: _vm.replaceImgshow(
                            _vm.data.config.congratulate.src
                          ),
                        },
                      }),
                    ]
                  ),
                  _vm.data.config.projectName.show
                    ? _c(
                        "div",
                        {
                          staticStyle: { position: "absolute", "z-index": "1" },
                          style: _vm.doCssPushPX(
                            _vm.data.htmlCss.projectName,
                            "projectName"
                          ),
                          on: {
                            mousedown: function ($event) {
                              $event.stopPropagation()
                              return _vm.onCursor_projectName($event)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              width: "100%",
                              height: "100%",
                              "pointer-events": "none",
                            },
                            attrs: {
                              src: require("@/assets/image/setLedActivityEnd/projectBorder.png"),
                            },
                          }),
                          _c(
                            "div",
                            {
                              class: _vm.data.config.projectName.font,
                              staticStyle: {
                                width: "100%",
                                "text-align": "center",
                                color: "white",
                                "margin-top": "-112px",
                              },
                              style: {
                                "line-height":
                                  _vm.data.htmlCss.projectName.height + "px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.project.name) + " ")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  (
                    _vm.saleText === 0
                      ? _vm.data.config.saledNum.endShow
                      : _vm.data.config.saledNum.sellOutShow
                  )
                    ? [
                        !_vm.data.config.saledNum.isSeparate
                          ? _c(
                              "div",
                              {
                                class: _vm.data.config.saledNum.font,
                                staticStyle: {
                                  display: "flex",
                                  position: "absolute",
                                },
                                style: _vm.doCssPushPX(
                                  _vm.data.htmlCss.saledNum,
                                  "saledNum"
                                ),
                                on: {
                                  mousedown: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onCursor_saledNum($event)
                                  },
                                },
                              },
                              [
                                (
                                  _vm.saleText === 0
                                    ? _vm.data.config.saledNum.saleText
                                    : _vm.data.config.saledNum.sellOutText
                                )
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "40px",
                                          "font-size": "18px",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "40px",
                                            height: "120px",
                                            "pointer-events": "none",
                                          },
                                          attrs: {
                                            src: require("@/assets/image/setLedActivityEnd/left.png"),
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "30px",
                                              "min-height": "100px",
                                              "padding-left": "10px",
                                              "text-align": "center",
                                              "border-radius": "6px",
                                              "font-size": "18px",
                                              color: "white",
                                              "margin-top": "-115px",
                                              display: "flex",
                                              "flex-direction": "column",
                                              "justify-content": "center",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.saleText === 0
                                                    ? _vm.data.config.saledNum
                                                        .saleText
                                                    : _vm.data.config.saledNum
                                                        .sellOutText
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-top": "5px",
                                      "margin-left": "5px",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        style: {
                                          fontSize:
                                            _vm.data.htmlCss.saledNum.fontSize,
                                          color:
                                            _vm.data.htmlCss.saledNum.color,
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.saledNum))]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          color: "#FFE153",
                                          "font-size": "40px",
                                        },
                                      },
                                      [_vm._v("套")]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : [
                              _c(
                                "div",
                                {
                                  class: _vm.data.config.saledNum.font,
                                  staticStyle: {
                                    display: "flex",
                                    position: "absolute",
                                  },
                                  style: _vm.doCssPushPX(
                                    _vm.data.htmlCss.saledNum_text,
                                    "saledNum_text"
                                  ),
                                  on: {
                                    mousedown: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onCursor_saledNum_text($event)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "40px",
                                        "font-size": "18px",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "40px",
                                          height: "120px",
                                          "pointer-events": "none",
                                        },
                                        attrs: {
                                          src: require("@/assets/image/setLedActivityEnd/left.png"),
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "30px",
                                            "min-height": "100px",
                                            "padding-left": "10px",
                                            "text-align": "center",
                                            "border-radius": "6px",
                                            "font-size": "18px",
                                            color: "white",
                                            "margin-top": "-115px",
                                            display: "flex",
                                            "flex-direction": "column",
                                            "justify-content": "center",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.saleText === 0
                                                  ? _vm.data.config.saledNum
                                                      .saleText
                                                  : _vm.data.config.saledNum
                                                      .sellOutText
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  class: _vm.data.config.saledNum.font,
                                  staticStyle: {
                                    display: "flex",
                                    position: "absolute",
                                  },
                                  style: _vm.doCssPushPX(
                                    _vm.data.htmlCss.saledNum_num,
                                    "saledNum_num"
                                  ),
                                  on: {
                                    mousedown: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onCursor_saledNum_num($event)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-top": "5px",
                                        "margin-left": "5px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          style: {
                                            fontSize:
                                              _vm.data.htmlCss.saledNum
                                                .fontSize,
                                            color:
                                              _vm.data.htmlCss.saledNum.color,
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.saledNum))]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "6px",
                                            color: "#FFE153",
                                            "font-size": "40px",
                                          },
                                        },
                                        [_vm._v("套")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                      ]
                    : _vm._e(),
                  (
                    _vm.saleText === 0
                      ? _vm.data.config.saledPrice.endShow
                      : _vm.data.config.saledPrice.sellOutShow
                  )
                    ? [
                        !_vm.data.config.saledPrice.isSeparate
                          ? _c(
                              "div",
                              {
                                class: _vm.data.config.saledPrice.font,
                                staticStyle: {
                                  display: "flex",
                                  position: "absolute",
                                },
                                style: _vm.doCssPushPX(
                                  _vm.data.htmlCss.saledPrice,
                                  "saledPrice"
                                ),
                                on: {
                                  mousedown: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onCursor_saledPrice($event)
                                  },
                                },
                              },
                              [
                                _vm.data.config.saledPrice.saleText
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "40px",
                                          "font-size": "18px",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "40px",
                                            height: "120px",
                                            "pointer-events": "none",
                                          },
                                          attrs: {
                                            src: require("@/assets/image/setLedActivityEnd/left.png"),
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "30px",
                                              "min-height": "100px",
                                              "padding-left": "10px",
                                              "text-align": "center",
                                              "border-radius": "6px",
                                              "font-size": "18px",
                                              color: "white",
                                              "margin-top": "-115px",
                                              display: "flex",
                                              "flex-direction": "column",
                                              "justify-content": "center",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.data.config.saledPrice
                                                    .saleText
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-top": "5px",
                                      "margin-left": "5px",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        style: {
                                          fontSize:
                                            _vm.data.htmlCss.saledPrice
                                              .fontSize,
                                          color:
                                            _vm.data.htmlCss.saledPrice.color,
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.saledPrice))]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          color: "#FFE153",
                                          "font-size": "40px",
                                        },
                                      },
                                      [_vm._v("元")]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : [
                              _vm.data.config.saledPrice.saleText
                                ? _c(
                                    "div",
                                    {
                                      class: _vm.data.config.saledPrice.font,
                                      staticStyle: {
                                        display: "flex",
                                        position: "absolute",
                                      },
                                      style: _vm.doCssPushPX(
                                        _vm.data.htmlCss.saledPrice_text,
                                        "saledPrice_text"
                                      ),
                                      on: {
                                        mousedown: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.onCursor_saledPrice_text(
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "40px",
                                            "font-size": "18px",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "40px",
                                              height: "120px",
                                              "pointer-events": "none",
                                            },
                                            attrs: {
                                              src: require("@/assets/image/setLedActivityEnd/left.png"),
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "30px",
                                                "min-height": "100px",
                                                "padding-left": "10px",
                                                "text-align": "center",
                                                "border-radius": "6px",
                                                "font-size": "18px",
                                                color: "white",
                                                "margin-top": "-115px",
                                                display: "flex",
                                                "flex-direction": "column",
                                                "justify-content": "center",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.data.config.saledPrice
                                                      .saleText
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  class: _vm.data.config.saledPrice.font,
                                  staticStyle: {
                                    display: "flex",
                                    position: "absolute",
                                  },
                                  style: _vm.doCssPushPX(
                                    _vm.data.htmlCss.saledPrice_num,
                                    "saledPrice_num"
                                  ),
                                  on: {
                                    mousedown: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onCursor_saledPrice_num($event)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-top": "5px",
                                        "margin-left": "5px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          style: {
                                            fontSize:
                                              _vm.data.htmlCss.saledPrice
                                                .fontSize,
                                            color:
                                              _vm.data.htmlCss.saledPrice.color,
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.saledPrice))]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "6px",
                                            color: "#FFE153",
                                            "font-size": "40px",
                                          },
                                        },
                                        [_vm._v("元")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                      ]
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticStyle: { position: "absolute" },
                      style: _vm.doCssPushPX(
                        _vm.data.htmlCss.powerBy,
                        "powerBy"
                      ),
                    },
                    [_vm._v(_vm._s(_vm.powerBy) + " ")]
                  ),
                  (
                    _vm.saleText === 0
                      ? _vm.data.config.custom.endShow
                      : _vm.data.config.custom.sellOutShow
                  )
                    ? _c(
                        "div",
                        {
                          class: _vm.data.config.custom.font,
                          staticStyle: { position: "absolute" },
                          style: _vm.doCssPushPX(
                            _vm.data.htmlCss.custom,
                            "custom"
                          ),
                          on: {
                            mousedown: function ($event) {
                              $event.stopPropagation()
                              return _vm.onCursor_custom($event)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.saleText === 0
                                ? _vm.data.config.custom.endText
                                : _vm.data.config.custom.sellOutText
                            ) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.isSet
                    ? [
                        _c("div", {
                          staticClass: "cursor_right_bottom",
                          on: {
                            mousedown: function ($event) {
                              $event.stopPropagation()
                              return _vm.onCursor_right_bottom($event)
                            },
                          },
                        }),
                        _c("div", {
                          staticClass: "cursor_right",
                          on: {
                            mousedown: function ($event) {
                              $event.stopPropagation()
                              return _vm.onCursor_right($event)
                            },
                          },
                        }),
                        _c("div", {
                          staticClass: "cursor_bottom",
                          on: {
                            mousedown: function ($event) {
                              $event.stopPropagation()
                              return _vm.onCursor_bottom($event)
                            },
                          },
                        }),
                        _c("div", {
                          staticClass: "cursor_congratulate",
                          style: {
                            left:
                              _vm.data.htmlCss.congratulate.left +
                              _vm.data.htmlCss.congratulate.width -
                              30 +
                              "px",
                            top:
                              _vm.data.htmlCss.congratulate.top +
                              _vm.data.htmlCss.congratulate.width /
                                _vm.congratulateScale -
                              30 +
                              "px",
                          },
                          on: {
                            mousedown: function ($event) {
                              $event.stopPropagation()
                              return _vm.onCursor_congratulate_size($event)
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.isSet
            ? _c(
                "div",
                {
                  staticClass: "setting-control-icon",
                  on: {
                    click: function ($event) {
                      _vm.dialogShow = true
                    },
                  },
                },
                [
                  _c("svg-icon", {
                    attrs: {
                      "class-name": "setting-control",
                      "icon-class": "setting-control",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-drawer",
            {
              attrs: {
                modal: false,
                visible: _vm.dialogShow,
                "with-header": false,
                size: "400px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogShow = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "drawer-body" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "10px",
                            "background-color": "rgb(245,245,245)",
                            "font-size": "20px",
                            "font-weight": "bold",
                          },
                        },
                        [_vm._v(" 页面配置 ")]
                      ),
                      [
                        _c(
                          "div",
                          { staticClass: "dialog_title" },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _vm._v(" 背景图 "),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 12 },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      "font-size": "14px",
                                      color: "#999999",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.upSuccess({
                                          Data: {
                                            url: _vm.defaultData.imgSrc.bg,
                                          },
                                        })
                                      },
                                    },
                                  },
                                  [_vm._v("使用默认图")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("img-up", { on: { upSuccess: _vm.upBackground } }),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("span", { staticClass: "dialog-name" }, [
                                _vm._v("整体位置"),
                              ]),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value: _vm.data.config.background.center,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.background,
                                          "center",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.background.center",
                                    },
                                  },
                                  [_vm._v("居中")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: false },
                                    model: {
                                      value: _vm.data.config.background.center,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.background,
                                          "center",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.background.center",
                                    },
                                  },
                                  [_vm._v("自定义")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("span", { staticClass: "dialog-name" }, [
                                _vm._v("背景颜色"),
                              ]),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  height: "40px",
                                },
                                attrs: { span: 16 },
                              },
                              [
                                _c("el-color-picker", {
                                  attrs: { "show-alpha": "" },
                                  model: {
                                    value: _vm.data.config.background.color,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.config.background,
                                        "color",
                                        $$v
                                      )
                                    },
                                    expression: "data.config.background.color",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("span", { staticClass: "dialog-name" }, [
                                _vm._v("模拟状态"),
                              ]),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 0 },
                                    model: {
                                      value: _vm.saleText,
                                      callback: function ($$v) {
                                        _vm.saleText = $$v
                                      },
                                      expression: "saleText",
                                    },
                                  },
                                  [_vm._v("活动结束")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 1 },
                                    model: {
                                      value: _vm.saleText,
                                      callback: function ($$v) {
                                        _vm.saleText = $$v
                                      },
                                      expression: "saleText",
                                    },
                                  },
                                  [_vm._v("售罄")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      [
                        _c(
                          "div",
                          { staticClass: "dialog_title" },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _vm._v(" 恭贺 "),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 12 },
                              },
                              [
                                _vm.data.config.congratulate.src
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: !_vm.data.config.congratulate
                                            .show
                                            ? "隐藏"
                                            : "显示",
                                          placement: "bottom",
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          staticStyle: { "font-size": "22px" },
                                          attrs: {
                                            "class-name": "color-hui",
                                            "icon-class": !_vm.data.config
                                              .congratulate.show
                                              ? "eye"
                                              : "eye-open",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.data.config.congratulate.show =
                                                !_vm.data.config.congratulate
                                                  .show
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "14px",
                                          color: "#999999",
                                        },
                                      },
                                      [_vm._v("请先上传图片")]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("img-up", {
                          attrs: { showText: false },
                          on: { upSuccess: _vm.upCongratulate },
                        }),
                      ],
                      [
                        _c(
                          "div",
                          { staticClass: "dialog_title" },
                          [
                            _c("el-col", { attrs: { span: 14 } }, [
                              _vm._v(" 项目名称 "),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 10 },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: !_vm.data.config.projectName.show
                                        ? "隐藏"
                                        : "显示",
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      staticStyle: { "font-size": "22px" },
                                      attrs: {
                                        "class-name": "color-hui",
                                        "icon-class": !_vm.data.config
                                          .projectName.show
                                          ? "eye"
                                          : "eye-open",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.data.config.projectName.show =
                                            !_vm.data.config.projectName.show
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.data.type === 1
                          ? _c(
                              "div",
                              { staticClass: "dialog_content" },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _vm._v(" 整体排向 "),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          placement: "top",
                                          content: "字体排放方式",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "tips el-icon-question",
                                          staticStyle: { "margin-left": "5px" },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    attrs: { span: 16 },
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: true },
                                        model: {
                                          value:
                                            _vm.data.config.projectName
                                              .isTransverse,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data.config.projectName,
                                              "isTransverse",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.config.projectName.isTransverse",
                                        },
                                      },
                                      [_vm._v("横向")]
                                    ),
                                    _c(
                                      "el-radio",
                                      {
                                        staticStyle: {
                                          "padding-right": "11px",
                                        },
                                        attrs: { label: false },
                                        model: {
                                          value:
                                            _vm.data.config.projectName
                                              .isTransverse,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data.config.projectName,
                                              "isTransverse",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.config.projectName.isTransverse",
                                        },
                                      },
                                      [_vm._v("竖向 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 字体 "),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.data.config.projectName.font,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.projectName,
                                          "font",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.projectName.font",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "微软雅黑", value: "" },
                                    }),
                                    _vm._l(_vm.fonts, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 字号 "),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c("el-input-number", {
                                  staticStyle: { width: "100%" },
                                  attrs: { min: 12 },
                                  model: {
                                    value:
                                      _vm.data.htmlCss.projectName.fontSize,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.htmlCss.projectName,
                                        "fontSize",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "data.htmlCss.projectName.fontSize",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 颜色 "),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  height: "40px",
                                },
                                attrs: { span: 16 },
                              },
                              [
                                _c("el-color-picker", {
                                  model: {
                                    value: _vm.data.htmlCss.projectName.color,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.htmlCss.projectName,
                                        "color",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "data.htmlCss.projectName.color",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.data.type === 1
                          ? _c(
                              "div",
                              { staticClass: "dialog_content" },
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _vm._v(" 字体居中 "),
                                ]),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    attrs: { span: 16 },
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: true },
                                        model: {
                                          value:
                                            _vm.data.config.projectName.center,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data.config.projectName,
                                              "center",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.config.projectName.center",
                                        },
                                      },
                                      [_vm._v("居中")]
                                    ),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: false },
                                        model: {
                                          value:
                                            _vm.data.config.projectName.center,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data.config.projectName,
                                              "center",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.config.projectName.center",
                                        },
                                      },
                                      [_vm._v("自定义")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      [
                        _c("div", { staticClass: "dialog_title" }, [
                          _vm._v(" 销售数量 "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _vm._v(" 是否分离 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "top",
                                      content:
                                        "设置为“分离”时销售文字和售罄文字将会和数量分开，可以单独进行拖动位置！",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "tips el-icon-question",
                                      staticStyle: { "margin-left": "5px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value:
                                        _vm.data.config.saledNum.isSeparate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledNum,
                                          "isSeparate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledNum.isSeparate",
                                    },
                                  },
                                  [_vm._v("分离")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: false },
                                    model: {
                                      value:
                                        _vm.data.config.saledNum.isSeparate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledNum,
                                          "isSeparate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledNum.isSeparate",
                                    },
                                  },
                                  [_vm._v("不分离")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.data.config.saledNum.isSeparate &&
                                  _vm.data.type === 1,
                                expression:
                                  "!data.config.saledNum.isSeparate && data.type === 1",
                              },
                            ],
                            staticClass: "dialog_content",
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _vm._v(" 整体排向 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "top",
                                      content: "字体排放方式",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "tips el-icon-question",
                                      staticStyle: { "margin-left": "5px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value:
                                        _vm.data.config.saledNum.isTransverse
                                          .all,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledNum.isTransverse,
                                          "all",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledNum.isTransverse.all",
                                    },
                                  },
                                  [_vm._v("横向")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    staticStyle: { "padding-right": "11px" },
                                    attrs: { label: false },
                                    model: {
                                      value:
                                        _vm.data.config.saledNum.isTransverse
                                          .all,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledNum.isTransverse,
                                          "all",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledNum.isTransverse.all",
                                    },
                                  },
                                  [_vm._v("竖向 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.data.config.saledNum.isSeparate &&
                                  _vm.data.type === 1,
                                expression:
                                  "data.config.saledNum.isSeparate && data.type === 1",
                              },
                            ],
                            staticClass: "dialog_content",
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _vm._v(" 文字排向 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "top",
                                      content: "字体排放方式",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "tips el-icon-question",
                                      staticStyle: { "margin-left": "5px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value:
                                        _vm.data.config.saledNum.isTransverse
                                          .text,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledNum.isTransverse,
                                          "text",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledNum.isTransverse.text",
                                    },
                                  },
                                  [_vm._v("横向")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    staticStyle: { "padding-right": "11px" },
                                    attrs: { label: false },
                                    model: {
                                      value:
                                        _vm.data.config.saledNum.isTransverse
                                          .text,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledNum.isTransverse,
                                          "text",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledNum.isTransverse.text",
                                    },
                                  },
                                  [_vm._v("竖向 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.data.config.saledNum.isSeparate,
                                expression: "data.config.saledNum.isSeparate",
                              },
                            ],
                            staticClass: "dialog_content",
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _vm._v(" 数量排向 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "top",
                                      content: "字体排放方式",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "tips el-icon-question",
                                      staticStyle: { "margin-left": "5px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value:
                                        _vm.data.config.saledNum.isTransverse
                                          .num,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledNum.isTransverse,
                                          "num",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledNum.isTransverse.num",
                                    },
                                  },
                                  [_vm._v("横向")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    staticStyle: { "padding-right": "11px" },
                                    attrs: { label: false },
                                    model: {
                                      value:
                                        _vm.data.config.saledNum.isTransverse
                                          .num,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledNum.isTransverse,
                                          "num",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledNum.isTransverse.num",
                                    },
                                  },
                                  [_vm._v("竖向 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 活动结束 "),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value: _vm.data.config.saledNum.endShow,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledNum,
                                          "endShow",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledNum.endShow",
                                    },
                                  },
                                  [_vm._v("显示")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: false },
                                    model: {
                                      value: _vm.data.config.saledNum.endShow,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledNum,
                                          "endShow",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledNum.endShow",
                                    },
                                  },
                                  [_vm._v("不显示")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 活动售罄 "),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value:
                                        _vm.data.config.saledNum.sellOutShow,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledNum,
                                          "sellOutShow",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledNum.sellOutShow",
                                    },
                                  },
                                  [_vm._v("显示")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: false },
                                    model: {
                                      value:
                                        _vm.data.config.saledNum.sellOutShow,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledNum,
                                          "sellOutShow",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledNum.sellOutShow",
                                    },
                                  },
                                  [_vm._v("不显示")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 字体 "),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.data.config.saledNum.font,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledNum,
                                          "font",
                                          $$v
                                        )
                                      },
                                      expression: "data.config.saledNum.font",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "微软雅黑", value: "" },
                                    }),
                                    _vm._l(_vm.fonts, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 字号 "),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c("el-input-number", {
                                  staticStyle: { width: "100%" },
                                  attrs: { min: 12 },
                                  model: {
                                    value: _vm.data.htmlCss.saledNum.fontSize,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.htmlCss.saledNum,
                                        "fontSize",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "data.htmlCss.saledNum.fontSize",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 颜色 "),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  height: "40px",
                                },
                                attrs: { span: 16 },
                              },
                              [
                                _c("el-color-picker", {
                                  model: {
                                    value: _vm.data.htmlCss.saledNum.color,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.htmlCss.saledNum,
                                        "color",
                                        $$v
                                      )
                                    },
                                    expression: "data.htmlCss.saledNum.color",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 销售文字 "),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.data.config.saledNum.saleText,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.config.saledNum,
                                        "saleText",
                                        $$v
                                      )
                                    },
                                    expression: "data.config.saledNum.saleText",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 售罄文字 "),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.data.config.saledNum.sellOutText,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.config.saledNum,
                                        "sellOutText",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "data.config.saledNum.sellOutText",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 字体居中 "),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value: _vm.data.config.saledNum.center,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledNum,
                                          "center",
                                          $$v
                                        )
                                      },
                                      expression: "data.config.saledNum.center",
                                    },
                                  },
                                  [_vm._v("居中")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: false },
                                    model: {
                                      value: _vm.data.config.saledNum.center,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledNum,
                                          "center",
                                          $$v
                                        )
                                      },
                                      expression: "data.config.saledNum.center",
                                    },
                                  },
                                  [_vm._v("自定义")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      [
                        _c("div", { staticClass: "dialog_title" }, [
                          _vm._v(" 销售金额 "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _vm._v(" 是否分离 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "top",
                                      content:
                                        "设置为“分离”时销售文字和售罄文字将会和数量分开，可以单独进行拖动位置！",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "tips el-icon-question",
                                      staticStyle: { "margin-left": "5px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value:
                                        _vm.data.config.saledPrice.isSeparate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledPrice,
                                          "isSeparate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledPrice.isSeparate",
                                    },
                                  },
                                  [_vm._v("分离")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: false },
                                    model: {
                                      value:
                                        _vm.data.config.saledPrice.isSeparate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledPrice,
                                          "isSeparate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledPrice.isSeparate",
                                    },
                                  },
                                  [_vm._v("不分离")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.data.config.saledPrice.isSeparate &&
                                  _vm.data.type === 1,
                                expression:
                                  "!data.config.saledPrice.isSeparate && data.type === 1",
                              },
                            ],
                            staticClass: "dialog_content",
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _vm._v(" 整体排向 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "top",
                                      content: "字体排放方式",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "tips el-icon-question",
                                      staticStyle: { "margin-left": "5px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value:
                                        _vm.data.config.saledPrice.isTransverse
                                          .all,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledPrice
                                            .isTransverse,
                                          "all",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledPrice.isTransverse.all",
                                    },
                                  },
                                  [_vm._v("横向")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    staticStyle: { "padding-right": "11px" },
                                    attrs: { label: false },
                                    model: {
                                      value:
                                        _vm.data.config.saledPrice.isTransverse
                                          .all,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledPrice
                                            .isTransverse,
                                          "all",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledPrice.isTransverse.all",
                                    },
                                  },
                                  [_vm._v("竖向 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.data.config.saledPrice.isSeparate &&
                                  _vm.data.type === 1,
                                expression:
                                  "data.config.saledPrice.isSeparate && data.type === 1",
                              },
                            ],
                            staticClass: "dialog_content",
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _vm._v(" 文字排向 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "top",
                                      content: "字体排放方式",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "tips el-icon-question",
                                      staticStyle: { "margin-left": "5px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value:
                                        _vm.data.config.saledPrice.isTransverse
                                          .text,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledPrice
                                            .isTransverse,
                                          "text",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledPrice.isTransverse.text",
                                    },
                                  },
                                  [_vm._v("横向")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    staticStyle: { "padding-right": "11px" },
                                    attrs: { label: false },
                                    model: {
                                      value:
                                        _vm.data.config.saledPrice.isTransverse
                                          .text,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledPrice
                                            .isTransverse,
                                          "text",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledPrice.isTransverse.text",
                                    },
                                  },
                                  [_vm._v("竖向 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.data.config.saledPrice.isSeparate,
                                expression: "data.config.saledPrice.isSeparate",
                              },
                            ],
                            staticClass: "dialog_content",
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _vm._v(" 数量排向 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "top",
                                      content: "字体排放方式",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "tips el-icon-question",
                                      staticStyle: { "margin-left": "5px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value:
                                        _vm.data.config.saledPrice.isTransverse
                                          .num,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledPrice
                                            .isTransverse,
                                          "num",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledPrice.isTransverse.num",
                                    },
                                  },
                                  [_vm._v("横向")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    staticStyle: { "padding-right": "11px" },
                                    attrs: { label: false },
                                    model: {
                                      value:
                                        _vm.data.config.saledPrice.isTransverse
                                          .num,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledPrice
                                            .isTransverse,
                                          "num",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledPrice.isTransverse.num",
                                    },
                                  },
                                  [_vm._v("竖向 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 活动结束 "),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value: _vm.data.config.saledPrice.endShow,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledPrice,
                                          "endShow",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledPrice.endShow",
                                    },
                                  },
                                  [_vm._v("显示")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: false },
                                    model: {
                                      value: _vm.data.config.saledPrice.endShow,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledPrice,
                                          "endShow",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledPrice.endShow",
                                    },
                                  },
                                  [_vm._v("不显示")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 活动售罄 "),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value:
                                        _vm.data.config.saledPrice.sellOutShow,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledPrice,
                                          "sellOutShow",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledPrice.sellOutShow",
                                    },
                                  },
                                  [_vm._v("显示")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: false },
                                    model: {
                                      value:
                                        _vm.data.config.saledPrice.sellOutShow,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledPrice,
                                          "sellOutShow",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledPrice.sellOutShow",
                                    },
                                  },
                                  [_vm._v("不显示")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 字体 "),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.data.config.saledPrice.font,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledPrice,
                                          "font",
                                          $$v
                                        )
                                      },
                                      expression: "data.config.saledPrice.font",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "微软雅黑", value: "" },
                                    }),
                                    _vm._l(_vm.fonts, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 字号 "),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c("el-input-number", {
                                  staticStyle: { width: "100%" },
                                  attrs: { min: 12 },
                                  model: {
                                    value: _vm.data.htmlCss.saledPrice.fontSize,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.htmlCss.saledPrice,
                                        "fontSize",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "data.htmlCss.saledPrice.fontSize",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 颜色 "),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  height: "40px",
                                },
                                attrs: { span: 16 },
                              },
                              [
                                _c("el-color-picker", {
                                  model: {
                                    value: _vm.data.htmlCss.saledPrice.color,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.htmlCss.saledPrice,
                                        "color",
                                        $$v
                                      )
                                    },
                                    expression: "data.htmlCss.saledPrice.color",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 销售金额文字 "),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.data.config.saledPrice.saleText,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.config.saledPrice,
                                        "saleText",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "data.config.saledPrice.saleText",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 字体居中 "),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value: _vm.data.config.saledPrice.center,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledPrice,
                                          "center",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledPrice.center",
                                    },
                                  },
                                  [_vm._v("居中")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: false },
                                    model: {
                                      value: _vm.data.config.saledPrice.center,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.saledPrice,
                                          "center",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.saledPrice.center",
                                    },
                                  },
                                  [_vm._v("自定义")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      [
                        _c("div", { staticClass: "dialog_title" }, [
                          _vm._v(" 自定义展示 "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 活动结束 "),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value: _vm.data.config.custom.endShow,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.custom,
                                          "endShow",
                                          $$v
                                        )
                                      },
                                      expression: "data.config.custom.endShow",
                                    },
                                  },
                                  [_vm._v("显示")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: false },
                                    model: {
                                      value: _vm.data.config.custom.endShow,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.custom,
                                          "endShow",
                                          $$v
                                        )
                                      },
                                      expression: "data.config.custom.endShow",
                                    },
                                  },
                                  [_vm._v("不显示")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 活动售罄 "),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value: _vm.data.config.custom.sellOutShow,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.custom,
                                          "sellOutShow",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.custom.sellOutShow",
                                    },
                                  },
                                  [_vm._v("显示")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: false },
                                    model: {
                                      value: _vm.data.config.custom.sellOutShow,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.custom,
                                          "sellOutShow",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.config.custom.sellOutShow",
                                    },
                                  },
                                  [_vm._v("不显示")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 字体 "),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.data.config.custom.font,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.custom,
                                          "font",
                                          $$v
                                        )
                                      },
                                      expression: "data.config.custom.font",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "微软雅黑", value: "" },
                                    }),
                                    _vm._l(_vm.fonts, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 字号 "),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c("el-input-number", {
                                  staticStyle: { width: "100%" },
                                  attrs: { min: 12 },
                                  model: {
                                    value: _vm.data.htmlCss.custom.fontSize,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.htmlCss.custom,
                                        "fontSize",
                                        $$v
                                      )
                                    },
                                    expression: "data.htmlCss.custom.fontSize",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 颜色 "),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  height: "40px",
                                },
                                attrs: { span: 16 },
                              },
                              [
                                _c("el-color-picker", {
                                  model: {
                                    value: _vm.data.htmlCss.custom.color,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.htmlCss.custom,
                                        "color",
                                        $$v
                                      )
                                    },
                                    expression: "data.htmlCss.custom.color",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 活动结束文字 "),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.data.config.custom.endText,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.config.custom,
                                        "endText",
                                        $$v
                                      )
                                    },
                                    expression: "data.config.custom.endText",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 活动售罄文字 "),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.data.config.custom.sellOutText,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.config.custom,
                                        "sellOutText",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "data.config.custom.sellOutText",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "dialog_content" },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _vm._v(" 字体居中 "),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 16 },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value: _vm.data.config.custom.center,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.custom,
                                          "center",
                                          $$v
                                        )
                                      },
                                      expression: "data.config.custom.center",
                                    },
                                  },
                                  [_vm._v("居中")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: false },
                                    model: {
                                      value: _vm.data.config.custom.center,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.config.custom,
                                          "center",
                                          $$v
                                        )
                                      },
                                      expression: "data.config.custom.center",
                                    },
                                  },
                                  [_vm._v("自定义")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      _c("div", { staticClass: "save-btn" }, [
                        _c(
                          "div",
                          {
                            staticClass: "save-left",
                            on: {
                              click: function ($event) {
                                return _vm.saveOption(0)
                              },
                            },
                          },
                          [_vm._v("重置为默认值")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "save-right",
                            on: {
                              click: function ($event) {
                                return _vm.saveOption(1)
                              },
                            },
                          },
                          [_vm._v("保存当前设置")]
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }