var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tableStyle" },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            "span-method": _vm.arraySpanMethod,
            border: "",
            "header-cell-style": { background: "#fafafa" },
            "row-class-name": _vm.isCell ? _vm.setCellClass : "",
          },
          on: {
            "cell-mouse-enter": _vm.handleMouse,
            "cell-mouse-leave": _vm.handleMouseLeve,
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "num", label: "序号", width: "50", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "活动名称", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "type", label: "场次名称", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "场次时间",
              align: "center",
              width: "450",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("dateFormat")(scope.row.start_time * 1000)
                      ) +
                        " - " +
                        _vm._s(
                          _vm._f("dateFormat")(scope.row.end_time * 1000)
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "user_count", align: "center", label: "客户数量" },
          }),
          _c("el-table-column", {
            attrs: { prop: "batch_count", align: "center", label: "批次数量" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "重置",
                          placement: "top-start",
                        },
                      },
                      [
                        _c("svg-icon", {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "user/delconfirm"],
                              expression: "['admin','user/delconfirm']",
                            },
                          ],
                          attrs: {
                            "class-name": "greens",
                            "icon-class": "reset",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleReset(scope.row)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "关联客户",
                          placement: "top-start",
                        },
                      },
                      [
                        _c("svg-icon", {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "user/confirm"],
                              expression: "['admin','user/confirm']",
                            },
                          ],
                          attrs: {
                            "class-name": "greens",
                            "icon-class": "contact",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleMerge(scope.row)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "查看详情",
                          placement: "top-start",
                        },
                      },
                      [
                        _c("svg-icon", {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "user/grouproom"],
                              expression: "['admin','user/grouproom']",
                            },
                          ],
                          attrs: {
                            "class-name": "greens",
                            "icon-class": "info",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleInfoMerge(scope.row)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.rowData.name + " - " + _vm.rowData.type,
            "destroy-on-close": "",
            visible: _vm.isRoom,
            width: "80%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isRoom = $event
            },
          },
        },
        [
          _c("newTabele", {
            attrs: { "dialog-data": _vm.dialogData, "row-data": _vm.rowData },
            on: {
              handleSearchData: _vm.handleSearchData,
              change: _vm.handleSaveUser,
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isRoom = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "user/confirm"],
                      expression: "['admin','user/confirm']",
                    },
                  ],
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleSaveUser },
                },
                [_vm._v("保 存 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.rowData.name + " - " + _vm.rowData.type,
            "destroy-on-close": "",
            visible: _vm.isOpen,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isOpen = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "tableLeft",
              staticStyle: { width: "100%" },
              attrs: {
                height: "400",
                data: _vm.dialogData.round_user_list,
                stripe: "",
                "header-cell-style": { background: "#fafafa" },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  align: "center",
                  type: "index",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "name", label: "姓名" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "level", label: "分类" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }