import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    ranking: {
      type: [Number, String],
      default: ''
    },
    numberList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flicker: {
      type: Boolean,
      default: false
    },
    user_class: {
      type: Number,
      default: 0
    },
    flickerChild: {
      type: Boolean,
      default: false
    },
    newClass: {
      type: String,
      default: ''
    },
    labelStyle: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {};
  }
};