import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getProject, getProjectName, setPName, setProject, setProjectName, setWsToken } from '@/utils/auth';
import { AddProject, selProject } from '@/api/user';
export default {
  name: 'Index',
  data: function data() {
    return {
      // 项目名称
      name: getProjectName(),
      // 项目列表
      projectList: [],
      //  获取项目数据
      project: JSON.parse(getProject())
    };
  },
  // 初始化
  created: function created() {
    this.getProjectList();
  },
  methods: {
    // 获取 项目列表
    getProjectList: function getProjectList() {
      var _this = this;
      selProject().then(function (res) {
        if (res.status === 200) {
          var info = res.data.info;
          _this.projectList = info;
        }
      });
    },
    // 选择项目 存储项目
    handleCommand: function handleCommand(e) {
      var _this2 = this;
      // 是当前项目 不用切换
      if (this.project.project_id === e) {
        return;
      }

      // 切换 id
      this.project.project_id = e;
      setProject(this.project);

      // 组装数据
      var data = {
        id: this.project.id,
        project_id: e,
        is_default: 0
      };

      // 切换名字
      this.projectList.forEach(function (item) {
        if (e === item.id) {
          if (item.status === 2) {
            _this2.$confirm('不能增删改数据，只能导出', '失效项目', {
              confirmButtonText: '确定',
              cancelButtonText: '关闭',
              type: 'warning'
            }).then(function () {
              _this2.name = item.project_name;
              setProjectName(item.project_name);
              setPName(item.name);
              AddProject(data).then(function (res) {
                if (res.status === 200) {
                  var ws_token = res.data.ws_token;
                  setWsToken(ws_token);
                  _this2.$message({
                    type: 'success',
                    message: res.data.msg
                  });

                  // 切换成功刷新整个页面
                  _this2.$router.replace({
                    name: 'Dashboard'
                  });
                  setTimeout(function () {
                    location.reload();
                  }, 200);
                }
              });
            });
          } else {
            _this2.name = item.project_name;
            setProjectName(item.project_name);
            setPName(item.name);
            AddProject(data).then(function (res) {
              if (res.status === 200) {
                var ws_token = res.data.ws_token;
                setWsToken(ws_token);
                _this2.$message({
                  type: 'success',
                  message: res.data.msg
                });

                // 切换成功刷新整个页面
                _this2.$router.replace({
                  name: 'Dashboard'
                });
                setTimeout(function () {
                  location.reload();
                }, 200);
              }
            });
          }
        }
      });
    }
  }
};