import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import drawingBoard from './components/drawingBoard';
import { removeSuffix } from '@/utils/else.js';
export default {
  components: {
    drawingBoard: drawingBoard
  },
  data: function data() {
    return {
      // 页面搜索
      search: {
        inxId: 'all',
        round_id: 0,
        batch: 0,
        is_not_sale: 0,
        is_not_tag: 0,
        is_not_round: 0,
        no_batch: 0,
        cate_id: 0,
        build_id: 0,
        no: '',
        special: '',
        tag_id: 0,
        unit_id: 0,
        floor_id: 0
      },
      // 活动场次批次
      event: {
        is_round: false,
        is_batch: false,
        is_event: false
      },
      // 活动批次
      activity: {},
      // 楼栋
      areaList: [],
      // 楼层
      unitList: [],
      // 区域
      floorList: [],
      moveList: [],
      selectMoveItem: {},
      selectMoveIndex: 0,
      oldSelect: {},
      occurImage: {},
      // 图片文件
      fileList: [],
      bgImageSrc: ''
    };
  },
  mounted: function mounted() {},
  methods: {
    // 取消操作 恢复之前数据
    cancel: function cancel() {
      this.$set(this.moveList, this.selectMoveIndex, this.oldSelect);
      this.selectMoveItem = this.moveList[this.selectMoveIndex];
    },
    // 提交修改
    onSubmit: function onSubmit() {
      this.moveList[this.selectMoveIndex] = this.selectMoveItem;
    },
    // 删除块
    removeBlock: function removeBlock() {
      if (!this.selectMoveItem.id) {
        return this.$message({
          message: '没有选中删除项',
          type: 'warning'
        });
      }
      this.moveList.splice(this.selectMoveIndex, 1);
    },
    // 新增移动块
    addMoveBlock: function addMoveBlock() {
      if (this.occurImage) return;
      var n = parseInt(Math.random() * 1000);
      this.moveList.push({
        id: n,
        name: '车位名称',
        state: 1,
        width: 100,
        height: 50
      });
    },
    // 停止移动 修改原数据
    stop: function stop(e) {
      var _this = this;
      var index = e.index,
        item = e.item,
        target = e.target;
      this.domStyle(target).then(function (styleObj) {
        item = _objectSpread(_objectSpread({}, item), styleObj);
        _this.moveList[index] = item;
        _this.oldSelect = _objectSpread({}, item);
        _this.selectMoveItem = _this.moveList[index];
        _this.selectMoveIndex = index;
      });
    },
    // dom中取样式
    domStyle: function domStyle(target) {
      return new Promise(function (r) {
        var newObj = {};
        Object.keys(target.style).forEach(function (obj) {
          if (target.style[obj]) {
            try {
              newObj[obj] = removeSuffix(target.style[obj]);
            } catch (error) {}
          }
        });
        r(newObj);
      });
    },
    // 列表搜索事件
    getTableSearchList: function getTableSearchList() {},
    // 重置表单
    resetForm: function resetForm() {
      this.search = {
        inxId: 'all',
        is_not_sale: 0,
        is_not_tag: 0,
        is_not_round: 0,
        build_id: 0,
        unit_id: 0,
        cate_id: 0,
        no: '',
        tag_id: 0
      };
      this.unitList = [];
      this.floorList = [];
    },
    // 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) this.$message.error('上传大小不能超过 2MB!'); // 限制大小
      return isLt2M;
    },
    // 文件上传
    imgupSuccess: function imgupSuccess(e) {
      this.fileList = [];
      this.bgImageSrc = this.imgUrl + e.Data.url;
    }
  }
};