var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "table-box table-style" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableLoading,
                expression: "tableLoading",
              },
            ],
            ref: "refTable",
            attrs: {
              border: "",
              size: "medium",
              data: _vm.tableData,
              height: _vm.tableHeight,
              "header-cell-style": { background: "#fafafa" },
              "element-loading-background": "rgba(0, 0, 0, 0.5)",
              "element-loading-text": "数据正在加载中",
              "element-loading-spinner": "el-icon-loading",
            },
            on: { "row-click": _vm.rowClick },
          },
          [
            _c("el-table-column", {
              attrs: { type: "expand", align: "center", width: "25" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (props) {
                    return [
                      _c("div", { staticClass: "padding" }, [
                        _vm._v(_vm._s(props.row.content)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm.isPC
              ? _c("el-table-column", {
                  attrs: { prop: "name", align: "center", label: "日志内容" },
                })
              : _vm._e(),
            _vm.isPC
              ? _c("el-table-column", {
                  attrs: {
                    prop: "platform",
                    width: "100",
                    align: "center",
                    label: "终端",
                  },
                })
              : _vm._e(),
            _vm.isPC
              ? _c("el-table-column", {
                  attrs: { prop: "cate", align: "center", label: "操作分类" },
                })
              : _vm._e(),
            _c("el-table-column", {
              attrs: { prop: "user_name", align: "center", label: "用户名" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "user_tel",
                width: "150",
                align: "center",
                label: "用户手机号",
              },
            }),
            _vm.isPC
              ? _c("el-table-column", {
                  attrs: {
                    prop: "user_id",
                    width: "80",
                    align: "center",
                    label: "用户id",
                  },
                })
              : _vm._e(),
            _vm.isPC
              ? _c("el-table-column", {
                  attrs: { prop: "ip", align: "center", label: "用户ip" },
                })
              : _vm._e(),
            _vm.isPC
              ? _c("el-table-column", {
                  attrs: { width: "220", align: "center", label: "时间" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    scope.row.create_time * 1000,
                                    "yyyy-MM-DD HH:mm:ss"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1149502842
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }