import _toConsumableArray from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import API from '@/api/ipa.js';
import { adminParameter, authGetEvent, projectConfigure } from '@/api/public';
import { userAdmin } from '@/api/clientSet';
import Api_index from '@/api/index';
import eventSelect from '@/components/eventSelect';
import utils from "@/utils/utils";
import Api from '@/api/deposit';
export default {
  name: 'UserTable',
  components: {
    eventSelect: eventSelect
  },
  data: function data() {
    return {
      adminList: [],
      signData: ['', '报名审核', '认筹审核'],
      signArray: [{
        label: '全部',
        id: 0
      }, {
        label: '报名审核',
        id: 1
      }, {
        label: '认筹审核',
        id: 2
      }],
      authArray: [{
        label: '全部',
        id: 0
      }, {
        label: '待签约',
        id: 1
      }, {
        label: '签约中',
        id: 2
      }, {
        label: '签约完毕',
        id: 3
      }, {
        label: '已失效',
        id: 4
      }],
      //  签约状态
      status: 0,
      // 页面高度
      appHeight: 0,
      // div高度
      topHeight: 0,
      // 摇号排号 批次
      lotteryList: [],
      // 选中的顾问
      admin_id: 0,
      // 是否展开
      is_more: false,
      // 是否展开text
      moreText: '展开',
      // 是否展开 icon
      moreIcon: 'el-icon-arrow-down',
      currentPage4: 1,
      form: {},
      value: '',
      input: '',
      // 客户状态表 参数
      page: 1,
      // 分页
      limit: 10,
      // 数量
      total: 0,
      // 总数
      is_reserv: 0,
      // 是否有预留
      is_rand: 0,
      // 是否到号
      searchParams: {
        search: '',
        types: 0
      },
      // 搜索关键字
      // 活动列表
      event_list: '',
      // 列表
      list: [],
      sign_up: true,
      is_price: true,
      // table字段
      isTableText: '',
      adminListUser: [],
      // 是否展示存档
      data_file: false,
      sign_up_rand: 0,
      // 获取批次列表
      batchList: [],
      // 公共信息批次
      eventData: {},
      // 排序数组
      sortRoom: [],
      selectionArray: {},
      tableLoading: false,
      loading: {},
      select_event: {},
      // 选择的活动和场次id集
      eventList: [] // 项目活动列表
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['roles', 'customSetting'])), {}, {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 142;
    }
  }),
  watch: {
    is_more: function is_more() {
      this.$nextTick(function () {
        this.topHeight = this.$refs.top.offsetHeight;
      });
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.getMes();
    this.appHeight = this.$refs.app.offsetHeight;
    this.topHeight = this.$refs.top.offsetHeight;
    window.onresize = function () {
      _this.appHeight = _this.$refs.app.offsetHeight;
      _this.topHeight = _this.$refs.top.offsetHeight;
    };
    this.getCustomList();
  },
  destroyed: function destroyed() {
    window.onresize = null;
  },
  methods: {
    handleSelectionChange: function handleSelectionChange(row) {
      var ids = [];
      var names = [];
      row.forEach(function (item) {
        ids.push(item.sign_id);
        names.push(item.name);
      });
      this.selectionArray = {
        ids: ids,
        names: names
      };
    },
    // 批量操作
    handleCommand: function handleCommand(key) {
      var _this2 = this;
      if (key === 'delAll') {
        var _this$selectionArray = this.selectionArray,
          ids = _this$selectionArray.ids,
          names = _this$selectionArray.names;
        if (!(ids !== null && ids !== void 0 && ids.length)) {
          this.$message({
            type: 'error',
            message: '请选择签约记录！'
          });
          return;
        }
        var not = false;
        ids.forEach(function (i, idx) {
          if (!i) {
            not = true;
            // 异步 防重叠
            setTimeout(function () {
              return _this2.$message.error("".concat(names[idx], "\u65E0\u8BDA\u610F\u7B7E\u7EA6\u8BB0\u5F55\uFF0C\u4E0D\u5141\u8BB8\u5220\u9664\uFF01"));
            }, 1);
          }
        });
        if (not) return;
        var taht = this;
        this.$confirm('确定批量删除吗?', '提示', {
          callback: function callback() {
            taht.loading = taht.$loading({
              lock: true,
              text: '加载中...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            API.del({
              ids: _toConsumableArray(taht.selectionArray.ids)
            }).then(function (res) {
              taht.$message({
                type: 'success',
                message: res.data.msg
              });
              taht.getTableList();
            }).finally(function (res) {
              taht.loading.close();
            });
          }
        });
      }
    },
    // 查看合同
    handleInfo: function handleInfo(postId, row) {
      var _this3 = this;
      // if (row.handled > 0) {
      //     if (row.word_url) {
      //         window.open("https://view.officeapps.live.com/op/view.aspx?src=" + this.imgUrl + row.word_url)
      //     } else {
      this.loading = this.$loading({
        lock: true,
        text: '请求中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      API.downUrl({
        id: postId
      }).then(function (res) {
        console.log(res);
        if (res.status === 200) {
          var data = res.data.data;
          _this3.loading.close();
          _this3.$nextTick(function () {
            _this3.openPDF(data.data.downloadUrl);
          });
        }
      }).catch(function () {
        _this3.loading.close();
      });
      // }
      // } else {
      //     this.$message({
      //         type: 'error',
      //         message: '未签约没有合同'
      //     })
      // }
    },
    openPDF: function openPDF(url) {
      var _this4 = this;
      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      // 请求文件流
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';

      // 获取文件下载进度
      xhr.addEventListener('progress', function (progress) {
        var percentage = (progress.loaded / progress.total * 100).toFixed(2);
        console.log("\u4E0B\u8F7D\u8FDB\u5EA6 ".concat(percentage, "%..."));
      });

      // 获取到后
      xhr.onload = function () {
        _this4.loading.close();
        // 转成所需文件流
        var b = new Blob([xhr.response], {
          type: 'application/pdf'
        });
        // 创建一个临时链接 用于打开
        var link = window.URL.createObjectURL(b);
        // 打开链接
        var pdfpage = window.open(link);
        // 防止页面没有打开成功
        if (pdfpage) {
          var close = function close(e) {
            // 阻止触发两次
            if (!closeCount) {
              closeCount = true;
              return;
            }
            console.log('关闭PDF页面，链接已经不可用: ', link);
            // 主动释放链接 防止打开很多页面 造成的性能和内存影响
            window.URL.revokeObjectURL(link);
          };
          // 添加关闭事件
          pdfpage.addEventListener('unload', close);
          var closeCount = false;
        } else {
          _this4.$message.error('打开失败');
        }
      };
      xhr.onerror = function () {
        _this4.loading.close();
        _this4.$message.error('打开失败');
      };
      xhr.send();
    },
    // 作废
    handleVoid: function handleVoid(row) {
      var _this5 = this;
      if (row.contract_id) {
        API.eCancel({
          id: row.sign_id
        }).then(function (res) {
          if (res.status === 200) {
            _this5.$message({
              type: 'success',
              message: res.data.msg
            });
            _this5.getCustomList();
          }
        });
      } else if (row.sign_id) {
        this.$confirm('此操作不可复原，确认作废吗？', '提醒', {
          confirmButtonText: '确定',
          cancelButtonText: '关闭',
          type: 'warning'
        }).then(function () {
          API.cancel({
            id: row.sign_id
          }).then(function (res) {
            if (res.status === 200) {
              _this5.$message({
                type: 'success',
                message: res.data.msg
              });
              _this5.getCustomList();
            }
          });
        }).catch(function () {});
      } else {
        this.$message({
          type: 'error',
          message: '未签约没有合同，不能作废'
        });
      }
    },
    // 签约确认
    handleConfirm: function handleConfirm(row) {
      var _this6 = this;
      var ids = [row.id];
      this.$confirm("是否将此记录，签约状态置为 签约完成？", "提示", {
        type: "warning"
      }).then(function (res) {
        API.signConfirm({
          ids: ids
        }).then(function (res) {
          if (res.status === 200) {
            _this6.$message({
              type: 'success',
              message: res.data.msg
            });
            _this6.getCustomList();
          }
        });
      });
    },
    numberRow: function numberRow() {
      this.$router.push({
        name: 'SendNumberList'
      });
    },
    // 场次选择回调
    selectRoundHandle: function selectRoundHandle(e) {
      this.select_event = {
        event_id: e.event.id,
        round_id: e.round.id
      };
      if (this.roles.includes('userstatus/index') || this.roles.includes('admin')) {
        this.getCustomList();
      }
    },
    handleSizeChange: function handleSizeChange(val) {
      this.limit = val;
      this.getCustomList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.getCustomList();
    },
    // 获取客户状态表
    getCustomList: function getCustomList() {
      var _this7 = this;
      var data = _objectSpread(_objectSpread({}, this.searchParams), {}, {
        page: this.page,
        // 分页
        limit: this.limit,
        // 数量
        status: this.status
      });
      this.tableLoading = true;
      API.roomIndex(data).then(function (res) {
        _this7.list = res.data.list || [];
        _this7.total = res.data.count;
        _this7.tableLoading = false;
      });
    },
    // 查询
    searchClick: function searchClick() {
      this.getCustomList();
    },
    // 重置
    resetClick: function resetClick() {
      this.searchParams = {
        types: 0
      };
      this.status = 0;
      this.getCustomList();
    },
    // 导出
    exportMes: function exportMes() {
      var data = _objectSpread(_objectSpread({}, this.searchParams), {}, {
        page: this.page,
        // 分页
        limit: this.limit,
        // 数量
        status: this.status
      });
      API.export(data).then(function (res) {
        // let url = res.data.msg
        // window.location.href = this.host + url
        utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
      });
    },
    // 客户详情
    toUserDetail: function toUserDetail(scope) {
      var id = scope.row.id;
      this.$router.push({
        name: 'UserDetails',
        query: {
          id: id,
          round: this.eventData.is_round
        }
      });
    },
    // 获取信息
    getMes: function getMes() {
      var _this8 = this;
      adminParameter().then(function (res) {
        _this8.event = res.data.event;
        var _res$data = res.data,
          list = _res$data.list,
          event = _res$data.event;
        _this8.adminList = list;
        console.log(_this8.adminList);
        _this8.eventData = event;
      });
    }
  }
};