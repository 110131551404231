var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "switch-box2" },
    [
      _vm._l(_vm.switchList, function (item, i) {
        return [
          _vm.type === "num"
            ? [
                item.num > 0
                  ? _c(
                      "div",
                      {
                        key: i,
                        staticClass: "switch-list",
                        class: i === _vm.switchTab ? "on" : "",
                        on: {
                          click: function ($event) {
                            return _vm.bindSwitch(item.id, i)
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(item.title) + " "),
                        _c("span", [_vm._v(_vm._s(item.num))]),
                      ]
                    )
                  : _vm._e(),
              ]
            : [
                _c(
                  "div",
                  {
                    key: i,
                    staticClass: "switch-list",
                    class: i === _vm.switchTab ? "on" : "",
                    on: {
                      click: function ($event) {
                        return _vm.bindSwitch(item.id, i)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(item.title) + " " + _vm._s(item.num) + " "
                    ),
                  ]
                ),
              ],
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }