var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail-box" },
    [
      _c(
        "el-form",
        {
          ref: "trendRules",
          attrs: { model: _vm.form, "label-width": "110px" },
        },
        [
          _vm._l(_vm.statusInfo, function (item, index) {
            return [
              _vm.getType(item)
                ? _c("div", { key: index, staticClass: "box-top" }, [
                    _c(
                      "div",
                      { staticClass: "block-box" },
                      [
                        _c(
                          "div",
                          { staticClass: "title-h2-c" },
                          [
                            _vm._v(" " + _vm._s(item.area_name) + " "),
                            item.type === 1 && _vm.type === 1
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "orange-btn but addnew",
                                    attrs: {
                                      icon: "el-icon-plus",
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addPropertyOwner(index)
                                      },
                                    },
                                  },
                                  [_vm._v("增加产权人 ")]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "calc(100% - 230px)",
                                  "text-align": "right",
                                },
                              },
                              [
                                item.type === 1 && _vm.type === 1
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "8px 20px" },
                                        attrs: {
                                          icon: "el-icon-sort",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onOwnerSort(index)
                                          },
                                        },
                                      },
                                      [_vm._v("排序 ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "line-wrapper" }, [
                          _c("div", { staticClass: "line" }),
                        ]),
                        item.type === 1 && _vm.type === 1
                          ? _c(
                              "div",
                              { staticClass: "el-rowClass propertyBox" },
                              _vm._l(item.owners, function (list, inx) {
                                return _c(
                                  "div",
                                  { key: inx, staticClass: "propertyList" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "title-button-box flex align-items",
                                      },
                                      [
                                        _c("span", { staticClass: "buttons" }, [
                                          _vm._v(
                                            "第" + _vm._s(inx + 1) + "产权人"
                                          ),
                                        ]),
                                        item.type === 1 && _vm.type === 1
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "buttonDelete mL10",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleDelete(
                                                      index,
                                                      inx
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._l(list.forms, function (fromItem, i) {
                                      return [
                                        fromItem.fill_in_type === _vm.type &&
                                        fromItem.parent_cause_show
                                          ? [
                                              fromItem.owner_type === 41
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "title-button-box flex align-items",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "buttons",
                                                        },
                                                        [_vm._v("配偶信息")]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              fromItem.form_type === "置业顾问"
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      key: i,
                                                      attrs: {
                                                        label: fromItem.name,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio-group",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              fromItem
                                                                .owner_result
                                                                .disabled == 1,
                                                          },
                                                          model: {
                                                            value:
                                                              fromItem
                                                                .owner_result
                                                                .value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                fromItem.owner_result,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "fromItem.owner_result.value",
                                                          },
                                                        },
                                                        _vm._l(
                                                          fromItem.admins,
                                                          function (radios) {
                                                            return _c(
                                                              "el-radio",
                                                              {
                                                                key: radios.id,
                                                                attrs: {
                                                                  label:
                                                                    radios.id +
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    radios.name
                                                                  ) + " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              fromItem.owner_type === 2 &&
                                              inx === 0
                                                ? [
                                                    fromItem.form_type ===
                                                    "text"
                                                      ? _c(
                                                          "el-form-item",
                                                          {
                                                            key: i,
                                                            attrs: {
                                                              label:
                                                                fromItem.name,
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  fromItem.placeholder,
                                                                disabled:
                                                                  fromItem
                                                                    .owner_result
                                                                    .disabled ==
                                                                  1,
                                                                size: "small",
                                                              },
                                                              model: {
                                                                value:
                                                                  fromItem
                                                                    .owner_result
                                                                    .value,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      fromItem.owner_result,
                                                                      "value",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "fromItem.owner_result.value",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                : [
                                                    fromItem.form_type ===
                                                    "text"
                                                      ? _c(
                                                          "el-form-item",
                                                          {
                                                            key: i,
                                                            attrs: {
                                                              label:
                                                                fromItem.name,
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  fromItem.placeholder,
                                                                disabled:
                                                                  fromItem
                                                                    .owner_result
                                                                    .disabled ==
                                                                  1,
                                                                size: "small",
                                                              },
                                                              model: {
                                                                value:
                                                                  fromItem
                                                                    .owner_result
                                                                    .value,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      fromItem.owner_result,
                                                                      "value",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "fromItem.owner_result.value",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                              fromItem.form_type === "radio"
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      key: i,
                                                      attrs: {
                                                        label: fromItem.name,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio-group",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              fromItem
                                                                .owner_result
                                                                .disabled == 1,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.onShowParentOwnerChange(
                                                                fromItem,
                                                                inx,
                                                                index
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              fromItem
                                                                .owner_result
                                                                .value2,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                fromItem.owner_result,
                                                                "value2",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "fromItem.owner_result.value2",
                                                          },
                                                        },
                                                        _vm._l(
                                                          fromItem.children,
                                                          function (radios) {
                                                            return _c(
                                                              "el-radio",
                                                              {
                                                                key: radios.id,
                                                                attrs: {
                                                                  label:
                                                                    radios.id,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    radios.name
                                                                  ) + " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              fromItem.form_type === "select"
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      key: i,
                                                      attrs: {
                                                        label: fromItem.name,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-checkbox-group",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              fromItem
                                                                .owner_result
                                                                .disabled == 1,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.onShowParentOwnerChange(
                                                                fromItem,
                                                                inx
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              fromItem
                                                                .owner_result
                                                                .value2,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                fromItem.owner_result,
                                                                "value2",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "fromItem.owner_result.value2",
                                                          },
                                                        },
                                                        _vm._l(
                                                          fromItem.children,
                                                          function (select) {
                                                            return _c(
                                                              "el-checkbox",
                                                              {
                                                                key: select.id,
                                                                attrs: {
                                                                  label:
                                                                    select.id,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    select.name
                                                                  ) + " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              fromItem.form_type === "number"
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      key: i,
                                                      attrs: {
                                                        label: fromItem.name,
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          placeholder:
                                                            fromItem.placeholder,
                                                          disabled:
                                                            fromItem
                                                              .owner_result
                                                              .disabled == 1,
                                                          size: "small",
                                                          type: "number",
                                                        },
                                                        model: {
                                                          value:
                                                            fromItem
                                                              .owner_result
                                                              .value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              fromItem.owner_result,
                                                              "value",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "fromItem.owner_result.value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              fromItem.form_type === "file"
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      key: i,
                                                      attrs: {
                                                        label: fromItem.name,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                        },
                                                        [
                                                          _c("GMainUpload", {
                                                            attrs: {
                                                              "is-oss": true,
                                                              userCreateInfoId:
                                                                _vm.userCreateInfoId,
                                                              "data-index":
                                                                index,
                                                              "data-index1":
                                                                inx,
                                                              "data-index2": i,
                                                              "file-list":
                                                                fromItem
                                                                  .owner_result
                                                                  .value2,
                                                              "list-type":
                                                                _vm.listTypes,
                                                              text: fromItem.placeholder,
                                                              "file-size": 50,
                                                              disabled:
                                                                fromItem
                                                                  .owner_result
                                                                  .disabled ==
                                                                1,
                                                            },
                                                            on: {
                                                              handleSuccess:
                                                                _vm.handleProperty,
                                                              handOperation:
                                                                _vm.handleBuildOperation,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        item.type === 2 && _vm.type === 1
                          ? _c(
                              "div",
                              { staticClass: "el-rowClass propertyBox" },
                              _vm._l(item.banks, function (list, inx) {
                                return _c(
                                  "div",
                                  { key: inx, staticClass: "propertyList" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "title-button-box flex align-items",
                                      },
                                      [
                                        item.type === 1 &&
                                        _vm.type === 1 &&
                                        inx !== 0
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "buttonDelete mL10",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleDelete(
                                                      index,
                                                      inx,
                                                      "banks"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._l(list.forms, function (fromItem, i) {
                                      return [
                                        fromItem.fill_in_type === _vm.type &&
                                        fromItem.parent_cause_show
                                          ? [
                                              fromItem.form_type === "置业顾问"
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      key: i,
                                                      attrs: {
                                                        label: fromItem.name,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio-group",
                                                        {
                                                          model: {
                                                            value:
                                                              fromItem
                                                                .bank_result
                                                                .value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                fromItem.bank_result,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "fromItem.bank_result.value",
                                                          },
                                                        },
                                                        _vm._l(
                                                          fromItem.admins,
                                                          function (radios) {
                                                            return _c(
                                                              "el-radio",
                                                              {
                                                                key: radios.id,
                                                                attrs: {
                                                                  label:
                                                                    radios.id +
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    radios.name
                                                                  ) + " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              fromItem.owner_type === 2 &&
                                              inx === 0
                                                ? [
                                                    fromItem.form_type ===
                                                      "text" ||
                                                    fromItem.form_type === "map"
                                                      ? _c(
                                                          "el-form-item",
                                                          {
                                                            key: i,
                                                            attrs: {
                                                              label:
                                                                fromItem.name,
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  fromItem.placeholder,
                                                                disabled: "",
                                                                size: "small",
                                                              },
                                                              model: {
                                                                value:
                                                                  fromItem
                                                                    .bank_result
                                                                    .value,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      fromItem.bank_result,
                                                                      "value",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "fromItem.bank_result.value",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                : [
                                                    fromItem.form_type ===
                                                      "text" ||
                                                    fromItem.form_type === "map"
                                                      ? _c(
                                                          "el-form-item",
                                                          {
                                                            key: i,
                                                            attrs: {
                                                              label:
                                                                fromItem.name,
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  fromItem.placeholder,
                                                                size: "small",
                                                              },
                                                              model: {
                                                                value:
                                                                  fromItem
                                                                    .bank_result
                                                                    .value,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      fromItem.bank_result,
                                                                      "value",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "fromItem.bank_result.value",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                              fromItem.form_type === "radio"
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      key: i,
                                                      attrs: {
                                                        label: fromItem.name,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio-group",
                                                        {
                                                          model: {
                                                            value:
                                                              fromItem
                                                                .bank_result
                                                                .value2,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                fromItem.bank_result,
                                                                "value2",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "fromItem.bank_result.value2",
                                                          },
                                                        },
                                                        _vm._l(
                                                          fromItem.children,
                                                          function (radios) {
                                                            return _c(
                                                              "el-radio",
                                                              {
                                                                key: radios.id,
                                                                attrs: {
                                                                  label:
                                                                    radios.id,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    radios.name
                                                                  ) + " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              fromItem.form_type === "select"
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      key: i,
                                                      attrs: {
                                                        label: fromItem.name,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-checkbox-group",
                                                        {
                                                          model: {
                                                            value:
                                                              fromItem
                                                                .bank_result
                                                                .value2,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                fromItem.bank_result,
                                                                "value2",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "fromItem.bank_result.value2",
                                                          },
                                                        },
                                                        _vm._l(
                                                          fromItem.children,
                                                          function (select) {
                                                            return _c(
                                                              "el-checkbox",
                                                              {
                                                                key: select.id,
                                                                attrs: {
                                                                  label:
                                                                    select.id,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    select.name
                                                                  ) + " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              fromItem.form_type === "number"
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      key: i,
                                                      attrs: {
                                                        label: fromItem.name,
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          placeholder:
                                                            fromItem.placeholder,
                                                          size: "small",
                                                          type: "number",
                                                        },
                                                        model: {
                                                          value:
                                                            fromItem.bank_result
                                                              .value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              fromItem.bank_result,
                                                              "value",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "fromItem.bank_result.value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              fromItem.form_type === "file"
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      key: i,
                                                      attrs: {
                                                        label: fromItem.name,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                        },
                                                        [
                                                          _c("GMainUpload", {
                                                            attrs: {
                                                              "is-oss": true,
                                                              userCreateInfoId:
                                                                _vm.userCreateInfoId,
                                                              "file-size": 50,
                                                              "data-index":
                                                                index,
                                                              "data-index1":
                                                                inx,
                                                              "data-index2": i,
                                                              "file-list":
                                                                fromItem
                                                                  .bank_result
                                                                  .value2,
                                                              "list-type":
                                                                _vm.listTypes,
                                                              text: fromItem.placeholder,
                                                            },
                                                            on: {
                                                              handleSuccess:
                                                                _vm.handlePropertyBank,
                                                              handOperation:
                                                                _vm.handleBuildOperation,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                )
                              }),
                              0
                            )
                          : [
                              _c(
                                "el-row",
                                {
                                  staticClass: "el-rowClass",
                                  attrs: { gutter: 20 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _vm._l(
                                        item.forms,
                                        function (fromItem, i) {
                                          return [
                                            fromItem.fill_in_type ===
                                              _vm.type &&
                                            fromItem.parent_cause_show &&
                                            !fromItem.hidde
                                              ? [
                                                  fromItem.form_type ===
                                                  "置业顾问"
                                                    ? _c(
                                                        "el-form-item",
                                                        {
                                                          key: i,
                                                          attrs: {
                                                            label:
                                                              fromItem.name,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-radio-group",
                                                            {
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onShowParentChange(
                                                                      fromItem
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  fromItem
                                                                    .normal_result
                                                                    .value,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      fromItem.normal_result,
                                                                      "value",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "fromItem.normal_result.value",
                                                              },
                                                            },
                                                            _vm._l(
                                                              fromItem.admins,
                                                              function (
                                                                radios
                                                              ) {
                                                                return _c(
                                                                  "el-radio",
                                                                  {
                                                                    key: radios.id,
                                                                    attrs: {
                                                                      label:
                                                                        radios.id +
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        radios.name
                                                                      ) + " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  fromItem.form_type === "text"
                                                    ? _c(
                                                        "el-form-item",
                                                        {
                                                          key: i,
                                                          attrs: {
                                                            label:
                                                              fromItem.name,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                width: "25%",
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    fromItem.placeholder,
                                                                  size: "small",
                                                                },
                                                                model: {
                                                                  value:
                                                                    fromItem
                                                                      .normal_result
                                                                      .value,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        fromItem.normal_result,
                                                                        "value",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "fromItem.normal_result.value",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  fromItem.form_type ===
                                                  "number"
                                                    ? _c(
                                                        "el-form-item",
                                                        {
                                                          key: i,
                                                          attrs: {
                                                            label:
                                                              fromItem.name,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                width: "25%",
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    fromItem.placeholder,
                                                                  size: "small",
                                                                  type: "number",
                                                                },
                                                                model: {
                                                                  value:
                                                                    fromItem
                                                                      .normal_result
                                                                      .value,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        fromItem.normal_result,
                                                                        "value",
                                                                        typeof $$v ===
                                                                          "string"
                                                                          ? $$v.trim()
                                                                          : $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "fromItem.normal_result.value",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  fromItem.form_type ===
                                                    "radio" &&
                                                  !fromItem.is_show_tp
                                                    ? _c(
                                                        "el-form-item",
                                                        {
                                                          key: i,
                                                          attrs: {
                                                            label:
                                                              fromItem.name,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-radio-group",
                                                            {
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleRadio(
                                                                      fromItem
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  fromItem
                                                                    .normal_result
                                                                    .value2,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      fromItem.normal_result,
                                                                      "value2",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "fromItem.normal_result.value2",
                                                              },
                                                            },
                                                            _vm._l(
                                                              fromItem.children,
                                                              function (
                                                                radios
                                                              ) {
                                                                return _c(
                                                                  "el-radio",
                                                                  {
                                                                    key: radios.id,
                                                                    attrs: {
                                                                      label:
                                                                        radios.id,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        radios.name
                                                                      ) + " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  fromItem.form_type ===
                                                  "select"
                                                    ? _c(
                                                        "el-form-item",
                                                        {
                                                          key: i,
                                                          attrs: {
                                                            label:
                                                              fromItem.name,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-checkbox-group",
                                                            {
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onShowParentChange(
                                                                      fromItem
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  fromItem
                                                                    .normal_result
                                                                    .value2,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      fromItem.normal_result,
                                                                      "value2",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "fromItem.normal_result.value2",
                                                              },
                                                            },
                                                            _vm._l(
                                                              fromItem.children,
                                                              function (
                                                                select
                                                              ) {
                                                                return _c(
                                                                  "el-checkbox",
                                                                  {
                                                                    key: select.id,
                                                                    attrs: {
                                                                      label:
                                                                        select.id,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        select.name
                                                                      ) + " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  fromItem.form_type ===
                                                    "file" &&
                                                  !fromItem.is_show_tp
                                                    ? _c(
                                                        "el-form-item",
                                                        {
                                                          key: i,
                                                          attrs: {
                                                            label:
                                                              fromItem.name,
                                                          },
                                                        },
                                                        [
                                                          _c("GMainUpload", {
                                                            attrs: {
                                                              "is-oss": true,
                                                              userCreateInfoId:
                                                                _vm.userCreateInfoId,
                                                              "data-index":
                                                                index,
                                                              "data-index1": i,
                                                              "file-size": 50,
                                                              "file-list":
                                                                fromItem
                                                                  .normal_result
                                                                  .value2,
                                                              "list-type":
                                                                _vm.listTypes,
                                                              text: fromItem.placeholder,
                                                            },
                                                            on: {
                                                              handleSuccess:
                                                                _vm.handleOtherImg,
                                                              handOperation:
                                                                _vm.handleBuildOperation,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : fromItem.form_type ===
                                                      "popup"
                                                    ? _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label:
                                                              fromItem.name,
                                                          },
                                                        },
                                                        [
                                                          _c("IntentionRoom", {
                                                            attrs: {
                                                              title:
                                                                fromItem.name,
                                                              "select-data":
                                                                fromItem
                                                                  .normal_result
                                                                  .auto_value,
                                                              select_number:
                                                                fromItem.img_number,
                                                            },
                                                            on: {
                                                              limit:
                                                                _vm.onRoomList,
                                                              submit: function (
                                                                e
                                                              ) {
                                                                _vm.handlePopup(
                                                                  fromItem,
                                                                  e
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : fromItem.form_type ===
                                                        "意向户型" &&
                                                      _vm.cateNum
                                                    ? _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label:
                                                              fromItem.name,
                                                          },
                                                        },
                                                        [
                                                          _c("HouseType", {
                                                            ref: "HouseType",
                                                            refInFor: true,
                                                            attrs: {
                                                              title:
                                                                fromItem.name,
                                                              "select-data":
                                                                fromItem
                                                                  .normal_result
                                                                  .auto_value,
                                                            },
                                                            on: {
                                                              submit: function (
                                                                e
                                                              ) {
                                                                _vm.handleHouse(
                                                                  fromItem,
                                                                  e
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }