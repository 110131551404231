var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "dialog-header serch-header" }, [
      _c("img", {
        staticStyle: { width: "34px" },
        attrs: { src: require("../../assets/image/suc.png") },
      }),
      _c("span", [
        _vm._v("找到" + _vm._s(_vm.userList.length || 0) + "个客户"),
      ]),
    ]),
    _c("div", { staticClass: "checkeNum" }, [
      _vm._v(
        " " +
          _vm._s(
            _vm.checkedName ? "已选： " + _vm.checkedName : "请选择客户！"
          ) +
          " "
      ),
    ]),
    _c("div", { staticClass: "dialog-content" }, [
      _c(
        "div",
        { staticClass: "dialog-1-content" },
        _vm._l(_vm.userList, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "dialog-content-item",
              on: {
                click: function ($event) {
                  return _vm.selectUser(item)
                },
              },
            },
            [
              _c(
                "div",
                {
                  class: [
                    "checked",
                    {
                      "current-checked": item.checked,
                      checked_disabled: !item.succeed,
                    },
                  ],
                },
                [_vm._v(" " + _vm._s(item.checked ? "√" : "") + " ")]
              ),
              item.tips
                ? _c("div", { staticClass: "signet" }, [
                    item.succeed
                      ? _c("img", {
                          attrs: {
                            "class-name": "signet_icon",
                            src: require("../../assets/image/signet_green.png"),
                            alt: "",
                          },
                        })
                      : item.error
                      ? _c("img", {
                          attrs: {
                            "class-name": "signet_icon",
                            src: require("../../assets/image/signet_red.png"),
                            alt: "",
                          },
                        })
                      : item.repetition
                      ? _c("img", {
                          attrs: {
                            "class-name": "signet_icon",
                            src: require("../../assets/image/signet_yellow.png"),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "signet_tips",
                        style: {
                          color: item.succeed
                            ? "#22A271"
                            : item.error
                            ? "#FF3131"
                            : "#FFBF00",
                        },
                      },
                      [_vm._v(" " + _vm._s(item.tips) + " ")]
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "dialog-container" }, [
                _c("div", { staticClass: "dialog-content-item-top" }, [
                  _c(
                    "div",
                    { staticClass: "dialog-content-item-left" },
                    [
                      _c(
                        "el-image",
                        {
                          staticClass: "dialog-content-item-avatar",
                          attrs: {
                            fit: "fill",
                            src: item.avatar,
                            "preview-src-list": [item.avatar],
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "dialog-content-item-avatar",
                              attrs: { slot: "error" },
                              slot: "error",
                            },
                            [
                              _c("img", {
                                staticClass: "dialog-content-item-avatar",
                                attrs: {
                                  src: require("@/assets/image/avatar_icon.png"),
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "dialog-content-item-right" }, [
                    _c(
                      "span",
                      { staticClass: "dialog-content-item-right-username" },
                      [
                        _vm._v(_vm._s(item.name) + " "),
                        item.rand_name
                          ? _c(
                              "span",
                              { staticClass: "dialog-content-item-tag" },
                              [_vm._v(" " + _vm._s(item.rand_name) + " ")]
                            )
                          : _vm._e(),
                      ]
                    ),
                    item.code
                      ? _c(
                          "div",
                          { staticClass: "dialog-content-item-right-order" },
                          [_vm._v(" 诚意单号：" + _vm._s(item.code) + " ")]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "dialog-content-item-bottom" },
                  [
                    _c(
                      "span",
                      { staticClass: "dialog-content-item-right-otherInfo" },
                      [_vm._v(" 手机号：" + _vm._s(_vm.iphone(item.tel)) + " ")]
                    ),
                    item.admin_name
                      ? _c(
                          "div",
                          {
                            staticClass: "dialog-content-item-right-otherInfo",
                          },
                          [
                            _vm._v(
                              "置业顾问： " +
                                _vm._s(item.admin_name) +
                                " " +
                                _vm._s(
                                  item.t_name ? "(" + item.t_name + ")" : ""
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.uao_list[item.id]
                      ? _vm._l(_vm.uao_list[item.id], function (i, j) {
                          return _c(
                            "div",
                            {
                              key: j,
                              staticClass:
                                "dialog-content-item-right-otherInfo",
                            },
                            [
                              _vm._v(
                                " 产权人" +
                                  _vm._s(j + 1) +
                                  "：" +
                                  _vm._s(i.name) +
                                  " " +
                                  _vm._s(i.card ? "(" + i.card + ")" : "") +
                                  " "
                              ),
                            ]
                          )
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "dialog-footer" }, [
      _c(
        "div",
        { staticClass: "dialog-footer-right" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-footer-right-btn",
              attrs: { plain: "" },
              on: { click: _vm.dialogConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }