import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Index',
  props: {
    inputW: {
      type: Number,
      default: 100
    },
    prefix: {
      type: [String, Number],
      default: ''
    },
    inputText: {
      type: String,
      default: ''
    },
    textValue: {
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: 999
    },
    number: {
      type: Number,
      default: 1
    }
  },
  methods: {
    bindValue: function bindValue(e) {
      // if (e.target.value > this.max) {
      // 	this.textValue = '';
      // 	return;
      // }

      this.$emit('handleInputValue', parseFloat(e.target.value));
    }
  }
};