import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SaleOver',
  props: {
    //   项目名称
    projectName: {
      type: String,
      default: ''
    },
    // 已售套数
    saledNum: {
      type: [String, Number],
      default: ''
    },
    // 销售金额
    saledPrice: {
      type: [String, Number],
      default: ''
    },
    // 是否显示已售套数
    isShowSaledNum: {
      type: Boolean,
      default: false
    },
    // 是否显示已售金额
    isShowSaledPrice: {
      type: Boolean,
      default: false
    },
    // 是否显示活动结束
    isShowSaledEnd: {
      type: Boolean,
      default: false
    },
    // 结束文字
    endText: {
      type: String,
      default: ''
    },
    // powerBy
    powerBy: {
      type: String,
      default: ''
    },
    // 售出售罄文字 类型
    saleText: {
      // 0 售出 1 售罄
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {};
  }
};