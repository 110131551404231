//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    pay: {
      type: Object,
      default: function _default() {
        return {
          handled: -1
        };
      }
    }
  },
  data: function data() {
    return {
      priceStatus: ['未付款', '已付款', '部分付款']
    };
  }
};