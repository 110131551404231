import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import moment from 'moment';
export default {
  methods: {
    // 画背景图
    drawBgImg: function drawBgImg(ctx, canvas, scale, img) {
      var x = 0;
      var y = 0;
      var w = canvas.width;
      var h = canvas.height;
      var r = 8;
      ctx.save();
      ctx.moveTo(x + r, y);
      ctx.arcTo(x + w, y, x + w, y + h, r);
      ctx.arcTo(x + w, y + h, x, y + h, r);
      ctx.arcTo(x, y + h, x, y, r);
      ctx.arcTo(x, y, x + w, y, r);
      ctx.closePath();
      ctx.strokeStyle = 'transparent'; // 设置绘制圆形边框的颜色
      ctx.clip();
      ctx.drawImage(img, x, y, w, h);
    },
    // 画中间白色区域
    drawContent: function drawContent(ctx, canvas, scale) {
      // 必须得向上取整，不然会有缝隙
      var w = Math.ceil(251 * scale);
      var h = Math.ceil(306 * scale);
      var x = Math.ceil(canvas.width / 2 - w / 2);
      var y = Math.ceil(140 * scale);
      var r = Math.ceil(40 * scale);
      ctx.fillStyle = 'transparent';
      ctx.strokeStyle = '#fff';
      ctx.lineJoin = 'round'; // 交点设置成圆角
      ctx.lineWidth = r;
      ctx.strokeRect(x + r / 2, y + r / 2, w - r, h - r);
      ctx.restore();
      ctx.fillStyle = '#fff';
      ctx.fillRect(x + r, y + r, w - r * 2, h - r * 2);
    },
    // 画项目名称
    drawName: function drawName(ctx, canvas, scale) {
      var fontSize = 18 * scale;
      var lineHeight = 25 * scale;
      var maxWidth = 180 * scale;
      ctx.font = "normal 800 ".concat(fontSize, "px/").concat(lineHeight, "px PingFang SC");
      ctx.fillStyle = '#200300';
      ctx.lineWidth = 1;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      var lineWidth = 0; // 截取的字符宽度
      var x = canvas.width / 2;
      var y = lineHeight / 2 + 152 * scale; // 绘制字体距离canvas顶部初始的高度
      var lastSubStrIndex = 0; // 每次开始截取的字符串的索引
      var str = this.adminList.name;
      for (var i = 0; i < str.length; i++) {
        lineWidth += ctx.measureText(str[i]).width;
        if (lineWidth > maxWidth) {
          ctx.fillText(str.substring(lastSubStrIndex, i), x, y); // 绘制截取部分
          y += lineHeight; // lineHeight为字体的高度
          lineWidth = 0;
          lastSubStrIndex = i;
        }
        if (i == str.length - 1) {
          // 绘制剩余部分
          ctx.fillText(str.substring(lastSubStrIndex, i + 1), x, y);
        }
      }
    },
    // 画选房端标签 type: 1 选房端 2 助手端 3 资格审核端
    drawTips_1: function drawTips_1(ctx, canvas, scale, type, suffix) {
      // 避免显示undefind 和 null
      var text = suffix ? suffix + '-' : '';
      switch (type) {
        case 1:
          text += '客户端';
          break;
        case 2:
          text += '助手端';
          break;
        case 3:
          text = '购房客户-报名端';
          // text += '报名端'
          break;
        default:
          text += '客户端';
          break;
      }
      var w = ctx.measureText(text).width;
      var h = 20 * scale;
      var r = 20 * scale;
      var x = canvas.width / 2 - w / 2;
      var y = Math.ceil(395 * scale);
      var fontSize = 11 * scale;
      var lineHeight = 16;
      ctx.fillStyle = 'transparent';
      switch (type) {
        case 1:
          ctx.strokeStyle = 'rgba(255, 105, 21,.2)';
          break;
        case 2:
          ctx.strokeStyle = 'rgba(34, 162, 113,.2)';
          break;
        case 3:
          ctx.strokeStyle = 'rgba(255, 46, 70,.2)';
          break;
        default:
          ctx.strokeStyle = 'rgba(255, 105, 21,.2)';
      }
      ctx.lineJoin = 'round'; // 交点设置成圆角
      ctx.lineWidth = r;
      ctx.font = "normal 800 ".concat(fontSize, "px/").concat(lineHeight, "px PingFang SC");
      ctx.textAlign = 'center';
      ctx.strokeRect(x + r / 2, y + r / 2, w - r, h - r);
      ctx.fillRect(x + r, y + r, w - r * 2, h - r * 2);
      ctx.restore();
      switch (type) {
        case 1:
          ctx.fillStyle = '#FF6915';
          break;
        case 2:
          ctx.fillStyle = '#22A271';
          break;
        case 3:
          ctx.fillStyle = '#FF2E46';
          break;
        default:
          ctx.fillStyle = '#FF6915';
      }
      ctx.fillText(text, canvas.width / 2, Math.ceil((398 + lineHeight / 2) * scale));
      ctx.restore();
    },
    // 画提示文本
    drawTips_2: function drawTips_2(ctx, canvas, scale) {
      var fontSize = 10 * scale;
      var lineHeight = 14;
      var x = canvas.width / 2;
      var y = (421 + lineHeight / 2) * scale;
      ctx.restore();
      ctx.font = "normal 400 ".concat(fontSize, "px/").concat(lineHeight, "px PingFang SC");
      ctx.textAlign = 'center';
      ctx.fillStyle = 'rgba(0,0,0,.3)';
      ctx.fillText('识别小程序码加入活动', x, y);
      ctx.restore();
    },
    // 画下载日期
    drawDownloadTime: function drawDownloadTime(ctx, canvas, scale) {
      var fontSize = 11 * scale;
      var lineHeight = 16;
      var x = canvas.width / 2;
      var y = (461 + lineHeight / 2) * scale;
      ctx.restore();
      ctx.font = "normal 400 ".concat(fontSize, "px/").concat(lineHeight, "px PingFang SC");
      ctx.textAlign = 'center';
      ctx.fillStyle = 'rgba(255,255,255,.4)';
      ctx.fillText("\u4E0B\u8F7D\u65E5\u671F\uFF1A".concat(moment().format('YYYY/MM/DD')), x, y);
      ctx.restore();
    },
    // 画项目二维码
    drawQRImg: function drawQRImg(ctx, canvas, scale, url) {
      var _this = this;
      return new Promise(function (resolve) {
        var w = 169 * scale; // 图片宽度 = 图片高度
        var h = w; // 图片宽度 = 图片高度
        var x = canvas.width / 2 - w / 2;
        var y = 214 * scale;
        var lineHeight = 25 * scale;
        var maxWidth = 180;
        var projectName_widht = ctx.measureText(_this.adminList.name).width;
        if (projectName_widht <= maxWidth) {
          y -= lineHeight / 2;
        }
        var img = new Image();
        img.onload = function () {
          ctx.drawImage(img, x, y, w, h);
          resolve();
        };
        img.src = url;
      });
    }
  }
};