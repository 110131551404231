import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
export default {
  name: 'CustomFocus',
  props: {
    preference: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    tableData: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    projectList: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      // 表格数据
      // tableData: [],
      appHeight: '',
      topHeight: ''
      // ----接口相关-----
      // 偏好
      // preference: "",
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['customSetting'])), {}, {
    tableHeight: function tableHeight() {
      return this.appHeight - (this.topHeight + 54);
    }
  }),
  mounted: function mounted() {
    var _this = this;
    // 表格
    var that = this;
    that.appHeight = this.$refs.app.offsetHeight;
    that.topHeight = this.$refs.apptop.offsetHeight;
    window.onresize = function () {
      that.appHeight = _this.$refs.app.offsetHeight;
      that.topHeight = _this.$refs.apptop.offsetHeight;
    };

    // 获取客户信息
    // this.getCustomDetail();
  },
  destroyed: function destroyed() {
    window.onresize = null;
  },
  methods: {
    // // 获取客户信息
    // getCustomDetail() {
    //     let id = this.$route.query.id
    //     let params = {
    //         id
    //     }
    //     API.customDetail(params).then((res) => {
    //         this.preference = res.data.preference;
    //         this.tableData = res.data.collect;
    //     });
    // },
  }
};