import { render, staticRenderFns } from "./building.vue?vue&type=template&id=87ef3f92&scoped=true"
import script from "./building.vue?vue&type=script&lang=js"
export * from "./building.vue?vue&type=script&lang=js"
import style0 from "./building.vue?vue&type=style&index=0&id=87ef3f92&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87ef3f92",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\链商2\\pc-new-jf\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('87ef3f92')) {
      api.createRecord('87ef3f92', component.options)
    } else {
      api.reload('87ef3f92', component.options)
    }
    module.hot.accept("./building.vue?vue&type=template&id=87ef3f92&scoped=true", function () {
      api.rerender('87ef3f92', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/roomSite/building.vue"
export default component.exports