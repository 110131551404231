import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getBrowse, intention } from '@/api/roomCar';
import { mapGetters } from 'vuex';
export default {
  name: 'Attention',
  props: {
    tableHeight: {
      type: Number,
      default: 0
    },
    room: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    list: {
      type: [Object, Array],
      default: function _default() {
        return [];
      }
    },
    heat: {
      type: [Object, Array],
      default: function _default() {
        return {};
      }
    },
    type: {
      type: String,
      default: 'build'
    }
  },
  data: function data() {
    return {
      // 当前 选择tab 下标
      tabIndex: 0,
      // 总页数
      total: 0,
      // 每页条数
      pageSize: 10,
      // 页数
      page: 1,
      // 表格数据
      tableData: [],
      // 其余意向
      tableData1: [],
      // 浏览记录
      tableData2: [],
      // 刷新组将
      pagin: true
    };
  },
  computed: _objectSpread({}, mapGetters(['customSetting'])),
  created: function created() {
    this.getIntention(0);
  },
  methods: {
    // 列表切换
    handleTab: function handleTab(e) {
      this.tabIndex = e;
      this.page = 1;
      this.pagin = false;
      this.$nextTick(function () {
        this.pagin = true;
      });
      this.getIntention(e);
    },
    // 默认获取 第一意向人数
    getIntention: function getIntention(type) {
      var _this = this;
      switch (type) {
        case 0:
          intention({
            type: 1,
            id: this.$route.query.id,
            page: this.page,
            limit: this.pageSize
          }).then(function (res) {
            if (res.status === 200) {
              var _res$data = res.data,
                collect_list = _res$data.collect_list,
                count = _res$data.count;
              _this.tableData = collect_list;
              _this.total = count;
            }
          });
          break;
        case 1:
          intention({
            type: 2,
            id: this.$route.query.id,
            page: this.page,
            limit: this.pageSize
          }).then(function (res) {
            if (res.status === 200) {
              var _res$data2 = res.data,
                collect_list = _res$data2.collect_list,
                count = _res$data2.count;
              _this.tableData1 = collect_list;
              _this.total = count;
            }
          });
          break;
        case 2:
          getBrowse({
            room_id: this.$route.query.id,
            page: this.page,
            limit: this.pageSize
          }).then(function (res) {
            if (res.status === 200) {
              var _res$data3 = res.data,
                browse_info = _res$data3.browse_info,
                count = _res$data3.count;
              _this.tableData2 = browse_info;
              _this.total = count;
            }
          });
          break;
      }
    },
    // 获取pageSize
    handleSizeChange: function handleSizeChange(e) {
      this.pageSize = e;
      this.getIntention(this.tabIndex);
    },
    // 跳转页数
    handleCurrentChange: function handleCurrentChange(e) {
      this.page = e;
      this.getIntention(this.tabIndex);
    }
  }
};