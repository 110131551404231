import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//

import itemBox from './itemBox.vue';
import { mapState } from 'vuex';
export default {
  name: 'ProjectContent',
  components: {
    itemBox: itemBox
  },
  props: {
    // 单行数量
    sRowNum: {
      type: Number,
      default: 5
    },
    // 纵向数量
    sColumn: {
      type: [Number, String],
      default: 0
    },
    // 整体位置
    sPosition: {
      type: Number,
      default: 40
    },
    // 左右边距
    sMargin: {
      type: Number,
      default: 140
    },
    // 卡片左右边距
    sLeftMargin: {
      type: Number,
      default: 25
    },
    // 卡片上下边距
    sTopMargin: {
      type: Number,
      default: 50
    },
    // 上边距
    topHeight: {
      type: Number,
      default: 0
    },
    // 整体高度   如果存在设置的整体高度就直接使用整体高度，否则就使用上边距进行计算自适应高度
    contentHeight: {
      type: Number,
      default: 0
    }
  },
  computed: _objectSpread({}, mapState({
    editFormatList: function editFormatList(state) {
      return state.editFormat.list;
    }
  })),
  data: function data() {
    return {};
  }
};