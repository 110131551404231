var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "simple-setting" }, [
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("左右滚动速度")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            attrs: { min: 1, max: 10 },
            model: {
              value: _vm.editFormatList.host_json.notice_duration,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList.host_json, "notice_duration", $$v)
              },
              expression: "editFormatList.host_json.notice_duration",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }