var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "edit-format-bg" }, [
      _c("img", { attrs: { src: _vm.replaceImgshow(_vm.lottery.logo) } }),
    ]),
    _c("div", { staticClass: "box" }, [
      _c("div", { staticClass: "box-content" }, [
        _c("div", { staticClass: "box-left" }, [
          _c("div", { staticClass: "box-left-title" }, [
            _vm._v(" 摇号结果实时公示 "),
          ]),
          _c(
            "div",
            { staticClass: "lotteryWindow" },
            _vm._l(_vm.window_list, function (item, index) {
              return _c("div", { key: item.id, staticClass: "window-list" }, [
                _c("div", { staticClass: "sill-name lists-box" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _c(
                  "div",
                  {
                    class: [
                      "sill-val",
                      "lists-box",
                      { repeatStyle: item.new_code_info.status == 1 },
                    ],
                  },
                  [
                    _c("span", { staticStyle: { "letter-spacing": "0.3em" } }, [
                      _vm._v(" " + _vm._s(item.new_code_info.code)),
                    ]),
                    item.new_code_info.status == 1
                      ? _c("p", { staticClass: "pText" }, [_vm._v(" 重复 ")])
                      : _vm._e(),
                  ]
                ),
              ])
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "box-right" }, [
          _c("div", { staticClass: "box-right-title" }, [
            _vm._v(" 滚动排序汇总 "),
          ]),
          _c("div", { staticClass: "rolling" }, [
            _vm._m(0),
            _c("div", { ref: "scrollRef", staticClass: "list-lr" }, [
              _c(
                "div",
                { staticClass: "lottery-lists" },
                [
                  _c(
                    _vm.scroll,
                    {
                      tag: "component",
                      attrs: {
                        "class-option": _vm.classOption,
                        data: _vm.lotteryLog == undefined ? [] : _vm.lotteryLog,
                      },
                      on: { ScrollEnd: _vm.rollComplete },
                    },
                    _vm._l(_vm.lotteryLog, function (item, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "lottery-lists-items" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "tables",
                              staticStyle: { width: "150px" },
                            },
                            [_vm._v(_vm._s(item.user_code))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "tables",
                              staticStyle: { width: "150px" },
                            },
                            [_vm._v(_vm._s(item.code))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "tables",
                              staticStyle: {
                                width: "440px",
                                "font-size": "36px",
                              },
                            },
                            [
                              item.other_remark
                                ? _c(
                                    "div",
                                    _vm._l(
                                      item.other_remark.split(";"),
                                      function (house_name, indexs) {
                                        return _c(
                                          "p",
                                          {
                                            key: indexs,
                                            staticClass: "houses",
                                          },
                                          [_vm._v(_vm._s(house_name))]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tab-title" }, [
      _c("div", { staticClass: "titles", staticStyle: { width: "150px" } }, [
        _vm._v("入场编号"),
      ]),
      _c("div", { staticClass: "titles", staticStyle: { width: "150px" } }, [
        _vm._v("摇号结果"),
      ]),
      _c("div", { staticClass: "titles", staticStyle: { width: "440px" } }, [
        _vm._v("原房号"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }