import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import newTabele from './newTabele';
import { debounce } from '@/utils/debounce';
export default {
  name: 'MergeTable',
  components: {
    newTabele: newTabele
  },
  props: {
    tableData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    dialogData: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    ids: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      cellIndex: null,
      // 两层合并的数据
      // hover  class
      isCell: false,
      // 关联弹框
      isRoom: false,
      // 折叠筐 默认打开
      activeName: '',
      dataList: [],
      // 搜索
      form: {
        status: '',
        search: ''
      },
      is_show: true,
      // 单条数据
      rowData: {},
      // 详情
      isOpen: false
    };
  },
  methods: {
    // 打开详情弹窗
    handleInfoMerge: function handleInfoMerge(row) {
      this.event_id = row.event_id;
      this.round_id = row.id;

      //  组装数据
      var data = {
        event_id: row.event_id,
        round_id: row.id
      };

      // 单条数据
      this.rowData = row;
      this.$emit('handleMerge', data);
      this.isOpen = true;
    },
    handleSearchData: function handleSearchData(data) {
      var datas = _objectSpread({
        event_id: this.event_id,
        round_id: this.round_id
      }, data);
      this.$emit('handleMerge', datas);
    },
    // 搜索清空
    handleSearch: function handleSearch(type) {
      var _this = this;
      if (type === 1) {
        var data = {
          round_id: this.round_id,
          search: ''
        };
        this.form.search = '';
        this.form.status = '';
        // this.$refs['ruleForm'].resetFields()
        this.$emit('handleSearch', data, type);
      } else {
        var _data = {
          round_id: this.round_id,
          search: this.form.search
        };
        this.is_show = false;
        this.$nextTick(function () {
          _this.is_show = true;
        });
        this.$emit('handleSearch', _data, type);
      }
    },
    // 父列表选中事件  全选 为全选
    handleItemCheck: function handleItemCheck(index) {
      this.$emit('handleItemCheck', index);
    },
    // 子列表 选中事件
    handleInfoCheck: function handleInfoCheck(index, inx) {
      var _this2 = this;
      if (this.dialogData[index].user[inx].is_check) {
        this.ids.push(this.dialogData[index].user[inx].id);
      } else {
        this.ids.map(function (item, i) {
          if (item === _this2.dialogData[index].user[inx].id) {
            _this2.ids.splice(i, 1);
          }
        });
      }
      this.$emit('handleInfoCheck', index);
    },
    // 重置关联
    handleReset: debounce(function (row) {
      var _this3 = this;
      var data = {
        round_id: row.id,
        event_id: row.event_id
      };
      this.$confirm('此操作不可复原，确认重置吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        _this3.$emit('handleReset', data);
        // this.isRoom = false
      }).catch(function () {
        var datas = _objectSpread({
          event_id: _this3.event_id,
          round_id: _this3.round_id
        }, data);
        _this3.$emit('handleMerge', datas);
      });
    }, 200, true),
    // 打开弹框
    handleMerge: debounce(function (row) {
      this.isRoom = true;
      this.event_id = row.event_id;
      this.round_id = row.id;

      //  组装数据
      var data = {
        event_id: row.event_id,
        round_id: row.id
      };

      // 单条数据
      this.rowData = row;
      this.$emit('handleMerge', data);
    }, 200, true),
    // 提交选中
    handleSaveUser: debounce(function () {
      //  没有选中的数据不能提交
      if (this.dialogData.round_user_list == null || this.dialogData.round_user_list.length === 0) {
        // this.$message({
        //     type: 'warning',
        //     message: '还没选择关联的客户'
        // });
        this.handleReset(this.rowData);
        return;
      }

      // 存储 id
      var ids = [];
      this.dialogData.round_user_list.forEach(function (item) {
        ids.push(item.id);
      });
      var data = {
        user_ids: ids,
        event_id: this.event_id,
        round_id: this.round_id
      };
      this.$emit('handleSaveId', data);
      // this.isRoom = false
    }, 200, true),
    // 表格合并方法(两层数据)
    arraySpanMethod: function arraySpanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (columnIndex === 0 || columnIndex === 1) {
        if (row.typeIndex) {
          // 如果有值,说明需要合并
          return [row.typeIndex, 1];
        } else return [0, 0];
      }
    },
    // 单元格移入事件
    handleMouse: function handleMouse(row, column, cell, event) {
      if (!row.typeIndex) {
        // 不符合合并行的条件手动加class
        // 只能给第一行加才有效, 把之前保存的第一行的索引存起来
        this.cellIndex = row.firstIndex;
        this.isCell = true;
      }
    },
    // 单元格移除事件
    handleMouseLeve: function handleMouseLeve() {
      this.isCell = false;
    },
    // 设置行样式
    setCellClass: function setCellClass(_ref2) {
      var row = _ref2.row,
        column = _ref2.column,
        rowIndex = _ref2.rowIndex,
        columnIndex = _ref2.columnIndex;
      // 给当前鼠标移入的合并行第一行加上样式名
      if (row.index === this.cellIndex) {
        return 'cellClass';
      }
    }
  }
};