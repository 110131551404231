import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import screenfull from '@/components/my-screen';
import Api from '../../../api/rowNumber';
import editFormatMixin from '@/views/editFormat/editFormatMixin';
export default {
  name: 'NumberOrder',
  components: {
    screenfull: screenfull
  },
  mixins: [editFormatMixin],
  data: function data() {
    return {
      // 定时器
      timeId: null,
      // 摇号 规则
      rand: {},
      // 摇号列表
      lottery: [],
      h: 0,
      // 轮次信息
      list: {},
      // 摇号按钮
      startButton: false,
      // 下一轮摇号
      is_next: false,
      // 获取当前轮次
      roundNumber: 0,
      // 剩余摇号人数
      laveNumber: 0,
      // 判断本组是否有下一轮
      is_rand_next: false,
      // 根据个数改变样式
      heightClass: 'height10',
      styleObject: {}
    };
  },
  // 初始化
  created: function created() {
    this.initStartLottery();
  },
  methods: {
    // 上一轮 跳转历史页面
    handleLastRound: function handleLastRound() {
      // 获取摇号结果
      var data = {
        id: this.rand.id,
        group_id: this.list.group_id,
        round: this.list.max_round,
        type: 'row'
      };
      this.$router.push({
        name: 'NumberOrderHistory',
        query: data
      });
    },
    // 初始化摇号
    initStartLottery: function initStartLottery() {
      var _this = this;
      // 获取参数
      var query = JSON.parse(JSON.stringify(this.$route.query));
      var data = {
        id: query.id,
        group_id: query.group_id,
        round: query.round
      };

      // 查询摇号接口
      Api.numberSelOrderRow(data).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
            list = _res$data.list,
            row = _res$data.list.row;

          // 活动参数
          _this.list = list;

          // 摇号规则
          _this.rand = row;

          // 本组是否结束
          _this.is_rand_next = list.end;

          // 获取未摇号人数
          _this.laveNumber = list.not_user_count;

          //   this.styleObject = {
          //     background: `url(${this.imgUrl + row.logo})100%, no-repeat`,
          //     backgroundSize: 'cover',
          //     backgroundPosition: 'center, center',
          //     height: '100%'
          //   }

          // 改变参数
          query.round = list.max_round === 0 ? list.max_round + 1 : list.max_round;

          // 更新路由
          _this.$router.push({
            path: _this.$route.path,
            query: query
          });
          if (list.max_round === 0) {
            _this.getStartLottery();
          }
          if (query.round === list.max_round) {
            _this.is_next = true;
          } else {
            _this.is_next = false;
          }

          // 页面个数 高度
          if (row.rand_count > 10) {
            _this.heightClass = 'height20';
          } else {
            _this.heightClass = 'height10';
          }
          _this.lottery = list.max_round_list;

          // 获取当前轮次
          _this.roundNumber = list.max_round === 0 ? list.max_round + 1 : list.max_round;

          // 覆盖数据
          if (list.max_round_list.length < row.rand_count) {
            _this.lottery = list.max_round_list;
          } else {
            _this.lottery = list.max_round_list;
          }
        }
      });
    },
    // 获取下一轮 客户
    getStartLottery: function getStartLottery() {
      var _this2 = this;
      // 获取参数
      var query = JSON.parse(JSON.stringify(this.$route.query));
      console.log(query);
      var data = {
        id: query.id,
        group_id: query.group_id,
        round: query.round
      };
      Api.numberStartRow(data).then(function (res) {
        if (res.status === 200) {
          var _res$data2 = res.data,
            list = _res$data2.list,
            row = _res$data2.list.row;

          // 摇号结束
          if (list.end) {
            _this2.$confirm('本轮排号已结束！', '提醒', {
              confirmButtonText: '确定',
              cancelButtonText: '返回首页',
              closeOnClickModal: false,
              closeOnPressEscape: false,
              showClose: false,
              type: 'warning'
            }).then(function () {}).catch(function () {
              _this2.$router.push({
                name: 'NumberIndex',
                query: {
                  id: _this2.$route.query.id
                }
              });
            });
            return;
          }

          // 获取 未摇号人数
          _this2.laveNumber = list.not_user_count - list.this_round.length;

          // 摇号规则
          _this2.rand = row;

          // 活动参数
          _this2.list = list;

          // 获取当前轮次  加+1
          _this2.roundNumber = list.max_round + 1;

          // 改变参数
          query.round = list.max_round + 1;

          // 更新路由
          _this2.$router.push({
            path: _this2.$route.path,
            query: query
          });

          //  当前组是否结束
          _this2.is_rand_next = list.end;

          // 更新路由
          _this2.$router.push({
            path: _this2.$route.path,
            query: query
          });

          // 覆盖数据
          if (list.this_round.length < row.rand_count) {
            _this2.lottery = list.this_round;
          } else {
            _this2.lottery = list.this_round;
          }
        }
      });
    }
  }
};