import _interopRequireWildcard from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import Router from 'vue-router';
/* Layout */
import Layout from '@/layout';
Vue.use(Router);

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
	roles: ['admin','editor']    control the page roles (you can set multiple roles)
	title: 'title'               the name show in sidebar and breadcrumb (recommend set)
	icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
	noCache: true                if set true, the page will no be cached(default is false)
	affix: true                  if set true, the tag will affix in the tags-view
	breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
	activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  meta: {
    noCache: true
  },
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
},
// {
// 	path: '/login',
// 	component: () => import('@/views/authLogin/index'),
// 	hidden: true
// },
{
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/auth-redirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/401'));
    });
  },
  hidden: true
}, {
  path: '/lottery',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/lottery'));
    });
  },
  name: 'Lottery',
  meta: {
    title: '选房摇号',
    icon: 'icon'
  },
  hidden: true
}, {
  path: '/randRoom',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/randRoom/randRoom'));
    });
  },
  name: 'RandRoom',
  meta: {
    title: '选房抽号'
  },
  hidden: true
}, {
  path: '/formulaStartLootery',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/ formulaLottery/startLootery'));
    });
  },
  name: 'formulaStartLootery',
  meta: {
    title: '乒乓摇号'
  },
  hidden: true
}, {
  path: '/formulaPublicity',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/ formulaLottery/formulaPublicity'));
    });
  },
  name: 'formulaPublicity',
  meta: {
    title: '公示大屏'
  },
  hidden: true
}, {
  path: '/numberIndex',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/rowNumber/numberIndex'));
    });
  },
  name: 'NumberIndex',
  meta: {
    title: '选房摇号',
    icon: 'icon'
  },
  hidden: true
}, {
  path: '/startLottery',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/lottery/startLottery'));
    });
  },
  name: 'StartLottery',
  meta: {
    title: '选房摇号',
    icon: 'icon'
  },
  hidden: true
}, {
  path: '/startLotteryOrder',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/lottery/startLottery-order'));
    });
  },
  name: 'StartLotteryOrder',
  meta: {
    title: '选房摇号',
    icon: 'icon'
  },
  hidden: true
}, {
  path: '/startRowNumber',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/lottery/startRowNumber'));
    });
  },
  name: 'StartRowNumber',
  meta: {
    title: '选房摇号',
    icon: 'icon'
  },
  hidden: true
}, {
  path: '/startLotteryNo',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/lottery/startLottery-no'));
    });
  },
  name: 'StartLotteryNo',
  meta: {
    title: '选房摇号',
    icon: 'icon'
  },
  hidden: true
}, {
  path: '/startLotteryHistory',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/lottery/startLottery-history'));
    });
  },
  name: 'StartLotteryHistory',
  meta: {
    title: '选房摇号',
    icon: 'icon'
  },
  hidden: true
}, {
  path: '/startRowNumberOrder',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/lottery/startRowNumber-order'));
    });
  },
  name: 'StartRowNumberOrder',
  meta: {
    title: '选房摇号',
    icon: 'icon'
  },
  hidden: true
}, {
  path: '/startLotteryNoHistory',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/lottery/startLottery-noHistory'));
    });
  },
  name: 'StartLotteryNoHistory',
  meta: {
    title: '选房摇号',
    icon: 'icon'
  },
  hidden: true
}, {
  path: '/startLotteryOrderHistory',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/lottery/startLottery-orderHistory'));
    });
  },
  name: 'StartLotteryOrderHistory',
  meta: {
    title: '选房摇号',
    icon: 'icon'
  },
  hidden: true
}, {
  path: '/numberOrder',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/rowNumber/numberOrder'));
    });
  },
  name: 'NumberOrder',
  meta: {
    title: '选房排号',
    icon: 'icon'
  },
  hidden: true
}, {
  path: '/numberOrderHistory',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/rowNumber/numberOrderHistory'));
    });
  },
  name: 'NumberOrderHistory',
  meta: {
    title: '选房排号',
    icon: 'icon'
  },
  hidden: true
}, {
  path: '/screenShow',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/screenShow/index'));
    });
  },
  hidden: true,
  meta: {
    noCache: true
  }
}, {
  path: '/lotteryShow',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/Lottery/show'));
    });
  },
  hidden: true,
  meta: {
    noCache: true
  }
}, {
  path: '/lottery/open',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/Lottery/open'));
    });
  },
  hidden: true,
  meta: {
    noCache: true
  }
}, {
  path: '/lotterySplitScreen',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/lotterySplitScreen'));
    });
  },
  meta: {
    title: '摇号分屏'
  },
  hidden: true
}, {
  path: '/lotteryEntryIndex',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/lotteryEntryIndex'));
    });
  },
  meta: {
    title: '摇号录入'
  },
  hidden: true
}, {
  path: '/editFormat',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/editFormat'));
    });
  },
  name: 'EditFormat',
  meta: {
    title: '摇号画面版式'
  },
  hidden: true
}, {
  path: '/publicitySet',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/publicitySet'));
    });
  },
  name: 'PublicitySet',
  meta: {
    title: '公示画面版式'
  },
  hidden: true
}, {
  path: '/lotteryPublicity',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/lottery/publicity'));
    });
  },
  name: 'lotteryPublicity',
  meta: {
    title: '公示大屏'
  },
  hidden: true
}, {
  path: '/dragScreen',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/dragScreen/index'));
    });
  },
  hidden: true
}, {
  path: '/setLedActivityEnd',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/setLedActivityEnd/index'));
    });
  },
  name: 'SetLedActivityEnd',
  meta: {
    title: 'LED活动结束设置'
  },
  hidden: true
}, {
  path: '/activeReport',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/activityReport/index'));
    });
  },
  hidden: true
}, {
  path: '/activeoverview',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/activityReport/activeoverview'));
    });
  },
  hidden: true
}, {
  path: '/wpsPrint',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/wpsPrint/wpsPrint'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: '首页',
      icon: 'index',
      affix: true,
      noCache: true
    }
  }, {
    path: 'approvalDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/approvalDetails/index'));
      });
    },
    name: 'ApprovalDetail',
    meta: {
      title: '审批详情',
      icon: 'icon',
      affix: false,
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/assistHouse',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/assistHouse/index'));
    });
  },
  hidden: true,
  meta: {
    noCache: true
  }
}, {
  path: '/plane',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/plane/index'));
    });
  },
  hidden: true,
  meta: {
    noCache: true
  }
}, {
  path: '/printUp',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/tool/lottery/printUp/index'));
    });
  },
  hidden: true,
  meta: {
    noCache: true
  }
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export var asyncRoutes = [{
  path: '/sincerityRegister',
  component: Layout,
  redirect: '/sincerityRegister',
  meta: {
    title: '诚意登记',
    icon: 'opening',
    roles: ['admin', 'sendno/index', 'identifysign/index', 'auditevent/index', 'audit/auditalonelist', 'audit/auditfirstlist', 'audit/auditcomplexlist', 'identifyaudit/auditalonelist', 'identifyaudit/auditfirstlist', 'identifyaudit/auditcomplexlist', 'identifypay/index']
  },
  children: [{
    path: 'sincerityRegister',
    name: 'SincerityRegister',
    meta: {
      title: '登记设置',
      roles: ['admin', 'auditevent/index']
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sincerityRegister'));
      });
    }
  }, {
    path: 'registerUser',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/registerUser'));
      });
    },
    name: 'RegisterUser',
    meta: {
      title: '登记客户',
      roles: ['admin', 'register/list']
    }
  }, {
    path: 'info',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/registerUser/info/index'));
      });
    },
    name: 'RegisterUserInfo',
    meta: {
      title: '登记客户详情',
      roles: ['admin', 'register/info']
    }
  }, {
    path: 'toolReview',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/tool/review'));
      });
    },
    name: 'ToolReview',
    meta: {
      title: '报名审核',
      roles: ['admin', 'audit/auditalonelist', 'audit/auditfirstlist', 'audit/auditcomplexlist']
    }
  }, {
    path: 'pledge',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/pledge/pledge'));
      });
    },
    name: 'Pledge',
    meta: {
      title: '认筹审核',
      roles: ['admin', 'identifyaudit/auditalonelist', 'identifyaudit/auditfirstlist', 'identifyaudit/auditcomplexlist', 'identifyaudit/auditcomplexlist']
    }
  },
  /* {
  	path: 'price',
  	component: () => import('@/views/price/index'),
  	name: 'Price',
  	meta: {
  		title: '认筹金记录',
  		roles: ['admin', 'identifypay/index']
  	}
  }, */
  {
    path: 'details',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/price/details'));
      });
    },
    name: 'PriceDefault',
    meta: {
      title: '认筹金详情',
      roles: ['admin', 'identifypay/detail']
    },
    hidden: true
  }, {
    path: 'pledgeContract',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/pledgeContract/index'));
      });
    },
    name: 'pledgeContract',
    meta: {
      title: '诚意签约',
      roles: ['admin', 'identifysign/index']
    }
  }, {
    path: 'sendNumber',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/tool/sendNumber/index'));
      });
    },
    name: 'SendNumber',
    meta: {
      title: '认筹派号',
      roles: ['admin', 'sendno/index']
    }
  }, {
    path: 'sendNumberList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sendNumber/sendNumber'));
      });
    },
    name: 'SendNumberList',
    meta: {
      title: '派号列表',
      roles: ['admin', 'sendno/index']
    },
    hidden: true
  }]
},
/* {
	path: '/opening',
	component: Layout,
	redirect: '/opening/parameter', // 重定向
	meta: {
		title: '开盘维护',
		icon: 'opening',
		roles: ['admin', 'project/index', 'event/index', 'roomcate/index']
	},
	name: 'Opening',
	children: [
		{
			path: 'parameter',
			component: () => import('@/views/opening/parameter'),
			name: 'Parameter',
			meta: { title: '参数设置', roles: ['admin', 'project/index'] }
		},
     {
       path: 'paymentPage',
       hidden: true,
       component: () => import('@/views/opening/paymentPage.vue'),
       name: 'paymentPage',
       meta: { title: '付款方式设置', roles: ['admin', 'project/index'] }
     },
		{
			path: 'activity',
			component: () => import('@/views/opening/activity'),
			name: 'Activity',
			meta: { title: '活动计划', noCache: true, roles: ['admin', 'event/index'] }

		},
		{
			path: 'graphic',
			component: () => import('@/views/opening/graphic'),
			name: 'Graphic',
			meta: { title: '图文资料', roles: ['admin', 'roomcate/index'] }
		},
		{
			path: 'review',
			component: () => import('@/views/opening/review'),
			name: 'Review',
			meta: { title: '资格审核资料' },
			hidden: true
		}
	]
}, */
{
  path: '/userSite',
  component: Layout,
  redirect: '/userSite/index',
  children: [
  /* {
  	path: 'index',
  	component: () => import('@/views/userSite/index'),
  	name: 'Index',
  	meta: { title: '开盘客户', icon: 'user', roles: ['admin', 'user/index'] }
  }, */
  {
    path: 'addUser',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/userSite/child/addUser'));
      });
    },
    name: 'AddUser',
    meta: {
      title: '新增客户',
      roles: ['admin', 'user/add']
    }
  }, {
    path: 'editUser',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/userSite/child/editUser'));
      });
    },
    name: 'EditUser',
    meta: {
      title: '修改客户',
      roles: ['admin', 'user/edit']
    }
  }, {
    path: 'userDetails',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/userSite/userDetails'));
      });
    },
    name: 'UserDetails',
    meta: {
      title: '客户详情',
      noCache: true,
      roles: ['admin', 'user/details']
    }
  }, {
    path: 'mergeUser',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/userSite/mergeUser'));
      });
    },
    name: 'MergeUser',
    meta: {
      title: '关联场次',
      roles: ['admin', 'user/relationround']
    }
  }, {
    path: 'userAudit',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/userSite/userAudit'));
      });
    },
    name: 'UserAudit',
    meta: {
      title: '审核',
      roles: ['admin']
    }
  }, {
    path: 'userAuditInfo',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/userSite/child/userAuditInfo'));
      });
    },
    name: 'UserAuditInfo',
    meta: {
      title: '审核详情',
      roles: ['admin']
    }
  }]
}, {
  path: '/roomSite',
  component: Layout,
  redirect: '/roomSite/house',
  meta: {
    title: '房源管理',
    icon: 'room-icon',
    roles: ['admin', 'room/index', 'car/index']
  },
  name: 'RoomSite',
  children: [{
    path: 'house',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/roomSite/house'));
      });
    },
    name: 'House',
    meta: {
      title: '房源设置',
      roles: ['admin', 'room/index']
    }
  }, {
    path: 'building',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/roomSite/building'));
      });
    },
    name: 'Building',
    meta: {
      title: '车位设置',
      roles: ['admin', 'car/index']
    }
  }, {
    path: 'plan',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/roomSite/plan'));
      });
    },
    name: 'Plan',
    meta: {
      title: '车位平面图',
      roles: ['admin', 'car/index']
    },
    hidden: true
  }, {
    path: 'houseEdit',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/roomSite/houseEdit'));
      });
    },
    name: 'HouseEdit',
    meta: {
      title: '修改房源信息',
      roles: ['admin', 'room/edit'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'houseInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/roomSite/houseInfo'));
      });
    },
    name: 'HouseInfo',
    meta: {
      title: '房源信息',
      roles: ['admin', 'room/details'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'mergeHouse',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/roomSite/mergeHouse'));
      });
    },
    name: 'MergeHouse',
    meta: {
      title: '住宅关联'
    },
    hidden: true
  }, {
    path: 'buildingEdit',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/roomSite/buildingEdit'));
      });
    },
    name: 'BuildingEdit',
    meta: {
      title: '修改别墅信息',
      roles: ['admin', 'car/edit'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'car',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/roomSite/car'));
      });
    },
    name: 'Car',
    meta: {
      title: '车位设置',
      roles: ['admin', 'car/index']
    },
    hidden: true
  }, {
    path: 'buildingInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/roomSite/buildingInfo'));
      });
    },
    name: 'BuildingInfo',
    meta: {
      title: '别墅信息',
      roles: ['admin', 'car/details'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'mergeBuilding',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/roomSite/mergeBuilding'));
      });
    },
    name: 'MergeBuilding',
    meta: {
      title: '车位关联'
    },
    hidden: true
  }, {
    path: 'buildTerminal',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/roomSite/buildTerminal'));
      });
    },
    name: 'BuildTerminal',
    meta: {
      title: '车位终端显示'
    },
    hidden: true
  }, {
    path: 'houseTerminal',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/roomSite/houseTerminal'));
      });
    },
    name: 'HouseTerminal',
    meta: {
      title: '住宅终端显示'
    },
    hidden: true
  }]
}, {
  path: '/tool',
  component: Layout,
  redirect: '/tool/review',
  // 重定向
  meta: {
    title: '工具',
    icon: 'tool',
    roles: ['admin', 'roomhot/roomlist', 'reserv/index', 'reserv/index', 'eventrand/index', 'eventranduser/index', 'led/list', 'rownumber/index', 'help/scan', 'qrcode/list', 'signuser/timinglist', 'entry/index', 'signuser/timinglist', 'signuser/signindex', 'signuser/signupindex', 'admission/index', 'prints/index', 'randroom/list', 'pingpong/index'],
    isReturnRoles: true
  },
  name: 'Tool',
  children: [
  /* {
  	path: 'reviewDetails',
  	component: () => import('@/views/tool/review/reviewDetails'),
  	name: 'ReviewDetails',
  	meta: { title: '审核详情' },
  	hidden: true
  },
  {
  	path: 'editReviewDetails',
  	component: () => import('@/views/tool/review/editReviewDetails'),
  	name: 'EditReviewDetails',
  	meta: { title: '修改登记表' },
  	hidden: true
  },
  {
  	path: 'schedule',
  	component: () => import('@/views/tool/review/schedule'),
  	name: 'Schedule',
  	meta: { title: '填写附表' },
  	hidden: true
  },
  {
  	path: 'screen',
  	component: () => import('@/views/tool/screen'),
  	name: 'Screen',
  	meta: { title: '销售大屏', noCache: true, roles: ['admin', 'led/list'] }
  		},
  {
  	path: 'reserved',
  	component: () => import('@/views/tool/reserved'),
  	name: 'Reserved',
  	meta: { title: '预留设置', roles: ['admin', 'reserv/index'] }
  },
  {
  	path: 'signIn',
  	component: () => import('@/views/tool/signUser/signIn/Index'),
  	name: 'signIn',
  	meta: { title: '开盘&预约签到', roles: ['admin', 'signuser/signindex', 'signuser/signupindex'], noCache: false, isReturnRoles: true }
  },
       {
       path: 'formulaLotteryRecord',
       component: () => import('@/views/tool/ formulaLottery/formulaLotteryRecord'),
       name: 'formulaLotteryRecord',
       meta: { title: '摇号记录', noCache: false, roles: ['admin', 'pingpong/list'] },
       hidden: true
     },
  {
  	path: 'lotteryList',
  	component: () => import('@/views/tool/lottery/lotteryList'),
  	name: 'LotteryList',
  	meta: { title: '选房摇号', noCache: false, roles: ['admin', 'eventrand/index'] }
  },
  {
  	path: 'randRoomList',
  	component: () => import('@/views/tool/randRoom/randRoomList'),
  	name: 'randRoomList',
  	meta: { title: '选房抽号', noCache: false, roles: ['admin', 'randroom/list'] }
  },
  {
  	path: 'lotteryPreset',
  	component: () => import('@/views/tool/lottery/lotteryPreset'),
  	name: 'LotteryPreset',
  	meta: { title: '摇号预设', roles: ['admin', 'eventranduser/index'] }
  },
  {
  	path: 'admission',
  	component: () => import('@/views/tool/admission/Index'),
  	name: 'admission',
  	meta: { title: '入场审核', roles: ['admin', 'admission/index'], noCache: false }
  },
  {
  	path: 'assistHouse',
  	component: () => import('@/views/tool/assistHouse/index'),
  	name: 'AssistHouse',
  	meta: { title: '协助选房', roles: ['admin', 'help/scan'] }
  },
  {
  	path: 'heat',
  	component: () => import('@/views/tool/heat/heat'),
  	name: 'Heat',
  	meta: { title: '热度干预', roles: ['admin', 'roomhot/roomlist'] }
  },
  {
  	path: 'rowNumber',
  	component: () => import('@/views/tool/rowNumber/rowNumber'),
  	name: 'RowNumber',
  	meta: { title: '签约排号', roles: ['admin', 'rownumber/index'] }
  },
  {
  	path: '/lottery/set',
  	component: () => import('@/views/Lottery/set'),
  	name: 'setLottery',
  	meta: { title: '录入摇号', roles: ['admin', 'entry/index'] }
  },
     {
       path: 'formulaLotteryList',
       component: () => import('@/views/tool/ formulaLottery/lotteryList'),
       name: 'formulaLotteryList',
       meta: { title: '窗口摇号', noCache: false, roles: ['admin', 'pingpong/index'] }
     },
  {
  	path: 'timingList',
  	component: () => import('@/views/tool/signUser/timing'),
  	name: 'timingList',
  	meta: { title: '预约记录', roles: ['admin', 'signuser/timinglist'], noCache: false }
  },
  {
  	path: 'qrCodeGeneration',
  	component: () => import('@/views/tool/qrCodeGeneration/index'),
  	name: 'QrCodeGeneration',
  	meta: { title: '码牌生成器', roles: ['admin', 'qrcode/list'], noCache: false }
  }, */
  {
    path: 'index',
    name: 'templatePrinting',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/templatePrinting/index'));
      });
    },
    meta: {
      title: '套打配置',
      roles: ['admin', 'prints/index']
    }
  }
  /* {
  	path: 'manage',
  	component: () => import('@/views/tool/manage'),
  	name: 'Manage',
  	meta: { title: '销控管理', roles: ['admin'] },
  	hidden: true
  },
  		{
  	path: '/lottery/record',
  	component: () => import('@/views/Lottery/record'),
  	hidden: true,
  	name: 'lotteryEntryRecord',
  	meta: { title: '摇号记录', roles: ['admin', 'entry/list'] }
  },
  {
  	path: '/lottery/version',
  	component: () => import('@/views/Lottery/version'),
  	hidden: true,
  	name: 'lotteryEntryVersion',
  	meta: { title: '版本管理', roles: ['admin', 'entry/list'] }
  },
  {
  	path: 'setUp',
  	component: () => import('@/views/tool/rowNumber/setUp'),
  	name: 'SetUp',
  	meta: { title: '设置显示版式', roles: ['admin'] },
  	hidden: true
  },
  {
  	path: 'lotteryRecord',
  	component: () => import('@/views/tool/lottery/lotteryRecord'),
  	name: 'LotteryRecord',
  	meta: { title: '摇号记录' },
  	hidden: true
  },
  {
  	path: 'editRule',
  	component: () => import('@/views/tool/operate/editRule'),
  	name: 'EditRule',
  	meta: { title: '编辑规则' },
  	hidden: true
  },
  {
  	path: 'batchRule',
  	component: () => import('@/views/tool/operate/batchRule'),
  	name: 'BatchRule',
  	meta: { title: '设置摇号规则' },
  	hidden: true
  },
  {
  	path: 'basicsetting',
  	component: () => import('@/views/tool/basicsetting'),
  	name: 'Basicsetting',
  	meta: { title: '基础设置' },
  	hidden: true
  }, {
  	path: 'presetList',
  	component: () => import('@/views/tool/lottery/presetList'),
  	name: 'PresetList',
  	meta: { title: '预设名单' },
  	hidden: true
  }, {
  	path: 'showMould',
  	component: () => import('@/views/tool/lottery/showMould'),
  	name: 'ShowMould',
  	meta: { title: '设置显示版式' },
  	hidden: true
  },
  {
  	path: 'editNumber',
  	component: () => import('@/views/tool/rowNumber/editNumber'),
  	name: 'EditNumber',
  	meta: { title: '编辑规则' },
  	hidden: true
  },
  {
  	path: 'numberAnnal',
  	component: () => import('@/views/tool/rowNumber/numberAnnal'),
  	name: 'NumberAnnal',
  	meta: { title: '排号记录' },
  	hidden: true
  },
  
  		{
  	path: 'SignInList',
  	component: () => import('@/views/tool/signUser/signIn/SignInList'),
  	name: 'SignInList',
  	meta: { title: '开盘&预约签到记录', roles: ['admin', 'signuser/signlog', 'signuser/signuplog'], noCache: false },
  	hidden: true,
  },
  {
  	path: 'SigninprintShow',
  	component: () => import('@/views/tool/signUser/signIn/SigninprintShow'),
  	name: 'SigninprintShow',
  	meta: { title: '签到打印设置', roles: ['admin', 'signuser/getprint'], noCache: false },
  	hidden: true,
  },
  		{
  	path: 'ReleaseLog',
  	component: () => import('@/views/tool/admission/ReleaseLog'),
  	name: 'ReleaseLog',
  	meta: { title: '入场审核记录', roles: ['admin', 'admission/releaselog'], noCache: false },
  	hidden: true,
  }, */]
},
/* {
	path: '/activityData',
	component: Layout,
	redirect: '/activityData/sales',
	name: 'ActivityData',
	meta: {
		title: '活动数据',
		icon: 'activity-data',
		roles: ['admin', 'userstatus/index', 'roomstatus/index']
	},
	children: [
		{
			path: 'sales',
			component: () => import('@/views/activityData/sales'),
			name: 'Sales',
			meta: { title: '销售监控', roles: ['admin'] },
			hidden: true
		},
		{
			path: 'client',
			component: () => import('@/views/activityData/client'),
			name: 'Client',
			meta: { title: '客户监控', roles: ['admin'] },
			hidden: true
		},
		{
			path: 'roomTable',
			component: () => import('@/views/activityData/roomTable'),
			name: 'RoomTable',
			meta: { title: '房源状态表', roles: ['admin', 'roomstatus/index'] }
		},
		{
			path: 'userTable',
			component: () => import('@/views/activityData/userTable'),
			name: 'UserTable',
			meta: { title: '客户状态表', roles: ['admin', 'userstatus/index'] }
		}
	]
}, */
{
  path: '/analysis',
  component: Layout,
  redirect: '/analysis/index',
  children: [{
    path: 'analysisIndex',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/index'));
      });
    },
    name: 'AnalysisIndex',
    meta: {
      title: '落位分析',
      icon: 'analysis',
      roles: ['admin']
    },
    hidden: true
  }]
},
/* {
	path: '/recording',
	component: Layout,
	redirect: '/recording/chooseRoom',
	meta: { title: '交易记录', icon: 'transaction', roles: ['admin', 'order/index', 'pay/list', 'sign/list'] },
	name: 'Recording',
	children: [
		{
			path: '/recording/chooseRoom',
			component: () => import('@/views/recording/ChooseRoom'),
			name: 'ChooseRoom',
			meta: { title: '订单记录 ', noCache: false, roles: ['admin', 'order/index'] }
		},
		{
			path: '/recording/orderDetails',
			hidden: true,
			component: () => import('@/views/recording/orderDetails'),
			name: 'orderDetails',
			meta: { title: '订单详情 ', noCache: false, roles: ['admin', 'order/index'] }
		},
		{
			path: '/recording/finance/sincerity',
			component: () => import('@/views/recording/finance/sincerity'),
			name: 'Sincerity',
			meta: { title: '诚意金记录', roles: ['admin'] },
			hidden: true
		},
		{
			path: '/recording/finance/deposit',
			component: () => import('@/views/recording/finance/deposit'),
			name: 'Deposit',
			meta: { title: '定金记录', roles: ['admin', 'pay/list'] }
		},
		{
			path: '/recording/subscribe',
			component: () => import('@/views/recording/subscribe'),
			name: 'Subscribe',
			meta: { title: '签约存档', roles: ['admin', 'sign/list'] }
		},
		{
			path: '/recording/finance/electronic-order',
			component: () => import('@/views/recording/finance/electronic-order'),
			name: 'ElectronicOrder',
			meta: { title: '电子收据' },
			hidden: true
		},
		{
			path: '/recording/finance/contract',
			component: () => import('@/views/recording/finance/contract'),
			name: 'Contract',
			meta: { title: '电子合同' },
			hidden: true
		}

	]
}, */
{
  path: '/staffManage',
  component: Layout,
  redirect: '/staffManage/index',
  children: [{
    path: 'staff',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/staffManage/staff'));
      });
    },
    name: 'Staff',
    meta: {
      title: '员工管理',
      icon: 'Staff',
      roles: ['admin', 'admins/list', 'role/list', 'team/list']
    }
  }]
}, {
  path: '/logManagement',
  component: Layout,
  redirect: '/logManagement/index',
  // hidden: true,
  title: '日志管理',
  icon: 'log',
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/logManagement/index'));
      });
    },
    name: 'LogDetail',
    meta: {
      title: '日志管理',
      icon: 'log',
      roles: ['admin', 'log/loginlog', 'log/adminlog']
    }
  }]
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
router.beforeEach(function (to, from, next) {
  if (to.path == '/tool/assistHouse') {
    //协助选房直接打开新页面
    var path = router.resolve({
      path: '/assistHouse'
    });
    window.open("".concat(path.href), '_blank');
  } else {
    next();
  }
});
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;