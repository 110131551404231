/**
 * @ author (yang)
 * @ {预留管理请求接口}
 */

import request from '@/utils/request';

// 导入exel表格
export function importExel(params) {
  return request({
    url: '/reserv/Import',
    method: 'post',
    params: params
  });
}

// 预留列表  条件筛选 搜索
export function resLists(params) {
  return request({
    url: '/reserv/Index',
    method: 'get',
    params: params
  });
}
export function Export(params) {
  return request({
    url: '/reserv/Export',
    method: 'GET',
    params: params
  });
}

// 删除预留
export function resDel(params) {
  return request({
    url: '/reserv/Delete',
    method: 'get',
    params: params
  });
}

// 释放
export function Unlock(params) {
  return request({
    url: '/reserv/Unlock',
    method: 'GET',
    params: params
  });
}

// 审批接口
export function resSubmit(params) {
  return request({
    url: '/reserv/Audit',
    method: 'get',
    params: params
  });
}

// 首页 审核预留名单
export function examine(params) {
  return request({
    url: '/Index/toExamine',
    method: 'get',
    params: params
  });
}

// 预留状态
export function reserveStatus(params) {
  return request({
    url: '/reserv/Status',
    method: 'get',
    params: params
  });
}

// 导出模板
export function exportTemp(params) {
  return request({
    url: '/reserv/Download',
    method: 'get',
    params: params
  });
}

// 新增预留搜索
export function searchReserveUser(data) {
  return request({
    url: '/reserv/Add',
    method: 'post',
    data: data
  });
}

// 修改预留搜索
export function searchReserveEdit(data) {
  return request({
    url: '/reserv/Edit',
    method: 'post',
    data: data
  });
}

// 新增预留 提交
export function submitNewReserve(params) {
  return request({
    url: '/reserv/Add',
    method: 'get',
    params: params
  });
}

// 修改预留 提交
export function submitEditReserve(params) {
  return request({
    url: '/reserv/Edit',
    method: 'get',
    params: params
  });
}

// 预留导出
export function exportReserve(params) {
  return request({
    url: '/reserv/Export',
    method: 'get',
    params: params
  });
}

// 预留保存数据校验
export function reservRedisCheck(params) {
  return request({
    url: '/reserv/redisCheck',
    method: 'get',
    params: params
  });
}