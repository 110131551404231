import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createMap } from './utils/map';
export default {
  name: 'Map',
  props: {
    id: {
      type: String | Number,
      default: 0
    },
    level: {
      type: Number,
      default: 3
    }
  },
  data: function data() {
    return {
      // 楼层名称
      title: '2222222',
      // 地图宽高
      width: 500,
      height: 600,
      // 地图查询
      map: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    // this.mapInit()

    document.addEventListener('mouseup', function (u_e) {
      document.onmousemove = null;
      document.onmouseup = null;
    });
    this.$nextTick(function () {
      _this.mapInit();
    });
  },
  methods: {
    mapInit: function mapInit() {
      var container = this.$refs['car-map-container'];
      this.map = createMap(this.level, container);
      this.sendMessage();
    },
    sendMessage: function sendMessage(id) {
      console.log(id);
      // let ids = [1]
      // let i = 1
      // setInterval(() => {
      //     i++
      //     this.map.matchIds([i])
      // }, 1000)
    },
    mousedown: function mousedown(e, type) {
      this.drag(e, type);
    },
    drag: function drag(e, type) {
      var self = this;
      var target = e.target;
      var mouseX = e.clientX;
      var mouseY = e.clientY;
      // 获取初始宽高
      var width = this.width;
      var height = this.height;
      document.onmousemove = function (m_e) {
        var move_x = m_e.clientX - mouseX;
        var move_y = m_e.clientY - mouseY;
        if (type == "R") {
          self.width = width + move_x;
        } else if (type == "B") {
          self.height = height + move_y;
        } else {
          self.width = width + move_x;
          self.height = height + move_y;
        }
      };
    }
  }
};