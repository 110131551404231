var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "report-wrapper" }, [
    _vm.loading
      ? _c("div", { staticClass: "loading_box" }, [
          _c("i", { staticClass: "icon el-icon-loading" }),
          _c("p", { staticClass: "tips" }, [_vm._v("开盘报告下载中...")]),
        ])
      : _vm._e(),
    _vm.reqFinish
      ? _c(
          "div",
          { staticClass: "active-report", attrs: { id: "pdf_node" } },
          [
            _c("banner", {
              attrs: { time: _vm.project.end_time, title: _vm.project.name },
            }),
            _c("div", { staticClass: "card-wrapper" }, [
              _c(
                "div",
                { staticClass: "kaipan-sale" },
                [
                  _c("report-title", {
                    attrs: { title: "开盘总结", num: "01" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kaipan-sale-card" },
                [
                  _c(
                    "card",
                    { attrs: { "is-bottom": false } },
                    [
                      _c("template", { slot: "top" }, [
                        _vm.info.summarize
                          ? _c("div", { staticClass: "infoContent" }, [
                              _c(
                                "div",
                                { staticClass: "info_box" },
                                _vm._l(_vm.info.summarize, function (item, i) {
                                  return _c(
                                    "list-item",
                                    {
                                      key: "advance" + i,
                                      attrs: { num: i + 1 },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(" " + _vm._s(item) + " "),
                                      ]),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "kaipan-sale-top" },
                          [
                            _c("report-subtitle", {
                              attrs: { title: "销售概述" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "card-one-wrapper" }, [
                          _c("div", { staticClass: "card-one-box" }, [
                            _c("div", { staticClass: "card-box1" }, [
                              _c("div", { staticClass: "card-box-top" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/report/houseIconE.png"),
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "card-box-bottom" }, [
                                _c("div", { staticClass: "box-bottom-top" }, [
                                  _vm.isroomOrcar == 0
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.isNullNum(
                                                _vm.projectData2.cate1
                                                  .order_count +
                                                  _vm.projectData2.cate2
                                                    .order_count
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.isNullNum(
                                                _vm.projectData_project
                                                  .order_count
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                  _vm.isroomOrcar == 1
                                    ? _c(
                                        "span",
                                        { staticClass: "box-bottom-bottom" },
                                        [_vm._v("套")]
                                      )
                                    : _vm._e(),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "box-bottom-bottom" },
                                  [
                                    _vm.isroomOrcar == 0
                                      ? _c("span", [
                                          _vm._v(" 住宅+车位"),
                                          _c("br"),
                                          _vm._v("销售数量 "),
                                        ])
                                      : _c("span", [_vm._v(" 销售数量 ")]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "card-box2" }, [
                              _c("div", { staticClass: "card-box-top" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/report/purse.png"),
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "card-box-bottom" }, [
                                _c("div", { staticClass: "box-bottom-top" }, [
                                  _vm.isroomOrcar == 0
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (
                                                _vm.isNullNum(
                                                  +_vm.projectData2.cate1
                                                    .sum_price +
                                                    +_vm.projectData2.cate2
                                                      .sum_price
                                                ) / 10000
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              +_vm.projectData_project
                                                .order_count &&
                                                !+_vm.projectData_project
                                                  .sum_price
                                                ? "未提供"
                                                : _vm.tenThousandTrans(
                                                    _vm.projectData_project
                                                      .sum_price
                                                  )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "box-bottom-bottom" },
                                  [
                                    _vm.isroomOrcar == 0
                                      ? _c("span", [
                                          _vm._v(" 住宅+车位"),
                                          _c("br"),
                                          _vm._v("销售额(万元) "),
                                        ])
                                      : _c("span", [_vm._v(" 销售额(万元) ")]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "card-box3" }, [
                              _c("div", { staticClass: "card-box-top" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/report/percentange.png"),
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "card-box-bottom" }, [
                                _c("div", { staticClass: "box-bottom-top" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getPercentage(
                                          _vm.projectData_project.order_count,
                                          _vm.projectData_project.room_count
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "box-bottom-bottom" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.isroomOrcar == 0 ? "住宅" : ""
                                      ) + "去化率(%)"
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "time-text-content" }, [
                            _vm.order_type !== ""
                              ? _c("div", { staticClass: "time-text" }, [
                                  _c("div", { staticClass: "line-border" }),
                                  _c("span", [
                                    _vm._v(
                                      " 销售数量统计维度: " +
                                        _vm._s(
                                          _vm.order_type == 0
                                            ? "按认购订单及销控统计"
                                            : "按实际完成认购签约统计"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("div", {
                                    staticClass: "line-border line-border2",
                                  }),
                                ])
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "bubble-box" }, [
                            _c("div", { staticClass: "bubble-box-top" }, [
                              _c("div", { staticClass: "bubble-box-tone" }, [
                                _c(
                                  "span",
                                  { staticClass: "bubble-box-ttext" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.isNullNum(
                                            _vm.projectData_project.room_count
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm.project.cate_car === 1 &&
                                this.project.cate_room == 1
                                  ? _c(
                                      "span",
                                      { staticClass: "bubble-box-btext" },
                                      [_vm._v("推案住宅总数量")]
                                    )
                                  : _c(
                                      "span",
                                      { staticClass: "bubble-box-btext" },
                                      [
                                        _vm._v(
                                          "推案总数量(" +
                                            _vm._s(_vm.customSetting.unit) +
                                            ")"
                                        ),
                                      ]
                                    ),
                              ]),
                              _c("div", { staticClass: "bubble-box-ttwo" }, [
                                _c(
                                  "span",
                                  { staticClass: "bubble-box-ttext" },
                                  [_vm._v(" " + _vm._s(_vm.averagePrice) + " ")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "bubble-box-btext" },
                                  [
                                    _vm._v(
                                      "成交均价(" +
                                        _vm._s(
                                          _vm.isroomOrcar == 0 ||
                                            _vm.isroomOrcar == 1
                                            ? "元/㎡"
                                            : "元/个"
                                        ) +
                                        ")"
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.collectData.user_count &&
                                      _vm.collectData.user_count != 0,
                                    expression:
                                      "collectData.user_count && collectData.user_count != 0",
                                  },
                                ],
                                staticClass: "bubble-box-bottom",
                              },
                              [
                                _c("p", { staticClass: "content" }, [
                                  _c("span", [
                                    _vm._v(
                                      "有" +
                                        _vm._s(_vm.collectData.user_count) +
                                        "位客户认购了系统的推荐" +
                                        _vm._s(_vm.customSetting.title) +
                                        "，"
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "增加销售额" +
                                        _vm._s(
                                          _vm.tenThousandTransCopy(
                                            _vm.collectData.avg_price
                                          )
                                        ) +
                                        "元，"
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "提高去化率" +
                                        _vm._s(
                                          _vm.getPercentage(
                                            _vm.collectData.order_count,
                                            _vm.projectData_project.room_count
                                          )
                                        ) +
                                        "%"
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "card-one-table" },
                            [
                              _c("custom-tablecopy", {
                                attrs: {
                                  "table-data": _vm.basicData(
                                    _vm.projectData.cate1,
                                    _vm.projectData.cate2
                                  ),
                                  "table-head": _vm.basicDataHead,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kaipan-sale" },
                [
                  _c("report-title", {
                    attrs: { title: "销售详情", num: "02" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kaipan-sale-card" },
                [
                  _c(
                    "card",
                    { attrs: { "is-bottom": false } },
                    [
                      _c("template", { slot: "top" }, [
                        _c(
                          "div",
                          { staticClass: "kaipan-sale-top" },
                          [
                            _vm.project.cate_room == 1
                              ? _c("report-subtitle", {
                                  attrs: { title: "住宅销售详情" },
                                })
                              : _vm._e(),
                            _vm.project.cate_room == 1
                              ? _c(
                                  "div",
                                  { staticClass: "house-sale-table" },
                                  [
                                    _c("custom-table", {
                                      attrs: {
                                        "table-data": _vm.roomData.cate1,
                                        "table-head": _vm.houseSaleDataHeadTwo,
                                        isTen: "",
                                        showSummary: "",
                                        avgPprice: Math.floor(
                                          +this.projectData2.cate1.avg_price
                                        ),
                                        "header-color": "#84A4F3",
                                      },
                                    }),
                                    _c("custom-table", {
                                      attrs: {
                                        "table-data": _vm.roomData.build1,
                                        "table-head": _vm.houseSaleDataHeadOne,
                                        isTen: "",
                                        showSummary: "",
                                        avgPprice: Math.floor(
                                          +this.projectData2.cate1.avg_price
                                        ),
                                        "header-color": "#84A4F3",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.project.cate_villa == 1 ||
                            _vm.project.cate_shops == 1 ||
                            _vm.project.cate_car == 1
                              ? _c("report-subtitle", {
                                  attrs: {
                                    title:
                                      _vm.customSetting.villa_setting
                                        .house_name + "销售详情",
                                  },
                                })
                              : _vm._e(),
                            _vm.project.cate_villa == 1 ||
                            _vm.project.cate_shops == 1 ||
                            _vm.project.cate_car == 1
                              ? _c(
                                  "div",
                                  { staticClass: "villa-sale-table" },
                                  [
                                    _c("custom-table", {
                                      attrs: {
                                        "table-data": _vm.roomData.cate2,
                                        "table-head": _vm.villaSaleDataHeadTwo,
                                        isTen: "",
                                        showSummary: "",
                                        avgPprice: Math.floor(
                                          +this.projectData2.cate2.avg_price
                                        ),
                                        "header-color": "#84A4F3",
                                      },
                                    }),
                                    _c("custom-table", {
                                      attrs: {
                                        "table-data": _vm.roomData.build2,
                                        "table-head": _vm.villaSaleDataHeadOne,
                                        isTen: "",
                                        showSummary: "",
                                        avgPprice: Math.floor(
                                          +this.projectData2.cate2.avg_price
                                        ),
                                        "header-color": "#84A4F3",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kaipan-sale" },
                [_c("report-title", { attrs: { title: "销控图", num: "03" } })],
                1
              ),
              _c(
                "div",
                { staticClass: "kaipan-sale-card" },
                [
                  _c(
                    "card",
                    { attrs: { "is-bottom": false } },
                    [
                      _c("template", { slot: "top" }, [
                        _vm.roomtable.length &&
                        (_vm.type_show == 0 || _vm.type_show == 2)
                          ? _c(
                              "div",
                              { staticClass: "kaipan-sale-top" },
                              [
                                _vm.project.cate_room == 1
                                  ? _c("report-subtitle", {
                                      attrs: { title: "住宅销控" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.roomtable.length &&
                        (_vm.type_show == 0 || _vm.type_show == 2)
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "infoContent table-wrapper-content",
                              },
                              _vm._l(_vm.roomtable, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "table-list-content",
                                  },
                                  [
                                    item.buildingName
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "building-box theade",
                                          },
                                          [_vm._v(_vm._s(item.buildingName))]
                                        )
                                      : _vm._e(),
                                    item.unitName
                                      ? _c(
                                          "div",
                                          { staticClass: "unit-box  theade" },
                                          [_vm._v(_vm._s(item.unitName))]
                                        )
                                      : _vm._e(),
                                    Math.round(item.count.avg_price)
                                      ? _c(
                                          "div",
                                          { staticClass: "price-box theade" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                Math.round(item.count.avg_price)
                                              ) + "元/m²"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "room-wrapper" },
                                      _vm._l(
                                        item.floor,
                                        function (items, indexs) {
                                          return _c(
                                            "div",
                                            {
                                              key: indexs,
                                              staticClass: "floor-list",
                                            },
                                            _vm._l(
                                              items.room,
                                              function (datas, indesx1) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: indesx1,
                                                    class: [
                                                      "room-list",
                                                      {
                                                        backgroundRed:
                                                          datas.is_order == 1,
                                                        not_status:
                                                          datas.not_status ==
                                                          -1,
                                                        is_reserv:
                                                          datas.is_reserv ==
                                                            1 &&
                                                          datas.is_order != 1,
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(datas.no_name)
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c("div", { staticClass: "tfooter" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "count-table countClass",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item.count.room_count) +
                                              "/" +
                                              _vm._s(item.count.order_count)
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "statistics-table" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "statis-list backgroundRed",
                                            },
                                            [
                                              _vm._v(
                                                "认购(" +
                                                  _vm._s(
                                                    item.count.order_count
                                                  ) +
                                                  ")"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "statis-list" },
                                            [
                                              _vm._v(
                                                " 去化率(" +
                                                  _vm._s(
                                                    !(
                                                      item.count.order_count /
                                                      item.count.room_count
                                                    )
                                                      ? "0%"
                                                      : Math.round(
                                                          (item.count
                                                            .order_count /
                                                            item.count
                                                              .room_count) *
                                                            100
                                                        ) + "%"
                                                  ) +
                                                  ") "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "statis-list is_reserv",
                                            },
                                            [
                                              _vm._v(
                                                "预留(" +
                                                  _vm._s(
                                                    item.count.reserv_count
                                                  ) +
                                                  ")"
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm.cartable.length &&
                        (_vm.type_show == 1 || _vm.type_show == 2)
                          ? _c(
                              "div",
                              { staticClass: "kaipan-sale-top" },
                              [
                                _vm.project.cate_car == 1
                                  ? _c("report-subtitle", {
                                      attrs: { title: "车位销控" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.cartable.length &&
                        (_vm.type_show == 1 || _vm.type_show == 2)
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "infoContent table-wrapper-content",
                              },
                              _vm._l(_vm.cartable, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "table-list-content car-tablecontent",
                                  },
                                  [
                                    item.buildingName &&
                                    item.buildingName != " "
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "building-box theade",
                                          },
                                          [_vm._v(_vm._s(item.buildingName))]
                                        )
                                      : _vm._e(),
                                    item.unitName
                                      ? _c(
                                          "div",
                                          { staticClass: "unit-box  theade" },
                                          [_vm._v(_vm._s(item.unitName))]
                                        )
                                      : _vm._e(),
                                    item.floorName
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "floorName-box  theade",
                                          },
                                          [_vm._v(_vm._s(item.floorName))]
                                        )
                                      : _vm._e(),
                                    _vm.priceCount(item.count.avg_price)
                                      ? _c(
                                          "div",
                                          { staticClass: "price-box theade" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.priceCount(
                                                  item.count.avg_price
                                                )
                                              ) + "万元/个"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("div", { staticClass: "room-wrapper" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "floor-list car-floor-list",
                                        },
                                        _vm._l(
                                          item.room,
                                          function (datas, indesx1) {
                                            return _c(
                                              "div",
                                              {
                                                key: indesx1,
                                                class: [
                                                  "room-list",
                                                  {
                                                    backgroundRed:
                                                      datas.is_order == 1,
                                                    not_status:
                                                      datas.not_status == -1,
                                                    is_reserv:
                                                      datas.is_reserv == 1 &&
                                                      datas.is_order != 1,
                                                  },
                                                ],
                                              },
                                              [_vm._v(_vm._s(datas.no_name))]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                    _c("div", { staticClass: "tfooter" }, [
                                      _c(
                                        "div",
                                        { staticClass: "count-table" },
                                        [
                                          _vm._v(
                                            _vm._s(item.count.room_count) +
                                              "/" +
                                              _vm._s(item.count.order_count)
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "statistics-table" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "statis-list backgroundRed",
                                            },
                                            [
                                              _vm._v(
                                                "认购(" +
                                                  _vm._s(
                                                    item.count.order_count
                                                  ) +
                                                  ")"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "statis-list" },
                                            [
                                              _vm._v(
                                                "去化率(" +
                                                  _vm._s(
                                                    !(
                                                      item.count.order_count /
                                                      item.count.room_count
                                                    )
                                                      ? "0%"
                                                      : Math.round(
                                                          (item.count
                                                            .order_count /
                                                            item.count
                                                              .room_count) *
                                                            100
                                                        ) + "%"
                                                  ) +
                                                  ") "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "statis-list is_reserv",
                                            },
                                            [
                                              _vm._v(
                                                "预留(" +
                                                  _vm._s(
                                                    item.count.reserv_count
                                                  ) +
                                                  ")"
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "bottom-c-text" },
              [
                _c("footer-custom", {
                  attrs: {
                    title: "云销控，10年成就专业品质，让开盘变得更简单",
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }