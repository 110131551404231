var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-cus", staticStyle: { position: "relative" } },
    [
      _c("div", { staticClass: "edit-format-bg" }, [
        _c("img", { attrs: { src: _vm.replaceImgshow(_vm.rand.logo) } }),
      ]),
      _c(
        "div",
        { style: _vm.mainStyle() },
        [
          _c("screenfull"),
          _c(
            "div",
            { ref: "topHeight", staticClass: "topHeight" },
            [
              _vm.editFormatList.name_show === 0
                ? _c("project-title", {
                    attrs: {
                      innertext: _vm.rand.name,
                      "p-color": _vm.editFormatList.name_color,
                      "p-font": _vm.editFormatList.name_size,
                      "p-position": _vm.editFormatList.name_position,
                    },
                  })
                : _vm._e(),
              _vm.editFormatList.round_show === 0
                ? _c("project-rounds", {
                    attrs: {
                      innertext:
                        _vm.list.group_name +
                        "组，第" +
                        _vm.list.this_round +
                        "轮",
                      "p-color": _vm.editFormatList.round_color,
                      "p-font": _vm.editFormatList.round_size,
                      "p-position": _vm.editFormatList.round_position,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "project-content",
                {
                  attrs: {
                    "s-position": _vm.editFormatList.card_position,
                    "s-row-num": _vm.editFormatList.card_row_num,
                    "content-height": _vm.editFormatList.content_height,
                    "top-height": _vm.tbHeight,
                    "s-margin": _vm.editFormatList.card_margin,
                    "s-left-margin": _vm.editFormatList.card_info_left_margin,
                    "s-top-margin": _vm.editFormatList.card_info_top_margin,
                  },
                },
                _vm._l(_vm.lottery, function (item, i) {
                  return _c(
                    "item-box",
                    {
                      key: i,
                      attrs: {
                        "is-show-num": _vm.rand.show_rand_code,
                        num: _vm.getSequenceNumber(i + 1, _vm.roundNumber),
                      },
                    },
                    [
                      _vm.rand.show_name
                        ? _c(
                            "span",
                            {
                              staticClass: "mes",
                              style: {
                                width:
                                  _vm.editFormatList.host_json.name_width + "%",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.rand.show_name === 2
                                    ? item.name
                                    : _vm.nickName(item.name)
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.rand.show_tel
                        ? _c(
                            "span",
                            {
                              staticClass: "mes",
                              style: {
                                width:
                                  _vm.editFormatList.host_json.tel_width + "%",
                              },
                            },
                            [_vm._v(_vm._s(_vm.getTel(item.tel)))]
                          )
                        : _vm._e(),
                      _vm.rand.show_audit_order
                        ? _c(
                            "span",
                            {
                              staticClass: "mes",
                              style: {
                                width:
                                  _vm.editFormatList.host_json.code_width + "%",
                              },
                            },
                            [_vm._v(_vm._s(item.code))]
                          )
                        : _vm._e(),
                      _vm.rand.show_card
                        ? _c(
                            "span",
                            {
                              staticClass: "mes",
                              style: {
                                width:
                                  _vm.editFormatList.host_json.card_width + "%",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getCard(item.card, _vm.rand.show_card)
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.rand.show_other_remark
                        ? _c(
                            "span",
                            {
                              staticClass: "mes",
                              style: {
                                width:
                                  _vm.editFormatList.host_json
                                    .other_remark_width + "%",
                              },
                            },
                            [_vm._v(_vm._s(item.other_remark))]
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: "bottomHeight", staticClass: "edit-btn-group" },
            [
              true
                ? _c(
                    "project-bottom",
                    {
                      attrs: {
                        "s-margin": _vm.editFormatList.card_margin,
                        "is-show-tip": false,
                        "flex-pos": "space-around",
                      },
                    },
                    [
                      _vm.lastButton
                        ? _c("rounds-btn", {
                            attrs: { "btn-text": "上一轮记录" },
                            on: {
                              btnClick: function ($event) {
                                return _vm.handleUpDown("up")
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.nextButton
                        ? _c("rounds-btn", {
                            attrs: { "btn-text": "下一轮摇号" },
                            on: {
                              btnClick: function ($event) {
                                return _vm.handleUpDown("down")
                              },
                            },
                          })
                        : _vm._e(),
                      _c("rounds-btn", {
                        attrs: { "btn-text": "返回摇号" },
                        on: { btnClick: _vm.handleStartLottery },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          false
            ? _c(
                "div",
                { staticClass: "content" },
                [
                  _c("screenfull"),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.rand.name)),
                  ]),
                  _c("div", { staticClass: "lottery-box" }, [
                    _c("div", { staticClass: "lottery-bg" }, [
                      _c("div", { staticClass: "lottery-num" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            _vm._s(_vm.list.group_name) +
                              " 组，第 " +
                              _vm._s(_vm.list.this_round) +
                              " 轮"
                          ),
                        ]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                          },
                          [
                            _vm._v(
                              "还有" +
                                _vm._s(_vm.list.not_user_count) +
                                "人未摇号"
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "lottery-list" },
                        _vm._l(_vm.lottery, function (item, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "list",
                              class: _vm.heightClass,
                            },
                            [
                              _vm.rand.show_rand_code
                                ? _c("div", { staticClass: "number" }, [
                                    _vm._v(
                                      "第 " +
                                        _vm._s(_vm.getNumberAdd(i + 1)) +
                                        " 号"
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.rand.show_name
                                ? _c("div", { staticClass: "name" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.rand.show_name === 2
                                          ? item.name
                                          : _vm.nickName(item.name)
                                      ) + " "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.rand.show_tel
                                ? _c("div", { staticClass: "card" }, [
                                    _vm._v(_vm._s(_vm.getTel(item.tel))),
                                  ])
                                : _vm._e(),
                              _vm.rand.show_audit_order
                                ? _c("div", { staticClass: "card" }, [
                                    _vm._v(_vm._s(item.code)),
                                  ])
                                : _vm._e(),
                              _vm.rand.show_card
                                ? _c("div", { staticClass: "card" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getCard(
                                          item.card,
                                          _vm.rand.show_card
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.rand.show_other_remark
                                ? _c("span", { staticClass: "mes" }, [
                                    _vm._v(_vm._s(item.other_remark)),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "lottery-button" }, [
                    _c(
                      "div",
                      { staticClass: "lottery-block" },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "text-center",
                                attrs: { span: 8 },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "w flex" },
                                  [
                                    _vm.lastButton
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "button-style",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleUpDown("up")
                                              },
                                            },
                                          },
                                          [_vm._v(" 上一轮记录 ")]
                                        )
                                      : _vm._e(),
                                    _vm.nextButton
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "button-style",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleUpDown("down")
                                              },
                                            },
                                          },
                                          [_vm._v(" 下一轮记录 ")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "text-center",
                                attrs: { span: 8 },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "button-style",
                                    on: { click: _vm.handleStartLottery },
                                  },
                                  [_vm._v("返回摇号")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.rand.is_sub_screen === 1
        ? _vm._l(
            _vm.editFormatList.sub_screenDatas,
            function (sub_item, sub_index) {
              return _c(
                "div",
                {
                  key: sub_index,
                  staticStyle: {
                    border: "1px solid rgba(255,255,255,.5)",
                    position: "absolute",
                    "z-index": "99",
                  },
                  style: _vm.subStyle(sub_index),
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "overflow-y-noshow",
                      staticStyle: {
                        display: "flex",
                        "flex-wrap": "wrap",
                        width: "100%",
                        height: "100%",
                      },
                    },
                    [
                      _c(
                        "el-carousel",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "indicator-position": "none",
                            interval: sub_item.interval
                              ? sub_item.interval * 1000
                              : 0,
                            arrow: "never",
                            height: "100%",
                          },
                        },
                        _vm._l(
                          sub_item.roundsData,
                          function (roundItem, roundIndex) {
                            return _c("el-carousel-item", { key: roundIndex }, [
                              _c(
                                "div",
                                {
                                  style: {
                                    textAlign: sub_item.title.textAlign,
                                    color: sub_item.title.color,
                                    fontSize: sub_item.title.fontSize + "px",
                                    marginTop: sub_item.title.top + "px",
                                  },
                                },
                                [_vm._v(_vm._s(roundItem.name))]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-wrap": "wrap",
                                    width: "100%",
                                    height: "100%",
                                  },
                                  style: {
                                    width:
                                      "calc(100% - " +
                                      sub_item.cardPosition.left +
                                      "px)",
                                    marginLeft:
                                      sub_item.cardPosition.left + "px",
                                    height:
                                      "calc(100% - " +
                                      sub_item.title.fontSize * 2 +
                                      "px - " +
                                      sub_item.title.top +
                                      "px - " +
                                      sub_item.cardPosition.top +
                                      "px)",
                                    marginTop: sub_item.cardPosition.top + "px",
                                  },
                                },
                                [
                                  sub_item.type === "card"
                                    ? _vm._l(
                                        roundItem.datas,
                                        function (row_numItem, row_numIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: row_numIndex,
                                              staticStyle: {
                                                margin: "10px",
                                                "border-radius": "10px",
                                                "text-align": "center",
                                                color: "white",
                                                "line-height": "24px",
                                                display: "flex",
                                                "flex-wrap": "wrap",
                                                "align-content": "center",
                                              },
                                              style: {
                                                width:
                                                  "calc(" +
                                                  100 / sub_item.row_num +
                                                  "%  - 20px )",
                                                "background-color":
                                                  sub_item.background_color,
                                              },
                                            },
                                            [
                                              _vm.rand.sub_show_rand_code
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          sub_item.code
                                                            .fontSize + "px",
                                                        color:
                                                          sub_item.code.color,
                                                        "line-height":
                                                          sub_item.code
                                                            .lineHeight + "px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row_numItem.rand_code
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.rand.sub_show_name
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          sub_item.name
                                                            .fontSize + "px",
                                                        color:
                                                          sub_item.name.color,
                                                        "line-height":
                                                          sub_item.name
                                                            .lineHeight + "px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.rand
                                                              .sub_show_name ===
                                                              2
                                                              ? row_numItem.name
                                                              : _vm.nickName(
                                                                  row_numItem.name
                                                                )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.rand.sub_show_tel
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          sub_item.tel
                                                            .fontSize + "px",
                                                        color:
                                                          sub_item.tel.color,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getTel(
                                                              row_numItem.tel
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.rand.sub_show_audit_order
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          sub_item.audit_order
                                                            .fontSize + "px",
                                                        color:
                                                          sub_item.audit_order
                                                            .color,
                                                        "line-height":
                                                          sub_item.audit_order
                                                            .lineHeight + "px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row_numItem.code
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.rand.sub_show_card
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          sub_item.card
                                                            .fontSize + "px",
                                                        color:
                                                          sub_item.card.color,
                                                        "line-height":
                                                          sub_item.card
                                                            .lineHeight + "px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getCard(
                                                              row_numItem.card,
                                                              _vm.rand
                                                                .sub_show_card
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.rand.sub_show_other_remark
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          sub_item.other_remark
                                                            .fontSize + "px",
                                                        color:
                                                          sub_item.other_remark
                                                            .color,
                                                        "line-height":
                                                          sub_item.other_remark
                                                            .lineHeight + "px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row_numItem.other_remark
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      )
                                    : sub_item.type === "list"
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              margin: "10px",
                                              height: "calc(100% - 20px)",
                                              padding: "10px 0",
                                            },
                                            style: {
                                              width:
                                                "calc(" +
                                                100 / sub_item.row_num +
                                                "% - 20px)",
                                              "background-color":
                                                sub_item.background_color,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "flex-wrap": "nowrap",
                                                  width: "100%",
                                                },
                                              },
                                              [
                                                _vm.rand.sub_show_rand_code
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "word-break":
                                                            "break-all",
                                                          "text-align":
                                                            "center",
                                                        },
                                                        style: {
                                                          "font-size":
                                                            sub_item.code
                                                              .fontSize + "px",
                                                          color:
                                                            sub_item.code.color,
                                                          "line-height":
                                                            sub_item.code
                                                              .lineHeight +
                                                            "px",
                                                          width:
                                                            sub_item.code
                                                              .width + "%",
                                                        },
                                                      },
                                                      [_vm._v(" 顺序号 ")]
                                                    )
                                                  : _vm._e(),
                                                _vm.rand.sub_show_name
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "word-break":
                                                            "break-all",
                                                          "text-align":
                                                            "center",
                                                        },
                                                        style: {
                                                          "font-size":
                                                            sub_item.name
                                                              .fontSize + "px",
                                                          color:
                                                            sub_item.name.color,
                                                          "line-height":
                                                            sub_item.name
                                                              .lineHeight +
                                                            "px",
                                                          width:
                                                            sub_item.name
                                                              .width + "%",
                                                        },
                                                      },
                                                      [_vm._v(" 姓名 ")]
                                                    )
                                                  : _vm._e(),
                                                _vm.rand.sub_show_tel
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "word-break":
                                                            "break-all",
                                                          "text-align":
                                                            "center",
                                                        },
                                                        style: {
                                                          "font-size":
                                                            sub_item.tel
                                                              .fontSize + "px",
                                                          color:
                                                            sub_item.tel.color,
                                                          "line-height":
                                                            sub_item.tel
                                                              .lineHeight +
                                                            "px",
                                                          width:
                                                            sub_item.tel.width +
                                                            "%",
                                                        },
                                                      },
                                                      [_vm._v(" 手机号 ")]
                                                    )
                                                  : _vm._e(),
                                                _vm.rand.sub_show_audit_order
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "word-break":
                                                            "break-all",
                                                          "text-align":
                                                            "center",
                                                        },
                                                        style: {
                                                          "font-size":
                                                            sub_item.audit_order
                                                              .fontSize + "px",
                                                          color:
                                                            sub_item.audit_order
                                                              .color,
                                                          "line-height":
                                                            sub_item.audit_order
                                                              .lineHeight +
                                                            "px",
                                                          width:
                                                            sub_item.audit_order
                                                              .width + "%",
                                                        },
                                                      },
                                                      [_vm._v(" 诚意单号 ")]
                                                    )
                                                  : _vm._e(),
                                                _vm.rand.sub_show_card
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "word-break":
                                                            "break-all",
                                                          "text-align":
                                                            "center",
                                                        },
                                                        style: {
                                                          "font-size":
                                                            sub_item.card
                                                              .fontSize + "px",
                                                          color:
                                                            sub_item.card.color,
                                                          "line-height":
                                                            sub_item.card
                                                              .lineHeight +
                                                            "px",
                                                          width:
                                                            sub_item.card
                                                              .width + "%",
                                                        },
                                                      },
                                                      [_vm._v(" 身份证号 ")]
                                                    )
                                                  : _vm._e(),
                                                _vm.rand.sub_show_other_remark
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "word-break":
                                                            "break-all",
                                                          "text-align":
                                                            "center",
                                                        },
                                                        style: {
                                                          "font-size":
                                                            sub_item
                                                              .other_remark
                                                              .fontSize + "px",
                                                          color:
                                                            sub_item
                                                              .other_remark
                                                              .color,
                                                          "line-height":
                                                            sub_item
                                                              .other_remark
                                                              .lineHeight +
                                                            "px",
                                                          width:
                                                            sub_item
                                                              .other_remark
                                                              .width + "%",
                                                        },
                                                      },
                                                      [_vm._v(" 其他信息 ")]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "overflow-y-noshow",
                                                staticStyle: {
                                                  height: "calc(100% - 30px)",
                                                  "margin-top": "10px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "vue-seamless-scroll",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      height: "100%",
                                                    },
                                                    attrs: {
                                                      "class-option":
                                                        _vm.classOptionC,
                                                    },
                                                  },
                                                  _vm._l(
                                                    roundItem.datas,
                                                    function (
                                                      row_numItem,
                                                      row_numIndex
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: row_numIndex,
                                                          staticStyle: {
                                                            display: "flex",
                                                            "flex-wrap":
                                                              "nowrap",
                                                            width: "100%",
                                                          },
                                                        },
                                                        [
                                                          _vm.rand
                                                            .sub_show_rand_code
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "word-break":
                                                                      "break-all",
                                                                    "text-align":
                                                                      "center",
                                                                  },
                                                                  style: {
                                                                    "font-size":
                                                                      sub_item
                                                                        .code
                                                                        .fontSize +
                                                                      "px",
                                                                    color:
                                                                      sub_item
                                                                        .code
                                                                        .color,
                                                                    "line-height":
                                                                      sub_item
                                                                        .code
                                                                        .lineHeight +
                                                                      "px",
                                                                    width:
                                                                      sub_item
                                                                        .code
                                                                        .width +
                                                                      "%",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        row_numItem.rand_code
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.rand.sub_show_name
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "word-break":
                                                                      "break-all",
                                                                    "text-align":
                                                                      "center",
                                                                  },
                                                                  style: {
                                                                    "font-size":
                                                                      sub_item
                                                                        .name
                                                                        .fontSize +
                                                                      "px",
                                                                    color:
                                                                      sub_item
                                                                        .name
                                                                        .color,
                                                                    "line-height":
                                                                      sub_item
                                                                        .name
                                                                        .lineHeight +
                                                                      "px",
                                                                    width:
                                                                      sub_item
                                                                        .name
                                                                        .width +
                                                                      "%",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.rand
                                                                          .sub_show_name ===
                                                                          2
                                                                          ? row_numItem.name
                                                                          : _vm.nickName(
                                                                              row_numItem.name
                                                                            )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.rand.sub_show_tel
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "word-break":
                                                                      "break-all",
                                                                    "text-align":
                                                                      "center",
                                                                  },
                                                                  style: {
                                                                    "font-size":
                                                                      sub_item
                                                                        .tel
                                                                        .fontSize +
                                                                      "px",
                                                                    color:
                                                                      sub_item
                                                                        .tel
                                                                        .color,
                                                                    "line-height":
                                                                      sub_item
                                                                        .tel
                                                                        .lineHeight +
                                                                      "px",
                                                                    width:
                                                                      sub_item
                                                                        .tel
                                                                        .width +
                                                                      "%",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getTel(
                                                                          row_numItem.tel
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.rand
                                                            .sub_show_audit_order
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "word-break":
                                                                      "break-all",
                                                                    "text-align":
                                                                      "center",
                                                                  },
                                                                  style: {
                                                                    "font-size":
                                                                      sub_item
                                                                        .audit_order
                                                                        .fontSize +
                                                                      "px",
                                                                    color:
                                                                      sub_item
                                                                        .audit_order
                                                                        .color,
                                                                    "line-height":
                                                                      sub_item
                                                                        .audit_order
                                                                        .lineHeight +
                                                                      "px",
                                                                    width:
                                                                      sub_item
                                                                        .audit_order
                                                                        .width +
                                                                      "%",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        row_numItem.code
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.rand.sub_show_card
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "word-break":
                                                                      "break-all",
                                                                    "text-align":
                                                                      "center",
                                                                  },
                                                                  style: {
                                                                    "font-size":
                                                                      sub_item
                                                                        .card
                                                                        .fontSize +
                                                                      "px",
                                                                    color:
                                                                      sub_item
                                                                        .card
                                                                        .color,
                                                                    "line-height":
                                                                      sub_item
                                                                        .card
                                                                        .lineHeight +
                                                                      "px",
                                                                    width:
                                                                      sub_item
                                                                        .card
                                                                        .width +
                                                                      "%",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getCard(
                                                                          row_numItem.card,
                                                                          _vm
                                                                            .rand
                                                                            .sub_show_card
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.rand
                                                            .sub_show_other_remark
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "word-break":
                                                                      "break-all",
                                                                    "text-align":
                                                                      "center",
                                                                  },
                                                                  style: {
                                                                    "font-size":
                                                                      sub_item
                                                                        .other_remark
                                                                        .fontSize +
                                                                      "px",
                                                                    color:
                                                                      sub_item
                                                                        .other_remark
                                                                        .color,
                                                                    "line-height":
                                                                      sub_item
                                                                        .other_remark
                                                                        .lineHeight +
                                                                      "px",
                                                                    width:
                                                                      sub_item
                                                                        .other_remark
                                                                        .width +
                                                                      "%",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        row_numItem.other_remark
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ])
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            }
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }