var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "postlist" } },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: _vm.isPC ? 9 : 24 } }, [
            _c(
              "div",
              { staticClass: "button-box align-right" },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "role/add"],
                        expression: "['admin', 'role/add']",
                      },
                    ],
                    staticClass: "greens-btn",
                    attrs: { size: "small" },
                    on: { click: _vm.handleAddPost },
                  },
                  [_vm._v("新增岗位 ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "table-box" },
              [
                _c(
                  "el-table",
                  {
                    ref: "postTable",
                    attrs: {
                      data: _vm.postTable,
                      "header-cell-style": { background: "#fafafa" },
                      height: _vm.tableHeight + 48,
                      border: "",
                      "highlight-current-row": "",
                      size: "medium",
                    },
                    on: { "row-click": _vm.handleRow },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "岗位名称",
                        prop: "name",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "状态", width: "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scoped) {
                            return [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "role/status"],
                                      expression: "['admin', 'role/status']",
                                    },
                                  ],
                                  staticClass: "flex align-items ju-center",
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleStatus(scoped.row)
                                      },
                                    },
                                    model: {
                                      value: scoped.row.status,
                                      callback: function ($$v) {
                                        _vm.$set(scoped.row, "status", $$v)
                                      },
                                      expression: "scoped.row.status",
                                    },
                                  }),
                                  scoped.row.status
                                    ? _c(
                                        "span",
                                        { staticClass: "status1 mL10" },
                                        [_vm._v("启用")]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "status2 mL10" },
                                        [_vm._v("禁用")]
                                      ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "justify",
                        label: "操作",
                        prop: "yue",
                        width: "150",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scoped) {
                            return [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content: "添加人员",
                                    effect: "dark",
                                    placement: "top-end",
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "role/addmember"],
                                        expression:
                                          "[\n                    'admin',\n                    'role/addmember',\n                  ]",
                                      },
                                    ],
                                    attrs: {
                                      "class-name": "greens",
                                      "icon-class": "addUser",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleAddStaff(scoped.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content: "编辑",
                                    effect: "dark",
                                    placement: "top-end",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled:
                                          scoped.row.is_system === 0
                                            ? false
                                            : true,
                                        type: "text",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleEditInfo(scoped.row)
                                        },
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: ["admin", "role/edit"],
                                            expression:
                                              "[\n                      'admin',\n                      'role/edit',\n                    ]",
                                          },
                                        ],
                                        class: {
                                          "disable-css":
                                            scoped.row.is_system != 0,
                                        },
                                        attrs: {
                                          "class-name": "greens",
                                          "icon-class": "edit",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content: "功能授权",
                                    effect: "dark",
                                    placement: "top-end",
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "role/setrole"],
                                        expression: "['admin', 'role/setrole']",
                                      },
                                    ],
                                    attrs: {
                                      "class-name": "greens",
                                      "icon-class": "features",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleFeatures(scoped.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content: "删除岗位",
                                    effect: "dark",
                                    placement: "top-end",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled:
                                          scoped.row.is_system === 0
                                            ? false
                                            : true,
                                        type: "text",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleDelStaff(
                                            scoped.row,
                                            scoped.$index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: ["admin", "role/del"],
                                            expression: "['admin', 'role/del']",
                                          },
                                        ],
                                        class: {
                                          "disable-css":
                                            scoped.row.is_system != 0,
                                        },
                                        attrs: {
                                          "class-name": "greens",
                                          "icon-class": "delete",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: _vm.isPC ? 15 : 24 } }, [
            _c(
              "div",
              { staticClass: "button-box flex align-items space-between" },
              [
                _c("div", { staticClass: "font-style line-32" }, [
                  _vm.selectedUserNum > 0
                    ? _c("span", [
                        _vm._v("已选 " + _vm._s(_vm.selectedUserNum) + " 条"),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "line-32" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["admin", "role/memberdel"],
                            expression: "['admin', 'role/memberdel']",
                          },
                        ],
                        staticClass: "greens-btn",
                        attrs: { size: "small" },
                        on: { click: _vm.handleBatchDel },
                      },
                      [_vm._v("批量移除 ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "table-box postList" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.staffLoading,
                        expression: "staffLoading",
                      },
                    ],
                    ref: "userTable",
                    attrs: {
                      data: _vm.staffTable,
                      "header-cell-style": { background: "#fafafa" },
                      height: _vm.tableHeight + 48,
                      border: "",
                      size: "medium",
                    },
                    on: {
                      "row-click": _vm.handleUserRow,
                      "selection-change": _vm.handleUserSelection,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "selection", width: "55" },
                    }),
                    _vm.isPC
                      ? _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "序号",
                            type: "index",
                            width: "50",
                          },
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "姓名", prop: "name" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scoped) {
                            return [
                              _c(
                                "span",
                                { class: scoped.row.is_saas ? "red" : "" },
                                [_vm._v(_vm._s(scoped.row.name))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm.isPC
                      ? _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "手机号",
                            prop: "tel",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scoped) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        class: scoped.row.is_saas ? "red" : "",
                                      },
                                      [_vm._v(_vm._s(scoped.row.tel))]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1805369106
                          ),
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "状态", width: "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scoped) {
                            return [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "role/memberstatus"],
                                      expression:
                                        "[\n                  'admin',\n                  'role/memberstatus',\n                ]",
                                    },
                                  ],
                                  staticClass: "flex align-items ju-center",
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      disabled: scoped.row.is_saas
                                        ? true
                                        : false,
                                      "inactive-value": 0,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleUserStatus(scoped.row)
                                      },
                                    },
                                    model: {
                                      value: scoped.row.status,
                                      callback: function ($$v) {
                                        _vm.$set(scoped.row, "status", $$v)
                                      },
                                      expression: "scoped.row.status",
                                    },
                                  }),
                                  scoped.row.status
                                    ? _c(
                                        "span",
                                        { staticClass: "status1 mL10" },
                                        [_vm._v("启用")]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "status2 mL10" },
                                        [_vm._v("禁用")]
                                      ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "操作",
                        prop: "yue",
                        width: "180",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.counselor
                                ? [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: ["admin", "role/membersort"],
                                            expression:
                                              "['admin', 'role/membersort']",
                                          },
                                        ],
                                      },
                                      [
                                        scope.$index !== 0
                                          ? _c("svg-icon", {
                                              attrs: {
                                                "class-name": "greens",
                                                "icon-class": "move_up",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleSort(
                                                    scope.$index,
                                                    -1
                                                  )
                                                },
                                              },
                                            })
                                          : _c("svg-icon", {
                                              attrs: {
                                                "class-name": "greens forbid",
                                                "icon-class": "move_up",
                                              },
                                            }),
                                        scope.$index !==
                                        _vm.staffTable.length - 1
                                          ? _c("svg-icon", {
                                              attrs: {
                                                "class-name": "greens",
                                                "icon-class": "move_down",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleSort(
                                                    scope.$index,
                                                    1
                                                  )
                                                },
                                              },
                                            })
                                          : _c("svg-icon", {
                                              attrs: {
                                                "class-name": "greens forbid",
                                                "icon-class": "move_down",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content: "移除",
                                    effect: "dark",
                                    placement: "top-end",
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "role/memberdel"],
                                        expression:
                                          "[\n                    'admin',\n                    'role/memberdel',\n                  ]",
                                      },
                                    ],
                                    attrs: {
                                      "class-name": "greens",
                                      "icon-class": "delete",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleDelUser(scope.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.addEditPostText,
            visible: _vm.dialogPost,
            center: "",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPost = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addEditPost",
              attrs: { model: _vm.addEditPost, rules: _vm.addEditPostRules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "岗位名称", prop: "name" } },
                [
                  _vm.addEditPostText === "增加一个岗位"
                    ? _c("el-autocomplete", {
                        staticClass: "inline-input",
                        attrs: {
                          "fetch-suggestions": _vm.onTemListSelect,
                          placeholder: "请输入内容",
                        },
                        model: {
                          value: _vm.addEditPost.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addEditPost,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addEditPost.name",
                        },
                      })
                    : _c("el-input", {
                        attrs: {
                          maxlength: "16",
                          placeholder: "请输入内容",
                          "show-word-limit": "",
                          size: "small",
                        },
                        model: {
                          value: _vm.addEditPost.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addEditPost,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addEditPost.name",
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.dialogPost = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handlePostSave($event)
                    },
                  },
                },
                [
                  _vm._v(
                    "确定" +
                      _vm._s(
                        _vm.addEditPostText === "增加一个岗位"
                          ? "，并进行功能授权"
                          : ""
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.addStaff,
            center: "",
            title: "增加人员",
            width: _vm.isPC ? "800px" : "95%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addStaff = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _vm.isPC
                ? _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "font-style number" }, [
                      _vm.selectedNum > 0
                        ? _c("span", [
                            _vm._v("已选 " + _vm._s(_vm.selectedNum) + " 条"),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "16",
                      placeholder: "请输入内容",
                      "show-word-limit": "",
                      size: "small",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.addStaffSearch($event)
                      },
                    },
                    model: {
                      value: _vm.addStaffVal,
                      callback: function ($$v) {
                        _vm.addStaffVal =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "addStaffVal",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: _vm.isPC ? 6 : 12 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "green-btn",
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.addStaffSearch },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.addStaffReset },
                    },
                    [_vm._v("清空")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-box mT15" },
            [
              _c(
                "el-table",
                {
                  ref: "memberListTable",
                  attrs: {
                    data: _vm.memberList,
                    "header-cell-style": { background: "#fafafa" },
                    height: 300,
                    border: "",
                    "highlight-current-row": "",
                    size: "medium",
                  },
                  on: {
                    "row-click": _vm.handleMemberList,
                    "selection-change": _vm.handleSelectionChange,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm.isPC
                    ? _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "序号",
                          type: "index",
                          width: "50",
                        },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "姓名",
                      prop: "name",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scoped) {
                          return [
                            _c(
                              "span",
                              { class: scoped.row.is_saas ? "red" : "" },
                              [_vm._v(_vm._s(scoped.row.name))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.isPC
                    ? _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "手机号",
                          prop: "tel",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scoped) {
                                return [
                                  _c(
                                    "span",
                                    { class: scoped.row.is_saas ? "red" : "" },
                                    [_vm._v(_vm._s(scoped.row.tel))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1805369106
                        ),
                      })
                    : _vm._e(),
                  _vm.isPC
                    ? _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "岗位",
                          prop: "role_name",
                        },
                      })
                    : _vm._e(),
                  _vm.isPC
                    ? _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "团队",
                          prop: "team_name",
                        },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "账号状态", width: "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scoped) {
                          return [
                            scoped.row.status === 1
                              ? _c("span", [_vm._v("启用")])
                              : _c("span", [_vm._v("停用")]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-size": 10,
                      "page-sizes": [10, 20, 30, 50, 100, 200, 500],
                      total: _vm.total,
                      layout: "total, prev, pager, next, sizes, jumper",
                      small: _vm.isPC ? false : true,
                    },
                    on: {
                      "current-change": _vm.handleCurrentChange,
                      "size-change": _vm.handleSizeChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.addStaff = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSaveStaff },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isPC
        ? _c(
            "el-dialog",
            {
              attrs: {
                id: "admin",
                title: _vm.funName + " - 功能授权",
                visible: _vm.features,
                center: "",
                width: "1200px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.features = $event
                },
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { fill: "#22A271", model: "tabText" },
                  model: {
                    value: _vm.tabText,
                    callback: function ($$v) {
                      _vm.tabText = $$v
                    },
                    expression: "tabText",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "kpAdmin" } }, [
                    _vm._v("开盘助手 (" + _vm._s(_vm.kpAdminNumber) + ")"),
                  ]),
                  _c("el-radio-button", { attrs: { label: "admin" } }, [
                    _vm._v("后台 (" + _vm._s(_vm.adminNumber) + ")"),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "featuresList mT10",
                  staticStyle: { height: "400px" },
                },
                [
                  _vm.tabText === "admin"
                    ? _c("treetable", {
                        attrs: { type: "admin", auth: _vm.adminRuleList },
                        on: { handleNumber: _vm.handleNumber },
                      })
                    : _vm._e(),
                  _vm.tabText === "kpAdmin"
                    ? _c("treetable", {
                        attrs: { type: "kpAdmin", auth: _vm.helperRuleList },
                        on: { handleNumber: _vm.handleNumber },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.features = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleSaveFeatures },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "base-popup",
            {
              attrs: { visible: _vm.features },
              on: {
                "update:visible": function ($event) {
                  _vm.features = $event
                },
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { fill: "#22A271", model: "tabText" },
                  model: {
                    value: _vm.tabText,
                    callback: function ($$v) {
                      _vm.tabText = $$v
                    },
                    expression: "tabText",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "kpAdmin" } }, [
                    _vm._v("开盘助手 (" + _vm._s(_vm.kpAdminNumber) + ")"),
                  ]),
                  _c("el-radio-button", { attrs: { label: "admin" } }, [
                    _vm._v("后台 (" + _vm._s(_vm.adminNumber) + ")"),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mT10" },
                [
                  _vm.tabText === "admin"
                    ? _c("treetable", {
                        attrs: { type: "admin", auth: _vm.adminRuleList },
                        on: { handleNumber: _vm.handleNumber },
                      })
                    : _vm._e(),
                  _vm.tabText === "kpAdmin"
                    ? _c("treetable", {
                        attrs: { type: "kpAdmin", auth: _vm.helperRuleList },
                        on: { handleNumber: _vm.handleNumber },
                      })
                    : _vm._e(),
                  _c("div", { staticStyle: { height: "54px" } }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "popup-slot-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.features = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { margin: "0" },
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSaveFeatures },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }