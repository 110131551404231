import Vue from 'vue';

// 组件
import IdCardDrive from './IdCardDrive';
import OperatorUser from "./operator";
import JianFa from "./jianfa";
import SearchUser from './SearchUser/index.vue';

// 组件注册导出
export default {
  install: function install() {
    Vue.component('id-card-drive', IdCardDrive);
    Vue.component('operator-user', OperatorUser);
    Vue.component('jian-fa', JianFa);
    Vue.component('search-user', SearchUser);
  }
};