import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
export default {
  beforeCreate: function beforeCreate() {
    var _this$$route = this.$route,
      params = _this$$route.params,
      query = _this$$route.query;
    var path = params.path;
    this.$router.replace({
      path: '/' + path,
      query: query
    });
  },
  render: function render(h) {
    return h(); // avoid warning message
  }
};