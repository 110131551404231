import request from '@/utils/request';
// 套打模板列表
export function prints(data) {
  return request({
    url: '/prints/index',
    method: 'POST',
    data: data
  });
}
// 保存流转单
export function setHelpWord(data) {
  return request({
    url: '/prints/setHelpWord',
    method: 'POST',
    data: data
  });
}
// 保存认购单
export function setOrderWord(data) {
  return request({
    url: '/prints/setOrderWord',
    method: 'POST',
    data: data
  });
}

// 保存认筹单
export function setIdeWord(data) {
  return request({
    url: '/prints/setIdeWord',
    method: 'POST',
    data: data
  });
}
// 选房套打变量
export function getOrderVar(data) {
  return request({
    url: '/prints/getOrderVar',
    method: 'POST',
    data: data
  });
}
// 认筹套打变量
export function getIdeVar(data) {
  return request({
    url: '/prints/getIdeVar',
    method: 'POST',
    data: data
  });
}
// 在线签约配置获取
export function signIndex(params) {
  return request({
    url: '/prints/signIndex',
    method: 'GET',
    params: params
  });
}
// 保存在线签约配置
export function preserve(data) {
  return request({
    url: '/prints/preserve',
    method: 'POST',
    data: data
  });
}
// 上传印章
export function sealImg(data) {
  return request({
    url: '/upload/sealImg',
    method: 'POST',
    data: data
  });
}
// 上传文档
export function signWord(data) {
  return request({
    url: '/upload/signWord',
    method: 'POST',
    data: data
  });
}
// 保存定金模板
export function setPriceWord(data) {
  return request({
    url: '/prints/setPriceWord',
    method: 'POST',
    data: data
  });
}
// 保存定金模板
export function setRandPrint(data) {
  return request({
    url: '/prints/setRandPrint',
    method: 'POST',
    data: data
  });
}
// 保存定金模板
export function varList(data) {
  return request({
    url: '/prints/varList',
    method: 'POST',
    data: data
  });
}
export function varDel(data) {
  return request({
    url: '/prints/varDel',
    method: 'POST',
    data: data
  });
}
export function selVar(data) {
  return request({
    url: '/prints/selVar',
    method: 'POST',
    data: data
  });
}
export function varAdd(data) {
  return request({
    url: '/prints/varAdd',
    method: 'POST',
    data: data
  });
}
export function varEdit(data) {
  return request({
    url: '/prints/varEdit',
    method: 'POST',
    data: data
  });
}