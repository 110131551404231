import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Title',
  props: {
    title: {
      type: String,
      default: '背景图'
    },
    titleR: {
      type: String,
      default: '使用默认图'
    },
    showR: {
      type: [Boolean, Number],
      default: false
    },
    showSwitch: {
      type: Boolean,
      default: false
    },
    show: {
      type: [Boolean, Number],
      default: true
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    onSetShow: function onSetShow() {
      this.$emit('update:show', this.show === 0 ? 1 : 0);
    }
  }
};