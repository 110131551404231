import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import screenfull from '@/components/my-screen';
import { eventRandIndex } from '@/api/lottery';
import { debounce } from '@/utils/debounce';
import lotteryMixin from './lotteryMixin';
export default {
  name: 'Lottery',
  components: {
    screenfull: screenfull
  },
  mixins: [lotteryMixin],
  data: function data() {
    return {
      styleObject: {},
      // 基本数据
      rand: {},
      // 摇号组
      group: [],
      list: {}
    };
  },
  created: function created() {
    this.getDataIndex();
  },
  methods: {
    // 去摇号
    handleStartLottery: debounce(function () {
      var data = {
        id: this.rand.id,
        group_id: this.group_id,
        round: 1
      };

      // 随机摇号
      if (this.rand.type === 1) {
        // 分组摇号
        if (this.rand.type_group === 1) {
          this.$router.push({
            name: 'StartLottery',
            query: data
          });
        } else {
          // 不分组摇号
          this.$router.push({
            name: 'StartLotteryNo',
            query: data
          });
        }
      } else {
        if (this.rand.type_group === 1) {
          // 分组排号
          this.$router.push({
            name: 'StartRowNumber',
            query: data
          });
        } else {
          // 顺序摇号
          this.$router.push({
            name: 'StartLotteryOrder',
            query: data
          });
        }
      }
    }, 200, true),
    //    获取主页 基础数据
    getDataIndex: function getDataIndex() {
      var _this = this;
      eventRandIndex({
        id: this.$route.query.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data$list = res.data.list,
            group = _res$data$list.group,
            rand = _res$data$list.rand,
            group_id = _res$data$list.group_id;
          _this.rand = rand;
          _this.styleObject = {
            background: "url(".concat(_this.imgUrl + rand.logo, ")100%, no-repeat"),
            backgroundSize: '100%',
            // backgroundPosition: 'center, center',
            height: '100%'
          };
          _this.group = group;
          _this.group_id = group_id;
          _this.list = res.data.list;
          // 在缓存里加一个
          localStorage.setItem(parseInt(new Date().getTime() / 86400000).toString(), "".concat(rand.id, "-").concat(_this.list.event.type));
        }
      });
    }
  }
};