/**
 * @author: Rrq,
 * @mailbox: 15280973293@163.com,
 * @date: 2021-04-27 19:32,
 */

import request from '@/utils/request';
export default {
  // ------ 人员列表 ------
  // 获取人员列表
  adminList: function adminList(params) {
    return request({
      url: 'admins/List',
      method: 'get',
      params: params
    });
  },
  // 删除用户
  adminDel: function adminDel(params) {
    return request({
      url: 'admins/Del',
      method: 'get',
      params: params
    });
  },
  // 添加人员
  adminAdd: function adminAdd(data) {
    return request({
      url: 'admins/Add',
      method: 'post',
      data: data
    });
  },
  //	添加/修改 人员信息
  adminInfo: function adminInfo(params) {
    return request({
      url: 'admins/Info',
      method: 'get',
      params: params
    });
  },
  // 修改人员
  adminEdit: function adminEdit(data) {
    return request({
      url: 'admins/Edit',
      method: 'post',
      data: data
    });
  },
  // 人员状态
  adminStatus: function adminStatus(data) {
    return request({
      url: 'admins/Status',
      method: 'post',
      data: data
    });
  },
  // 重置密码
  adminReset: function adminReset(data) {
    return request({
      url: 'admins/Reset',
      method: 'post',
      data: data
    });
  },
  // 获取未发送人数
  postAdminSend: function postAdminSend(data) {
    return request({
      url: 'admins/Send',
      method: 'post',
      data: data
    });
  },
  // 发送初始密码
  getAdminSend: function getAdminSend(params) {
    return request({
      url: 'admins/Send',
      method: 'get',
      params: params
    });
  },
  // 导出指定人员
  getAdminImport: function getAdminImport(data) {
    return request({
      url: 'admins/Import',
      method: 'POST',
      data: data
    });
  },
  // 导出人员
  getAdminExport: function getAdminExport(params) {
    return request({
      url: 'admins/Export',
      method: 'get',
      params: params
    });
  },
  // ------ 岗位管理 ------
  // 获取 岗位列表
  roleList: function roleList(params) {
    return request({
      url: 'role/List',
      method: 'get',
      params: params
    });
  },
  // 添加岗位
  roleAdd: function roleAdd(data) {
    return request({
      url: 'role/Add',
      method: 'post',
      data: data
    });
  },
  // 岗位信息
  roleInfo: function roleInfo(params) {
    return request({
      url: 'role/Info',
      method: 'get',
      params: params
    });
  },
  // 修改岗位
  roleEdit: function roleEdit(data) {
    return request({
      url: 'role/Edit',
      method: 'post',
      data: data
    });
  },
  // 删除岗位
  roleDel: function roleDel(params) {
    return request({
      url: 'role/Del',
      method: 'get',
      params: params
    });
  },
  // 岗位状态
  roleStatus: function roleStatus(data) {
    return request({
      url: 'role/Status',
      method: 'post',
      data: data
    });
  },
  // 人员管理
  roleMember: function roleMember(params) {
    return request({
      url: 'role/Member',
      method: 'get',
      params: params
    });
  },
  // 添加人员 - 列表
  getRoleAddMember: function getRoleAddMember(params) {
    return request({
      url: 'role/AddMember',
      method: 'get',
      params: params
    });
  },
  // 添加人员 - 保存
  postRoleAddMember: function postRoleAddMember(data) {
    return request({
      url: 'role/AddMember',
      method: 'post',
      data: data
    });
  },
  // 移除人员
  roleMemberDel: function roleMemberDel(data) {
    return request({
      url: 'role/MemberDel',
      method: 'post',
      data: data
    });
  },
  // 人员状态
  roleMemberStatus: function roleMemberStatus(data) {
    return request({
      url: 'role/MemberStatus',
      method: 'post',
      data: data
    });
  },
  // 人员授权-列表
  getRoleSetRole: function getRoleSetRole(params) {
    return request({
      url: 'role/SetRole',
      method: 'get',
      params: params
    });
  },
  // 人员授权-保存
  postRoleSetRole: function postRoleSetRole(data) {
    return request({
      url: 'role/SetRole',
      method: 'post',
      data: data
    });
  },
  // ------ 销售团队 ------
  //	团队列表
  teamList: function teamList(params) {
    return request({
      url: 'team/List',
      method: 'get',
      params: params
    });
  },
  //	添加团队
  teamAdd: function teamAdd(data) {
    return request({
      url: 'team/Add',
      method: 'post',
      data: data
    });
  },
  //	团队信息
  teamInfo: function teamInfo(params) {
    return request({
      url: 'team/Info',
      method: 'get',
      params: params
    });
  },
  //	修改团队
  teamEdit: function teamEdit(data) {
    return request({
      url: 'team/Edit',
      method: 'post',
      data: data
    });
  },
  //	修改团队
  teamDel: function teamDel(params) {
    return request({
      url: 'team/Del',
      method: 'get',
      params: params
    });
  },
  //	修改团队状态
  teamStatus: function teamStatus(data) {
    return request({
      url: 'team/Status',
      method: 'post',
      data: data
    });
  },
  //	人员管理
  teamMember: function teamMember(params) {
    return request({
      url: 'team/Member',
      method: 'get',
      params: params
    });
  },
  //	添加人员 - 列表
  getTeamAddMember: function getTeamAddMember(params) {
    return request({
      url: 'team/AddMember',
      method: 'get',
      params: params
    });
  },
  //	添加人员 - 保存
  postTeamAddMember: function postTeamAddMember(data) {
    return request({
      url: 'team/AddMember',
      method: 'post',
      data: data
    });
  },
  //	移除人员
  teamMemberDel: function teamMemberDel(data) {
    return request({
      url: 'team/MemberDel',
      method: 'post',
      data: data
    });
  },
  //	人员状态
  teamMemberStatus: function teamMemberStatus(data) {
    return request({
      url: 'team/MemberStatus',
      method: 'post',
      data: data
    });
  },
  //	人员状态
  batchDel: function batchDel(data) {
    return request({
      url: 'admins/Del',
      method: 'post',
      data: data
    });
  },
  MemberSort: function MemberSort(data) {
    return request({
      url: 'role/MemberSort',
      method: 'POST',
      data: data
    });
  },
  syncJf: function syncJf(data) {
    return request({
      url: 'admins/syncJf',
      method: 'POST',
      data: data
    });
  }
};