var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "simple-setting" }, [
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("宽度缩放")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            model: {
              value: _vm.editFormatList.host_json.page_width,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList.host_json, "page_width", $$v)
              },
              expression: "editFormatList.host_json.page_width",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("高度缩放")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            model: {
              value: _vm.editFormatList.host_json.page_height,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList.host_json, "page_height", $$v)
              },
              expression: "editFormatList.host_json.page_height",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("上下平移")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            model: {
              value: _vm.editFormatList.host_json.page_positionX,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList.host_json, "page_positionX", $$v)
              },
              expression: "editFormatList.host_json.page_positionX",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("左右平移")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            model: {
              value: _vm.editFormatList.host_json.page_positionY,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList.host_json, "page_positionY", $$v)
              },
              expression: "editFormatList.host_json.page_positionY",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }