//
//
//
//
//
//
//
//
//

export default {
  name: 'CustomBtn',
  props: {
    iconType: {
      // ADD REDUCE UP DOWN
      type: String,
      default: 'ADD'
    },
    isGrey: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  }
};