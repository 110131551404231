import _typeof from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GMainUpload from '@/components/my-upload';
import IntentionRoom from '@/components/IntentionRoom/index.vue';
import HouseType from '@/components/HouseType/index.vue';
import { roomCatelist } from "@/components/HouseType/api";
export default {
  name: 'InputBox',
  computed: {
    formItem: function (_formItem) {
      function formItem() {
        return _formItem.apply(this, arguments);
      }
      formItem.toString = function () {
        return _formItem.toString();
      };
      return formItem;
    }(function () {
      return formItem;
    })
  },
  components: {
    GMainUpload: GMainUpload,
    IntentionRoom: IntentionRoom,
    HouseType: HouseType
  },
  props: {
    statusInfo: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    type: {
      type: Number,
      default: 1
    },
    userCreateInfoId: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      activeName: '0',
      form: {},
      radio: 3,
      checkList: ['选中且禁用', '复选框 A'],
      listTypes: 'picture',
      buildList: [],
      cateNum: 0
    };
  },
  mounted: function mounted() {
    this.getRoomCateList();
  },
  methods: {
    getRoomCateList: function getRoomCateList() {
      var _this = this;
      roomCatelist().then(function (res) {
        var _res$data;
        _this.cateNum = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.list.length) || 0;
      });
    },
    getType: function getType(item) {
      if (this.type === 1) {
        return item.typeUserNumber > 0;
      } else {
        return item.typeNumber > 0;
      }
    },
    // 反回产权人上传的图片
    handleProperty: function handleProperty(url, index) {
      this.$emit('handleProperty', url, index);
    },
    // 反回其他上传的图片
    handleOtherImg: function handleOtherImg(url, index) {
      this.$emit('handleOtherImg', url, index);
    },
    handleBuildOperation: function handleBuildOperation(type) {},
    // 添加产权人
    addPropertyOwner: function addPropertyOwner(index) {
      this.$emit('addPropertyOwner', index);
    },
    // 删除产权人
    handleDelete: function handleDelete(index, inx) {
      this.$emit('handleDelete', index, inx);
    },
    // 单选选中事件
    handleRadio: function handleRadio(fromItem) {
      // this.$emit('handleRadio', fromItem.down_index,fromItem.owner_type,fromItem.normal_result.value2)
      this.$emit('doShowParent', true, fromItem.id, [fromItem.normal_result.value2 + ""], null, fromItem);
      // console.log(index,i,name,val);
    },
    onRoomList: function onRoomList(limit) {
      this.$emit('limit', limit);
    },
    handlePopup: function handlePopup(fromItem, data) {
      this.$emit('handlePopup', fromItem.id, data);
    },
    handleHouse: function handleHouse(fromItem, data) {
      this.$emit('handleHouse', fromItem.id, data);
    },
    onShowParentChange: function onShowParentChange(fromItem) {
      var value = [];
      for (var i = 0; i < fromItem.normal_result.value2.length; i++) {
        value.push(fromItem.normal_result.value2[i] + "");
      }
      this.$emit('doShowParent', true, fromItem.id, value);
    },
    onShowParentOwnerChange: function onShowParentOwnerChange(fromItem, ownerBankIndex, index) {
      var value = [];
      if (_typeof(fromItem.owner_result.value2) === "object") {
        for (var i = 0; i < fromItem.owner_result.value2.length; i++) {
          value.push(fromItem.owner_result.value2[i] + "");
        }
      } else {
        value = [fromItem.owner_result.value2 + ""];
      }
      this.$emit('doShowParent', true, fromItem.id, value, ownerBankIndex);
      // 判断是否是产权人需要增加产权人
      if (fromItem.owner_type === 46) {
        for (var _i = 0; _i < fromItem.children.length; _i++) {
          // 判断当前选择的是
          if (fromItem.owner_result.value2 === fromItem.children[_i].id && fromItem.children[_i].owner_type === 47) {
            this.$emit('doSpouseAddOwner', index, ownerBankIndex);
            break;
          }
        }
      }
    },
    // 产权人排序
    onOwnerSort: function onOwnerSort(index) {
      this.$emit('onOwnerSort', index);
    }
  }
};