//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    sign: {
      type: Object,
      default: function _default() {
        return {
          handled: -1
        };
      }
    }
  }
};