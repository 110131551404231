import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
export default {
  name: 'ComplexCard',
  props: {
    rand: {
      type: Object,
      default: function _default() {}
    }
  },
  computed: _objectSpread({}, mapState({
    editFormatList: function editFormatList(state) {
      return state.editFormat.list;
    }
  }))
};