/**
 * @author: Rrq,
 * @mailbox: 15280973293@163.com,
 * @date: 2021-08-18 14:58,
 */

import request from '@/utils/request';
// import qs from 'qs'

export default {
  // 登录日志列表
  loginLog: function loginLog(params) {
    return request({
      url: '/log/LoginLog',
      method: 'get',
      params: params
    });
  },
  // 操作日志列表
  adminLog: function adminLog(params) {
    return request({
      url: '/log/AdminLog',
      method: 'get',
      params: params
    });
  },
  // 登陆日志 - 导出
  loginCsv: function loginCsv(params) {
    return request({
      url: '/log/LoginCsv',
      method: 'get',
      params: params
    });
  },
  // 操作日志 - 导出
  adminCsv: function adminCsv(params) {
    return request({
      url: '/log/AdminCsv',
      method: 'get',
      params: params
    });
  },
  getScvData: function getScvData(url, params) {
    return request({
      url: url,
      method: 'get',
      params: params
    });
  }
};