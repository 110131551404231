import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.trim.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '../../assistHouse/mixins/card_socket';
import { randRoomUser, randRoomdStart, randRoomdStop } from '@/api/lottery';
import { debounce } from "@/utils/debounce";
import { adminParameter } from "@/api/public";
export default {
  name: "randRoom",
  mixins: [mixins],
  data: function data() {
    return {
      // 按钮类型：0：开始，1：下一套，2：停止，3：达到最大限购数量
      buttonType: 0,
      // 类型 0：查找客户，1：多个客户选择，2：选中客户
      type: 0,
      // 搜索
      searchParams: {
        tel: "",
        card: ""
      },
      searchLoading: false,
      userList: [],
      userInfo: {},
      rand_id: 0,
      roomName: "*****",
      roomInterval: "",
      project_name: "",
      // 当前第几套
      before_count: 0
    };
  },
  created: function created() {
    var _this = this;
    this.rand_id = this.$route.query.id;
    //  项目配置
    adminParameter().then(function (res) {
      if (res.status === 200) {
        _this.project_name = res.data.list.name;
      }
    });
  },
  mounted: function mounted() {
    this.wsConnect();
  },
  methods: {
    onLeftBottom: function onLeftBottom() {
      // 查询用户
      if (this.type === 0) {
        this.getUserList();
      }
      // 点击用户
      else if (this.type === 1) {
        for (var i = 0; i < this.userList.length; i++) {
          if (this.userList[i].checked) {
            this.userInfo = this.userList[i];
            this.before_count = this.userInfo.buy_count - this.userInfo.remaining_count;
            break;
          }
        }
        this.searchParams = {
          tel: this.userInfo.tel,
          card: ""
        };
        this.getUserList();
        // this.buttonType = 0;
        // this.type = 2;
      }
      // 换个客户
      else if (this.type === 2) {
        this.userInfo = {};
        this.userList = [];
        this.roomName = "*****";
        this.type = 0;
      }
    },
    // 查找用户
    getUserList: debounce(function (isRenovate) {
      var _this2 = this;
      // 避免因为不是搜索客户时刷身份证
      if (this.searchParams.card && this.type !== 0) return;
      if (!this.searchParams.card) {
        if (!this.searchParams.tel || !this.searchParams.tel.trim()) {
          this.$message({
            type: 'error',
            message: '请输入客户的手机号、身份证、姓名查询'
          });
          return;
        }
      } else {
        this.searchParams.tel = "";
      }
      this.searchLoading = true;
      randRoomUser({
        rand_id: this.rand_id,
        serach: this.searchParams.card || this.searchParams.tel
      }).then(function (res) {
        _this2.userList = res.data.list || [];
        _this2.searchLoading = false;
        if (_this2.userList.length === 1) {
          _this2.userInfo = _this2.userList[0];
          _this2.before_count = _this2.userInfo.buy_count - _this2.userInfo.remaining_count;
          if (!isRenovate) {
            _this2.buttonType = 0;
            _this2.roomName = res.data.room_list ? res.data.room_list : "*****";
          }
          _this2.type = 2;
        } else if (_this2.userList.length === 0) {
          _this2.$message({
            type: 'info',
            message: "没有搜索到客户！"
          });
        } else {
          _this2.userList[0].checked = true;
          _this2.type = 1;
        }
        _this2.searchParams = {
          tel: "",
          card: ""
        };
      }).catch(function (res) {
        _this2.$message({
          type: 'error',
          message: res.data.msg
        });
      });
    }, 1000, true),
    // 点击客户
    onCheckedUser: debounce(function (index) {
      var userList = JSON.parse(JSON.stringify(this.userList));
      for (var i = 0; i < userList.length; i++) {
        userList[i].checked = i === index;
      }
      this.userList = userList;
    }, 1000, true),
    // 点击右侧按钮
    onRightButton: debounce(function () {
      var _this3 = this;
      // 开始
      if (this.buttonType === 0) {
        if (this.userInfo.remaining_count <= 0) {
          this.$confirm('客户名额已用完，更换客户？', '提示', {
            cancelButtonText: '取消',
            confirmButtonText: '确定'
          }).then(function () {
            _this3.onLeftBottom();
          });
          return;
        }
        if (this.roomInterval) clearInterval(this.roomInterval);
        randRoomdStart({
          rand_id: this.rand_id,
          user_id: this.userInfo.id
        }).then(function (res) {
          var list = res.data.list || [];
          if (list.length) {
            _this3.before_count += 1;
            _this3.roomInterval = setInterval(function () {
              _this3.roomName = list[Math.floor(Math.random() * list.length)];
            }, 50);
            _this3.buttonType = 2;
          } else {
            _this3.$message({
              type: 'error',
              message: '没有房源！'
            });
          }
        });
      }
      // 下一套
      else if (this.buttonType === 1) {
        if (this.userInfo.remaining_count <= 0) {
          this.onLeftBottom();
          return;
        }
        this.$confirm('客户还具备' + this.userInfo.remaining_count + '个名额，抽取下一个? ', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确定'
        }).then(function () {
          if (_this3.roomInterval) clearInterval(_this3.roomInterval);
          randRoomdStart({
            rand_id: _this3.rand_id,
            user_id: _this3.userInfo.id
          }).then(function (res) {
            var list = res.data.list || [];
            if (list.length) {
              _this3.before_count += 1;
              _this3.roomInterval = setInterval(function () {
                _this3.roomName = list[Math.floor(Math.random() * list.length)];
              }, 50);
              _this3.buttonType = 2;
            } else {
              _this3.$message({
                type: 'error',
                message: '没有房源！'
              });
            }
          });
        });
      }
      // 结束
      else if (this.buttonType === 2) {
        this.buttonType = 1;
        randRoomdStop({
          rand_id: this.rand_id,
          user_id: this.userInfo.id
        }).then(function (res) {
          _this3.roomName = res.data.room;
          if (_this3.roomInterval) clearInterval(_this3.roomInterval);
          // 重新获取当前用户信息，确保限购剩余数量是最新的
          _this3.searchParams = {
            tel: _this3.userInfo.tel,
            card: ""
          };
          _this3.getUserList(true);
        }).catch(function (res) {
          _this3.$message({
            type: 'error',
            message: res.data.msg
          });
          _this3.roomName = "*****";
          if (_this3.roomInterval) clearInterval(_this3.roomInterval);
        });
      }
    }, 1000, true)
  }
};