import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { tenThousandTransCopy, addCommas } from '../util'; // 判空
export default {
  props: {
    // 头部颜色
    headerColor: {
      type: String,
      default: 'red'
    },
    // 是否有border
    isHeaderBorder: {
      type: Boolean,
      default: false
    },
    isRound: {
      //是否保留整数四舍五入
      type: Boolean,
      default: false
    },
    // 头部数据
    tableHead: {
      type: Array,
      default: function _default() {
        return [{
          label: '姓名',
          prop: 'name'
        }, {
          label: '时间',
          prop: 'date'
        }, {
          label: '地址',
          prop: 'address'
        }];
      }
    },
    // 表格数据
    tableData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isTen: {
      type: Boolean,
      default: false
    },
    showSummary: {
      //表格是否展示合并
      type: Boolean,
      default: false
    },
    avgPprice: {
      //合计:成交均价
      // type:String,
      default: ''
    }
  },
  data: function data() {
    return {
      headerCellStyleOne: {
        'background-color': 'rgba(0, 0, 0, 0.04)',
        'color': 'rgb(103, 194, 58)',
        'border-bottom': '1px rgb(103, 194, 58) solid'
      },
      addCommas: ''
    };
  },
  created: function created() {
    this.tenThousandTransCopy = tenThousandTransCopy;
    this.addCommas = addCommas;
  },
  methods: {
    renderheader: function renderheader(h, _ref) {
      var column = _ref.column;
      // renderheader函数得用到el-table-column上，而不是el-table
      return h("span", {}, [h("span", {}, column.label.split("//")[0]),
      // 其中//也可以用其他符号替代
      h("br"), h("span", {}, column.label.split("//")[1])]);
    },
    getSummaries: function getSummaries(param) {
      var _this = this;
      var columns = param.columns,
        data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        var isCount = _this.tableHead[index];
        if (isCount.count) {
          //有合计属性的才计算
          if (column.property == 'Removalrate') {
            //去化率合计计算方式
            var order_count = data.map(function (item) {
              return Number(item['order_count']);
            }).reduce(function (prev, curr) {
              var value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            });
            var room_count = data.map(function (item) {
              return Number(item['room_count']);
            }).reduce(function (prev, curr) {
              var value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            });
            sums[index] = Math.ceil(order_count / room_count * 100) + '%';
          } else if (column.property == 'avg_price') {
            //成交均价计算方式
            sums[index] = _this.isTen ? Math.floor(_this.avgPprice / 10000 * 100) / 100 : addCommas(_this.avgPprice);
          } else {
            console.log('其他计算方式');
            var values = data.map(function (item) {
              return Number(item[column.property]);
            });
            if (!values.every(function (value) {
              return isNaN(value);
            })) {
              sums[index] = values.reduce(function (prev, curr) {
                var value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
            }
          }
        } else {
          sums[index] = '';
        }
      });
      return sums;
    }
  }
};