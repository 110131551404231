var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "report-wrapper" }, [
    _vm.loading
      ? _c("div", { staticClass: "loading_box" }, [
          _c("i", { staticClass: "icon el-icon-loading" }),
          _c("p", { staticClass: "tips" }, [_vm._v("开盘报告下载中...")]),
        ])
      : _vm._e(),
    _vm.reqFinish
      ? _c(
          "div",
          { staticClass: "active-report", attrs: { id: "pdf_node" } },
          [
            _c("banner", {
              attrs: { time: _vm.project.end_time, title: _vm.project.name },
            }),
            _c("div", { staticClass: "card-wrapper" }, [
              _c(
                "div",
                { staticClass: "basic-data" },
                [
                  _c("report-title", { attrs: { title: "开盘概述" } }),
                  _c("img", {
                    staticClass: "house-img",
                    attrs: { src: require("../../assets/report/house.png") },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "basic-data-card" },
                [
                  _c(
                    "card",
                    { attrs: { "is-bottom": true } },
                    [
                      _c("template", { slot: "top" }, [
                        _c("div", { staticClass: "card-one-wrapper" }, [
                          _c("div", { staticClass: "card-one-box" }, [
                            _c("div", { staticClass: "card-box1" }, [
                              _c("div", { staticClass: "card-box-top" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/report/houseIconE.png"),
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "card-box-bottom" }, [
                                _c("div", { staticClass: "box-bottom-top" }, [
                                  _vm.isroomOrcar == 0
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.isNullNum(
                                                _vm.projectData2.cate1
                                                  .order_count +
                                                  _vm.projectData2.cate2
                                                    .order_count
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.isNullNum(
                                                _vm.projectData_project
                                                  .order_count
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "box-bottom-bottom" },
                                  [
                                    _vm.isroomOrcar == 0
                                      ? _c("span", [
                                          _vm._v(" 住宅+车位"),
                                          _c("br"),
                                          _vm._v("销售数量 "),
                                        ])
                                      : _c("span", [_vm._v(" 销售数量 ")]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "card-box2" }, [
                              _c("div", { staticClass: "card-box-top" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/report/purse.png"),
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "card-box-bottom" }, [
                                _c("div", { staticClass: "box-bottom-top" }, [
                                  _vm.isroomOrcar == 0
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (
                                                _vm.isNullNum(
                                                  +_vm.projectData2.cate1
                                                    .sum_price +
                                                    +_vm.projectData2.cate2
                                                      .sum_price
                                                ) / 10000
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              +_vm.projectData_project
                                                .order_count &&
                                                !+_vm.projectData_project
                                                  .sum_price
                                                ? "未提供"
                                                : _vm.tenThousandTrans(
                                                    _vm.projectData_project
                                                      .sum_price
                                                  )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "box-bottom-bottom" },
                                  [
                                    _vm.isroomOrcar == 0
                                      ? _c("span", [
                                          _vm._v(" 住宅+车位"),
                                          _c("br"),
                                          _vm._v("销售额(万元) "),
                                        ])
                                      : _c("span", [_vm._v(" 销售额(万元) ")]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "card-box3" }, [
                              _c("div", { staticClass: "card-box-top" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/report/percentange.png"),
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "card-box-bottom" }, [
                                _c("div", { staticClass: "box-bottom-top" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getPercentage(
                                          _vm.projectData_project.order_count,
                                          _vm.projectData_project.room_count
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "box-bottom-bottom" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.isroomOrcar == 0 ? "住宅" : ""
                                      ) + "去化率(%)"
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _vm.order_type !== ""
                            ? _c("div", { staticClass: "time-text" }, [
                                _c("div", { staticClass: "line-border" }),
                                _c("span", [
                                  _vm._v(
                                    " 销售数量统计维度: " +
                                      _vm._s(
                                        _vm.order_type == 0
                                          ? "按认购订单及销控统计"
                                          : "按实际完成认购签约统计"
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", {
                                  staticClass: "line-border line-border2",
                                }),
                              ])
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "active-course-title" },
                          [
                            _c("report-subtitle", {
                              attrs: { title: "基础数据" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "card-one-wrapper" }, [
                          _c("div", { staticClass: "bubble-box" }, [
                            _c("div", { staticClass: "bubble-box-top" }, [
                              _c("div", { staticClass: "bubble-box-tone" }, [
                                _c(
                                  "span",
                                  { staticClass: "bubble-box-ttext" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.isNullNum(
                                            _vm.projectData_project.room_count
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm.project.cate_car === 1 &&
                                this.project.cate_room == 1
                                  ? _c(
                                      "span",
                                      { staticClass: "bubble-box-btext" },
                                      [_vm._v(" 推案住宅总数量 ")]
                                    )
                                  : _c(
                                      "span",
                                      { staticClass: "bubble-box-btext" },
                                      [
                                        _vm._v(
                                          "推案总数量(" +
                                            _vm._s(_vm.customSetting.unit) +
                                            ")"
                                        ),
                                      ]
                                    ),
                              ]),
                              _c("div", { staticClass: "bubble-box-ttwo" }, [
                                _c(
                                  "span",
                                  { staticClass: "bubble-box-ttext" },
                                  [_vm._v(" " + _vm._s(_vm.averagePrice) + " ")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "bubble-box-btext" },
                                  [
                                    _vm._v(
                                      "成交均价(" +
                                        _vm._s(
                                          _vm.isroomOrcar == 0 ||
                                            _vm.isroomOrcar == 1
                                            ? "元/㎡"
                                            : "元/个"
                                        ) +
                                        ")"
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.collectData.user_count &&
                                      _vm.collectData.user_count != 0 &&
                                      _vm.xtRecommend == 1,
                                    expression:
                                      "collectData.user_count && collectData.user_count != 0 && xtRecommend == 1",
                                  },
                                ],
                                staticClass: "bubble-box-bottom",
                              },
                              [
                                _c("p", { staticClass: "content" }, [
                                  _c("span", [
                                    _vm._v(
                                      "有" +
                                        _vm._s(_vm.collectData.user_count) +
                                        "位客户认购了系统的推荐" +
                                        _vm._s(_vm.customSetting.title) +
                                        "，"
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "增加销售额" +
                                        _vm._s(
                                          _vm.tenThousandTransCopy(
                                            _vm.collectData.avg_price
                                          )
                                        ) +
                                        "元，"
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "提高去化率" +
                                        _vm._s(
                                          _vm.getPercentage(
                                            _vm.collectData.order_count,
                                            _vm.projectData_project.room_count
                                          )
                                        ) +
                                        "%"
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "card-one-table" },
                            [
                              _c("custom-tablecopy", {
                                attrs: {
                                  "table-data": _vm.basicData(
                                    _vm.projectData.cate1,
                                    _vm.projectData.cate2
                                  ),
                                  "table-head": _vm.basicDataHead,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("template", { slot: "bottom" }, [
                        _c("div", { staticClass: "proportion-sales" }, [
                          _vm.unsoldData.room_count != 0
                            ? _c(
                                "div",
                                { staticClass: "summary-title" },
                                [
                                  _c("report-subtitle", {
                                    attrs: {
                                      title:
                                        "未售" +
                                        _vm.customSetting.custom_data
                                          .house_res_report +
                                        "的特性",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.unsoldData.room_count != 0
                            ? _c(
                                "div",
                                { staticClass: "info_box" },
                                _vm._l(_vm.unsoldDataCopy, function (item, i) {
                                  return _c(
                                    "list-item",
                                    {
                                      key: "unsoldDataCopy" + i,
                                      attrs: { num: i + 1 },
                                    },
                                    [
                                      _vm.isArray(item)
                                        ? _c(
                                            "span",
                                            [
                                              _vm._l(item, function (items, j) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: "unsoldDataCopyS" + j,
                                                  },
                                                  [_vm._v(_vm._s(items))]
                                                )
                                              }),
                                              _vm._v(" 。 "),
                                            ],
                                            2
                                          )
                                        : _c("span", [
                                            _vm._v(_vm._s(item) + "。"),
                                          ]),
                                    ]
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kaipan-sale" },
                [
                  _c("report-title", {
                    attrs: { title: "销售情况", num: "02" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kaipan-sale-card" },
                [
                  _c(
                    "card",
                    {
                      attrs: {
                        "is-bottom":
                          (_vm.adminRank && _vm.adminRank.length != 0) ||
                          (_vm.teamRank && _vm.teamRank.length != 0),
                      },
                    },
                    [
                      _c("template", { slot: "top" }, [
                        _c(
                          "div",
                          { staticClass: "kaipan-sale-top" },
                          [
                            _vm.project.cate_room == 1
                              ? _c("report-subtitle", {
                                  attrs: { title: "住宅销售详情" },
                                })
                              : _vm._e(),
                            _vm.project.cate_room == 1
                              ? _c(
                                  "div",
                                  { staticClass: "house-sale-table" },
                                  [
                                    _c("custom-table", {
                                      attrs: {
                                        "table-data": _vm.roomData.cate1,
                                        "table-head": _vm.houseSaleDataHeadTwo,
                                        isTen: "",
                                        showSummary: "",
                                        avgPprice: Math.floor(
                                          +this.projectData2.cate1.avg_price
                                        ),
                                        "header-color": "#84A4F3",
                                      },
                                    }),
                                    _c("custom-table", {
                                      attrs: {
                                        "table-data": _vm.roomData.build1,
                                        "table-head": _vm.houseSaleDataHeadOne,
                                        isTen: "",
                                        showSummary: "",
                                        avgPprice: Math.floor(
                                          +this.projectData2.cate1.avg_price
                                        ),
                                        "header-color": "#84A4F3",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.project.cate_room == 1
                              ? _c(
                                  "div",
                                  { staticClass: "proportion-sales-title" },
                                  [_c("center-title")],
                                  1
                                )
                              : _vm._e(),
                            _vm.project.cate_room == 1
                              ? _c(
                                  "div",
                                  { staticClass: "echarts-house" },
                                  [
                                    _c("pie-chart", {
                                      attrs: {
                                        id: "echart-one",
                                        "chart-data": _vm.chartDataHouse,
                                        name: "住宅各户型销售数量占比",
                                      },
                                      on: {
                                        setRenderState: function (state) {
                                          return _vm.setRenderState(
                                            "house",
                                            state
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.project.cate_villa == 1 ||
                            _vm.project.cate_shops == 1 ||
                            _vm.project.cate_car == 1
                              ? _c("report-subtitle", {
                                  attrs: {
                                    title:
                                      _vm.customSetting.villa_setting
                                        .house_name + "销售详情",
                                  },
                                })
                              : _vm._e(),
                            _vm.project.cate_villa == 1 ||
                            _vm.project.cate_shops == 1 ||
                            _vm.project.cate_car == 1
                              ? _c(
                                  "div",
                                  { staticClass: "villa-sale-table" },
                                  [
                                    _c("custom-table", {
                                      attrs: {
                                        "table-data": _vm.roomData.cate2,
                                        "table-head": _vm.villaSaleDataHeadTwo,
                                        isTen: "",
                                        showSummary: "",
                                        avgPprice: Math.floor(
                                          +this.projectData2.cate2.avg_price
                                        ),
                                        "header-color": "#84A4F3",
                                      },
                                    }),
                                    _c("custom-table", {
                                      attrs: {
                                        "table-data": _vm.roomData.build2,
                                        "table-head": _vm.villaSaleDataHeadOne,
                                        isTen: "",
                                        showSummary: "",
                                        avgPprice: Math.floor(
                                          +this.projectData2.cate2.avg_price
                                        ),
                                        "header-color": "#84A4F3",
                                      },
                                    }),
                                    _vm.project.cate_villa == 1 ||
                                    _vm.project.cate_car == 1 ||
                                    _vm.project.cate_shops == 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "proportion-sales-title-villa",
                                          },
                                          [
                                            _c("center-title", {
                                              attrs: {
                                                title:
                                                  _vm.customSetting
                                                    .villa_setting.house_name +
                                                  "各" +
                                                  _vm.customSetting
                                                    .villa_setting.house_type +
                                                  "销售数量占比",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.project.cate_villa == 1 ||
                                    _vm.project.cate_car == 1 ||
                                    _vm.project.cate_shops == 1
                                      ? _c(
                                          "div",
                                          { staticClass: "echart-villa" },
                                          [
                                            _c("pie-chart", {
                                              attrs: {
                                                id: "echart-two",
                                                "chart-data":
                                                  _vm.chartDataVilla,
                                                name:
                                                  _vm.customSetting
                                                    .villa_setting.house_name +
                                                  "各" +
                                                  _vm.customSetting
                                                    .villa_setting.house_type +
                                                  "销售数量占比",
                                              },
                                              on: {
                                                setRenderState: function (
                                                  state
                                                ) {
                                                  return _vm.setRenderState(
                                                    "villa",
                                                    state
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _vm.project.cate_room == 1 && _vm.YData.length
                        ? _c("template", { slot: "bottom" }, [
                            _c(
                              "div",
                              { staticClass: "sales-ranking-wrapper" },
                              [
                                _c("report-subtitle", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.project.cate_room == 1,
                                      expression: "project.cate_room == 1",
                                    },
                                  ],
                                  attrs: {
                                    "sub-title": "（只统计住宅建筑类型）",
                                    title: "各楼层销售总量",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.project.cate_room == 1,
                                        expression: "project.cate_room == 1",
                                      },
                                    ],
                                  },
                                  [
                                    _c("histogram-chart", {
                                      attrs: {
                                        id: "histogramChart",
                                        "x-data": _vm.XData,
                                        "y-data": _vm.YData,
                                      },
                                      on: {
                                        setRenderState: function (state) {
                                          return _vm.setRenderState(
                                            "histogram",
                                            state
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.showProject("03客户情况").show
                ? _c(
                    "div",
                    { staticClass: "kaipan-sale-view" },
                    [
                      _c("report-title", {
                        attrs: {
                          num: _vm.showProject("03客户情况").num,
                          title: "客户情况",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showProject("03客户情况").show
                ? _c(
                    "div",
                    { staticClass: "kaipan-sale-process" },
                    [
                      _c(
                        "card",
                        {
                          attrs: {
                            "is-bottom": true,
                            showTop:
                              _vm.RegionCount.length ||
                              _vm.CityCount.length ||
                              _vm.UserAge.length,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "top",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "kaipan-process" },
                                      [
                                        _vm.RegionCount.length ||
                                        _vm.CityCount.length
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "active-course-title",
                                                },
                                                [
                                                  _c("report-subtitle", {
                                                    attrs: {
                                                      title: "成交客户区域分布",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm.CityCount.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "proportion-sales-title",
                                                    },
                                                    [
                                                      _c("center-title", {
                                                        attrs: {
                                                          title: "按市分布占比",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.CityCount.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "echarts-house",
                                                    },
                                                    [
                                                      _c("pie-chart", {
                                                        attrs: {
                                                          id: "echart-municipal",
                                                          "chart-data":
                                                            _vm.CityCount,
                                                          name: "客户按市分布占比",
                                                        },
                                                        on: {
                                                          setRenderState:
                                                            function (state) {
                                                              return _vm.setRenderState(
                                                                "house",
                                                                state
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.RegionCount.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "proportion-sales-title",
                                                    },
                                                    [
                                                      _c("center-title", {
                                                        attrs: {
                                                          title: "按区分布占比",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.RegionCount.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "echarts-house",
                                                    },
                                                    [
                                                      _c("pie-chart", {
                                                        attrs: {
                                                          id: "echart-region",
                                                          "chart-data":
                                                            _vm.RegionCount,
                                                          name: "客户按区分布占比",
                                                        },
                                                        on: {
                                                          setRenderState:
                                                            function (state) {
                                                              return _vm.setRenderState(
                                                                "house",
                                                                state
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _vm.UserAge.length
                                          ? _c("report-subtitle", {
                                              attrs: {
                                                title: "成交客户年龄段分布",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.UserAge.length
                                          ? _c(
                                              "div",
                                              { staticClass: "echarts-house" },
                                              [
                                                _c("columnar-chart", {
                                                  attrs: {
                                                    ispercent: true,
                                                    id: "userageChart",
                                                    "is-legend": false,
                                                    "x-data": _vm.userAgexData,
                                                    "y-data-one":
                                                      _vm.userAgexDataDataOne,
                                                    "y-data-two": [0],
                                                  },
                                                  on: {
                                                    setRenderState: function (
                                                      state
                                                    ) {
                                                      return _vm.setRenderState(
                                                        "userageChart1",
                                                        state
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2091538170
                          ),
                        },
                        [
                          _c(
                            "template",
                            { slot: "bottom" },
                            [
                              _c("report-subtitle", {
                                attrs: {
                                  "sub-title": "（统计已认购的客户）",
                                  title: "客户选房时长统计",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "customer-time" },
                                [
                                  _c("customer-arc", {
                                    attrs: {
                                      "chart-data": _vm.customChooseTime,
                                      name: _vm.customChooseTime_name,
                                    },
                                    on: {
                                      setRenderState: function (state) {
                                        return _vm.setRenderState(
                                          "customer",
                                          state
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("report-subtitle", {
                                attrs: { title: "已认购客户与其意向的适配度" },
                              }),
                              _c("div", { staticClass: "has-buy-condition" }, [
                                _c(
                                  "div",
                                  { staticClass: "has-buy-conditon-wrapper" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "has-buy-left-wrapper" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "has-buy-left-icon" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("../../assets/report/person.png"),
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "left-icon-text" },
                                              [_vm._v("已认购客户中")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "brackets-wrapper" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("../../assets/report/brackets.png"),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "has-buy-right-text" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "has-buy-text" },
                                          [
                                            _vm._v(
                                              "/" +
                                                _vm._s(
                                                  _vm.getPercentage(
                                                    _vm.collectFit.cate_count,
                                                    _vm.collectFit.user_count
                                                  )
                                                ) +
                                                "%的客户买到了意向" +
                                                _vm._s(
                                                  _vm.customSetting.custom_data
                                                    .house_type_report
                                                ) +
                                                "。"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "has-buy-text" },
                                          [
                                            _vm._v(
                                              "/" +
                                                _vm._s(
                                                  _vm.getPercentage(
                                                    _vm.collectFit.room_count,
                                                    _vm.collectFit.user_count
                                                  )
                                                ) +
                                                "%的客户买到了意向单内的" +
                                                _vm._s(
                                                  _vm.customSetting.custom_data
                                                    .house_res_report
                                                ) +
                                                "。"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "has-buy-text" },
                                          [
                                            _vm._v(
                                              "/" +
                                                _vm._s(
                                                  _vm.getPercentage(
                                                    _vm.collectFit.one_count,
                                                    _vm.collectFit.user_count
                                                  )
                                                ) +
                                                "%的客户买到了第一意向" +
                                                _vm._s(
                                                  _vm.customSetting.custom_data
                                                    .house_res_report
                                                ) +
                                                "。"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "has-buy-text" },
                                          [
                                            _vm._v(
                                              "/" +
                                                _vm._s(
                                                  _vm.getPercentage(
                                                    _vm.collectFit.one_count2,
                                                    _vm.collectFit.user_count
                                                  )
                                                ) +
                                                "%的客户买到了第二意向" +
                                                _vm._s(
                                                  _vm.customSetting.custom_data
                                                    .house_res_report
                                                ) +
                                                "。"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "has-buy-text" },
                                          [
                                            _vm._v(
                                              "/" +
                                                _vm._s(
                                                  _vm.getPercentage(
                                                    _vm.collectFit.one_count3,
                                                    _vm.collectFit.user_count
                                                  )
                                                ) +
                                                "%的客户买到了第三意向" +
                                                _vm._s(
                                                  _vm.customSetting.custom_data
                                                    .house_res_report
                                                ) +
                                                "。"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "has-buy-text" },
                                          [
                                            _vm._v(
                                              "/" +
                                                _vm._s(
                                                  100 -
                                                    _vm.getPercentage(
                                                      _vm.collectFit.room_count,
                                                      _vm.collectFit.user_count
                                                    )
                                                ) +
                                                "%的客户购买的" +
                                                _vm._s(
                                                  _vm.customSetting.custom_data
                                                    .house_res_report
                                                ) +
                                                "是意向单之外的。"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "has-buy-text" },
                                          [
                                            _vm._v(
                                              "/" +
                                                _vm._s(
                                                  100 -
                                                    _vm.getPercentage(
                                                      _vm.collectFit.cate_count,
                                                      _vm.collectFit.user_count
                                                    )
                                                ) +
                                                "%的客户买了非意向" +
                                                _vm._s(
                                                  _vm.customSetting.custom_data
                                                    .house_type_report
                                                ) +
                                                "。"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.unsoldData.room_count != 0 &&
              _vm.showProject("04销售排名").show
                ? _c(
                    "div",
                    { staticClass: "kaipan-sale-view" },
                    [
                      _c("report-title", {
                        attrs: {
                          num: _vm.showProject("04销售排名").num,
                          title: "销售排名",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.unsoldData.room_count != 0 &&
              _vm.showProject("04销售排名").show
                ? _c(
                    "div",
                    { staticClass: "kaipan-summary" },
                    [
                      _c("card", {
                        attrs: { "is-bottom": false },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "top",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "summary" },
                                    [
                                      _c("report-subtitle", {
                                        attrs: { title: "销售排名" },
                                      }),
                                      _vm.adminRank && _vm.adminRank.length != 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "sales-ranking-subtitle",
                                            },
                                            [
                                              _c("center-title", {
                                                attrs: {
                                                  title: "顾问销售排名",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.adminRank && _vm.adminRank.length != 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "sales-ranking-table",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  class:
                                                    _vm.project.cate_room ===
                                                      1 &&
                                                    _vm.project.cate_car === 1
                                                      ? "sales-ranking-tableHead columns6"
                                                      : "sales-ranking-tableHead columns5",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "sales-ranking-tableheadText width70",
                                                    },
                                                    [_vm._v("销售榜")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "sales-ranking-tableheadText",
                                                    },
                                                    [_vm._v("顾问姓名")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "sales-ranking-tableheadText",
                                                    },
                                                    [_vm._v("销售金额")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "sales-ranking-tableheadText",
                                                    },
                                                    [
                                                      _vm._v("客户"),
                                                      _c("br"),
                                                      _vm._v("转化率"),
                                                    ]
                                                  ),
                                                  (_vm.project.cate_room ===
                                                    1 &&
                                                    _vm.project.cate_car !==
                                                      1) ||
                                                  (_vm.project.cate_car === 1 &&
                                                    _vm.project.cate_room !== 1)
                                                    ? [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "sales-ranking-tableheadText",
                                                          },
                                                          [_vm._v("销售数量")]
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                  _vm.project.cate_room === 1 &&
                                                  _vm.project.cate_car === 1
                                                    ? [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "sales-ranking-tableheadText",
                                                          },
                                                          [_vm._v("住宅销量")]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "sales-ranking-tableheadText",
                                                          },
                                                          [_vm._v("车位销量")]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "sales-ranking-tableheadText",
                                                          },
                                                          [
                                                            _vm._v("车位"),
                                                            _c("br"),
                                                            _vm._v("同购比"),
                                                          ]
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                              _vm._l(
                                                _vm.adminRank,
                                                function (item, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: item.id,
                                                      class:
                                                        _vm.project
                                                          .cate_room === 1 &&
                                                        _vm.project.cate_car ===
                                                          1
                                                          ? "sales-ranking-tableBody columns6"
                                                          : "sales-ranking-tableBody columns5",
                                                    },
                                                    [
                                                      index <= 2
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "sales-ranking-tablebodyText width70",
                                                            },
                                                            [
                                                              index == 0
                                                                ? _c("img", {
                                                                    attrs: {
                                                                      src: require("../../assets/report/no1.png"),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                              index == 1
                                                                ? _c("img", {
                                                                    attrs: {
                                                                      src: require("../../assets/report/no2.png"),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                              index == 2
                                                                ? _c("img", {
                                                                    attrs: {
                                                                      src: require("../../assets/report/no3.png"),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "sale-ranking-num",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  index + 1
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sales-ranking-name",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.name)
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sales-ranking-tablebodyText",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.avg_price
                                                                ? Math.round(
                                                                    +item.avg_price /
                                                                      10000
                                                                  ) + "万"
                                                                : "未提供"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sales-ranking-tablebodyText",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getPercentage(
                                                                  item.order_user_count,
                                                                  item.user_count
                                                                )
                                                              ) +
                                                              "% "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.project.cate_room ===
                                                      1
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "sales-ranking-tablebodyText",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.isNullNum(
                                                                    item.cate1_count
                                                                  )
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .customSetting
                                                                      .unit
                                                                  )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.project.cate_car === 1
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "sales-ranking-tablebodyText",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.isNullNum(
                                                                    item.cate2_count
                                                                  )
                                                                ) + " 个"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.project.cate_room ===
                                                        1 &&
                                                      _vm.project.cate_car === 1
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "sales-ranking-tablebodyText",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.carProportion
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.teamRank &&
                                                _vm.teamRank.length != 0,
                                              expression:
                                                "teamRank && teamRank.length != 0",
                                            },
                                          ],
                                          staticClass: "sales-ranking-subtitle",
                                        },
                                        [
                                          _c("center-title", {
                                            attrs: { title: "团队销售排名" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.teamRank &&
                                                _vm.teamRank.length != 0,
                                              expression:
                                                "teamRank && teamRank.length != 0",
                                            },
                                          ],
                                          staticClass: "sales-ranking-table",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class:
                                                _vm.project.cate_room === 1 &&
                                                _vm.project.cate_car === 1
                                                  ? "sales-ranking-tableHead columns6"
                                                  : "sales-ranking-tableHead columns5",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "sales-ranking-tableheadText width70",
                                                },
                                                [_vm._v("销售榜")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "sales-ranking-tableheadText",
                                                },
                                                [_vm._v("团队名称")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "sales-ranking-tableheadText",
                                                },
                                                [_vm._v("销售金额")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "sales-ranking-tableheadText",
                                                },
                                                [
                                                  _vm._v("客户"),
                                                  _c("br"),
                                                  _vm._v("转化率"),
                                                ]
                                              ),
                                              (_vm.project.cate_room === 1 &&
                                                _vm.project.cate_car !== 1) ||
                                              (_vm.project.cate_car === 1 &&
                                                _vm.project.cate_room !== 1)
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "sales-ranking-tableheadText",
                                                      },
                                                      [_vm._v("销售数量")]
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.project.cate_room === 1 &&
                                              _vm.project.cate_car === 1
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "sales-ranking-tableheadText",
                                                      },
                                                      [_vm._v("住宅销量")]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "sales-ranking-tableheadText",
                                                      },
                                                      [_vm._v("车位销量")]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "sales-ranking-tableheadText",
                                                      },
                                                      [
                                                        _vm._v("车位"),
                                                        _c("br"),
                                                        _vm._v("同购比"),
                                                      ]
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _vm._l(
                                            _vm.teamRank,
                                            function (item, num) {
                                              return _c(
                                                "div",
                                                {
                                                  key: item.id,
                                                  class:
                                                    _vm.project.cate_room ===
                                                      1 &&
                                                    _vm.project.cate_car === 1
                                                      ? "sales-ranking-tableBody columns6"
                                                      : "sales-ranking-tableBody columns5",
                                                },
                                                [
                                                  num <= 2
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sales-ranking-tablebodyText width70",
                                                        },
                                                        [
                                                          num == 0
                                                            ? _c("img", {
                                                                attrs: {
                                                                  src: require("../../assets/report/no1.png"),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          num == 1
                                                            ? _c("img", {
                                                                attrs: {
                                                                  src: require("../../assets/report/no2.png"),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          num == 2
                                                            ? _c("img", {
                                                                attrs: {
                                                                  src: require("../../assets/report/no3.png"),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sale-ranking-num",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(num + 1)
                                                          ),
                                                        ]
                                                      ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "sales-ranking-name",
                                                    },
                                                    [_vm._v(_vm._s(item.name))]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "sales-ranking-tablebodyText",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.avg_price
                                                            ? Math.round(
                                                                +item.avg_price /
                                                                  10000
                                                              ) + "万"
                                                            : "未提供"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "sales-ranking-tablebodyText",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getPercentage(
                                                              item.order_user_count,
                                                              item.user_count
                                                            )
                                                          ) +
                                                          "% "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.project.cate_room === 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sales-ranking-tablebodyText",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.isNullNum(
                                                                item.cate1_count
                                                              )
                                                            ) +
                                                              " " +
                                                              _vm._s(
                                                                _vm
                                                                  .customSetting
                                                                  .unit
                                                              )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.project.cate_car === 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sales-ranking-tablebodyText",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.isNullNum(
                                                                item.cate2_count
                                                              )
                                                            ) + " 个"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.project.cate_room === 1 &&
                                                  _vm.project.cate_car === 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sales-ranking-tablebodyText",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.carProportion
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _vm.unsoldUserCopy.length > 0
                                        ? _c(
                                            "div",
                                            { staticClass: "summary-title" },
                                            [
                                              _c("report-subtitle", {
                                                attrs: {
                                                  title: "未认购客户的特性",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "info_box" },
                                        _vm._l(
                                          _vm.unsoldUserCopy,
                                          function (item, i) {
                                            return _c(
                                              "list-item",
                                              {
                                                key: "unsoldUserCopy" + i,
                                                attrs: { num: i + 1 },
                                              },
                                              [_vm._v(_vm._s(item))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          759993029
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showProject("05开盘流程").show
                ? _c(
                    "div",
                    { staticClass: "kaipan-sale-view" },
                    [
                      _c("report-title", {
                        attrs: {
                          num: _vm.showProject("05开盘流程").num,
                          title: "开盘流程",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showProject("05开盘流程").show
                ? _c(
                    "div",
                    { staticClass: "kaipan-sale-process" },
                    [
                      _c(
                        "card",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "top",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "kaipan-process" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "summary-title-o" },
                                          [
                                            _c("report-subtitle", {
                                              attrs: {
                                                title: "开盘各环节一览",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        Object.keys(_vm.projectRule.sign)
                                          .length != 0
                                          ? _c("process-item", {
                                              attrs: {
                                                title:
                                                  _vm.projectRule.sign
                                                    .SignType == 1
                                                    ? "开盘签到"
                                                    : "签约签到",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "icon",
                                                    fn: function () {
                                                      return [
                                                        _c("img", {
                                                          attrs: {
                                                            src: require("../../assets/report/signin.png"),
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "content",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "process-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getTimeDay(
                                                                    _vm
                                                                      .projectRule
                                                                      .sign
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm
                                                                        .projectRule
                                                                        .selfSelect
                                                                        .BuyType !=
                                                                      1,
                                                                    expression:
                                                                      "projectRule.selfSelect.BuyType != 1",
                                                                  },
                                                                ],
                                                              },
                                                              [_vm._v("，")]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm
                                                                        .projectRule
                                                                        .selfSelect
                                                                        .BuyType !=
                                                                      1,
                                                                    expression:
                                                                      "projectRule.selfSelect.BuyType != 1",
                                                                  },
                                                                ],
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "客户签到率"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm
                                                                        .projectRule
                                                                        .selfSelect
                                                                        .BuyType !=
                                                                      1,
                                                                    expression:
                                                                      "projectRule.selfSelect.BuyType != 1",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "process-num",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getPercentage(
                                                                        _vm
                                                                          .projectRule
                                                                          .sign
                                                                          .signCount,
                                                                        _vm
                                                                          .projectRule
                                                                          .userCount
                                                                      )
                                                                    ) +
                                                                    "% "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                1428945057
                                              ),
                                            })
                                          : _vm._e(),
                                        Object.keys(_vm.projectRule.selectNum)
                                          .length != 0
                                          ? _c("process-item", {
                                              attrs: { title: "选房摇号" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "icon",
                                                    fn: function () {
                                                      return [
                                                        _c("img", {
                                                          attrs: {
                                                            src: require("../../assets/report/houseIcon.png"),
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "content",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "process-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " 摇号分为" +
                                                                _vm._s(
                                                                  _vm.isNullNum(
                                                                    _vm
                                                                      .projectRule
                                                                      .selectNum
                                                                      .levelCount
                                                                  )
                                                                ) +
                                                                "组， 共" +
                                                                _vm._s(
                                                                  _vm.isNullNum(
                                                                    _vm
                                                                      .projectRule
                                                                      .selectNum
                                                                      .roundCount
                                                                  )
                                                                ) +
                                                                "轮， 每轮" +
                                                                _vm._s(
                                                                  _vm.isNullNum(
                                                                    _vm
                                                                      .projectRule
                                                                      .selectNum
                                                                      .RandCount
                                                                  )
                                                                ) +
                                                                "人。 平均每轮摇号时长" +
                                                                _vm._s(
                                                                  _vm.isNullNum(
                                                                    _vm
                                                                      .projectRule
                                                                      .selectNum
                                                                      .avg
                                                                  )
                                                                ) +
                                                                "分钟，选房入场率达到 "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "process-num",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getPercentage(
                                                                        _vm
                                                                          .projectRule
                                                                          .selectNum
                                                                          .userCount,
                                                                        _vm
                                                                          .projectRule
                                                                          .userCount
                                                                      )
                                                                    ) +
                                                                    "% "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                1290377664
                                              ),
                                            })
                                          : _vm._e(),
                                        Object.keys(_vm.projectRule.selfSelect)
                                          .length != 0 &&
                                        _vm.flowData.is_batch != 1
                                          ? _c("process-item", {
                                              attrs: {
                                                title:
                                                  _vm.projectRule.selfSelect
                                                    .BuyType == 1
                                                    ? "自主选房"
                                                    : "协助选房",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "icon",
                                                    fn: function () {
                                                      return [
                                                        _c("img", {
                                                          staticStyle: {
                                                            width: "40px",
                                                          },
                                                          attrs: {
                                                            src: require("../../assets/report/thumbs.png"),
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "content",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "process-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.project
                                                                    .pin_ren_gou
                                                                ) +
                                                                "数量" +
                                                                _vm._s(
                                                                  _vm.isNullNum(
                                                                    _vm
                                                                      .projectRule
                                                                      .selfSelect
                                                                      .order_count
                                                                  )
                                                                ) +
                                                                _vm._s(
                                                                  _vm
                                                                    .customSetting
                                                                    .unit
                                                                ) +
                                                                "， 订单总额 "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "process-num",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.tenThousandTrans(
                                                                        _vm
                                                                          .projectRule
                                                                          .selfSelect
                                                                          .count_price
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              "万元， 订单面积" +
                                                                _vm._s(
                                                                  _vm
                                                                    .projectRule
                                                                    .selfSelect
                                                                    .area_count ||
                                                                    0
                                                                ) +
                                                                "平米 "
                                                            ),
                                                            _vm.projectRule
                                                              .selfSelect
                                                              .BuyType == 1
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    " 。平均" +
                                                                      _vm._s(
                                                                        _vm
                                                                          .project
                                                                          .pin_ren_gou
                                                                      ) +
                                                                      "用时 "
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "process-num",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.getTimeFomat(
                                                                              _vm
                                                                                .projectRule
                                                                                .selfSelect
                                                                                .avg
                                                                            )
                                                                              .time
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm.getTimeFomat(
                                                                    _vm
                                                                      .projectRule
                                                                      .selfSelect
                                                                      .avg
                                                                  ).isS
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "秒"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "分钟"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                3624984181
                                              ),
                                            })
                                          : _vm._e(),
                                        Object.keys(_vm.projectRule.signNum)
                                          .length != 0
                                          ? _c("process-item", {
                                              attrs: { title: "签约排号" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "icon",
                                                    fn: function () {
                                                      return [
                                                        _c("img", {
                                                          staticStyle: {
                                                            width: "32px",
                                                          },
                                                          attrs: {
                                                            src: require("../../assets/report/threeline.png"),
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "content",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "process-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " 排号共" +
                                                                _vm._s(
                                                                  _vm.isNullNum(
                                                                    _vm
                                                                      .projectRule
                                                                      .signNum
                                                                      .roundCount
                                                                  )
                                                                ) +
                                                                "轮， 每轮" +
                                                                _vm._s(
                                                                  _vm.isNullNum(
                                                                    _vm
                                                                      .projectRule
                                                                      .signNum
                                                                      .RandCount
                                                                  )
                                                                ) +
                                                                "人， 共" +
                                                                _vm._s(
                                                                  _vm.isNullNum(
                                                                    _vm
                                                                      .projectRule
                                                                      .signNum
                                                                      .userCount
                                                                  )
                                                                ) +
                                                                "人。 平均每轮排号时长" +
                                                                _vm._s(
                                                                  _vm.isNullNum(
                                                                    _vm
                                                                      .projectRule
                                                                      .signNum
                                                                      .avg
                                                                  )
                                                                ) +
                                                                "分钟，签约入场率达 "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "process-num",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getPercentage(
                                                                        _vm
                                                                          .projectRule
                                                                          .signNum
                                                                          .intoUserCount,
                                                                        _vm
                                                                          .projectRule
                                                                          .signNum
                                                                          .userCount
                                                                      )
                                                                    ) +
                                                                    "% "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                1200181187
                                              ),
                                            })
                                          : _vm._e(),
                                        Object.keys(_vm.projectRule.pay)
                                          .length != 0
                                          ? _c("process-item", {
                                              attrs: {
                                                title:
                                                  _vm.projectRule.pay
                                                    .PriceType == 1
                                                    ? "线上定金"
                                                    : "线下定金",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "icon",
                                                    fn: function () {
                                                      return [
                                                        _c("img", {
                                                          attrs: {
                                                            src: require("../../assets/report/price.png"),
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "content",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "process-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " 已交纳定金 " +
                                                                _vm._s(
                                                                  _vm.isNullNum(
                                                                    _vm
                                                                      .projectRule
                                                                      .pay
                                                                      .pay_count
                                                                  )
                                                                ) +
                                                                "份，定金总额 " +
                                                                _vm._s(
                                                                  _vm.tenThousandTrans(
                                                                    _vm
                                                                      .projectRule
                                                                      .pay
                                                                      .pay_sum
                                                                  )
                                                                ) +
                                                                "万元，订单总金额 "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "process-num",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.tenThousandTrans(
                                                                        _vm
                                                                          .projectRule
                                                                          .pay
                                                                          .avg_price
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " 万元， 订单面积" +
                                                                _vm._s(
                                                                  _vm
                                                                    .projectRule
                                                                    .pay
                                                                    .area_count ||
                                                                    0
                                                                ) +
                                                                "平米 "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                269755195
                                              ),
                                            })
                                          : _vm._e(),
                                        Object.keys(_vm.projectRule.signUp)
                                          .length != 0
                                          ? _c("process-item", {
                                              attrs: {
                                                title:
                                                  _vm.projectRule.signUp
                                                    .SignUpType == 1
                                                    ? "线上签约"
                                                    : "线下签约",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "icon",
                                                    fn: function () {
                                                      return [
                                                        _c("img", {
                                                          attrs: {
                                                            src: require("../../assets/report/signContract.png"),
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "content",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "process-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " 已签约 " +
                                                                _vm._s(
                                                                  _vm.isNullNum(
                                                                    _vm
                                                                      .projectRule
                                                                      .signUp
                                                                      .pay_count
                                                                  )
                                                                ) +
                                                                "份，订单总额 "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "process-num",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.tenThousandTrans(
                                                                        _vm
                                                                          .projectRule
                                                                          .signUp
                                                                          .avg_price
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " 万元， 订单面积" +
                                                                _vm._s(
                                                                  _vm
                                                                    .projectRule
                                                                    .signUp
                                                                    .area_count ||
                                                                    0
                                                                ) +
                                                                "平米 "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                4189663265
                                              ),
                                            })
                                          : _vm._e(),
                                        Object.keys(_vm.projectRule.archive)
                                          .length != 0
                                          ? _c("process-item", {
                                              attrs: {
                                                "is-end-line": false,
                                                title: "资料存档",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "icon",
                                                    fn: function () {
                                                      return [
                                                        _c("img", {
                                                          attrs: {
                                                            src: require("../../assets/report/dataArchiving.png"),
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "content",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "process-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " 已存档 " +
                                                                _vm._s(
                                                                  _vm.isNullNum(
                                                                    _vm
                                                                      .projectRule
                                                                      .archive
                                                                      .count
                                                                  )
                                                                ) +
                                                                "份，订单总额 "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "process-num",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.tenThousandTrans(
                                                                        _vm
                                                                          .projectRule
                                                                          .archive
                                                                          .avg_price
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " 万元， 订单面积" +
                                                                _vm._s(
                                                                  _vm
                                                                    .projectRule
                                                                    .archive
                                                                    .area_count ||
                                                                    0
                                                                ) +
                                                                "平米 "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                3707112375
                                              ),
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2293469812
                          ),
                        },
                        [
                          _c("template", { slot: "bottom" }, [
                            _c(
                              "div",
                              { staticClass: "active-course-des" },
                              [
                                _c("report-subtitle", {
                                  attrs: { title: "活动历程" },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "active-course-text" },
                                  [
                                    _vm._v(" 本项目经历了 "),
                                    _c(
                                      "span",
                                      { staticClass: "active-course-num" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.isNullNum(
                                                _vm.flowData.e2_count
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" 次公测、 "),
                                    _c(
                                      "span",
                                      { staticClass: "active-course-num" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.isNullNum(
                                                _vm.flowData.e3_count
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" 次内测。 "),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "active-course-text" },
                                  [
                                    _vm._v(" 正式开盘持续 "),
                                    _c(
                                      "span",
                                      { staticClass: "active-course-num" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getMin(_vm.flowData.seconds)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" 分钟， "),
                                    _vm.flowData.is_batch == 1
                                      ? _c("span", [_vm._v("分成")])
                                      : _vm._e(),
                                    _vm.flowData.is_batch == 1
                                      ? _c(
                                          "span",
                                          { staticClass: "active-course-num" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.isNullNum(
                                                  _vm.flowData.round_count
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.flowData.is_batch == 1
                                      ? _c("span", [_vm._v("个场次，")])
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "active-course-text" },
                                  [
                                    _vm.flowData.is_round == 1
                                      ? _c(
                                          "span",
                                          { staticClass: "active-course-num" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.isNullNum(
                                                  _vm.flowData.batch_count
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.flowData.is_round == 1,
                                            expression:
                                              "flowData.is_round == 1",
                                          },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          "个批次" +
                                            _vm._s(_vm.project.pin_ren_gou) +
                                            "。"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "active-course-text" },
                                      [_vm._v("最终圆满结束~")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showProject("06落位转化").show
                ? _c(
                    "div",
                    { staticClass: "kaipan-sale-view" },
                    [
                      _c("report-title", {
                        attrs: {
                          num: _vm.showProject("06落位转化").num,
                          title: "落位转化",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showProject("06落位转化").show
                ? _c(
                    "div",
                    { staticClass: "kaipan-sale-process" },
                    [
                      _c("card", {
                        attrs: { "is-bottom": false },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "top",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "kaipan-process" }, [
                                    _c(
                                      "div",
                                      { staticClass: "summary-title-o" },
                                      [
                                        _c("report-subtitle", {
                                          attrs: {
                                            title: "落位与实际销售情况的对比",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "summary-title-box" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "summary-box-title" },
                                          [
                                            _vm._v(
                                              " 开盘" +
                                                _vm._s(
                                                  _vm.customSetting.title
                                                ) +
                                                "落位率为 " +
                                                _vm._s(
                                                  _vm.getPercentage(
                                                    _vm.SalesStatus.event
                                                      .one_count,
                                                    _vm.SalesStatus.event
                                                      .room_count
                                                  )
                                                ) +
                                                "% 实际销售去化率 " +
                                                _vm._s(
                                                  _vm.getPercentage(
                                                    _vm.SalesStatus.event
                                                      .order_count,
                                                    _vm.SalesStatus.event
                                                      .room_count
                                                  )
                                                ) +
                                                "% "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "summary-chart-wrapper",
                                          },
                                          [
                                            _vm.buildChart.length
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "font-size": "16px",
                                                      "font-family":
                                                        "Source Han Sans CN",
                                                      "font-weight": "400",
                                                      color:
                                                        "rgba(0, 0, 0, 0.5)",
                                                      "margin-top": "10px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "楼栋\\区域落位率&去化率对比"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._l(
                                              _vm.buildChart,
                                              function (item, i) {
                                                return _c(
                                                  "div",
                                                  { key: "buildchart-" + i },
                                                  [
                                                    _c("columnar-chart", {
                                                      attrs: {
                                                        id: "columnar-one-" + i,
                                                        "is-legend": i === 0,
                                                        "x-data":
                                                          item.buildName,
                                                        "y-data-one":
                                                          item.buildYDataOne,
                                                        "y-data-two":
                                                          item.buildYDataTwo,
                                                      },
                                                      on: {
                                                        setRenderState:
                                                          function (state) {
                                                            return _vm.setRenderState(
                                                              "buildchart_" + i,
                                                              state
                                                            )
                                                          },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "line-box-wrapper",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "line-wrapper new-line",
                                                }),
                                              ]
                                            ),
                                            _vm.cateChart.length
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "font-size": "16px",
                                                      "font-family":
                                                        "Source Han Sans CN",
                                                      "font-weight": "400",
                                                      color:
                                                        "rgba(0, 0, 0, 0.5)",
                                                      "margin-top": "10px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "户型\\类型落位率&去化率对比"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._l(
                                              _vm.cateChart,
                                              function (item, i) {
                                                return _c(
                                                  "div",
                                                  { key: "catechart-" + i },
                                                  [
                                                    _c("columnar-chart", {
                                                      attrs: {
                                                        id: "columnar-two-" + i,
                                                        "is-legend": i === 0,
                                                        "x-data": item.cateName,
                                                        "y-data-one":
                                                          item.cateYDataOne,
                                                        "y-data-two":
                                                          item.cateYDataTwo,
                                                      },
                                                      on: {
                                                        setRenderState:
                                                          function (state) {
                                                            return _vm.setRenderState(
                                                              "catechart_" + i,
                                                              state
                                                            )
                                                          },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "summary-box-title summary-box-title2",
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                "开盘预估成功率高于60%的客户中 ，"
                                              ),
                                            ]),
                                            _c("div", [
                                              _vm._v(
                                                " 买到意向房源的客户达到 " +
                                                  _vm._s(
                                                    _vm.getPercentage_c(
                                                      _vm.SalesStatus.SUcount,
                                                      _vm.userCount
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "summary-table" },
                                          [
                                            _c("customTable", {
                                              attrs: {
                                                "table-data":
                                                  _vm.SalesStatus.success,
                                                "table-head":
                                                  _vm.summaryDataHead,
                                                "header-color": "#FFA471",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          386218355
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.info.advance || _vm.info.iteration || _vm.info.flowpath) &&
              _vm.showProject("07总结及建议").show
                ? _c(
                    "div",
                    { staticClass: "kaipan-sale-view" },
                    [
                      _c("report-title", {
                        attrs: {
                          num: _vm.showProject("07总结及建议").num,
                          title: "开盘总结及建议",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.info.advance || _vm.info.iteration || _vm.info.flowpath) &&
              _vm.showProject("07总结及建议").show
                ? _c(
                    "div",
                    { staticClass: "kaipan-sale-process" },
                    [
                      _c("card", {
                        attrs: { "is-bottom": false },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "top",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "infoContent" }, [
                                    _vm.info.advance
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            { staticClass: "summary-title" },
                                            [
                                              _c("report-subtitle", {
                                                attrs: {
                                                  title: "推进过程小结",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "info_box" },
                                            _vm._l(
                                              _vm.info.advance,
                                              function (item, i) {
                                                return _c(
                                                  "list-item",
                                                  {
                                                    key: "advance" + i,
                                                    attrs: { num: i + 1 },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " " + _vm._s(item) + " "
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.info.iteration
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            { staticClass: "summary-title" },
                                            [
                                              _c("report-subtitle", {
                                                attrs: {
                                                  title: "系统迭代建议",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "info_box" },
                                            _vm._l(
                                              _vm.info.iteration,
                                              function (item, i) {
                                                return _c(
                                                  "list-item",
                                                  {
                                                    key: "iteration" + i,
                                                    attrs: { num: i + 1 },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " " + _vm._s(item) + " "
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.info.flowpath
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            { staticClass: "summary-title" },
                                            [
                                              _c("report-subtitle", {
                                                attrs: {
                                                  title: "开盘流程建议",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "info_box" },
                                            _vm._l(
                                              _vm.info.flowpath,
                                              function (item, i) {
                                                return _c(
                                                  "list-item",
                                                  {
                                                    key: "flowpath" + i,
                                                    attrs: { num: i + 1 },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " " + _vm._s(item) + " "
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3418383548
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "bottom-c-text" },
              [
                _c("footer-custom", {
                  attrs: {
                    title: "云销控，10年成就专业品质，让开盘变得更简单",
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }