var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "block-box" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "info-box" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { offset: 3, span: 7 } }, [
                _c("div", { staticClass: "detail-item2" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("姓名：")]),
                  _c("div", { staticClass: "text" }, [
                    _c("div", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.new_user.name)),
                    ]),
                    !_vm.is_add
                      ? _c("div", { staticClass: "edit" }, [
                          _vm._v(_vm._s(_vm.old_user.name)),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { offset: 3, span: 7 } }, [
                _c("div", { staticClass: "detail-item2" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("登录手机号：")]),
                  _c("div", { staticClass: "text" }, [
                    _c("div", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.new_user.tel)),
                    ]),
                    !_vm.is_add
                      ? _c("div", { staticClass: "edit" }, [
                          _vm._v(_vm._s(_vm.old_user.tel)),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { offset: 3, span: 7 } }, [
                _c("div", { staticClass: "detail-item2" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("限购数量：")]),
                  _c("div", { staticClass: "text" }, [
                    _c("div", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.new_user.buy_count)),
                    ]),
                    !_vm.is_add
                      ? _c("div", { staticClass: "edit" }, [
                          _vm._v(_vm._s(_vm.old_user.buy_count)),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { offset: 3, span: 7 } }, [
                _c("div", { staticClass: "detail-item2" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("置业顾问：")]),
                  _c("div", { staticClass: "text" }, [
                    _c("div", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.new_user.admin_name)),
                    ]),
                    !_vm.is_add
                      ? _c("div", { staticClass: "edit" }, [
                          _vm._v(_vm._s(_vm.old_user.admin_name)),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._m(1),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { offset: 3, span: 7 } }, [
                _c("div", { staticClass: "detail-item2" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("诚意金额：")]),
                  _c("div", { staticClass: "text" }, [
                    _vm.projectList.cate_room
                      ? _c("div", { staticClass: "row" }, [
                          _c("span", [
                            _vm._v("住宅: " + _vm._s(_vm.new_price.room_price)),
                          ]),
                          _c("span", [
                            _vm._v(
                              "份数: " + _vm._s(_vm.new_price.room_number)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.projectList.cate_villa
                      ? _c("div", { staticClass: "row" }, [
                          _c("span", [
                            _vm._v(
                              "别墅: " + _vm._s(_vm.new_price.villa_price)
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              "份数: " + _vm._s(_vm.new_price.villa_number)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.projectList.cate_car
                      ? _c("div", { staticClass: "row" }, [
                          _c("span", [
                            _vm._v("车位: " + _vm._s(_vm.new_price.car_price)),
                          ]),
                          _c("span", [
                            _vm._v("份数: " + _vm._s(_vm.new_price.car_number)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.projectList.cate_shops
                      ? _c("div", { staticClass: "row" }, [
                          _c("span", [
                            _vm._v(
                              "商品: " + _vm._s(_vm.new_price.shops_price)
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              "份数: " + _vm._s(_vm.new_price.shops_number)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    !_vm.is_add
                      ? _c("div", { staticClass: "edit" }, [
                          _vm.projectList.cate_room
                            ? _c("div", { staticClass: "row" }, [
                                _c("span", [
                                  _vm._v(
                                    "住宅: " + _vm._s(_vm.old_price.room_price)
                                  ),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    "份数: " + _vm._s(_vm.old_price.room_number)
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.projectList.cate_villa
                            ? _c("div", { staticClass: "row" }, [
                                _c("span", [
                                  _vm._v(
                                    "别墅: " + _vm._s(_vm.old_price.villa_price)
                                  ),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    "份数: " +
                                      _vm._s(_vm.old_price.villa_number)
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.projectList.cate_car
                            ? _c("div", { staticClass: "row" }, [
                                _c("span", [
                                  _vm._v(
                                    "车位: " + _vm._s(_vm.old_price.car_price)
                                  ),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    "份数: " + _vm._s(_vm.old_price.car_number)
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.projectList.cate_shops
                            ? _c("div", { staticClass: "row" }, [
                                _c("span", [
                                  _vm._v(
                                    "商品: " + _vm._s(_vm.old_price.shops_price)
                                  ),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    "份数: " +
                                      _vm._s(_vm.old_price.shops_number)
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { offset: 3, span: 7 } }, [
                _c("div", { staticClass: "detail-item2" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("诚意单号：")]),
                  _c("div", { staticClass: "text" }, [
                    _c("div", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.new_user.code)),
                    ]),
                    !_vm.is_add
                      ? _c("div", { staticClass: "edit" }, [
                          _vm._v(_vm._s(_vm.old_user.code)),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { offset: 3, span: 7 } }, [
                _c("div", { staticClass: "detail-item2" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("客户类别：")]),
                  _c("div", { staticClass: "text" }, [
                    _c("div", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.new_user.level)),
                    ]),
                    !_vm.is_add
                      ? _c("div", { staticClass: "edit" }, [
                          _vm._v(_vm._s(_vm.old_user.level)),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { offset: 3, span: 7 } }, [
                _c("div", { staticClass: "detail-item2" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("批次序号：")]),
                  _c("div", { staticClass: "text" }, [
                    _c("div", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.new_user.num)),
                    ]),
                    !_vm.is_add
                      ? _c("div", { staticClass: "edit" }, [
                          _vm._v(_vm._s(_vm.old_user.num)),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { offset: 3, span: 7 } }, [
                _c("div", { staticClass: "detail-item2" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v("选房时长限定："),
                  ]),
                  _c("div", { staticClass: "text" }, [
                    _c("div", { staticClass: "info" }, [
                      _vm._v(
                        _vm._s(_vm.new_user.min) +
                          "分 " +
                          _vm._s(_vm.new_user.sec) +
                          "秒"
                      ),
                    ]),
                    !_vm.is_add
                      ? _c("div", { staticClass: "edit" }, [
                          _vm._v(
                            _vm._s(_vm.old_user.min) +
                              "分 " +
                              _vm._s(_vm.old_user.sec) +
                              "秒"
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { offset: 3, span: 7 } }, [
                _c("div", { staticClass: "detail-item2" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("其他信息：")]),
                  _c("div", { staticClass: "text" }, [
                    _c("div", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.new_user.other_remark)),
                    ]),
                    !_vm.is_add
                      ? _c("div", { staticClass: "edit" }, [
                          _vm._v(_vm._s(_vm.old_user.other_remark)),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._m(2),
          _c(
            "el-row",
            _vm._l(_vm.new_owner, function (item, index) {
              return _c(
                "el-col",
                { key: index, attrs: { offset: 3, span: 7 } },
                [
                  _c("div", { staticClass: "detail-item2" }, [
                    _c("div", { staticClass: "button-box right" }, [
                      _c("span", { staticClass: "button" }, [
                        _vm._v("产权人 "),
                        _c("span", { staticClass: "borderSpan" }, [
                          _vm._v(_vm._s(index + 1)),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-item2" }, [
                    _c("div", { staticClass: "name" }, [_vm._v("姓名：")]),
                    _c("div", { staticClass: "text" }, [
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(item.real_name)),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-item2" }, [
                    _c("div", { staticClass: "name" }, [_vm._v("手机号：")]),
                    _c("div", { staticClass: "text" }, [
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(item.tel)),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-item2" }, [
                    _c("div", { staticClass: "name" }, [_vm._v("身份证号：")]),
                    _c("div", { staticClass: "text" }, [
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(item.card)),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-item2" }, [
                    _c("div", { staticClass: "name" }, [_vm._v("其他信息：")]),
                    _c("div", { staticClass: "text" }, [
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(item.address)),
                      ]),
                    ]),
                  ]),
                  !_vm.is_add
                    ? _c("div", { staticClass: "edit-box" }, [
                        _c("div", { staticClass: "detail-item2" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("姓名："),
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _c("div", { staticClass: "info" }, [
                              _vm._v(_vm._s(_vm.old_owner[index].real_name)),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "detail-item2" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("手机号："),
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _c("div", { staticClass: "info" }, [
                              _vm._v(_vm._s(_vm.old_owner[index].tel)),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "detail-item2" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("身份证号："),
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _c("div", { staticClass: "info" }, [
                              _vm._v(_vm._s(_vm.old_owner[index].card)),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "detail-item2" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("其他信息："),
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _c("div", { staticClass: "info" }, [
                              _vm._v(_vm._s(_vm.old_owner[index].address)),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "button-bottom-box" }, [
      _c(
        "div",
        { staticClass: "flex ju-center" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "user/adopt"],
                  expression: "['admin','user/adopt']",
                },
              ],
              staticClass: "greens-btn",
              on: { click: _vm.handlePassAudit },
            },
            [_vm._v("通过驳回 ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "user/reject"],
                  expression: "['admin','user/reject']",
                },
              ],
              staticClass: "orange-btn",
              on: { click: _vm.handleTurnAudit },
            },
            [_vm._v("驳回审核 ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-fixed align-center" }, [
      _c("div", { staticClass: "title-top" }, [
        _c("div", { staticClass: "title" }, [_vm._v("客户详情")]),
        _c("div", { staticClass: "title-tips" }, [_vm._v("框内显示为旧信息")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "padding" }, [
      _c("div", { staticClass: "dashedTop" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "padding" }, [
      _c("div", { staticClass: "dashedTop" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }