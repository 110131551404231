import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import domtoimage from './dom-to-image';
import JsPDF from './jspdf.debug';

/**
 * @param  ele          要生成 pdf 的DOM元素（容器）
 * @param  padfName     PDF文件生成后的文件名字
 * */

function downloadPDF(ele, pdfName, callB) {
  var eleW = ele.offsetWidth; // 获得该容器的宽
  var eleH = ele.offsetHeight; // 获得该容器的高

  var option = {
    width: eleW,
    height: eleH,
    scale: 2 // 放大倍数（提高精度的同时，会加大文件体积）
  };
  domtoimage.toJpeg(ele, _objectSpread({}, option)).then(function (dataUrl) {
    // 回调调试：canvas 生成的图片数据
    callB && callB(dataUrl);
    var PDF = new JsPDF('', 'pt', [eleW, eleH]);

    // 将canvas图片数据，放入PDF（dataUrl,'JPEG',左,上,宽,高）
    PDF.addImage(dataUrl, 'JPEG', 0, 0, eleW, eleH);
    PDF.save(pdfName);
  });
}
export default {
  downloadPDF: downloadPDF
};