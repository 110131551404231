import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import imgUp from '../editFormat/components/imgUp';
import { SetConfColor, GetConfColor } from '@/api/screen';
export default {
  name: "index",
  components: {
    imgUp: imgUp
  },
  props: {
    isSet: {
      type: Boolean,
      default: true
    },
    // 已售套数
    saledNum: {
      type: [String, Number],
      default: '4,000'
    },
    // 销售金额
    saledPrice: {
      type: [String, Number],
      default: '4,000,000,000'
    },
    // powerBy
    powerBy: {
      type: String,
      default: 'powered by 云销控'
    },
    // 类型
    saleType: {
      // 0 活动结束 1 售罄
      type: Number,
      default: 0
    },
    Id: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      JSON1: {
        "type": 2,
        "config": {
          "custom": {
            "font": "",
            "center": false,
            "endShow": false,
            "endText": "感谢业主的信任与支持！",
            "sellOutShow": true,
            "sellOutText": "开盘全部售罄"
          },
          "saledNum": {
            "font": "",
            "center": false,
            "endShow": true,
            "saleText": "热销",
            "sellOutShow": false,
            "sellOutText": "售罄",
            "isTransverse": {
              "text": true,
              "num": true,
              "all": true
            },
            "isSeparate": false
          },
          "background": {
            "src": "public/img/rand/479b25e2-acd8-11ed-9b7e-00163e01ac31.png",
            "color": "rgba(0,0,0,0.5)",
            "center": false
          },
          "saledPrice": {
            "font": "",
            "center": false,
            "endShow": true,
            "saleText": "销售金额",
            "sellOutShow": true,
            "isTransverse": {
              "text": true,
              "num": true,
              "all": true
            },
            "isSeparate": false
          },
          "projectName": {
            "font": "",
            "show": true,
            "color": "black",
            "center": false,
            "isTransverse": true
          },
          "congratulate": {
            "src": "public/img/rand/b8759b61-9225-11ed-b44a-00163e01ac31.png",
            "show": true
          }
        },
        "htmlCss": {
          "custom": {
            "top": 306,
            "left": 835,
            "color": "#FD2601",
            "fontSize": 75
          },
          "powerBy": {
            "color": "#000000",
            "width": "100%",
            "bottom": "10px",
            "text-align": "center"
          },
          "saledNum": {
            "top": 307,
            "left": 835,
            "color": "#FFFFFF",
            "fontSize": 90
          },
          "saledNum_text": {
            "left": 0,
            "top": 0
          },
          "saledNum_num": {
            "left": 0,
            "top": 0
          },
          "background": {
            "top": 0,
            "left": 0,
            "width": "100vw",
            "height": "100vh"
          },
          "saledPrice": {
            "top": 440,
            "left": 835,
            "color": "#FFFFFF",
            "fontSize": 90
          },
          "saledPrice_text": {
            "left": 0,
            "top": 0
          },
          "saledPrice_num": {
            "left": 0,
            "top": 0
          },
          "projectName": {
            "top": 177,
            "left": 835,
            "color": "#FFFFFF",
            "width": 404,
            "height": 99,
            "fontSize": 50
          },
          "congratulate": {
            "top": 168,
            "left": 300,
            "width": 513
          }
        }
      },
      JSON2: {
        "type": 1,
        "config": {
          "custom": {
            "font": "",
            "center": true,
            "endShow": false,
            "endText": "感谢业主的信任与支持！",
            "sellOutShow": false,
            "sellOutText": "感谢业主的信任与支持"
          },
          "saledNum": {
            "font": "",
            "center": true,
            "endShow": true,
            "saleText": "热销",
            "sellOutShow": true,
            "sellOutText": "售罄",
            "isTransverse": {
              "text": true,
              "num": true,
              "all": true
            },
            "isSeparate": false
          },
          "background": {
            "src": "public/img/rand/ea360f5a-9216-11ed-b44a-00163e01ac31.png",
            "color": "rgba(0,0,0,0.5)",
            "center": true
          },
          "saledPrice": {
            "font": "",
            "center": true,
            "endShow": true,
            "saleText": "销售金额",
            "sellOutShow": true,
            "isTransverse": {
              "text": true,
              "num": true,
              "all": true
            },
            "isSeparate": false
          },
          "projectName": {
            "font": "",
            "show": true,
            "color": "black",
            "center": true,
            "isTransverse": true
          },
          "congratulate": {
            "src": "",
            "show": false
          }
        },
        "htmlCss": {
          "custom": {
            "top": "",
            "left": "",
            "color": "#FFFFFF",
            "fontSize": 30
          },
          "powerBy": {
            "color": "#000000",
            "width": "100%",
            "bottom": "10px",
            "text-align": "center"
          },
          "saledNum": {
            "top": 260,
            "left": "",
            "color": "#FFFFFF",
            "fontSize": 40
          },
          "saledNum_text": {
            "left": 0,
            "top": 0
          },
          "saledNum_num": {
            "left": 0,
            "top": 0
          },
          "background": {
            "top": 0,
            "left": 0,
            "width": 730,
            "height": 430
          },
          "saledPrice": {
            "top": 320,
            "left": "",
            "color": "#FFFFFF",
            "fontSize": 48
          },
          "saledPrice_text": {
            "left": 0,
            "top": 0
          },
          "saledPrice_num": {
            "left": 0,
            "top": 0
          },
          "projectName": {
            "top": 157,
            "left": "",
            "color": "#FFFFFF",
            "fontSize": 44
          },
          "congratulate": {
            "top": "",
            "left": "",
            "width": 100
          }
        }
      },
      JSON3: {
        "type": 1,
        "config": {
          "custom": {
            "font": "",
            "center": false,
            "endShow": false,
            "endText": "感谢业主的信任与支持！",
            "sellOutShow": false,
            "sellOutText": "感谢业主的信任与支持"
          },
          "saledNum": {
            "font": "",
            "center": false,
            "endShow": true,
            "saleText": "热销",
            "sellOutShow": true,
            "sellOutText": "售罄",
            "isTransverse": {
              "text": true,
              "num": true,
              "all": true
            },
            "isSeparate": false
          },
          "background": {
            "src": "public/img/rand/bccc0914-a212-11ed-b91f-00163e01ac31.png",
            "color": "rgba(0,0,0,0.5)",
            "center": false,
            "position": "bottom"
          },
          "saledPrice": {
            "font": "",
            "center": true,
            "endShow": true,
            "saleText": "销售金额",
            "sellOutShow": true,
            "isTransverse": {
              "text": true,
              "num": true,
              "all": true
            },
            "isSeparate": false
          },
          "projectName": {
            "font": "",
            "show": true,
            "color": "black",
            "center": false,
            "isTransverse": true
          },
          "congratulate": {
            "src": "public/img/rand/23e9e936-922a-11ed-b44a-00163e01ac31.png",
            "show": true
          }
        },
        "htmlCss": {
          "custom": {
            "top": "",
            "left": "",
            "color": "#FFFFFF",
            "fontSize": 30
          },
          "powerBy": {
            "color": "#000000",
            "width": "100%",
            "bottom": "10px",
            "text-align": "center"
          },
          "saledNum": {
            "top": 552,
            "left": "calc(50% + 30px)",
            "color": "#FFE1A5",
            "fontSize": 90
          },
          "background": {
            "top": 0,
            "left": 0,
            "width": "100vw",
            "height": "100vh"
          },
          "saledPrice": {
            "top": 683,
            "color": "#FFFFFF",
            "fontSize": 56
          },
          "projectName": {
            "top": 552,
            "color": "#FFE1A5",
            "right": "calc(50% + 30px)",
            "fontSize": 90
          },
          "congratulate": {
            "top": 250,
            "left": "calc(50% - 270px)",
            "width": 539
          },
          "saledNum_text": {
            "left": 0,
            "top": 0
          },
          "saledNum_num": {
            "left": 0,
            "top": 0
          },
          "saledPrice_text": {
            "left": 0,
            "top": 0
          },
          "saledPrice_num": {
            "left": 0,
            "top": 0
          }
        }
      },
      JSON4: {
        "type": 1,
        "config": {
          "custom": {
            "font": "",
            "center": false,
            "endShow": false,
            "endText": "感谢业主的信任与支持！",
            "sellOutShow": false,
            "sellOutText": "感谢业主的信任与支持！"
          },
          "saledNum": {
            "font": "",
            "center": true,
            "endShow": true,
            "saleText": "已售",
            "sellOutShow": true,
            "sellOutText": "售罄",
            "isTransverse": {
              "text": true,
              "num": true,
              "all": true
            },
            "isSeparate": false
          },
          "background": {
            "src": "public/img/rand/336fac96-a2d2-11ed-bb58-00163e01ac31.png",
            "color": "rgba(0,0,0,0.5)",
            "center": true
          },
          "saledPrice": {
            "font": "",
            "center": true,
            "endShow": true,
            "saleText": "销售金额",
            "sellOutShow": true,
            "isTransverse": {
              "text": true,
              "num": true,
              "all": true
            },
            "isSeparate": false
          },
          "projectName": {
            "font": "",
            "show": true,
            "center": true,
            "isTransverse": true
          },
          "congratulate": {
            "src": "public/img/rand/90149043-a29f-11ed-9f34-00163e01ac31.png",
            "show": true
          }
        },
        "htmlCss": {
          "custom": {
            "top": 0,
            "left": 0,
            "color": "#FFFFFF",
            "fontSize": 30
          },
          "powerBy": {
            "color": "#FFFFFF",
            "width": "100%",
            "bottom": "10px",
            "text-align": "center"
          },
          "saledNum": {
            "top": 503,
            "color": "#fef7a6",
            "fontSize": 48
          },
          "background": {
            "top": 0,
            "left": 0,
            "width": 1353,
            "height": 740
          },
          "saledPrice": {
            "top": 577,
            "color": "#fef7a6",
            "fontSize": 48
          },
          "projectName": {
            "top": 349,
            "color": "#fef7a6",
            "fontSize": 72
          },
          "congratulate": {
            "top": 117,
            "left": 492,
            "width": 369
          },
          "saledNum_text": {
            "left": 0,
            "top": 0
          },
          "saledNum_num": {
            "left": 0,
            "top": 0
          },
          "saledPrice_text": {
            "left": 0,
            "top": 0
          },
          "saledPrice_num": {
            "left": 0,
            "top": 0
          }
        }
      },
      JSON5: {
        "type": 1,
        "config": {
          "custom": {
            "font": "",
            "center": false,
            "endShow": false,
            "endText": "感谢业主的信任与支持！",
            "sellOutShow": false,
            "sellOutText": "感谢业主的信任与支持！"
          },
          "saledNum": {
            "font": "",
            "center": true,
            "endShow": true,
            "saleText": "已售",
            "sellOutShow": true,
            "sellOutText": "售罄",
            "isTransverse": {
              "text": true,
              "num": true,
              "all": true
            },
            "isSeparate": false
          },
          "background": {
            "src": "public/img/rand/0d11561b-a360-11ed-890a-00163e01ac31.png",
            "color": "rgba(0,0,0,0.5)",
            "center": true
          },
          "saledPrice": {
            "font": "",
            "center": true,
            "endShow": true,
            "saleText": "销售金额",
            "sellOutShow": true,
            "isTransverse": {
              "text": true,
              "num": true,
              "all": true
            },
            "isSeparate": false
          },
          "projectName": {
            "font": "",
            "show": true,
            "center": true,
            "isTransverse": true
          },
          "congratulate": {
            "src": "public/img/rand/89f0ec8b-a2d1-11ed-bb58-00163e01ac31.png",
            "show": true
          }
        },
        "htmlCss": {
          "custom": {
            "top": 0,
            "left": 0,
            "color": "#FFFFFF",
            "fontSize": 30
          },
          "powerBy": {
            "color": "#FFFFFF",
            "width": "100%",
            "bottom": "10px",
            "text-align": "center"
          },
          "saledNum": {
            "top": 340,
            "color": "#FFFFFF",
            "fontSize": 40
          },
          "background": {
            "top": 0,
            "left": 0,
            "width": 939,
            "height": 604
          },
          "saledPrice": {
            "top": 400,
            "color": "#FFFFFF",
            "fontSize": 48
          },
          "projectName": {
            "top": 260,
            "color": "#FFFFFF",
            "fontSize": 53
          },
          "congratulate": {
            "top": 145,
            "left": 357,
            "width": 218
          },
          "saledNum_text": {
            "left": 0,
            "top": 0
          },
          "saledNum_num": {
            "left": 0,
            "top": 0
          },
          "saledPrice_text": {
            "left": 0,
            "top": 0
          },
          "saledPrice_num": {
            "left": 0,
            "top": 0
          }
        }
      },
      defaultData: null,
      data: null,
      dialogShow: false,
      fonts: [{
        name: "阿里东方大楷",
        value: "Alimama_DongFangDaKai"
      }, {
        name: "阿里数黑体",
        value: "Alimama_ShuHeiTi_Bold"
      }, {
        name: "钉钉进步体",
        value: "DingTalk_JinBuTi_Regular"
      }, {
        name: "站酷高端黑",
        value: "zcool-gdh_Regular"
      }, {
        name: "站酷仓耳渔阳体",
        value: "TsangerYuYangT_W04_W04"
      }],
      // 恭贺图片的宽高比例
      congratulateScale: 0,
      saleText: 0,
      id: 0,
      project: {
        id: 0,
        name: ""
      }
    };
  },
  created: function created() {
    this.id = +(this.Id || this.$route.query.id);
    this.saleText = this.saleType;
    this.doGetConfColor();
  },
  mounted: function mounted() {},
  methods: {
    doGetConfColor: function doGetConfColor() {
      var _this = this;
      GetConfColor({
        id: this.id
      }).then(function (res) {
        if (res.data.info.json) {
          _this.data = JSON.parse(res.data.info.json);
          _this.defaultData = JSON.parse(res.data.info.json);
          _this.project.name = res.data.project.name;
          _this.project.id = res.data.info.project_id;
          _this.$nextTick(function () {
            var congratulate = document.getElementById("congratulate");
            if (congratulate) {
              _this.congratulateScale = congratulate.width / congratulate.height;
            }
          });
        } else {
          _this.$message({
            type: 'error',
            message: "不存在屏幕模板！"
          });
          _this.data = null;
        }
      }).catch(function (res) {});
    },
    upBackground: function upBackground(e) {
      this.data.config.background.src = e.Data.url;
    },
    upCongratulate: function upCongratulate(e) {
      var _this2 = this;
      this.data.config.congratulate.src = e.Data.url;
      this.$nextTick(function () {
        _this2.congratulateScale = document.getElementById("congratulate").width / document.getElementById("congratulate").height;
      });
    },
    /**
     * 给css加px
     * @param css
     * @param key
     */
    doCssPushPX: function doCssPushPX(css, key) {
      var CSS = JSON.parse(JSON.stringify(css));
      var pxkey = ["left", "top", "width", "height", "fontSize", "right", "bottom"];
      for (var k in CSS) {
        if (!CSS[k] && CSS[k] !== 0) {
          delete CSS[k];
        }
        {
          CSS[k] += "";
          if (pxkey.indexOf(k) >= 0 && CSS[k].indexOf("px") < 0 && CSS[k].indexOf("vw") < 0 && CSS[k].indexOf("vh") < 0 && CSS[k].indexOf("%") < 0) {
            CSS[k] += "px";
          }
        }
      }
      if (key === "background" && this.data.config.background.center) {
        CSS.left = "calc(50vw - " + this.data.htmlCss.background.width / 2 + "px)";
        CSS.top = "calc(50vh - " + this.data.htmlCss.background.height / 2 + "px)";
      } else if (key === "saledNum_num" || key === "saledNum_text" || key === "saledPrice_text" || key === "saledPrice_num") {
        var data = JSON.parse(JSON.stringify(key === "saledPrice_text" || key === "saledPrice_num" ? this.data.htmlCss.saledPrice : this.data.htmlCss.saledNum));
        for (var _k in data) {
          if (_k !== "left" && _k !== "top") {
            CSS[_k] = data[_k];
            CSS[_k] += "";
            if (pxkey.indexOf(_k) >= 0 && CSS[_k].indexOf("px") < 0 && CSS[_k].indexOf("vw") < 0 && CSS[_k].indexOf("vh") < 0 && CSS[_k].indexOf("%") < 0) {
              CSS[_k] += "px";
            }
          }
        }
      }
      // 字体居中
      if (key === "projectName" || key === "saledNum" || key === "saledPrice" || key === "custom" || key === "saledNum_num" || key === "saledNum_text") {
        if (this.data.config[key === "saledNum_num" || key === "saledNum_text" ? "saledNum" : key].center) {
          CSS.left = "0px";
          CSS.right = "0px";
          CSS.width = "100%";
          CSS["text-align"] = "center";
        }
      }
      // 字体竖向
      if (key === "projectName" || key === "saledNum" || key === "saledNum_num" || key === "saledNum_text" || key === "saledPrice" || key === "saledPrice_text" || key === "saledPrice_num") {
        var isTransverse = false;
        if (key === "projectName") {
          isTransverse = this.data.config.projectName.isTransverse;
        } else if (key === "saledNum_num") {
          isTransverse = this.data.config.saledNum.isTransverse.num;
        } else if (key === "saledNum_text") {
          isTransverse = this.data.config.saledNum.isTransverse.text;
        } else if (key === "saledNum") {
          isTransverse = this.data.config.saledNum.isTransverse.all;
        } else if (key === "saledPrice_num") {
          isTransverse = this.data.config.saledPrice.isTransverse.num;
        } else if (key === "saledPrice_text") {
          isTransverse = this.data.config.saledPrice.isTransverse.text;
        } else if (key === "saledPrice") {
          isTransverse = this.data.config.saledPrice.isTransverse.all;
        }
        if (!isTransverse) {
          CSS["width"] = CSS["fontSize"] || "10px";
          // CSS["word-break"] = "break-word";
          // CSS["text-align"] = "center";
          CSS["writing-mode"] = "vertical-lr";
          CSS["white-space"] = "nowrap";
        }
      }
      // powerBy颜色固定白色
      if (key === "powerBy") {
        CSS["color"] = "#FFFFFF";
      }
      return CSS;
    },
    /**
     * 背景整体按下
     * @param e
     */
    onCursor_background: function onCursor_background(e) {
      var _this3 = this;
      if (!this.isSet) {
        return;
      }
      // 点击位置和当前元素的位置差距
      if (this.data.config.background.center) {
        return;
      }
      var isOK = true;
      var distanceX = e.x - (this.data.htmlCss.background.left || 0),
        distanceY = e.y - (this.data.htmlCss.background.top || 0);
      document.onmousemove = function (event) {
        var left = event.x - distanceX;
        var top = event.y - distanceY;
        var width = _this3.data.htmlCss.background.width,
          height = _this3.data.htmlCss.background.height;
        if (width === "100vw") {
          width = window.innerWidth;
        }
        if (height === "100vh") {
          height = window.innerHeight;
        }
        if (left < 0) {
          left = 0;
        } else if (left > window.innerWidth - width) {
          left = window.innerWidth - width;
        }
        if (top < 0) {
          top = 0;
        } else if (top > window.innerHeight - height) {
          top = window.innerHeight - height;
        }
        _this3.data.htmlCss.background.top = top;
        _this3.data.htmlCss.background.left = left;
      };
      // 鼠标弹起
      document.onmouseup = function () {
        isOK = false;
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    /**
     * 右下按下事件
     * @param e
     */
    onCursor_right_bottom: function onCursor_right_bottom(e) {
      var _this4 = this;
      if (!this.isSet) {
        return;
      }
      // 移动
      document.onmousemove = function (event) {
        // 点击位置和当前元素的位置差距
        var distanceX = _this4.data.htmlCss.background.left || 0,
          distanceY = _this4.data.htmlCss.background.top || 0;
        if (_this4.data.config.background.center) {
          distanceX = window.innerWidth / 2 - _this4.data.htmlCss.background.width / 2;
          distanceY = window.innerHeight / 2 - _this4.data.htmlCss.background.height / 2;
        }
        var width = event.x - distanceX,
          height = event.y - distanceY;
        if (width < 0) {
          width = 0;
        } else if (width > window.innerWidth) {
          width = window.innerWidth;
        }
        if (height < 0) {
          height = 0;
        } else if (height > window.innerHeight) {
          height = window.innerHeight;
        }
        _this4.data.htmlCss.background.width = width;
        _this4.data.htmlCss.background.height = height;
      };
      // 鼠标弹起
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    /**
     * 右边按下事件
     * @param e
     */
    onCursor_right: function onCursor_right(e) {
      var _this5 = this;
      if (!this.isSet) {
        return;
      }
      // 移动
      document.onmousemove = function (event) {
        // 点击位置和当前元素的位置差距
        var distanceX = _this5.data.htmlCss.background.left || 0;
        if (_this5.data.config.background.center) {
          distanceX = window.innerWidth / 2 - _this5.data.htmlCss.background.width / 2;
        }
        var width = event.x - distanceX;
        if (width < 0) {
          width = 0;
        } else if (width > window.innerWidth) {
          width = window.innerWidth;
        }
        _this5.data.htmlCss.background.width = width;
      };
      // 鼠标弹起
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    /**
     * 下边按下事件
     * @param e
     */
    onCursor_bottom: function onCursor_bottom(e) {
      var _this6 = this;
      if (!this.isSet) {
        return;
      }
      // 移动
      document.onmousemove = function (event) {
        // 点击位置和当前元素的位置差距
        var distanceY = _this6.data.htmlCss.background.top || 0;
        if (_this6.data.config.background.center) {
          distanceY = window.innerHeight / 2 - _this6.data.htmlCss.background.height / 2;
        }
        var height = event.y - distanceY;
        if (height < 0) {
          height = 0;
        } else if (height > window.innerHeight) {
          height = window.innerHeight;
        }
        _this6.data.htmlCss.background.height = height;
      };
      // 鼠标弹起
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    /**
     * 恭贺大小按下事件
     * @param e
     */
    onCursor_congratulate_size: function onCursor_congratulate_size(e) {
      var _this7 = this;
      if (!this.isSet) {
        return;
      }
      document.onmousemove = function (event) {
        _this7.data.htmlCss.congratulate.width = event.x - (_this7.data.htmlCss.congratulate.left || 0) < 0 ? 0 : event.x - (_this7.data.htmlCss.congratulate.left || 0);
      };
      // 鼠标弹起
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    /**
     * 恭贺位置按下事件
     * @param e
     */
    onCursor_congratulate_XY: function onCursor_congratulate_XY(e) {
      var _this8 = this;
      if (!this.isSet) {
        return;
      }
      // 移动
      // 点击位置和当前元素的位置差距
      var distanceX = e.x - (this.data.htmlCss.congratulate.left || 0),
        distanceY = e.y - (this.data.htmlCss.congratulate.top || 0);
      var background = {
        width: this.data.htmlCss.background.width,
        height: this.data.htmlCss.background.height
      };
      if (background.width === "100vw" || background.height === "100vh") {
        background.width = window.innerWidth;
        background.height = window.innerHeight;
      }
      document.onmousemove = function (event) {
        var top = event.y - distanceY;
        var left = event.x - distanceX;
        if (left < 0) {
          left = 0;
        } else if (left > background.width - e.target.clientWidth) {
          left = background.width - e.target.clientWidth;
        }
        if (top < 0) {
          top = 0;
        } else if (top > background.height - e.target.clientHeight) {
          top = background.height - e.target.clientHeight;
        }
        _this8.data.htmlCss.congratulate.top = top;
        _this8.data.htmlCss.congratulate.left = left;
      };
      // 鼠标弹起
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    /**
     * 项目名字按下事件
     * @param e
     */
    onCursor_projectName: function onCursor_projectName(e) {
      var _this9 = this;
      if (!this.isSet) {
        return;
      }
      // 点击位置和当前元素的位置差距
      var distanceX = 0,
        distanceY = e.y - (this.data.htmlCss.projectName.top || 0);
      if (this.data.htmlCss.projectName.right === "calc(50% + 30px)") {
        distanceX = e.x - (window.innerWidth / 2 - 30 - e.target.clientWidth);
      } else {
        distanceX = e.x - (this.data.htmlCss.projectName.left || 0);
      }
      var background = {
        width: this.data.htmlCss.background.width,
        height: this.data.htmlCss.background.height
      };
      if (background.width === "100vw" || background.height === "100vh") {
        background.width = window.innerWidth;
        background.height = window.innerHeight;
      }
      document.onmousemove = function (event) {
        var top = event.y - distanceY;
        var left = event.x - distanceX;
        if (left < 0) {
          left = 0;
        } else if (left > background.width - e.target.clientWidth) {
          left = background.width - e.target.clientWidth;
        }
        if (top < 0) {
          top = 0;
        } else if (top > background.height - e.target.clientHeight) {
          top = background.height - e.target.clientHeight;
        }
        _this9.data.htmlCss.projectName.top = top;
        if (!_this9.data.config.projectName.center) _this9.data.htmlCss.projectName.left = left;
        _this9.data.htmlCss.projectName.right = "";
      };
      // 鼠标弹起
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    /**
     * 销售数量整体按下事件
     * @param e
     */
    onCursor_saledNum: function onCursor_saledNum(e) {
      var _this10 = this;
      if (!this.isSet) {
        return;
      }
      // 点击位置和当前元素的位置差距
      var distanceX = 0,
        distanceY = e.y - (this.data.htmlCss.saledNum.top || 0);
      if (this.data.htmlCss.saledNum.left === "calc(50% + 30px)") {
        distanceX = e.x - (window.innerWidth / 2 + 30);
      } else {
        distanceX = e.x - (this.data.htmlCss.saledNum.left || 0);
      }
      var background = {
        width: this.data.htmlCss.background.width,
        height: this.data.htmlCss.background.height
      };
      if (background.width === "100vw" || background.height === "100vh") {
        background.width = window.innerWidth;
        background.height = window.innerHeight;
      }
      document.onmousemove = function (event) {
        var top = event.y - distanceY;
        var left = event.x - distanceX;
        if (left < 0) {
          left = 0;
        } else if (left > background.width - e.target.clientWidth) {
          left = background.width - e.target.clientWidth;
        }
        if (top < 0) {
          top = 0;
        } else if (top > background.height - e.target.clientHeight) {
          top = background.height - e.target.clientHeight;
        }
        _this10.data.htmlCss.saledNum.top = top;
        if (!_this10.data.config.saledNum.center) _this10.data.htmlCss.saledNum.left = left;
      };
      // 鼠标弹起
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    /**
     * 销售数量文字事件
     * @param e
     */
    onCursor_saledNum_text: function onCursor_saledNum_text(e) {
      var _this11 = this;
      if (!this.isSet) {
        return;
      }
      // 点击位置和当前元素的位置差距
      var distanceX = 0,
        distanceY = e.y - (this.data.htmlCss.saledNum_text.top || 0);
      if (this.data.htmlCss.saledNum_text.left === "calc(50% + 30px)") {
        distanceX = e.x - (window.innerWidth / 2 + 30);
      } else {
        distanceX = e.x - (this.data.htmlCss.saledNum_text.left || 0);
      }
      var background = {
        width: this.data.htmlCss.background.width,
        height: this.data.htmlCss.background.height
      };
      if (background.width === "100vw" || background.height === "100vh") {
        background.width = window.innerWidth;
        background.height = window.innerHeight;
      }
      document.onmousemove = function (event) {
        var top = event.y - distanceY;
        var left = event.x - distanceX;
        if (left < 0) {
          left = 0;
        } else if (left > background.width - e.target.clientWidth) {
          left = background.width - e.target.clientWidth;
        }
        if (top < 0) {
          top = 0;
        } else if (top > background.height - e.target.clientHeight) {
          top = background.height - e.target.clientHeight;
        }
        _this11.data.htmlCss.saledNum_text.top = top;
        _this11.data.htmlCss.saledNum_text.left = left;
      };
      // 鼠标弹起
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    /**
     * 销售数量数量事件
     * @param e
     */
    onCursor_saledNum_num: function onCursor_saledNum_num(e) {
      var _this12 = this;
      if (!this.isSet) {
        return;
      }
      // 点击位置和当前元素的位置差距
      var distanceX = 0,
        distanceY = e.y - (this.data.htmlCss.saledNum_num.top || 0);
      if (this.data.htmlCss.saledNum_num.left === "calc(50% + 30px)") {
        distanceX = e.x - (window.innerWidth / 2 + 30);
      } else {
        distanceX = e.x - (this.data.htmlCss.saledNum_num.left || 0);
      }
      var background = {
        width: this.data.htmlCss.background.width,
        height: this.data.htmlCss.background.height
      };
      if (background.width === "100vw" || background.height === "100vh") {
        background.width = window.innerWidth;
        background.height = window.innerHeight;
      }
      document.onmousemove = function (event) {
        var top = event.y - distanceY;
        var left = event.x - distanceX;
        if (left < 0) {
          left = 0;
        } else if (left > background.width - e.target.clientWidth) {
          left = background.width - e.target.clientWidth;
        }
        if (top < 0) {
          top = 0;
        } else if (top > background.height - e.target.clientHeight) {
          top = background.height - e.target.clientHeight;
        }
        _this12.data.htmlCss.saledNum_num.top = top;
        _this12.data.htmlCss.saledNum_num.left = left;
      };
      // 鼠标弹起
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    /**
     * 销售金额整体按下事件
     * @param e
     */
    onCursor_saledPrice: function onCursor_saledPrice(e) {
      var _this13 = this;
      if (!this.isSet) {
        return;
      }
      // 点击位置和当前元素的位置差距
      var distanceX = e.x - (this.data.htmlCss.saledPrice.left || 0),
        distanceY = e.y - (this.data.htmlCss.saledPrice.top || 0);
      var background = {
        width: this.data.htmlCss.background.width,
        height: this.data.htmlCss.background.height
      };
      if (background.width === "100vw" || background.height === "100vh") {
        background.width = window.innerWidth;
        background.height = window.innerHeight;
      }
      document.onmousemove = function (event) {
        var top = event.y - distanceY;
        var left = event.x - distanceX;
        if (left < 0) {
          left = 0;
        } else if (left > background.width - e.target.clientWidth) {
          left = background.width - e.target.clientWidth;
        }
        if (top < 0) {
          top = 0;
        } else if (top > background.height - e.target.clientHeight) {
          top = background.height - e.target.clientHeight;
        }
        _this13.data.htmlCss.saledPrice.top = top;
        if (!_this13.data.config.saledPrice.center) _this13.data.htmlCss.saledPrice.left = left;
      };
      // 鼠标弹起
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    /**
     * 销售金额文字事件
     * @param e
     */
    onCursor_saledPrice_text: function onCursor_saledPrice_text(e) {
      var _this14 = this;
      if (!this.isSet) {
        return;
      }
      // 点击位置和当前元素的位置差距
      var distanceX = 0,
        distanceY = e.y - (this.data.htmlCss.saledPrice_text.top || 0);
      if (this.data.htmlCss.saledPrice_text.left === "calc(50% + 30px)") {
        distanceX = e.x - (window.innerWidth / 2 + 30);
      } else {
        distanceX = e.x - (this.data.htmlCss.saledPrice_text.left || 0);
      }
      var background = {
        width: this.data.htmlCss.background.width,
        height: this.data.htmlCss.background.height
      };
      if (background.width === "100vw" || background.height === "100vh") {
        background.width = window.innerWidth;
        background.height = window.innerHeight;
      }
      document.onmousemove = function (event) {
        var top = event.y - distanceY;
        var left = event.x - distanceX;
        if (left < 0) {
          left = 0;
        } else if (left > background.width - e.target.clientWidth) {
          left = background.width - e.target.clientWidth;
        }
        if (top < 0) {
          top = 0;
        } else if (top > background.height - e.target.clientHeight) {
          top = background.height - e.target.clientHeight;
        }
        _this14.data.htmlCss.saledPrice_text.top = top;
        _this14.data.htmlCss.saledPrice_text.left = left;
      };
      // 鼠标弹起
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    /**
     * 销售金额数量事件
     * @param e
     */
    onCursor_saledPrice_num: function onCursor_saledPrice_num(e) {
      var _this15 = this;
      if (!this.isSet) {
        return;
      }
      // 点击位置和当前元素的位置差距
      var distanceX = 0,
        distanceY = e.y - (this.data.htmlCss.saledPrice_num.top || 0);
      if (this.data.htmlCss.saledPrice_num.left === "calc(50% + 30px)") {
        distanceX = e.x - (window.innerWidth / 2 + 30);
      } else {
        distanceX = e.x - (this.data.htmlCss.saledPrice_num.left || 0);
      }
      var background = {
        width: this.data.htmlCss.background.width,
        height: this.data.htmlCss.background.height
      };
      if (background.width === "100vw" || background.height === "100vh") {
        background.width = window.innerWidth;
        background.height = window.innerHeight;
      }
      document.onmousemove = function (event) {
        var top = event.y - distanceY;
        var left = event.x - distanceX;
        if (left < 0) {
          left = 0;
        } else if (left > background.width - e.target.clientWidth) {
          left = background.width - e.target.clientWidth;
        }
        if (top < 0) {
          top = 0;
        } else if (top > background.height - e.target.clientHeight) {
          top = background.height - e.target.clientHeight;
        }
        _this15.data.htmlCss.saledPrice_num.top = top;
        _this15.data.htmlCss.saledPrice_num.left = left;
      };
      // 鼠标弹起
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    /**
     * 自定义展示按下事件
     * @param e
     */
    onCursor_custom: function onCursor_custom(e) {
      var _this16 = this;
      if (!this.isSet) {
        return;
      }
      // 点击位置和当前元素的位置差距
      var distanceX = e.x - (this.data.htmlCss.custom.left || 0),
        distanceY = e.y - (this.data.htmlCss.custom.top || 0);
      var background = {
        width: this.data.htmlCss.background.width,
        height: this.data.htmlCss.background.height
      };
      if (background.width === "100vw" || background.height === "100vh") {
        background.width = window.innerWidth;
        background.height = window.innerHeight;
      }
      document.onmousemove = function (event) {
        var top = event.y - distanceY;
        var left = event.x - distanceX;
        if (left < 0) {
          left = 0;
        } else if (left > background.width - e.target.clientWidth) {
          left = background.width - e.target.clientWidth;
        }
        if (top < 0) {
          top = 0;
        } else if (top > background.height - e.target.clientHeight) {
          top = background.height - e.target.clientHeight;
        }
        _this16.data.htmlCss.custom.top = top;
        if (!_this16.data.config.custom.center) _this16.data.htmlCss.custom.left = left;
      };
      // 鼠标弹起
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    /**
     * 点击保存或者重置
     * @param key
     */
    saveOption: function saveOption(key) {
      var _this17 = this;
      if (!this.isSet) {
        return;
      }
      // 保存
      if (key === 1) {
        var loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        SetConfColor({
          id: this.project.id ? this.id : 0,
          json: JSON.stringify(this.data)
        }).then(function (res) {
          _this17.id = res.data.id;
          _this17.$message({
            type: 'success',
            message: res.data.msg
          });
          if (_this17.$route.query.id !== res.data.id) {
            location.href = location.href.replace("id=" + _this17.$route.query.id, "id=" + res.data.id);
          }
          _this17.doGetConfColor();
        }).catch(function (res) {
          _this17.$message({
            type: 'error',
            message: res.data.msg
          });
        }).finally(function (res) {
          loading.close();
        });
      }
      // 重置
      else {
        this.$confirm("当前重置只是本地重置，如确认重置请重置成功后点击保存！", '确定要重置吗？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this17.$message({
            type: 'success',
            message: "重置成功！"
          });
          _this17.data = JSON.parse(JSON.stringify(_this17.defaultData));
        });
      }
    }
  }
};