var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "app", staticClass: "container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 9 } }, [
            _c(
              "div",
              {
                staticClass: "block-floor",
                style: { height: _vm.divHeight + "px" },
              },
              [
                _c("div", { staticClass: "room-title" }, [_vm._v("终端显示")]),
                _c("div", { staticClass: "option" }, [
                  _c("div", { staticClass: "titleName" }, [_vm._v("楼栋")]),
                  _c(
                    "div",
                    { staticClass: "floorList" },
                    _vm._l(_vm.buildData, function (item, index) {
                      return _c(
                        "span",
                        {
                          key: item.id,
                          staticClass: "list",
                          class: _vm.buildTab === index ? "on" : "",
                          on: {
                            click: function ($event) {
                              return _vm.handleBuild(index, item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    0
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "option" },
                  [
                    _vm.isUnits
                      ? [
                          _c("div", { staticClass: "titleName" }, [
                            _vm._v("单元"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "floorList" },
                            _vm._l(_vm.unitData, function (item, index) {
                              return _c(
                                "span",
                                {
                                  key: index,
                                  staticClass: "list",
                                  class: _vm.unitTab === index ? "on" : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUnit(index, item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.name === "" ? "无单元" : item.name
                                    )
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ]),
          _c(
            "el-col",
            { attrs: { span: 15 } },
            [
              _c("phone", {
                ref: "phone",
                attrs: {
                  "is-revision": _vm.isRevision,
                  "phone-data": _vm.phoneData,
                  "tab-data": _vm.tabData,
                  "sort-table": _vm.sortTable,
                  "name-table": _vm.nameTable,
                  "event-bool": _vm.event_bool,
                  "div-height": _vm.divHeight,
                },
                on: {
                  handleClose: _vm.handleClose,
                  handleTop: _vm.handleTop,
                  handleSave: _vm.handleSave,
                  handleLeftRight: _vm.handleLeftRight,
                  handleUpDownSort: _vm.handleUpDownSort,
                  handleBottom: _vm.handleBottom,
                  handleTab: _vm.handleTab,
                  onEdit: _vm.onEdit,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }