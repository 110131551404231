import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import staffList from './components/staffList';
import postList from './components/postList';
import salesTeam from './components/salesTeam';
import utils from '@/utils/utils';
export default {
  name: 'Index',
  components: {
    staffList: staffList,
    postList: postList,
    salesTeam: salesTeam
  },
  data: function data() {
    return {
      //
      is_input: false,
      //  是否手机端
      isPC: utils.isPC(),
      // 页面高度
      appHeight: 0,
      // div高度
      topHeight: 0,
      checkList: [],
      tabText: 'staff',
      // 搜索内容
      search: {
        is_online: 0,
        // 登录状态
        // 是否有团队
        null_team: 0,
        // 是否有岗位
        null_role: 0,
        // 文本框搜索内容
        textSearch: ''
      }
    };
  },
  computed: {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 142;
    }
  },
  watch: {
    tabText: function tabText() {
      this.$nextTick(function () {
        this.topHeight = this.$refs.top.offsetHeight;
      });
    }
  },
  mounted: function mounted() {
    var _this = this;
    var that = this;
    that.appHeight = this.$refs.app.offsetHeight;
    that.topHeight = this.$refs.top.offsetHeight;
    window.onresize = function () {
      that.appHeight = _this.$refs.app.offsetHeight;
      that.topHeight = _this.$refs.top.offsetHeight;
    };
  },
  // 注销window.onresize事件
  destroyed: function destroyed() {
    window.onresize = null;
  },
  methods: {
    // 页面搜索事件
    handleSearch: function handleSearch() {
      this.$refs.staff.handleSearch(this.search);
    },
    // 重置 搜索事件
    handleReset: function handleReset() {
      // 搜索内容
      this.search = {
        // 是否有团队
        null_team: 0,
        // 是否有岗位
        null_role: 0,
        // 文本框搜索内容
        textSearch: '',
        is_online: 0
      };
      this.$refs.staff.handleSearch(this.search);
    },
    handleSizeChange: function handleSizeChange(val) {
      console.log("\u6BCF\u9875 ".concat(val, " \u6761"));
    },
    handleCurrentChange: function handleCurrentChange(val) {
      console.log("\u5F53\u524D\u9875: ".concat(val));
    }
  }
};