var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOss
    ? _c(
        "div",
        { staticClass: "portal-main-common g-main-upload" },
        [
          _c(
            "el-upload",
            {
              class: { disabled: _vm.uploadDisabled },
              attrs: {
                action: _vm.action ? _vm.reqUrl + _vm.action : _vm.upload,
                multiple: "",
                "file-list": _vm.fileList,
                "list-type": _vm.listType,
                limit: _vm.limit,
                "http-request": _vm.handleUpload,
                "on-exceed": _vm.handleExceed,
                "on-success": _vm.handleAvatarSuccess,
                "on-remove": _vm.handleRemove,
                "before-upload": _vm.beforeAvatarUpload,
                disabled: _vm.disabled,
              },
            },
            [
              _vm.listType === "picture"
                ? [
                    _c(
                      "el-button",
                      { attrs: { size: "small", type: "primary" } },
                      [_vm._v("点击上传")]
                    ),
                  ]
                : [
                    _vm.listType == "text"
                      ? _c(
                          "el-button",
                          {
                            staticClass: "upload-btn",
                            attrs: { size: "mini", type: "text" },
                          },
                          [_vm._v("上传")]
                        )
                      : _c("i", { staticClass: "el-icon-plus" }),
                  ],
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [_vm._v(_vm._s(_vm.text))]
              ),
            ],
            2
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "portal-main-common g-main-upload" },
        [
          _c(
            "el-upload",
            {
              class: { disabled: _vm.uploadDisabled },
              attrs: {
                action: _vm.action
                  ? _vm.action.indexOf("http") >= 0
                    ? _vm.action
                    : _vm.reqUrl + _vm.action
                  : _vm.upload,
                multiple: "",
                "file-list": _vm.fileList,
                "list-type": _vm.listType,
                limit: _vm.limit,
                "http-request": _vm.handleFileUploadOss,
                "on-success": _vm.handleAvatarSuccess,
                "before-upload": _vm.beforeAvatarUpload,
                "on-exceed": _vm.handleExceed,
                "on-change": _vm.handleLimit,
                "on-remove": _vm.handleRemove,
                accept: _vm.accept,
                disabled: _vm.disabled,
              },
            },
            [
              _vm.listType === "picture"
                ? [
                    _c(
                      "el-button",
                      { attrs: { size: "small", type: "primary" } },
                      [_vm._v("点击上传")]
                    ),
                  ]
                : [
                    _vm.listType == "text"
                      ? _c(
                          "el-button",
                          {
                            staticClass: "upload-btn",
                            attrs: { size: "mini", type: "text" },
                          },
                          [_vm._v("上传")]
                        )
                      : _c("i", { staticClass: "el-icon-plus" }),
                  ],
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [_vm._v(_vm._s(_vm.text))]
              ),
            ],
            2
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }