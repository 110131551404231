import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { auditUserList, userAdopt, userReject } from '@/api/clientSet';
import { debounce } from '@/utils/debounce';
export default {
  name: 'UserAudit',
  data: function data() {
    return {
      // 页面高度 以及表格计算
      appHeight: '',
      topHeight: '',
      // 提交搜索
      search: {},
      event: {},
      activity: {},
      // 表格配置
      currentPage: 1,
      // 当前页数
      total: 0,
      // 总数
      // 表格数据
      tableData: [],
      // 表格选中 id
      userIds: [],
      // 当前页
      page: 1,
      // 页面条数
      pageSize: 10
    };
  },
  computed: {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 202;
    }
  },
  watch: {
    is_more: function is_more() {
      this.$nextTick(function () {
        this.topHeight = this.$refs.top.offsetHeight;
      });
    }
  },
  mounted: function mounted() {
    var _this = this;
    var that = this;
    that.appHeight = this.$refs.app.offsetHeight;
    that.topHeight = this.$refs.top.offsetHeight;
    window.onresize = function () {
      that.appHeight = _this.$refs.app.offsetHeight;
      that.topHeight = _this.$refs.top.offsetHeight;
    };
  },
  destroyed: function destroyed() {
    window.onresize = null;
  },
  // 初始化
  created: function created() {
    this.getTableList();
  },
  methods: {
    // 获取审核列表数据
    getTableList: function getTableList() {
      var _this2 = this;
      var data = {
        page: this.page,
        limit: this.pageSize
      };
      auditUserList(Object.assign(data, this.search)).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
            list = _res$data.list,
            user = _res$data.list.user,
            count = _res$data.count;
          if (user && user != null && user.length > 0) {
            _this2.tableData = user;
          } else {
            _this2.tableData = [];
          }
          _this2.total = count;
        }
      });
    },
    // 批量驳回审核
    handelBatchTurn: function handelBatchTurn() {
      this.turnAudit(this.userIds);
    },
    //  单条驳回审核
    handelTurnAudit: function handelTurnAudit(id) {
      var array = [];
      array.push(id);
      this.turnAudit(array);
    },
    // 驳回审核接口
    turnAudit: function turnAudit(ids) {
      var _this3 = this;
      userReject({
        ids: ids
      }).then(function (res) {
        if (res.status === 200) {
          _this3.$message({
            type: 'success',
            message: res.data.msg
          });
          _this3.getTableList();
        }
      });
    },
    // 添加人员点击行选中
    handleMemberList: debounce(function (row) {
      this.$refs.memberListTable.toggleRowSelection(row); // 设置复选框为未选状态
    }, 200, true),
    // 点击选中返回选中的id
    handleSelectionChange: function handleSelectionChange(row) {
      var ids = [];
      row.forEach(function (item) {
        ids.push(item.id);
      });
      this.userIds = ids;
    },
    // 单条通过审核
    handelPassAudit: function handelPassAudit(id) {
      var array = [];
      array.push(id);
      this.passAudit(array);
    },
    // 批量通过审核
    handelBatchPass: function handelBatchPass() {
      this.passAudit(this.userIds);
    },
    // 通过审核
    passAudit: function passAudit(ids) {
      var _this4 = this;
      userAdopt({
        ids: ids
      }).then(function (res) {
        if (res.status === 200) {
          _this4.$message({
            type: 'success',
            message: res.data.msg
          });
          _this4.getTableList();
        }
      });
    },
    // 跳转详情
    handelDetails: function handelDetails(row) {
      this.$router.push({
        name: 'UserAuditInfo',
        query: {
          id: row.id
        }
      });
    },
    // 列表搜索 查询
    getTableSearchList: function getTableSearchList() {
      this.page = 1;
      this.getTableList();
    },
    // 重置 搜索
    resetForm: function resetForm() {
      this.page = 1;
      this.search = {
        status: '',
        search: ''
      };
      this.getTableList();
    },
    // 展开更多
    bindMore: function bindMore() {
      this.is_more = !this.is_more;
      this.moreText = this.is_more ? '收起' : '展开';
      this.moreIcon = this.is_more ? 'el-icon-arrow-up' : 'el-icon-arrow-down';
    },
    // 没页条数
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.getTableList();
    },
    // 当前页
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.getTableList();
    }
  }
};