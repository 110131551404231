import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { varList, varDel, selVar, varAdd, varEdit } from '@/api/template';
export default {
  name: 'custom-table',
  data: function data() {
    return {
      tableData: [],
      reserve: [{
        label: '整数',
        value: 0
      }, {
        label: '保留1位',
        value: 1
      }, {
        label: '保留2位',
        value: 2
      }, {
        label: '保留3位',
        value: 3
      }],
      cn: [{
        label: '大写',
        value: 0,
        radioLabel: '是'
      }, {
        label: '小写',
        value: 1,
        radioLabel: '否'
      }],
      //  0加 1减 2乘 3除 4首付
      operator: [{
        label: '+',
        value: 0
      }, {
        label: '-',
        value: 1
      }, {
        label: '*',
        value: 2
      }, {
        label: '/',
        value: 3
      }, {
        label: '首付',
        value: 4
      }],
      countType: [{
        label: '变量',
        value: 0
      }, {
        label: '数值',
        value: 1
      }],
      loading: {},
      loadingSarch: {
        search: ''
      },
      timeout: null,
      constant: [] // 常用
      // searchList: [] // 搜索列表
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    querySearchAsync: function querySearchAsync(queryString, cb) {
      var constant = this.constant;
      var results = queryString ? constant.filter(this.createStateFilter(queryString)) : constant;
      cb(results);
    },
    createStateFilter: function createStateFilter(queryString) {
      return function (state) {
        return state.show_name.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    // 获取表单数据
    getTableData: function getTableData() {
      var _this = this;
      varList().then(function (result) {
        var _result$data = result.data,
          list = _result$data.list,
          info = _result$data.info;
        console.log(result.data);
        _this.tableData = list;
      });
    },
    // 显示内容
    showContent: function showContent(val, dataKey) {
      if (!dataKey) return '';
      var content = this[dataKey].find(function (item) {
        return item.value === val;
      });
      if (!content) return '';
      return content.label;
    },
    // 移除符号
    removeSymbol: function removeSymbol() {
      var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var texts = text.split(',');
      var newText = texts.map(function (t) {
        // 删除掉 $ { }
        return t.replace(/\$|{|}/ig, '');
      });
      return newText.join(',');
    },
    // 添加符号
    addSymbol: function addSymbol() {
      var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var texts = text.split(',');
      var newText = texts.map(function (t) {
        return "${".concat(t, "}");
      });
      return newText.join(',');
    },
    showLoading: function showLoading(type) {
      var row = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.getVar();
      var title = type === 'add' ? '添加变量' : '修改变量';
      this.loading = _objectSpread(_objectSpread({}, row), {}, {
        type: type,
        title: title,
        width: window.innerWidth > 470 ? '700px' : '100%',
        visible: true
      });
    },
    getVar: function getVar() {
      var _this2 = this;
      var params = _objectSpread({}, this.loadingSarch);
      selVar(params).then(function (result) {
        var data = result.data.data;
        _this2.constant = data;
      });
    },
    deleteData: function deleteData(row) {
      var _this3 = this;
      this.$confirm('该操作无法撤回，是否确认删除？', '提示').then(function () {
        varDel({
          id: row.id
        }).then(function (result) {
          var _result$data2;
          _this3.$message.success(((_result$data2 = result.data) === null || _result$data2 === void 0 ? void 0 : _result$data2.msg) || '删除成功');
          _this3.getTableData();
        });
      });
    },
    copyVar: function copyVar(item) {
      var _this4 = this;
      var str = item.show_name;
      navigator.clipboard.writeText(str).then(function () {
        _this4.$message.success('复制成功');
      });
    },
    closeDialog: function closeDialog() {
      this.loadingSarch = {};
      this.loading.visible = false;
    },
    submitData: function submitData() {
      var _this5 = this;
      var params = _objectSpread(_objectSpread({}, this.loading), {}, {
        status: 1 // 0 添加变量 1变量计算
      });
      var api = null;
      if (this.loading.type === 'add') {
        api = varAdd;
      } else {
        api = varEdit;
      }
      api(params).then(function (result) {
        var _result$data3;
        _this5.$message.success(((_result$data3 = result.data) === null || _result$data3 === void 0 ? void 0 : _result$data3.msg) || '保存成功');
        _this5.closeDialog();
        _this5.getTableData();
      });
    }
  }
};