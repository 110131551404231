import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { debounce } from '@/utils/debounce';
export default {
  name: 'Phone',
  props: {
    // 弹框tab 列表
    tabData: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    // 房号数据
    phoneData: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    // 楼栋还是住宅
    type: {
      type: String,
      default: 'room'
    },
    // 打开弹框
    isRevision: {
      type: Boolean,
      default: false
    },
    // 弹框table 数据
    sortTable: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    // 表格名称
    nameTable: {
      type: String,
      default: '楼栋名称'
    },
    // 高度
    divHeight: {
      type: Number,
      default: 0
    },
    eventBool: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      // tab 切换
      indexTab: 0,
      upIcon: 'up-talk',
      downIcon: 'down-talk',
      editShow: false,
      edit: {
        name: ''
      }
    };
  },
  computed: {
    tableHeight: function tableHeight() {
      var numbers = 0;
      if (this.type === 'room') {
        if (this.indexTab === 2 || this.indexTab === 3) {
          numbers = 279;
        } else {
          numbers = 244; // 372
        }
      } else {
        if (this.indexTab === 1 || this.indexTab === 3) {
          numbers = 279;
        } else {
          numbers = 244;
        }
      }
      return this.divHeight - numbers;
    }
  },
  methods: {
    handelEdit: function handelEdit(index) {
      //编辑名称
      this.edit.id = this.sortTable[index].id;
      this.edit.types = this.indexTab;
      this.edit.name = this.sortTable[index].name;
      this.editShow = true;
    },
    onEdit: function onEdit() {
      //提交修改
      if (this.edit.name) {
        this.$emit('onEdit', this.edit);
      } else {
        this.$message({
          type: 'warning',
          message: '名称不能为空'
        });
      }
    },
    // 从左到右正序倒序
    handleLeftRight: function handleLeftRight(type) {
      if (this.eventBool) {
        this.$message({
          type: 'warning',
          message: '有正在进行中的活动，不可编辑'
        });
        return;
      }
      this.$emit('handleLeftRight', type);
    },
    // 从上到下倒序正序
    handleUpDownSort: function handleUpDownSort(type) {
      if (this.eventBool) {
        this.$message({
          type: 'warning',
          message: '有正在进行中的活动，不可编辑'
        });
        return;
      }
      this.$emit('handleUpDownSort', type);
    },
    // 切换tab
    handleTab: function handleTab(index) {
      console.log(index);
      this.indexTab = index;
      this.$emit('handleTab', index);
    },
    // 关闭事件
    handleClose: function handleClose() {
      this.$emit('handleClose', false);
    },
    // 排序向上
    handleTop: function handleTop(index) {
      if (this.eventBool) {
        this.$message({
          type: 'warning',
          message: '有正在进行中的活动，不可编辑'
        });
        return;
      }
      if (index === 0) {
        this.$message({
          type: 'warning',
          message: '已经是第一个了'
        });
        return;
      }
      this.$emit('handleTop', index);
    },
    // 排序向下
    handleBottom: function handleBottom(index) {
      if (this.eventBool) {
        this.$message({
          type: 'warning',
          message: '有正在进行中的活动，不可编辑'
        });
        return;
      }
      if (index === this.sortTable.length - 1) {
        this.$message({
          type: 'warning',
          message: '已经是最后一个了'
        });
        return;
      }
      this.$emit('handleBottom', index);
    },
    // 保存提交
    handleSave: debounce(function () {
      this.$emit('handleSave');
      this.indexTab = 0;
    }, 200, true)
  }
};