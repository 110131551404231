var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.template_id == 0 || _vm.template_id == 1
    ? _c(
        "div",
        { staticStyle: { padding: "20px" } },
        [
          _vm._m(0),
          _c(
            "div",
            {
              staticStyle: {
                margin: "10px",
                "text-align": "center",
                "font-weight": "800",
                "font-size": "26px",
              },
            },
            [_vm._v(" " + _vm._s(_vm.project_name) + " ")]
          ),
          _c(
            "div",
            {
              staticStyle: {
                margin: "10px",
                "text-align": "center",
                "font-weight": "600",
                "font-size": "22px",
                "margin-bottom": "-20px",
              },
            },
            [_vm._v(" 摇号结果 ")]
          ),
          _vm._l(_vm.dataList, function (item, index) {
            return _c(
              "div",
              { key: index, staticStyle: { "margin-top": "40px" } },
              [
                _c(
                  "table",
                  {
                    staticStyle: {
                      width: "100%",
                      "border-collapse": "collapse",
                    },
                    attrs: { border: "1" },
                  },
                  [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            height: "40px",
                            "line-height": "40px",
                          },
                        },
                        [
                          _vm._v(
                            " 摇号轮次：" +
                              _vm._s(item.level ? item.level + "-" : "") +
                              "第" +
                              _vm._s(item.num) +
                              "轮 "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            height: "40px",
                            "line-height": "40px",
                            "text-align": "right",
                          },
                        },
                        [
                          _vm._v(
                            " 摇号时间：" +
                              _vm._s(_vm.doGetTime(+item.created_at * 1000)) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          " 打印人：" + _vm._s(item.admin_name || "无") + " "
                        ),
                      ]),
                      _c("td", { staticStyle: { "text-align": "right" } }, [
                        _vm._v(
                          " 打印时间：" +
                            _vm._s(_vm.doGetTime(+item.print_time * 1000)) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm.template_id == 0
                  ? [
                      _c(
                        "table",
                        {
                          staticStyle: {
                            width: "100%",
                            "border-collapse": "collapse",
                            "margin-top": "5px",
                          },
                          attrs: { border: "2" },
                        },
                        [
                          _vm._m(1, true),
                          _vm._l(
                            item.log_list || [],
                            function (log, log_index) {
                              return _c("tr", { key: log_index }, [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      height: "30px",
                                      "line-height": "30px",
                                      "text-align": "center",
                                      width: "100px",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(log_index + 1) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      height: "30px",
                                      "line-height": "30px",
                                      "text-align": "center",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " 第" +
                                        _vm._s(item.num) +
                                        "轮-" +
                                        _vm._s(log.code) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      height: "30px",
                                      "line-height": "30px",
                                      "text-align": "center",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(log.name) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      height: "30px",
                                      "line-height": "30px",
                                      "text-align": "center",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(log.user_code) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      height: "30px",
                                      "line-height": "30px",
                                      "text-align": "center",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(log.card) + " ")]
                                ),
                              ])
                            }
                          ),
                        ],
                        2
                      ),
                    ]
                  : _vm._e(),
                _vm.template_id == 1
                  ? [
                      _c(
                        "table",
                        {
                          staticStyle: {
                            width: "100%",
                            "border-collapse": "collapse",
                            "margin-top": "5px",
                          },
                          attrs: { border: "2" },
                        },
                        [
                          _vm._m(2, true),
                          _vm._l(
                            item.log_list || [],
                            function (log, log_index) {
                              return _c("tr", { key: log_index }, [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      height: "30px",
                                      "line-height": "30px",
                                      "text-align": "center",
                                      width: "100px",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(log_index + 1) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      height: "30px",
                                      "line-height": "30px",
                                      "text-align": "center",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " 第" +
                                        _vm._s(item.num) +
                                        "轮-" +
                                        _vm._s(log.code) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      height: "30px",
                                      "line-height": "30px",
                                      "text-align": "center",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(log.name) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      height: "30px",
                                      "line-height": "30px",
                                      "text-align": "center",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(log.user_code) + " ")]
                                ),
                              ])
                            }
                          ),
                          _vm._m(3, true),
                        ],
                        2
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          padding: "10px",
          "padding-top": "0",
          "border-bottom": "1px solid black",
          display: "flex",
          width: "100%",
          "justify-content": "space-between",
          "padding-bottom": "0",
        },
      },
      [
        _c("div", [
          _c("img", {
            staticStyle: { width: "70px" },
            attrs: { src: require("@/assets/image/yun-xk.png") },
          }),
        ]),
        _c("div", [_vm._v("摇号记录")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "th",
        {
          staticStyle: {
            height: "30px",
            "line-height": "30px",
            "text-align": "center",
            width: "100px",
          },
        },
        [_vm._v(" 序号 ")]
      ),
      _c(
        "th",
        {
          staticStyle: {
            height: "30px",
            "line-height": "30px",
            "text-align": "center",
          },
        },
        [_vm._v(" 轮次-序号 ")]
      ),
      _c(
        "th",
        {
          staticStyle: {
            height: "30px",
            "line-height": "30px",
            "text-align": "center",
          },
        },
        [_vm._v(" 姓名 ")]
      ),
      _c(
        "th",
        {
          staticStyle: {
            height: "30px",
            "line-height": "30px",
            "text-align": "center",
          },
        },
        [_vm._v(" 诚意单号 ")]
      ),
      _c(
        "th",
        {
          staticStyle: {
            height: "30px",
            "line-height": "30px",
            "text-align": "center",
          },
        },
        [_vm._v(" 身份证号 ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "th",
        {
          staticStyle: {
            height: "30px",
            "line-height": "30px",
            "text-align": "center",
            width: "100px",
          },
        },
        [_vm._v(" 序号 ")]
      ),
      _c(
        "th",
        {
          staticStyle: {
            height: "30px",
            "line-height": "30px",
            "text-align": "center",
          },
        },
        [_vm._v(" 选房顺序号 ")]
      ),
      _c(
        "th",
        {
          staticStyle: {
            height: "30px",
            "line-height": "30px",
            "text-align": "center",
          },
        },
        [_vm._v(" 姓名 ")]
      ),
      _c(
        "th",
        {
          staticStyle: {
            height: "30px",
            "line-height": "30px",
            "text-align": "center",
          },
        },
        [_vm._v(" 收据号 ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticStyle: { height: "110px" } }, [
      _c(
        "td",
        {
          staticStyle: {
            height: "30px",
            "line-height": "30px",
            "text-align": "center",
            width: "300px",
            "font-weight": "bold",
          },
        },
        [_vm._v(" 公证人员签字(盖章): ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }