import request from '@/utils/request';
export default {
  // 认筹金列表
  identifyPay: function identifyPay(data) {
    return request({
      url: '/identifyPay/index',
      method: 'POST',
      data: data
    });
  },
  // 认筹金详情
  detail: function detail(params) {
    return request({
      url: '/identifyPay/detail',
      method: 'GET',
      params: params
    });
  },
  refund: function refund(params) {
    return request({
      url: '/identifyPay/refund',
      method: 'GET',
      params: params
    });
  }
};