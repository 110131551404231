import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { adminParameter } from '@/api/public';
import Api from '@/api/register';
import utils from '@/utils/utils';
import moment from 'moment';
import { mapGetters } from "vuex";
import info from "./components/info";
import formData from "./components/formData";
import logView from './components/log-view';
import priceView from './components/price';
import signView from './components/sign';
export default {
  name: 'UserDetails',
  components: {
    info: info,
    formData: formData,
    logView: logView,
    priceView: priceView,
    signView: signView
  },
  data: function data() {
    return {
      // 页面全部数据
      isPC: utils.isPC(),
      data: {},
      activeName: "0",
      height: 0,
      logList: [],
      setting: {
        list: {}
      }
    };
  },
  computed: _objectSpread({}, mapGetters(['roles'])),
  watch: {
    // 如果路由有变化，会再次执行clear方法
    // "$route": "clear",
  },
  created: function created() {
    this.getParams();
    moment.locale('zh-cn');
    this.doGetInfo();
    this.height = document.body.clientHeight - 260;
  },
  methods: {
    // 获取配置
    getParams: function getParams() {
      var _this = this;
      adminParameter().then(function (res) {
        _this.setting = res.data;
      });
    },
    doGetInfo: function doGetInfo(data) {
      var _this2 = this;
      var loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (!data) data = {};
      data.id = this.$route.query.id;
      Api.getInfo(data).then(function (res) {
        var _this2$logList;
        _this2.data = res.data.data;
        var list = res.data.data.log || [];
        var log = [];
        var dataInfo = {};
        list.forEach(function (item, index) {
          var times = moment(parseInt(item.created_time * 1000)).format('YYYY-MM-DD'),
            time = moment(parseInt(item.created_time * 1000)).format('YYYY-MM-DD HH:mm:ss');
          if (!dataInfo[times]) {
            dataInfo[times] = {
              times: times,
              day_name: moment(time).fromNow(),
              log_list: []
            };
          }
          dataInfo[times].log_list.push(Object.assign(item, {
            key: index
          }));
        });
        Object.values(dataInfo);
        Object.keys(dataInfo).forEach(function (item) {
          log.push(dataInfo[item]);
        });
        if (data.page > 1) (_this2$logList = _this2.logList).push.apply(_this2$logList, log);else _this2.logList = log;
        if (list.length === 100) {
          _this2.$nextTick(function () {
            _this2.$refs["logList"].isPageLoad = false;
          });
        }
      }).finally(function (res) {
        loading.close();
      });
    },
    tabClick: function tabClick() {
      if (this.activeName === "1") {
        this.$refs["reg"].doarrData("reg");
      } else if (this.activeName === "2") {
        this.$refs["ide"].doarrData("ide");
      }
    }
  }
};