import request from '@/utils/request';
var exportAuditStatus0 = function exportAuditStatus0(params) {
  return request({
    url: 'identifyAudit/AuditInfo0',
    method: 'get',
    params: params
  });
};

// 初审详情
var exportAuditStatus1 = function exportAuditStatus1(params) {
  return request({
    url: 'identifyAudit/AuditInfo1',
    method: 'get',
    params: params
  });
};

// 初审详情
var exportAuditStatus2 = function exportAuditStatus2(params) {
  return request({
    url: 'identifyAudit/AuditInfo2',
    method: 'get',
    params: params
  });
};

// 修改表单
var AuditStatusEdit0 = function AuditStatusEdit0(data) {
  return request({
    url: '/identifyAudit/AuditIdeEdit0',
    method: 'post',
    data: data
  });
};
var AuditStatusEdit1 = function AuditStatusEdit1(data) {
  return request({
    url: '/identifyAudit/AuditIdeEdit1',
    method: 'post',
    data: data
  });
};
var AuditStatusEdit2 = function AuditStatusEdit2(data) {
  return request({
    url: '/identifyAudit/AuditIdeEdit2',
    method: 'post',
    data: data
  });
};

// 报名详情接口
var auditDetail = function auditDetail(params) {
  return request({
    url: 'identifyAudit/auditDetail',
    method: 'get',
    params: params
  });
};
export { exportAuditStatus0, exportAuditStatus1, exportAuditStatus2, AuditStatusEdit0, AuditStatusEdit1, AuditStatusEdit2, auditDetail };