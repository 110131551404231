var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "detail-box mT16" }, [
      _c("div", { staticClass: "export-form" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "permission",
                rawName: "v-permission",
                value: ["admin", "user/eventexport"],
                expression: "['admin','user/eventexport']",
              },
            ],
            staticClass: "btn-wrapper",
            on: { click: _vm.handleExport },
          },
          [_vm._v("导出客户名单 ")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("mergeTable", {
            attrs: {
              "table-data": _vm.tableData,
              "dialog-data": _vm.dialogData,
              ids: _vm.ids,
            },
            on: {
              handleMerge: _vm.handleMerge,
              handleInfoCheck: _vm.handleInfoCheck,
              handleItemCheck: _vm.handleItemCheck,
              handleSaveId: _vm.handleSaveId,
              handleSearch: _vm.getDialogId,
              handleReset: _vm.handleReset,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-fixed" }, [
      _c("div", { staticClass: "title-top-box" }, [
        _c("div", { staticClass: "title" }, [_vm._v("关联场次")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }