import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var getElWid = function getElWid(dom) {
  return dom.getBoundingClientRect().width || window.getComputedStyle(dom, null).width.replace('px', '');
};
export default {
  name: 'NoticeNav',
  componentsName: 'NoticeNav',
  data: function data() {
    return {
      show: true,
      scrollInterval: null,
      itemArr: null
    };
  },
  props: {
    title: '',
    text: '',
    textStyle: Object,
    titleStyle: Object,
    height: {
      type: Number,
      default: 69
    },
    bgColor: {
      type: String,
      default: 'transparent'
    },
    color: {
      type: String,
      default: '#fff'
    },
    leftIcon: {
      type: String,
      default: 'none'
    },
    RightIcon: {
      type: String,
      default: 'none'
    },
    delay: {
      //  动画延迟时间(ms)
      type: Number,
      default: 1
    },
    outview: Boolean,
    scrollable: {
      // 是否禁止动画
      type: Boolean,
      default: true
    },
    textGap: {
      //  横向滚动文本分段间隔
      type: String,
      default: '14px'
    },
    speed: {
      // 水平滚动速度，单位px/s
      type: Number,
      default: 1000
    },
    duration: Number
  },
  computed: {
    style: function style() {
      return {
        height: "".concat(this.height, "px"),
        backgroundColor: this.bgColor,
        lineHeight: "".concat(this.height, "px")
      };
    },
    titleStyles: function titleStyles() {
      return _objectSpread({
        color: this.color
      }, this.titleStyle);
    },
    textList: function textList() {
      if (typeof this.text === 'string') {
        return [this.text];
      } else if (Array.isArray(this.text)) {
        return this.text;
      } else {
        console.error('property text‘s type should be string or array');
        return [''];
      }
    },
    cpuDuration: {
      get: function get() {
        if (!this.duration) {
          return this.wrapperWid / this.speed;
        } else {
          return this.duration;
        }
      },
      set: function set(val) {
        return this.cpuDuration;
      }
    },
    cpuTransition: function cpuTransition() {
      return "transform ".concat(this.cpuDuration, "s linear");
    },
    textWrapStyle: function textWrapStyle() {
      return _objectSpread({
        marginLeft: this.outview ? '100%' : '5px',
        color: this.color,
        paddingRight: this.textGap
      }, this.textStyle);
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.destroy();
  },
  watch: {
    text: {
      handler: function handler(val, old) {
        var _this = this;
        this.init().then(function () {
          if (_this.scrollable) {
            setTimeout(function () {
              _this.loop();
            }, _this.delay);
          }
        });
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      var _this2 = this;
      return new Promise(function (resolve, reject) {
        _this2.$nextTick(function () {
          _this2.wrapperWid = _this2.getWrapWid();
          resolve();
        });
      });
    },
    destroy: function destroy() {
      clearInterval(this.scrollInterval);
    },
    getWrapWid: function getWrapWid() {
      var _this$$refs = this.$refs,
        textWrapper = _this$$refs.textWrapper,
        textContainer = _this$$refs.textContainer;
      this.initTransiton(textWrapper);
      this.initTransform(textWrapper);
      var wrapperWid = 0;
      this.itemArr = textWrapper.getElementsByClassName('text');
      this.containerWid = getElWid(textContainer);
      for (var i = 0; i < this.itemArr.length; i++) {
        var itemW = Math.ceil(getElWid(this.itemArr[i]));
        wrapperWid += itemW;
      }
      return wrapperWid;
    },
    scroll: function scroll(wid, lag) {
      var _this3 = this;
      var Wrap = this.$refs.textWrapper;
      this.setTransition(Wrap);
      this.setTranslate(Wrap, wid, 0, 0, this.cpuDuration * 1000 - 100).then(function () {
        _this3.initTransiton(Wrap);
        _this3.initTransform(Wrap);
        Wrap.style.marginLeft = '100%';
      });
    },
    loop: function loop() {
      var _this4 = this;
      this.destroy();
      var wW = this.wrapperWid;
      var cW = this.containerWid;
      var wid = !this.outview ? -wW : -wW - cW;
      this.scroll(wid);
      this.scrollInterval = setInterval(function () {
        _this4.scroll(-wW - cW);
      }, this.cpuDuration * 1000);
    },
    initTransiton: function initTransiton(dom) {
      dom.style.transition = 'none';
    },
    initTransform: function initTransform(dom) {
      dom.style.transform = "none";
    },
    setTransition: function setTransition(dom) {
      dom.style.transition = this.cpuTransition;
    },
    setTranslate: function setTranslate(dom, x, y, z, lag) {
      return new Promise(function (resolve, reject) {
        dom && (dom.style.transform = "translate3d(".concat(x, "px, ").concat(y, "px, ").concat(z, "px)"));
        setTimeout(function () {
          resolve(true);
        }, lag);
      });
    },
    handleClick: function handleClick() {
      this.$emit('click');
    },
    handleIconClick: function handleIconClick(method) {
      this.$emit(method);
    }
  }
};