//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CenterTitle',
  props: {
    title: {
      type: String,
      default: '住宅各户型销售数量占比'
    }
  }
};