import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex';
export default {
  data: function data() {
    return {
      scaleNum: 1,
      transNum: 0,
      isCanMove: false
    };
  },
  computed: _objectSpread({}, mapState({
    toolReviewRow: function toolReviewRow(state) {
      return state.toolReview.toolReviewRow;
    },
    isOpenDrawer: function isOpenDrawer(state) {
      return state.toolReview.isOpenDrawer;
    },
    drawerType: function drawerType(state) {
      return state.toolReview.drawerType;
    },
    attach_img_arr: function attach_img_arr(state) {
      return state.toolReview.attach_img_arr;
    },
    img_arr: function img_arr(state) {
      return state.toolReview.img_arr;
    },
    detailBtnType: function detailBtnType(state) {
      return state.toolReview.detailBtnType;
    },
    selectImg: function selectImg(state) {
      return state.toolReview.selectImg;
    },
    isShowImgPreviewBox: function isShowImgPreviewBox(state) {
      return state.toolReview.isShowImgPreviewBox;
    }
  })),
  destroyed: function destroyed() {
    this.CHANGE_IMG_PREVIEW(false);
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapMutations('toolReview', ['CHANGE_ROW', 'OPEN_CLOSE_DRAWER', 'CHANGE_DRAWER_TYPE', 'CHANGE_ALL_IMG', 'CHANGE_BTN_TYPE', 'CHANGE_CUREENT_IMG', 'CHANGE_IMG_PREVIEW'])), mapActions('toolReview', ['change_row', 'open_close_drawer'])), {}, {
    // 图片预览 btn
    imgPreviewBtn: function imgPreviewBtn(type) {
      this.scaleNum = 1;
      this.transNum = 0;
      if (type == 0) {
        // 后退
        var _this$findImgIndex = this.findImgIndex(this.selectImg),
          index = _this$findImgIndex.index,
          len = _this$findImgIndex.len,
          img = _this$findImgIndex.img;
        if (index != -1) {
          var nextIndex = index - 1;
          if (nextIndex != -1) {
            this.CHANGE_CUREENT_IMG(img[nextIndex]);
          } else {
            this.CHANGE_CUREENT_IMG(img[len - 1]);
          }
        }
      }
      if (type == 1) {
        // 前进
        var _this$findImgIndex2 = this.findImgIndex(this.selectImg),
          _index = _this$findImgIndex2.index,
          _len = _this$findImgIndex2.len,
          _img = _this$findImgIndex2.img;
        if (_index != -1) {
          var _nextIndex = _index + 1;
          if (_nextIndex <= _len - 1) {
            this.CHANGE_CUREENT_IMG(_img[_nextIndex]);
          } else {
            this.CHANGE_CUREENT_IMG(_img[0]);
          }
        }
      }
    },
    // 寻找当前图片下标
    findImgIndex: function findImgIndex(img) {
      if (this.detailBtnType == 0) {
        // 客户登记表
        var index = '';
        this.img_arr && this.img_arr.forEach(function (item, i) {
          if (item.url == img.url) {
            index = i;
          }
        });
        return {
          index: index,
          len: this.img_arr.length,
          img: this.img_arr
        };
      }
      if (this.detailBtnType == 1) {
        // 审核附表
        var _index2 = '';
        this.attach_img_arr && this.attach_img_arr.forEach(function (item, i) {
          if (item.url == img.url) {
            _index2 = i;
          }
        });
        return {
          index: _index2,
          len: this.attach_img_arr.length,
          img: this.attach_img_arr
        };
      }
    },
    // 图片点击预览
    imgPreviewCurrent: function imgPreviewCurrent(imgs) {
      this.CHANGE_IMG_PREVIEW(true);
      var _this$findImgIndex3 = this.findImgIndex({
          url: imgs
        }),
        index = _this$findImgIndex3.index,
        len = _this$findImgIndex3.len,
        img = _this$findImgIndex3.img;
      this.CHANGE_CUREENT_IMG(img[index]);
    },
    // 图片放大 缩小 旋转 移动
    imgScaleUp: function imgScaleUp() {
      console.log(11);
      this.scaleNum += 0.1;
    },
    imgScaleDown: function imgScaleDown() {
      this.scaleNum -= 0.1;
    },
    imgScaleTrans: function imgScaleTrans() {
      this.transNum += 90;
    }
  })
};