import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import vanSwitch from '/src/components/switch';
import operateTable from './component/operateTable';
import loginTable from './component/loginTable';
import Api from '/src/api/logapi';
import utils from '@/utils/utils';
import { mapGetters } from 'vuex';
export default {
  name: 'LogDetail',
  components: {
    vanSwitch: vanSwitch,
    operateTable: operateTable,
    loginTable: loginTable
  },
  data: function data() {
    return {
      start_time: '',
      end_time: '',
      checkMore: false,
      //  是否手机端
      isPC: utils.isPC(),
      telLayout: 'total, prev, pager, next, sizes',
      // moment工具
      moment: moment,
      // 切换
      switchList: [],
      // 页面高度 以及表格计算
      appHeight: '',
      topHeight: '',
      // 展开更多
      moreText: '展开',
      is_more: false,
      moreIcon: 'el-icon-arrow-down',
      // 表格配置
      total: 0,
      // 总数
      // 当前页
      page: 1,
      // 分页 一页几条
      pageSize: utils.isPC() ? 10 : 1000,
      // 操作日志数据
      perateTableInfo: [],
      // 登录日志数据
      loginTableInfo: [],
      // 表格loading
      tableLoading: false,
      // 操作类型
      typeList: [],
      // 终端下拉数据
      cateArray: [{
        label: '全部',
        value: ''
      }, {
        label: 'pc端',
        value: 'PC'
      }, {
        label: '助手端',
        value: '助手'
      }, {
        label: '客户端',
        value: '选房'
      }
      // {label: '用户', value: 4},
      ],
      // 终端下拉数据
      cateArray2: [{
        label: '全部',
        value: ''
      }, {
        label: 'pc端',
        value: 'PC'
      }, {
        label: '助手端',
        value: '助手'
      }],
      // 表格搜索 数据
      form: {},
      value: '',
      // 日期选择器
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      // 切换 日志
      type: 0
    };
  },
  computed: _objectSpread({
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 142;
    }
  }, mapGetters(['roles'])),
  watch: {
    is_more: function is_more() {
      this.$nextTick(function () {
        this.topHeight = this.$refs.top.offsetHeight;
      });
    }
  },
  // 初始化
  created: function created() {
    // 登录日志
    if (this.roles.includes('log/loginlog') || this.roles.includes('admin')) {
      this.getLoginTableInfo();
      this.switchList.push({
        title: '登录日志',
        id: 0,
        num: ''
      });
    }

    // 操作日志
    // if (this.roles.includes('log/adminlog') || this.roles.includes('admin')) {
    //     this.switchList.push({ title: '员工操作日志', id: 1, num: '' })
    //     if (this.switchList.length === 1) {
    //         this.type = 1
    //         this.getOperateTableInfo()
    //     }
    // }
  },
  mounted: function mounted() {
    var _this = this;
    var that = this;
    that.appHeight = this.$refs.app.offsetHeight;
    that.topHeight = this.$refs.top.offsetHeight;
    window.onresize = function () {
      that.appHeight = _this.$refs.app.offsetHeight;
      that.topHeight = _this.$refs.top.offsetHeight;
    };
  },
  destroyed: function destroyed() {
    window.onresize = null;
  },
  methods: {
    //  手机端重置
    resetClick2: function resetClick2() {
      this.page = 1;
      this.form = {};
      this.end_time = '';
      this.start_time = '';
      if (this.type === 1) {
        this.getOperateTableInfo(1);
      } else {
        this.getLoginTableInfo(1);
      }
    },
    //  手机端搜索
    searchClick2: function searchClick2() {
      this.page = 1;
      if (this.type === 1) {
        this.getOperateTableInfo(1);
      } else {
        this.getLoginTableInfo(1);
      }
    },
    //  查看更多
    handleCheckMore: function handleCheckMore() {
      this.checkMore = true;
    },
    //  登陆日志 - 导出
    handleLoginCsv: function handleLoginCsv() {
      if (this.loginTableInfo.length > 0) {
        //  loading
        var loading = this.$loading({
          lock: true,
          text: '导出中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        Api.loginCsv(this.getDataForm(0)).then(function (res) {
          if (res.status === 200) {
            utils.download_files_base64(res.data.data, '登录日志' + moment().format('YYYY-MM-DD HH_mm_ss'), res.data.suffix);
            loading.close();
            // 下载
            // utils.download_files(this.host + '/' + res.data.msg, '登录日志' + moment().format('YYYY-MM-DD HH_mm_ss') + '.csv').then(() => {
            //     loading.close()
            // })
          }
        });
      } else {
        this.$message({
          type: 'warning',
          message: '没有数据不可导出哟'
        });
      }
    },
    //  操作日志数据
    handleAdminCsv: function handleAdminCsv() {
      if (this.perateTableInfo.length > 0) {
        //  loading
        var loading = this.$loading({
          lock: true,
          text: '导出中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        Api.adminCsv(this.getDataForm(0)).then(function (res) {
          if (res.status === 200) {
            utils.download_files_base64(res.data.data, '操作日志' + moment().format('YYYY-MM-DD HH_mm_ss'), res.data.suffix);
            loading.close();
            // utils.download_files(this.host + '/' + res.data.msg, '操作日志' + moment().format('YYYY-MM-DD HH_mm_ss') + '.csv').then(() => {
            //     loading.close()
            // })
          }
        });
      } else {
        this.$message({
          type: 'warning',
          message: '没有数据不可导出哟'
        });
      }
    },
    //  提交数据 返回
    getDataForm: function getDataForm() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      if (this.form.timeVal && this.form.timeVal.length > 0) {
        this.form.end_time = this.form.timeVal.length > 0 ? (this.form.timeVal[1] / 1000).toFixed() : 0;
        this.form.start_time = this.form.timeVal.length > 0 ? (this.form.timeVal[0] / 1000).toFixed() : 0;
      }
      var data = {};
      if (type === 1) {
        // 分页
        data = {
          page: this.page,
          limit: this.pageSize
        };
      } else {
        delete this.form.page;
        delete this.form.limit;
      }
      return Object.assign(this.form, data);
    },
    // 获取登录日志
    getLoginTableInfo: function getLoginTableInfo(type) {
      var _this2 = this;
      this.tableLoading = true;
      var data = {};
      if (type === 1) {
        data = this.getDataForm();
        data.end_time = this.end_time > 0 ? this.end_time / 1000 : 0;
        data.start_time = this.start_time > 0 ? this.start_time / 1000 : 0;
      } else {
        data = this.getDataForm();
      }
      Api.loginLog(data).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
            list = _res$data.list,
            count = _res$data.count;
          if (list && list != null && list.length > 0) {
            _this2.loginTableInfo = list;
            _this2.total = count;
          } else {
            _this2.loginTableInfo = [];
          }
        }
      }).finally(function () {
        _this2.tableLoading = false;
      });
    },
    // 获取操作日志
    getOperateTableInfo: function getOperateTableInfo(type) {
      var _this3 = this;
      this.tableLoading = true;
      var data = {};
      if (type === 1) {
        data = this.getDataForm();
        data.end_time = this.end_time > 0 ? this.end_time / 1000 : 0;
        data.start_time = this.start_time > 0 ? this.start_time / 1000 : 0;
      } else {
        data = this.getDataForm();
      }
      Api.adminLog(this.getDataForm()).then(function (res) {
        if (res.status === 200) {
          var _res$data2 = res.data,
            list = _res$data2.list,
            typeList = _res$data2.typeList,
            count = _res$data2.count;
          if (list && list != null && list.length > 0) {
            _this3.perateTableInfo = list;
            _this3.typeList = typeList;
            _this3.total = count;
          } else {
            _this3.perateTableInfo = [];
          }
        }
      }).finally(function () {
        _this3.tableLoading = false;
      });
    },
    // 活动切换
    bindSwitch: function bindSwitch(val) {
      this.page = 1;
      this.form = {};
      this.type = val;
      if (val === 1) {
        this.getOperateTableInfo();
      } else {
        this.getLoginTableInfo();
      }
    },
    // 查询
    searchClick: function searchClick() {
      this.page = 1;
      if (this.type === 1) {
        this.getOperateTableInfo();
      } else {
        this.getLoginTableInfo();
      }
    },
    // 重置
    resetClick: function resetClick() {
      this.page = 1;
      this.form = {};
      if (this.type === 1) {
        this.getOperateTableInfo();
      } else {
        this.getLoginTableInfo();
      }
    },
    // 展开更多
    bindMore: function bindMore() {
      this.is_more = !this.is_more;
      this.moreText = this.is_more ? '收起' : '展开';
      this.moreIcon = this.is_more ? 'el-icon-arrow-up' : 'el-icon-arrow-down';
    },
    // 条数
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      if (this.type === 1) {
        this.getOperateTableInfo();
      } else {
        this.getLoginTableInfo();
      }
    },
    // 页数
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      if (this.type === 1) {
        this.getOperateTableInfo();
      } else {
        this.getLoginTableInfo();
      }
    }
  }
};