var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading",
            },
          ],
          attrs: {
            "highlight-current-row": "",
            size: "medium",
            height: _vm.tableHeight,
            "header-cell-style": { background: "#fafafa" },
            data: _vm.tableData,
            "element-loading-background": "rgba(0, 0, 0, 0.5)",
            "element-loading-text": "数据正在加载中",
            "element-loading-spinner": "el-icon-loading",
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", align: "center", label: "序号" },
          }),
          _c("el-table-column", {
            attrs: { prop: "content", align: "center", label: "日志内容" },
          }),
          _vm.isPC
            ? _c("el-table-column", {
                attrs: { prop: "platform", align: "center", label: "终端" },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { prop: "name", align: "center", label: "用户名" },
          }),
          _c("el-table-column", {
            attrs: { prop: "tel", align: "center", label: "用户手机号" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "role_list",
              align: "center",
              width: "200",
              label: "岗位",
            },
          }),
          _vm.isPC
            ? _c("el-table-column", {
                attrs: { prop: "data_id", align: "center", label: "用户id" },
              })
            : _vm._e(),
          _vm.isPC
            ? _c("el-table-column", {
                attrs: { prop: "ip", align: "center", label: "用户ip" },
              })
            : _vm._e(),
          _vm.isPC
            ? _c("el-table-column", {
                attrs: { align: "center", label: "时间" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  scope.row.create_time * 1000,
                                  "yyyy-MM-DD HH:mm:ss"
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1149502842
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }