var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "userDatails" } },
    [
      _vm._m(0),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.tabClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "基本信息", name: "0" } }, [
            _c(
              "div",
              {
                staticClass: "tab-pane-container",
                style: { height: _vm.height + "px" },
              },
              [_c("info", { attrs: { data: _vm.data } })],
              1
            ),
          ]),
          _c("el-tab-pane", { attrs: { label: "报名审核", name: "1" } }, [
            _c(
              "div",
              {
                staticClass: "tab-pane-container",
                style: { height: _vm.height + "px" },
              },
              [
                _c("form-data", {
                  key: "reg",
                  ref: "reg",
                  attrs: { data: _vm.data, keyStr: "reg" },
                }),
              ],
              1
            ),
          ]),
          _vm.setting.list.is_identify === 1
            ? _c("el-tab-pane", { attrs: { label: "认筹审核", name: "2" } }, [
                _c(
                  "div",
                  {
                    staticClass: "tab-pane-container",
                    style: { height: _vm.height + "px" },
                  },
                  [
                    _c("form-data", {
                      key: "ide",
                      ref: "ide",
                      attrs: { data: _vm.data, keyStr: "ide" },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.setting.list.is_identify_price === 1
            ? _c("el-tab-pane", { attrs: { label: "认筹金", name: "4" } }, [
                _c(
                  "div",
                  {
                    staticClass: "tab-pane-container",
                    style: { height: _vm.height + "px" },
                  },
                  [_c("price-view", { attrs: { pay: _vm.data.pay } })],
                  1
                ),
              ])
            : _vm._e(),
          _vm.setting.list.is_identify_sign === 1
            ? _c("el-tab-pane", { attrs: { label: "诚意签约", name: "5" } }, [
                _c(
                  "div",
                  {
                    staticClass: "tab-pane-container",
                    style: { height: _vm.height + "px" },
                  },
                  [_c("sign-view", { attrs: { sign: _vm.data.sign } })],
                  1
                ),
              ])
            : _vm._e(),
          _c("el-tab-pane", { attrs: { label: "日志", name: "3" } }, [
            _c(
              "div",
              {
                staticClass: "tab-pane-container",
                style: { height: _vm.height + "px" },
              },
              [
                _c("logView", {
                  ref: "logList",
                  attrs: { log: _vm.logList, name: _vm.data.name },
                  on: { handleSearchClick: _vm.doGetInfo },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-fixed" }, [
      _c("div", { staticClass: "title-top-box align-items" }, [
        _c("div", { staticClass: "title flex align-items" }, [
          _c("div", [_vm._v("登记客户详情")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }