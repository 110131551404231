var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "bgc" }, [
      _vm.config.rand.logo
        ? _c("img", {
            attrs: {
              src: _vm.replaceImgshow(_vm.config.rand.logo),
              alt: "背景图",
            },
          })
        : _c("img", {
            attrs: { src: require("@/assets/page/bg.png"), alt: "默认背景图" },
          }),
    ]),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "project-name" }, [
        _vm._v(_vm._s(_vm.config.rand.rand_name)),
      ]),
      _c("div", { staticClass: "hint-card" }, [
        _c("div", [
          _c("span", { staticClass: "hint-title" }, [
            _vm._v(
              _vm._s(
                _vm.config.round.is_repeat == 1
                  ? "重复轮"
                  : "第" + _vm.config.round.num + "轮"
              )
            ),
          ]),
          _c("span", { staticStyle: { "margin-left": "14px" } }, [
            _vm._v("已抽取：" + _vm._s(_vm.config.rand_count)),
          ]),
        ]),
        _vm._m(0),
      ]),
      _c(
        "div",
        { staticClass: "project-content" },
        [
          _vm._l(_vm.config.log_code, function (item, index) {
            return _c("show-card-vue", {
              key: index,
              staticClass: "show-card-vue",
              attrs: {
                cardbgc: _vm.bgc(item.is_repeat, item.is_waste),
                max: _vm.config.log_code.length || 10,
                after: item.user_class,
                number: _vm.digitization(item.code),
                hint: item.is_waste
                  ? "废号"
                  : item.is_repeat
                  ? "已摇号码"
                  : "&nbsp;&nbsp;",
              },
            })
          }),
          _vm.config.repeat_msg
            ? _c("notice-bar", {
                attrs: { speed: 20, text: _vm.config.repeat_msg },
              })
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page-colour", staticStyle: { "font-size": "30px" } },
      [
        _c("div", [
          _c("span", { staticClass: "color-lump white" }),
          _vm._v(" 有效号"),
        ]),
        _c("div", [
          _c("span", { staticClass: "color-lump existing" }),
          _vm._v("已摇号码"),
        ]),
        _c("div", [
          _c("span", { staticClass: "color-lump abandon" }),
          _vm._v("废号"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }