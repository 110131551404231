import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { userConfirm, userdelConfirm, userEventExport, userGroupUser, userRelation } from '@/api/clientSet';
import mergeTable from './components/mergeTable';
import { debounce } from '@/utils/debounce';
import utils from "@/utils/utils";
export default {
  name: 'MergeUser',
  components: {
    mergeTable: mergeTable
  },
  data: function data() {
    return {
      tableData: [],
      dialogData: {},
      ids: [],
      userList: []
    };
  },
  created: function created() {
    this.getTable();
  },
  methods: {
    // 打开弹框事件
    handleMerge: function handleMerge(data) {
      var _this = this;
      userGroupUser(data).then(function (res) {
        if (res.status === 200) {
          var list = res.data.list;
          _this.userList = list;
          list.leftLength = list.not_user_list == null ? 0 : list.not_user_list.length;
          list.rightLength = list.round_user_list == null ? 0 : list.round_user_list.length;
          _this.dialogData = list;
        }
      });
    },
    // 重置关联场次
    handleReset: function handleReset(data) {
      var _this2 = this;
      userdelConfirm(data).then(function (res) {
        if (res.status === 200) {
          _this2.$message({
            type: 'success',
            message: res.data.msg
          });

          // 刷新表格
          _this2.getTable();
        }
      });
    },
    // 提交选中关联场次
    handleSaveId: function handleSaveId(data) {
      var _this3 = this;
      var loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      userConfirm(data).then(function (res) {
        if (res.status === 200) {
          _this3.$message({
            type: 'success',
            message: res.data.msg
          });

          // 刷新表格
          _this3.getTable();
          loading.close();
        }
      }).catch(function (err) {
        loading.close();
      });
    },
    // 全选&非全选
    handleItemCheck: function handleItemCheck(index) {
      var _this4 = this;
      // 存储 选中几个变量
      var num = 0;
      this.dialogData[index].user.forEach(function (item) {
        if (_this4.dialogData[index].is_check) {
          item.is_check = true;
          num = _this4.dialogData[index].user.length;
        } else {
          item.is_check = false;
          num = 0;
        }
      });

      // 赋值选中几个
      this.dialogData[index].check_user = num;
    },
    // 操作子列表选中
    handleInfoCheck: function handleInfoCheck(index) {
      // 存储 选中几个变量
      var num = 0;
      this.dialogData[index].user.forEach(function (item) {
        if (item.is_check) {
          num += 1;
        }
      });

      // 赋值选中几个
      this.dialogData[index].check_user = num;

      // 全部选中
      if (num === this.dialogData[index].user.length) {
        this.dialogData[index].is_check = true;
      } else {
        this.dialogData[index].is_check = false;
      }
    },
    // 获取
    getDialogId: function getDialogId(data) {
      var _this5 = this;
      userGroupUser(data).then(function (res) {
        if (res.status === 200) {
          var list = res.data.list;
          _this5.dialogData = list.groupList;
        }
      });
    },
    // 导出房源
    handleExport: debounce(function () {
      var _this6 = this;
      //  loading
      var loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      // 导出接口
      userEventExport().then(function (res) {
        if (res.status === 200) {
          _this6.$message({
            type: 'success',
            message: '导出成功'
          });

          // 下载
          // window.location.href = this.host + res.data.info
          utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
          loading.close();
        }
      });
    }, 200, true),
    // 获取table数据
    getTable: function getTable() {
      var _this7 = this;
      userRelation().then(function (res) {
        if (res.status === 200) {
          var list = res.data.list;
          _this7.dealTable(list.event);
        }
      });
    },
    // 组装数据
    dealTable: function dealTable(tableData) {
      var getDate = []; // 存储新表格数据

      var firstIndex = 0; // 储存需要设置hover背景色的单元行(两层)

      tableData.forEach(function (item, index) {
        if (item.rounds && item.rounds.length) {
          item.rounds.forEach(function (subV, i, typeData) {
            var obj = {
              pid: item.id,
              id: subV.id,
              name: item.name,
              type: subV.name,
              index: getDate.length,
              num: index + 1,
              end_time: subV.end_time,
              start_time: subV.start_time,
              user_count: subV.user_count,
              batch_count: subV.batch_count,
              event_id: subV.event_id
            };
            if (i === 0) {
              obj.typeIndex = typeData.length;
              firstIndex = obj.index;
            } else {
              obj.firstIndex = firstIndex; // 存储第一行索引
            }
            getDate.push(obj);
          });
        }
      });
      this.tableData = getDate;
    }
  }
};