import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Api from '@/api/staff';
import { debounce } from '@/utils/debounce';
import utils from '@/utils/utils';
export default {
  name: 'SalesTeam',
  props: ['tableHeight'],
  data: function data() {
    return {
      value: 1,
      isPC: utils.isPC(),
      selectedNum: 0,
      // 团队列表
      teamList: [],
      // 新增岗位修改岗位弹出 title
      addEditPostText: '增加一个团队',
      // 是否发开新增岗位弹框
      dialogTeam: false,
      addEditPostRules: {},
      // 新增修改 岗位提交
      addEditTeam: {
        name: ''
      },
      // 人员列表
      staffList: [],
      // 添加人员 弹框
      addStaff: false,
      // 添加人员搜索
      addStaffVal: '',
      // 添加人员 列表
      memberList: [],
      // 添加人员 总数
      total: 0,
      // 已加人员
      selectedUserNum: 0
    };
  },
  // 初始化
  created: function created() {
    this.getPostList();
  },
  methods: {
    // 删除团队
    handleDelTeam: function handleDelTeam(row) {
      var _this = this;
      this.$confirm('此操作不可复原，确认删除吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        // 删除 接口
        Api.teamDel({
          id: row.id
        }).then(function (res) {
          if (res.status === 200) {
            // 获取要删除的下标
            var index = _this.teamList.findIndex(function (d) {
              return d.id === row.id;
            });

            // 执行删除
            _this.teamList.splice(index, 1);
            _this.$message({
              type: 'success',
              message: res.data.msg
            });
            _this.getPostList();
          }
        });
      });
    },
    // 修改 人员状态
    handleUserStatus: function handleUserStatus(row) {
      var _this2 = this;
      Api.teamMemberStatus({
        id: row.id,
        status: row.status
      }).then(function (res) {
        if (res.status === 200) {
          _this2.$message({
            type: 'success',
            message: res.data.msg
          });
        }
      });
    },
    // 人员列表 点击行 选中
    handleUserRow: debounce(function (row) {
      this.$refs.userTable.toggleRowSelection(row); // 设置复选框为未选状态
    }, 200, true),
    // 批量删除
    handleBatchDel: debounce(function () {
      this.delUserMethod(this.staffIds);
    }, 200, true),
    //  移出成员
    handleDelStaff: function handleDelStaff(row) {
      this.delUserMethod([row.id]);
    },
    // 人员列表删除 方法
    delUserMethod: function delUserMethod(ids) {
      var _this3 = this;
      this.$confirm('此操作不可复原，确认删除吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        var data = {
          id: _this3.postId,
          ids: ids
        };

        // 删除 接口
        Api.teamMemberDel(data).then(function (res) {
          if (res.status === 200) {
            _this3.getStaffList(_this3.postId);
            _this3.$message({
              type: 'success',
              message: res.data.msg
            });
            _this3.getPostList();
          }
        });
      });
    },
    // 添加人员保存
    handleSaveStaff: function handleSaveStaff() {
      var _this4 = this;
      Api.postTeamAddMember({
        id: this.postId,
        ids: this.userIds
      }).then(function (res) {
        if (res.status === 200) {
          _this4.$message({
            type: 'success',
            message: res.data.msg
          });
          _this4.addStaff = false;
          _this4.getStaffList(_this4.postId);
          _this4.getPostList();
        }
      });
    },
    // 添加人员 切换每页条数
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.handleAddStaff({
        id: this.postId
      });
    },
    // 添加人员 切换页数
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.handleAddStaff({
        id: this.postId
      });
    },
    // 添加人员列表点击事件
    handleMemberList: debounce(function (row) {
      this.$refs.memberListTable.toggleRowSelection(row); // 设置复选框为未选状态
    }, 200, true),
    // 添加人员清空搜索
    addStaffReset: function addStaffReset() {
      this.page = 1;
      this.addStaffVal = '';
      this.handleAddStaff({
        id: this.postId
      });
    },
    // 添加人员搜索
    addStaffSearch: function addStaffSearch() {
      this.page = 1;
      this.handleAddStaff({
        id: this.postId
      });
    },
    // 新增弹框 获取人员
    handleAddStaff: function handleAddStaff(row) {
      var _this5 = this;
      this.addStaff = true;
      var data = {
        id: row.id,
        search: this.addStaffVal,
        null_role: 0,
        null_team: 0,
        limit: this.pageSize,
        page: this.page
      };

      // 返回id
      this.postId = row.id;
      Api.getTeamAddMember(data).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
            memberList = _res$data.memberList,
            count = _res$data.count;
          _this5.memberList = memberList;
          _this5.total = count;
        }
      });
    },
    // 新增岗位
    handleTeam: function handleTeam() {
      this.dialogTeam = true;
      this.addEditPostText = '增加一个团队';
      this.addEditTeam.name = '';
    },
    // 修改岗位
    handleEditTeam: function handleEditTeam(row) {
      this.dialogTeam = true;
      this.addEditPostText = '编辑岗位名称';
      this.getTeamInfo(row.id);

      // 点击的当前行id
      this.postId = row.id;
    },
    // 获取岗位信息
    getTeamInfo: function getTeamInfo(id) {
      var _this6 = this;
      Api.teamInfo({
        id: id
      }).then(function (res) {
        if (res.status === 200) {
          var team = res.data.team;
          _this6.addEditTeam = team;
        }
      });
    },
    // 保存新增修改 岗位
    handleTeamSave: debounce(function () {
      var _this7 = this;
      if (this.addEditPostText === '增加一个团队') {
        Api.teamAdd(this.addEditTeam).then(function (res) {
          if (res.status === 200) {
            _this7.$message({
              type: 'success',
              message: res.data.msg
            });
            var id = res.data.id;

            // 前端添加
            _this7.teamList.unshift({
              id: id,
              name: _this7.addEditTeam.name,
              status: 1
            });

            // 清空
            _this7.$refs['addEditTeam'].resetFields();
          }
        });
      } else {
        Api.teamEdit(this.addEditTeam).then(function (res) {
          if (res.status === 200) {
            _this7.$message({
              type: 'success',
              message: res.data.msg
            });

            // 获取修改数据的下标
            var index = _this7.teamList.findIndex(function (item) {
              return item.id === _this7.postId;
            });

            // 前端修改
            _this7.teamList[index].name = _this7.addEditTeam.name;

            // 清空
            _this7.$refs['addEditTeam'].resetFields();
          }
        });
      }

      // 关闭弹框
      this.dialogTeam = false;
    }, 200, true),
    // 岗位 状态修改
    handleStatus: function handleStatus(row) {
      var _this8 = this;
      Api.teamStatus({
        id: row.id,
        status: row.status
      }).then(function (res) {
        if (res.status === 200) {
          _this8.$message({
            type: 'success',
            message: res.data.msg
          });
        }
      });
    },
    // 获取岗位列表
    getPostList: function getPostList() {
      var _this9 = this;
      Api.teamList().then(function (res) {
        if (res.status === 200) {
          var teamList = res.data.teamList;
          if (teamList !== null && teamList.length > 0) {
            _this9.teamList = teamList;

            // 默认选中 第一行
            _this9.$nextTick(function () {
              this.$refs.multipleTable.setCurrentRow(teamList[0], true);
              // 第一次获取 人员
              this.getStaffList(teamList[0].id);
              console.log(teamList[0].id);
              // 岗位id
              this.postId = teamList[0].id;
            });
          } else {
            _this9.teamList = [];
            _this9.staffList = [];
          }
        }
      });
    },
    // 团队人员 选中数量
    handleSelectionChange: function handleSelectionChange(row) {
      var ids = [];
      row.forEach(function (item) {
        ids.push(item.id);
      });
      this.userIds = ids;
      this.selectedNum = row.length;
    },
    // 获取选人员列表
    handleUserSelection: function handleUserSelection(row) {
      var ids = [];
      row.forEach(function (item) {
        ids.push(item.id);
      });
      this.staffIds = ids;
      this.selectedUserNum = row.length;
    },
    // 获取人员列表
    getStaffList: function getStaffList(id) {
      var _this10 = this;
      Api.teamMember({
        id: id,
        is_add_team: 1,
        limit: 9999
      }).then(function (res) {
        if (res.status === 200) {
          var memberList = res.data.memberList;
          _this10.staffList = memberList;
        }
      });
    },
    // 点击table 触发事件
    handleRow: debounce(function (row) {
      this.getStaffList(row.id);

      // 点击的当前行id
      this.postId = row.id;
    }, 200, true)
  }
};