//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ProcessItem',
  props: {
    title: {
      type: String,
      default: ''
    },
    // 是否显示 时间线
    isEndLine: {
      type: Boolean,
      default: true
    }
  }
};