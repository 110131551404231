// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".el-dropdown-menu__item .fail {\n  color: #999999;\n}\n", ""]);
// Exports
exports.locals = {
	"menuText": "#475852",
	"menuActiveText": "#FFFFFF",
	"subMenuActiveText": "#019B81",
	"menusonhoverBg": "#CFEAE1",
	"menusonhoverText": "#019B81",
	"menuBg": "#FFFFFF",
	"menuHoverText": "#019B81",
	"menuHoverBg": "rgba(34, 162, 113, 0.2)",
	"subMenuBg": "#FFFFFF",
	"subMenuHover": "#019B81",
	"sideBarWidth": "210px",
	"sonHoverText": "#019B81",
	"sonHoverBg": "rgba(34, 162, 113, 0.2)"
};
module.exports = exports;
