//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import item from '@/components/screenItem/index';
import SplitScreen from '@/utils/splitScreen';
import ArraySlice from '@/utils/arraySlice';
export default {
  name: 'ScreenLeft',
  components: {
    item: item
  },
  props: {
    houseList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    ledConfInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    projectRoomInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    projectInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      screenBox: '',
      docItems: '',
      isShow: false,
      isWaterfall: false,
      // 是否是瀑布流
      pageItem: [],
      // 切割数组
      carouselList: [] // 轮播数据
    };
  },
  watch: {
    houseList: function houseList(n_val, o_val) {
      var _this = this;
      if (n_val) {
        this.$nextTick(function () {
          // setTimeout(() => {
          // 大盒子
          var doc = _this.$refs.screenBox;
          // 盒子子元素
          var itemName = 'wrapper';
          // 获取分割位置信息
          var splitScreen = new SplitScreen(doc, itemName);
          _this.pageItem = splitScreen.getPageItems();
          // 分割数据获取新数据
          var arrSlice = new ArraySlice(_this.houseList, _this.pageItem);
          var newArr = arrSlice.getNewArr();
          _this.carouselList = newArr;
          // 开启最终显示结果
          _this.isShow = true;
          // }, 500)
        });
      }
    }
  },
  mounted: function mounted() {
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     // 大盒子
    //     const doc = this.$refs.screenBox
    //     // 盒子子元素
    //     const itemName = 'wrapper'
    //     // 获取分割位置信息
    //     const splitScreen = new SplitScreen(doc, itemName)
    //     this.pageItem = splitScreen.getPageItems()
    //     // 分割数据获取新数据
    //     const arrSlice = new ArraySlice(this.houseList, this.pageItem)
    //     const newArr = arrSlice.getNewArr()
    //     this.carouselList = newArr
    //     // 开启最终显示结果
    //     this.isShow = true
    //   }, 500)
    // })
  }
};