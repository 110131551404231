var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "诚意登记表单复制",
            visible: _vm.value,
            width: "500",
            "before-close": _vm.closeShow,
          },
          on: {
            "update:visible": function ($event) {
              _vm.value = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "从项目", prop: "old_id" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.form.old_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "old_id", $$v)
                        },
                        expression: "form.old_id",
                      },
                    },
                    _vm._l(_vm.ppp.list, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.project_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "复制到" } },
                [
                  _c("el-select", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.ppp.old.project_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ppp.old, "project_name", $$v)
                      },
                      expression: "ppp.old.project_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: " ", prop: "types" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.form.types,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "types", $$v)
                        },
                        expression: "form.types",
                      },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: 1 } }, [
                        _vm._v("报名表单"),
                      ]),
                      _c("el-checkbox", { attrs: { label: 2 } }, [
                        _vm._v("认筹表单"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeShow } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitCopy } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }