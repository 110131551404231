import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    number: {
      type: [String, Number],
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    after: {
      type: String,
      default: ''
    },
    cardbgc: {
      type: String,
      default: 'rgba(255,255,255, 0.1)'
    },
    max: {
      type: [String, Number],
      default: 10
    }
  },
  data: function data() {
    return {};
  }
};