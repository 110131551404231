import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment";
export default {
  name: "formData",
  data: function data() {
    return {
      statusInfo: [],
      srcImgList: [],
      infoTips: [],
      logList: [],
      isLog: false,
      status: 0,
      statusTxt: ['未提交', '审核中', '已通过']
    };
  },
  props: {
    data: {
      type: Object,
      default: {}
    },
    keyStr: {
      type: String,
      default: ""
    },
    type: {
      type: Number,
      default: 1
    }
  },
  created: function created() {},
  methods: {
    // 获取选中的值
    getSelected: function getSelected(array, selectedValue) {
      if (array && array.length) {
        for (var i = 0; i < array.length; i++) {
          if (array[i].id == selectedValue) {
            return array[i].name;
          }
        }
      }
    },
    doarrData: function doarrData(key) {
      this.isLog = false;
      if (key === "log") {
        this.isLog = true;
        return;
      }
      var infoTips, status, createInfo, adminInfo, userInfo, types;
      if (key === "ide") {
        var _this$data$ide$ide_au;
        this.statusInfo = this.data.ide ? this.data.ide.ide_dataList && this.data.ide.ide_dataList.length ? this.getInfoData(this.data.ide.ide_dataList) : [] : [];
        this.logList = this.data.ide ? this.data.ide.ide_logList && this.data.ide.ide_logList.length ? this.data.ide.ide_logList : [] : [];
        var srcImgList = [];
        if (this.data.ide && this.data.ide.ide_img_arr && this.data.ide.ide_img_arr.length) {
          for (var i = 0; i < this.data.ide.ide_img_arr.length; i++) {
            if (!this.fileName(this.data.ide.ide_img_arr[i].url)) {
              srcImgList.push(this.replaceImgshow(this.data.ide.ide_img_arr[i].url));
            }
          }
        }
        this.srcImgList = srcImgList;
        this.status = status = this.data.ide_status;
        createInfo = this.data.ide.ide_createInfo;
        adminInfo = this.data.ide.ide_adminInfo;
        userInfo = this.data.ide.ide_userInfo;
        types = (_this$data$ide$ide_au = this.data.ide.ide_audit_info) === null || _this$data$ide$ide_au === void 0 ? void 0 : _this$data$ide$ide_au.types;
      } else {
        var _this$data$reg, _this$data$reg2, _this$data$reg3, _this$data$reg4;
        this.statusInfo = this.data.reg ? this.data.reg.reg_dataList && this.data.reg.reg_dataList.length ? this.getInfoData(this.data.reg.reg_dataList) : [] : [];
        this.logList = this.data.reg ? this.data.reg.reg_logList && this.data.reg.reg_logList.length ? this.data.reg.reg_logList : [] : [];
        var _srcImgList = [];
        if (this.data.reg && this.data.reg.reg_img_arr && this.data.reg.reg_img_arr.length) {
          for (var _i = 0; _i < this.data.reg.reg_img_arr.length; _i++) {
            if (!this.fileName(this.data.reg.reg_img_arr[_i].url)) {
              _srcImgList.push(this.replaceImgshow(this.data.reg.reg_img_arr[_i].url));
            }
          }
        }
        this.srcImgList = _srcImgList;
        this.status = status = this.data.reg_status;
        createInfo = (_this$data$reg = this.data.reg) === null || _this$data$reg === void 0 ? void 0 : _this$data$reg.reg_createInfo;
        adminInfo = (_this$data$reg2 = this.data.reg) === null || _this$data$reg2 === void 0 ? void 0 : _this$data$reg2.reg_adminInfo;
        userInfo = (_this$data$reg3 = this.data.reg) === null || _this$data$reg3 === void 0 ? void 0 : _this$data$reg3.reg_userInfo;
        types = (_this$data$reg4 = this.data.reg) === null || _this$data$reg4 === void 0 || (_this$data$reg4 = _this$data$reg4.reg_audit_info) === null || _this$data$reg4 === void 0 ? void 0 : _this$data$reg4.types;
      }
      this.doShowParent();

      // 待审核
      if (status === 1 && createInfo) {
        infoTips = [[{
          name: '客户提交时间',
          value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm')
        }, {
          name: '客户姓名',
          value: createInfo.name
        }, {
          name: '登录手机号',
          value: this.getTel(createInfo.tel)
        }]];
      }
      // 已驳回、已通过
      if (status === -2 || status === 2) {
        infoTips = [];
        if (createInfo) {
          infoTips.push([{
            name: '客户提交时间',
            value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm')
          }, {
            name: '客户姓名',
            value: createInfo.name
          }, {
            name: '登录手机号',
            value: this.getTel(createInfo.tel)
          }]);
        }
        if (adminInfo) {
          infoTips.push([{
            name: '审核时间',
            value: moment(adminInfo.audit_at * 1000).format('YYYY-MM-DD HH:mm')
          }, {
            name: '审核人',
            value: adminInfo.name
          }]);
        }
      }
      // 复审
      if (types === 'ide2' || types === 'status2') {
        // 待审核
        if (status === 1) {
          infoTips = [];
          // if(createInfo){
          //     infoTips.push(  [
          //         { name: '初审通过时间', value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm') },
          //         { name: '审核人', value: createInfo.name }
          //     ])
          // }
          if (createInfo) {
            infoTips.push([{
              name: '客户提交时间',
              value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm')
            }, {
              name: '客户姓名',
              value: createInfo.name
            }, {
              name: '登录手机号',
              value: this.getTel(createInfo.tel)
            }]);
          }
        }
        // 已驳回、已通过
        if (status === -2 || status === 2) {
          infoTips = [];
          // if(createInfo){
          //     infoTips.push(  [
          //         { name: '初审通过时间', value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm') },
          //         { name: '审核人', value: createInfo.name }
          //     ])
          // }
          if (createInfo) {
            infoTips.push([{
              name: '客户姓名',
              value: createInfo.name
            }, {
              name: '客户提交时间',
              value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm')
            }, {
              name: '登录手机号',
              value: this.getTel(createInfo.tel)
            }]);
          }
          if (adminInfo) {
            infoTips.push([{
              name: '审核时间',
              value: moment(adminInfo.audit_at * 1000).format('YYYY-MM-DD HH:mm')
            }, {
              name: '审核人',
              value: adminInfo.name
            }]);
          }
        }
      }
      this.infoTips = infoTips;
    },
    // 处理数据
    getInfoData: function getInfoData(data) {
      data.forEach(function (item) {
        item.typeNumber = 0;
        item.typeUserNumber = 0;

        // 其他类型
        if (item.type === 0) {
          item.forms.forEach(function (forms) {
            if (forms.fill_in_type === 2) {
              item.typeNumber = item.typeNumber + 1;
            } else if (forms.fill_in_type === 1) {
              item.typeUserNumber = item.typeUserNumber + 1;
            }
            if (forms.form_type === 'radio') {
              if (forms.normal_result.value !== '') {
                // 婚姻状态
                if (forms.owner_type === 6) {
                  var index = forms.children.findIndex(function (d) {
                    return d.id == forms.normal_result.value;
                  });
                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改
                        if (_forms_.owner_type === 8 && forms.children[index].name === '未婚') {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  });
                  //   付款方式
                } else if (forms.owner_type === 7) {
                  var _index = forms.children.findIndex(function (d) {
                    return d.id == forms.normal_result.value;
                  });
                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改
                        if (_forms_.owner_type === 9 && (forms.children[_index].name === '全款' || forms.children[_index].name === '一次性付款')) {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  });
                } else if (forms.owner_type === 10) {
                  var _index2 = forms.children.findIndex(function (d) {
                    return d.id == forms.normal_result.value;
                  });
                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改
                        if (_forms_.owner_type === 12 && forms.children[_index2].owner_type === 11) {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  });
                }
              }
            }
          });
        } else if (item.type === 1 || item.type === 2) {
          // 1为产权人 2为银行资料
          var ownerBankKey = item.type == 1 ? "owner" : "bank";
          item[ownerBankKey + "s"].forEach(function (owners) {
            owners.forms.map(function (forms) {
              if (forms.fill_in_type === 2) {
                item.typeNumber = item.typeNumber + 1;
              } else {
                item.typeUserNumber = item.typeUserNumber + 1;
              }
            });
          });
        }
      });
      return data;
    },
    getType: function getType(item) {
      if (this.type === 1) {
        return item.typeUserNumber > 0;
      } else {
        return item.typeNumber > 0;
      }
    },
    /**
     * 父级影响显示隐藏
     * @param parent_id  当前id（父级）
     * @param parent_values 当前选项ids
     * @param isShowParent 是否开始判断父级隐藏
     * @param ownerBankIndex 产权人下标
     */
    doShowParent: function doShowParent(isShowParent, parent_id, parent_values, ownerBankIndex) {
      var _this = this;
      if (isShowParent) {
        var forms = JSON.parse(JSON.stringify(this.statusInfo));
        if (ownerBankIndex || ownerBankIndex === 0) {
          for (var i = 0; i < forms.length; i++) {
            // 产权人或者银行资料
            if (forms[i].type === 1) {
              // 1为产权人 2为银行资料
              var ownerBankKey = forms[i].type == 1 ? "owner" : "bank";
              for (var a = 0; a < forms[i][ownerBankKey + "s"][ownerBankIndex].forms.length; a++) {
                var item = forms[i][ownerBankKey + "s"][ownerBankIndex].forms[a];
                if (parent_id === item.parent_pid && item.parent_pid !== 0) {
                  var parent_ids = [];
                  if (item.parent_id) {
                    parent_ids = item.parent_id.split(",");
                  }
                  var parent_cause_show = false;
                  for (var c = 0; c < parent_ids.length; c++) {
                    if (parent_values.indexOf(parent_ids[c]) >= 0) {
                      parent_cause_show = true;
                    }
                  }
                  // 隐藏时清空之前的值
                  if (!parent_cause_show) {
                    item.normal_result.value = "";
                    item[ownerBankKey + "_result"].value = "";
                    item.normal_result.value2 = "";
                    item[ownerBankKey + "_result"].value2 = "";
                  }
                  item.parent_cause_show = parent_cause_show;
                } else if (item.parent_pid === 0) {
                  item.parent_cause_show = true;
                }
                forms[i][ownerBankKey + "s"][ownerBankIndex].forms[a] = item;
              }
            }
          }
        } else {
          for (var _i2 = 0; _i2 < forms.length; _i2++) {
            // 产权人或者银行资料
            if (forms[_i2].type === 1 || forms[_i2].type === 2) {
              // 1为产权人 2为银行资料
              var _ownerBankKey = forms[_i2].type == 1 ? "owner" : "bank";
              for (var l = 0; l < forms[_i2][_ownerBankKey + "s"].length; l++) {
                for (var _a = 0; _a < forms[_i2][_ownerBankKey + "s"][l].forms.length; _a++) {
                  var _item2 = forms[_i2][_ownerBankKey + "s"][l].forms[_a];
                  if (parent_id === _item2.parent_pid && _item2.parent_pid !== 0) {
                    var _parent_ids = [];
                    if (_item2.parent_id) {
                      _parent_ids = _item2.parent_id.split(",");
                    }
                    var _parent_cause_show = false;
                    for (var _c = 0; _c < _parent_ids.length; _c++) {
                      if (parent_values.indexOf(_parent_ids[_c]) >= 0) {
                        _parent_cause_show = true;
                      }
                    }
                    // 隐藏时清空之前的值
                    if (!_parent_cause_show) {
                      _item2.normal_result.value = "";
                      _item2[_ownerBankKey + "_result"].value = "";
                      _item2.normal_result.value2 = "";
                      _item2[_ownerBankKey + "_result"].value2 = "";
                    }
                    _item2.parent_cause_show = _parent_cause_show;
                  } else if (_item2.parent_pid === 0) {
                    _item2.parent_cause_show = true;
                  }
                  forms[_i2][_ownerBankKey + "s"][l].forms[_a] = _item2;
                }
              }
            }
            // 其他类型
            else {
              for (var _a2 = 0; _a2 < forms[_i2].forms.length; _a2++) {
                var _item3 = forms[_i2].forms[_a2];
                if (parent_id === _item3.parent_pid && _item3.parent_pid !== 0) {
                  var _parent_ids2 = [];
                  if (_item3.parent_id) {
                    _parent_ids2 = _item3.parent_id.split(",");
                  }
                  var _parent_cause_show2 = false;
                  for (var _c2 = 0; _c2 < _parent_ids2.length; _c2++) {
                    if (parent_values.indexOf(_parent_ids2[_c2]) >= 0) {
                      _parent_cause_show2 = true;
                    }
                  }
                  // 隐藏时清空之前的值
                  if (!_parent_cause_show2) {
                    _item3.normal_result.value = "";
                    _item3.normal_result.value2 = "";
                  }
                  _item3.parent_cause_show = _parent_cause_show2;
                } else if (_item3.parent_pid === 0) {
                  _item3.parent_cause_show = true;
                }
                forms[_i2].forms[_a2] = _item3;
              }
            }
          }
        }
        this.statusInfo = JSON.parse(JSON.stringify(forms));
      } else {
        var formData = JSON.parse(JSON.stringify(this.statusInfo));
        formData.forEach(function (item) {
          if (item.type != 1 && item.type != 2) {
            item.forms.forEach(function (_item) {
              var selectId = [];
              if (_item.form_type == '置业顾问') {
                // 置业顾问
                if (_item.normal_result.value) {
                  selectId = _item.normal_result.value.toString().split(',');
                } else if (_item.value) {
                  selectId = _item.value.toString().split(',');
                } else if (_item.select_value == 1) {
                  // 默认选择
                  selectId = _item.admins[0].id.toString().split(',');
                }
                _this.doShowParent(true, _item.id, selectId);
              } else if (_item.form_type == 'radio' || _item.form_type == 'select') {
                // 单选 || 多选
                if (_item.normal_result.value) {
                  selectId = _item.normal_result.value.toString().split(',');
                } else if (_item.value) {
                  selectId = _item.value.toString().split(',');
                } else if (_item.select_value == 1) {
                  if (_item.form_type == 'radio') {
                    selectId = _item.children[0].id.toString().split(',');
                  } else {
                    _item.normal_result.value = selectId.join(',');
                  }
                }
                _this.doShowParent(true, _item.id, selectId);
              }
            });
          } else if (item.type == 1) {
            // 1为产权人 2为银行资料
            var _ownerBankKey2 = item.type == 1 ? "owner" : "bank";
            // 产权人或者银行资料，多个
            for (var _l = 0; _l < item[_ownerBankKey2 + "s"].length; _l++) {
              // 问题
              for (var _a3 = 0; _a3 < item[_ownerBankKey2 + "s"][_l].forms.length; _a3++) {
                var _item = item[_ownerBankKey2 + "s"][_l].forms[_a3];
                var selectId = [];
                if (_item.form_type == '置业顾问') {
                  // 置业顾问
                  if (_item[_ownerBankKey2 + "_result"].value) {
                    selectId = _item[_ownerBankKey2 + "_result"].value.toString().split(',');
                  } else if (_item.value) {
                    selectId = _item.value.toString().split(',');
                  } else if (_item.select_value == 1) {
                    // 默认选择
                    selectId = _item.admins[0].id.toString().split(',');
                  }
                  _this.doShowParent(true, _item.id, selectId, _l);
                } else if (_item.form_type == 'radio' || _item.form_type == 'select') {
                  // 单选 || 多选
                  if (_item[_ownerBankKey2 + "_result"].value) {
                    selectId = _item[_ownerBankKey2 + "_result"].value.toString().split(',');
                  } else if (_item.value) {
                    selectId = _item.value.toString().split(',');
                  } else if (_item.select_value == 1) {
                    if (_item.form_type == 'radio') {
                      selectId = _item.children[0].id.toString().split(',');
                    } else {
                      _item[_ownerBankKey2 + "_result"].value = selectId.join(',');
                    }
                  }
                  _this.doShowParent(true, _item.id, selectId, _l);
                }
              }
            }
          }
        });
      }
    },
    // 返回文件名称
    fileName: function fileName(str) {
      var splits = str.split('/');
      var name = splits[splits.length - 1];
      var suffix = name.split('.');
      suffix = suffix[suffix.length - 1];
      var images = ["bmp", "jpg", "jpeg", "png", "tif", "gif", "pcx", "tga", "exif", "fpx", "svg", "dxf", "ufo", "eps", "raw", "WMF", "webp", "avif", "apng"];
      return images.indexOf(suffix) < 0 ? name : "";
    }
  }
};