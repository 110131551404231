import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCode, getInfo, resetPwd } from '@/api/user';
import defaultSettings from '@/settings';
import utils from '@/utils/utils';
import OSS from '@/utils/oss';
export default {
  name: 'Login',
  // components: {LangSelect},
  data: function data() {
    // 产权人 手机验证
    var checkTrendTel = function checkTrendTel(rule, value, callback) {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      } else {
        var reg = /^1\d{10}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };
    var checkPwd = function checkPwd(rule, value, callback) {
      if (!value) {
        return callback(new Error('密码不能为空'));
      } else {
        // const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{4,12}$/;
        var reg = new RegExp(/^[0-9A-Za-z]{4,12}$/);
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('用户密码必须为数字或者字母，只能为4~12位'));
        }
      }
    };
    return {
      // 忘记密码提交
      form: {
        tel: '',
        password: '',
        code: ''
      },
      rules: {
        tel: [{
          validator: checkTrendTel,
          trigger: 'blur',
          required: true
        }],
        password: [{
          validator: checkPwd,
          trigger: 'blur',
          required: true
        }]
      },
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          message: '账号不能为空'
        }],
        password: [{
          required: true,
          trigger: 'blur',
          message: '密码不能为空'
        }]
      },
      passwordType2: 'password',
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      is_password: false,
      otherQuery: {},
      is_projectList: false,
      // 选择项目 form
      projectVal: '',
      // 项目列表
      projectList: [],
      // 项目 详情
      info: '',
      // 初始值倒计时
      time: 0,
      defaultSettings: defaultSettings,
      isPC: utils.isPC()
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {
    // window.addEventListener('storage', this.afterQRScan)
  },
  mounted: function mounted() {
    if (this.loginForm.username === '') {
      this.$refs.username.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }
  },
  destroyed: function destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    // 提交新密码
    postNewPassword: function postNewPassword() {
      var _this = this;
      // 验证
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this.form.tel = _this.form.tel + '';
          resetPwd(_this.form).then(function (res) {
            if (res.status === 200) {
              _this.$message({
                type: 'success',
                message: res.data.msg
              });
              _this.$refs['ruleForm'].resetFields();
              _this.is_password = false;
            }
          });
        }
      });
    },
    // 获取验证码 ！
    postCode: function postCode() {
      var _this2 = this;
      if (this.form.tel === '') {
        this.$message({
          type: 'warning',
          message: '手机号为空'
        });
        return;
      }
      getCode({
        tel: this.form.tel
      }).then(function (res) {
        if (res.status === 200) {
          var time = res.data.time;
          if (time === 60) {
            _this2.$message({
              type: 'success',
              message: '已获取验证码5分钟内有效！'
            });
          } else {
            _this2.$message({
              type: 'error',
              message: "".concat(time, "\u540E\u83B7\u53D6\u9A8C\u8BC1\u7801\uFF0C\u9A8C\u8BC1\u78015\u5206\u949F\u5185\u6709\u6548\uFF01")
            });
          }
          if (time > 0) {
            var timeFun = setInterval(function () {
              time--;
              _this2.time = time;
              if (time === 0) {
                clearTimeout(timeFun);
              }
            }, 1000);
          }
        }
      });
    },
    // 保存选中项目
    saveProject: function saveProject() {
      var _this3 = this;
      if (this.projectVal === '') {
        this.$message({
          type: 'error',
          message: '你还未选择项目'
        });
      } else {
        var data = {
          id: this.info.info.id,
          project_id: this.projectVal,
          is_default: this.info.is_default
        };

        // 调用保存项目
        this.$store.dispatch('user/project', data).then(function (res) {
          if (res.status === 200) {
            // 再次调用登录
            _this3.$store.dispatch('user/logins', _this3.loginForm).then(function (res) {
              if (res.status === 200) {
                _this3.$router.push({
                  path: '/'
                });
              }
            });
          }
        });
      }
    },
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showPwd: function showPwd() {
      var _this4 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this4.$refs.password.focus();
      });
    },
    showPwd2: function showPwd2() {
      var _this5 = this;
      console.log(this.passwordType2);
      if (this.passwordType2 === 'password') {
        this.passwordType2 = '';
      } else {
        this.passwordType2 = 'password';
      }
      this.$nextTick(function () {
        _this5.$refs.password.focus();
      });
    },
    // 登录点击事件
    handleLogin: function handleLogin() {
      var _this6 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this6.loading = true;
          _this6.$store.dispatch('user/login', _this6.loginForm).then(function (res) {
            if (res.info.is_default === 1 || res.info.project_id === 0) {
              // 多个项目
              if (res.project_list && res.project_list.length > 1) {
                _this6.is_projectList = true;
                _this6.$router.push({
                  path: '/'
                });
                // 项目列表
                _this6.projectList = res.project_list;
                // 登录数据
                _this6.info = res;
                //	单个项目
              } else {
                _this6.$router.push({
                  path: '/'
                });
              }
            } else {
              // 多个项目
              if (res.project_list && res.project_list.length > 1) {
                getInfo().then(function (infoRes) {
                  var Data = infoRes.data.Data;
                  if (Data.roles.length === 0) {
                    _this6.$message({
                      type: 'error',
                      message: '当前项目没有权限，你可以选择其他项目。'
                    });
                    _this6.is_projectList = true;

                    // 项目列表
                    _this6.projectList = res.project_list;
                    // 登录数据
                    _this6.info = res;
                  } else {
                    _this6.$router.push({
                      path: '/'
                    });
                  }
                });
              } else {
                _this6.$router.push({
                  path: '/'
                });
              }
            }
            _this6.loading = false;
            OSS.getuploadSts();
          }).catch(function () {
            _this6.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    }
  }
};