import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import inputBox from '../components/input-box';
import { exportAuditStatus0, exportAuditStatus1, exportAuditStatus2, AuditStatusEdit0, AuditStatusEdit1, AuditStatusEdit2 } from '@/api/sincerityRegister';
import { userDelList } from '@/api/clientSet';
import toolReviewMixin from '../toolReviewMixin';
export default {
  name: 'EditReviewDetails',
  components: {
    inputBox: inputBox
  },
  mixins: [toolReviewMixin],
  data: function data() {
    return {
      activeName: '0',
      // 审核内容
      statusInfo: [],
      collect: '',
      // 意向房源
      collect_room_cate: '',
      // 意向户型
      // 审核信息
      auditInfo: [],
      // 隐藏信息
      hidenSpouse: [],
      type: 1,
      ownerSort: {
        dialogShow: false,
        data: [],
        index: 0
      },
      userCreateInfoId: 0
    };
  },
  created: function created() {
    var _this = this;
    // let types = this.$route.query.status;
    // this.type = parseInt(this.$route.query.types);
    var row = this.toolReviewRow;
    var types = this.toolReviewRow.types;
    this.type = parseInt(this.toolReviewRow.index);
    if (types === 'status0') {
      exportAuditStatus0({
        id: row.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
            statusInfo = _res$data.statusInfo,
            auditInfo = _res$data.auditInfo,
            info = _res$data.info,
            collect = _res$data.collect,
            collect_room_cate = _res$data.collect_room_cate;
          _this.statusInfo = _this.getInfoData(info.dataList, collect, collect_room_cate);
          _this.auditInfo = auditInfo;
          _this.userCreateInfoId = info.createInfo.id;
          _this.doShowParent();
        }
      });
    } else if (types === 'status1') {
      exportAuditStatus1({
        id: row.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data2 = res.data,
            statusInfo = _res$data2.statusInfo,
            auditInfo = _res$data2.auditInfo,
            info = _res$data2.info,
            collect = _res$data2.collect,
            collect_room_cate = _res$data2.collect_room_cate;
          _this.statusInfo = _this.getInfoData(info.dataList, collect, collect_room_cate);
          _this.auditInfo = auditInfo;
          _this.userCreateInfoId = info.createInfo.id;
          _this.doShowParent();
        }
      });
    } else {
      exportAuditStatus2({
        id: row.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data3 = res.data,
            statusInfo = _res$data3.statusInfo,
            auditInfo = _res$data3.auditInfo,
            info = _res$data3.info,
            collect = _res$data3.collect,
            collect_room_cate = _res$data3.collect_room_cate;
          _this.statusInfo = _this.getInfoData(info.dataList, collect, collect_room_cate);
          _this.auditInfo = auditInfo;
          _this.userCreateInfoId = info.createInfo.id;
          _this.doShowParent();
        }
      });
    }
  },
  methods: {
    // 其他格式单选时间
    handleRadio: function handleRadio(index, type, val) {
      var _this2 = this;
      var _loop = function _loop(i) {
        if (_this2.statusInfo[i].type === 0) {
          for (var j = 0; j < _this2.statusInfo[i].forms.length; j++) {
            // 付款方式
            if (type === 6 && _this2.statusInfo[i].forms[j].owner_type === 6) {
              var inx = _this2.statusInfo[i].forms[j].children.findIndex(function (d) {
                return d.id === val;
              });
              for (var k = 0; k < _this2.statusInfo.length; k++) {
                if (_this2.statusInfo[k].type === 0) {
                  for (var n = 0; n < _this2.statusInfo[k].forms.length; n++) {
                    if (_this2.statusInfo[k].forms[n].owner_type === 8) {
                      _this2.statusInfo[k].forms[n].is_show_tp = _this2.statusInfo[i].forms[j].children[inx].name === '未婚';
                      _this2.statusInfo = JSON.parse(JSON.stringify(_this2.statusInfo));
                    }
                  }
                }
              }
              // 如果选项为已婚
              if (val === 1251) {
                for (var _j = 0; _j < _this2.statusInfo[i].forms.length; _j++) {
                  if (_this2.statusInfo[i].forms[_j].type != 1) {
                    var Spouse = _this2.statusInfo[i].forms.filter(function (item) {
                      return item.name.includes('配偶');
                    });
                    if (!Spouse.length) {
                      _this2.statusInfo[i].forms.forEach(function (items, indexs) {
                        if (items.name === '婚姻状态') {
                          var _index = indexs;
                          _this2.hidenSpouse.forEach(function (item) {
                            _index++;
                            _this2.statusInfo[i].forms.splice(_index, 0, item);
                          });
                        }
                      });
                    }
                  }
                }
              } else {
                for (var _j2 = 0; _j2 < _this2.statusInfo[i].forms.length; _j2++) {
                  if (_this2.statusInfo[i].forms[_j2].type != 1) {
                    var noSpouse = _this2.statusInfo[i].forms.filter(function (item) {
                      return !item.name.includes('配偶');
                    });
                    var _Spouse = _this2.statusInfo[i].forms.filter(function (item) {
                      return item.name.includes('配偶');
                    });
                    _this2.statusInfo[i].forms = noSpouse;
                    if (_Spouse.length > 0) _this2.hidenSpouse = _Spouse;
                  }
                }
              }
              //   婚姻状态
            } else if (type === 7 && _this2.statusInfo[i].forms[j].owner_type === 7) {
              var _inx = _this2.statusInfo[i].forms[j].children.findIndex(function (d) {
                return d.id === val;
              });
              for (var _k = 0; _k < _this2.statusInfo.length; _k++) {
                if (_this2.statusInfo[_k].type === 0) {
                  for (var _n = 0; _n < _this2.statusInfo[_k].forms.length; _n++) {
                    if (_this2.statusInfo[_k].forms[_n].owner_type === 9) {
                      _this2.statusInfo[_k].forms[_n].is_show_tp = _this2.statusInfo[i].forms[j].children[_inx].name === '全款' || _this2.statusInfo[i].forms[j].children[_inx].name === '一次性付款';
                      _this2.statusInfo = JSON.parse(JSON.stringify(_this2.statusInfo));
                    }
                  }
                }
              }
              //     户籍所在
            } else if (type === 10 && _this2.statusInfo[i].forms[j].owner_type === 10) {
              var _inx2 = _this2.statusInfo[i].forms[j].children.findIndex(function (d) {
                return d.id === val;
              });
              for (var _k2 = 0; _k2 < _this2.statusInfo.length; _k2++) {
                if (_this2.statusInfo[_k2].type === 0) {
                  for (var _n2 = 0; _n2 < _this2.statusInfo[_k2].forms.length; _n2++) {
                    if (_this2.statusInfo[_k2].forms[_n2].owner_type === 12) {
                      _this2.statusInfo[_k2].forms[_n2].is_show_tp = _this2.statusInfo[i].forms[j].children[_inx2].owner_type === 11;
                      _this2.statusInfo = JSON.parse(JSON.stringify(_this2.statusInfo));
                      // return
                    }
                  }
                }
              }
            }
          }
        }
      };
      for (var i = 0; i < this.statusInfo.length; i++) {
        _loop(i);
      }
    },
    // 删除 产权人
    handleDelete: function handleDelete(index, inx) {
      var _this3 = this;
      this.$confirm('此操作不可复原，确认删除吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        _this3.statusInfo[index].owners.splice(inx, 1);
      }).catch(function () {});
    },
    // 添加 产权人
    addPropertyOwner: function addPropertyOwner(index) {
      var data = JSON.parse(JSON.stringify(this.statusInfo[index].owners[0]));
      delete data.audit_register_form_id;
      delete data.id;
      data.forms.map(function (form) {
        delete form.owner_result.id;
        delete form.owner_result.audit_register_form_owner_id;
        delete form.owner_result.is_admin_eidt;
        delete form.owner_result.disabled;
        delete form.owner_result.is_jf;
        form.owner_result.value = '';
        if (form.form_type === 'select') {
          form.owner_result.value2 = [];
        } else if (form.form_type === 'file') {
          form.owner_result.value2 = [];
        } else if (form.form_type === 'radio') {
          form.owner_result.value2 = [];
        }
      });
      this.statusInfo[index].owners.push(data);
    },
    // 产权人排序
    onOwnerSort: function onOwnerSort(index, key) {
      // 向上移位
      if (key === "top") {
        var data = JSON.parse(JSON.stringify(this.ownerSort.data));
        var topData = JSON.parse(JSON.stringify(data[index - 1]));

        // // 和上一个要交换的是建发
        // let isJf = topData.forms.find(item => item.owner_result.is_jf == 1)
        // // 如果产权人是建发数字案场传递过来的，则此产权人 不允许修改(只可能是产权人1才会出现此情况)
        // // 建发表单 并且 产权人2交换到产权人1
        // if (isJf && index == 1) {
        // 	this.$message.error('此产权人1不允许调整位置')
        // 	return
        // }
        for (var i = 0; i < data[index].forms.length; i++) {
          data[index - 1].forms[i].owner_result.value = data[index].forms[i].owner_result.value;
          data[index - 1].forms[i].owner_result.value2 = data[index].forms[i].owner_result.value2;
          data[index - 1].forms[i].value = data[index].forms[i].value;
        }
        for (var _i = 0; _i < data[index].forms.length; _i++) {
          data[index].forms[_i].owner_result.value = topData.forms[_i].owner_result.value;
          data[index].forms[_i].owner_result.value2 = topData.forms[_i].owner_result.value2;
          data[index].forms[_i].value = topData.forms[_i].value;
        }
        this.ownerSort.data = data;
      }
      // 向下移位
      else if (key === "bottom") {
        var _data = JSON.parse(JSON.stringify(this.ownerSort.data));
        var buttonData = JSON.parse(JSON.stringify(_data[index + 1]));

        // let isJf = data[index].forms.find(item => item.owner_result.is_jf == 1)
        //
        // // 如果产权人是建发数字案场传递过来的，则此产权人 不允许修改(只可能是产权人1才会出现此情况)
        // // 建发表单 并且 产权人1
        // if (isJf && index == 0) {
        // 	this.$message.error('此产权人1不允许调整位置')
        // 	return
        // }

        for (var _i2 = 0; _i2 < _data[index].forms.length; _i2++) {
          _data[index + 1].forms[_i2].owner_result.value = _data[index].forms[_i2].owner_result.value;
          _data[index + 1].forms[_i2].owner_result.value2 = _data[index].forms[_i2].owner_result.value2;
          _data[index + 1].forms[_i2].value = _data[index].forms[_i2].value;
        }
        for (var _i3 = 0; _i3 < _data[index].forms.length; _i3++) {
          _data[index].forms[_i3].owner_result.value = buttonData.forms[_i3].owner_result.value;
          _data[index].forms[_i3].owner_result.value2 = buttonData.forms[_i3].owner_result.value2;
          _data[index].forms[_i3].value = buttonData.forms[_i3].value;
        }
        this.ownerSort.data = _data;
      }
      // 确定修改排序
      else if (key === "submit") {
        this.statusInfo[this.ownerSort.index].owners = JSON.parse(JSON.stringify(this.ownerSort.data));
        this.ownerSort = {
          dialogShow: false,
          data: [],
          index: 0
        };
      }
      // 点击排序按钮
      else {
        var owners = this.statusInfo[index].owners;
        if (!owners || !owners.length) {
          this.$message({
            type: "error",
            message: "产权人不存在！"
          });
          return;
        }
        if (owners.length === 1) {
          this.$message({
            type: "info",
            message: "产权人数量为一，无需排序！"
          });
          return;
        }
        this.ownerSort = {
          dialogShow: true,
          data: JSON.parse(JSON.stringify(owners)),
          index: index
        };
      }
    },
    // 处理数据
    getInfoData: function getInfoData(data, collect, collect_room_cate) {
      var _this4 = this;
      // 修改多选，图片格式
      data.forEach(function (item, index) {
        item.typeNumber = 0;
        item.typeUserNumber = 0;

        // 其他类型
        if (item.type === 0) {
          item.forms.forEach(function (forms) {
            if (forms.fill_in_type === 2) {
              item.typeNumber = item.typeNumber + 1;
            } else if (forms.fill_in_type === 1) {
              item.typeUserNumber = item.typeUserNumber + 1;
            }
            if (forms.form_type === 'select') {
              if (forms.normal_result.value !== '') {
                var array = [];
                forms.normal_result.value.split(',').map(function (num) {
                  array.push(parseInt(num));
                });
                forms.normal_result.value2 = array;
              } else {
                forms.normal_result.value2 = [];
              }
            } else if (forms.form_type === 'file') {
              if (forms.normal_result.value !== '') {
                var _array = [];
                forms.normal_result.value.split(',').map(function (img) {
                  _array.push({
                    'url': _this4.replaceImgshow(img),
                    "name": img.split('/')[img.split('/').length - 1]
                  });
                });
                // 组装新数组
                forms.normal_result.value2 = _array;
              } else {
                forms.normal_result.value2 = [];
              }
            } else if (forms.form_type === 'radio') {
              if (forms.normal_result.value !== '') {
                forms.normal_result.value2 = parseInt(forms.normal_result.value);

                // 婚姻状态
                if (forms.owner_type === 6) {
                  var _index2 = forms.children.findIndex(function (d) {
                    return d.id === forms.normal_result.value2;
                  });
                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改
                        if (_forms_.owner_type === 8 && forms.children[_index2].name === '未婚') {
                          _forms_.is_show_tp = true;
                        }
                      });
                      var noSpouse = _item_.forms.filter(function (item) {
                        return !item.name.includes('配偶');
                      });
                      var Spouse = _item_.forms.filter(function (item) {
                        return item.name.includes('配偶');
                      });
                      _item_.forms = noSpouse;
                      if (Spouse.length > 0) _this4.hidenSpouse = Spouse;
                    }
                  });
                  //   付款方式
                } else if (forms.owner_type === 7) {
                  var _index3 = forms.children.findIndex(function (d) {
                    return d.id === forms.normal_result.value2;
                  });
                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改 首付比例
                        if (_forms_.owner_type === 9 && (forms.children[_index3].name === '全款' || forms.children[_index3].name === '一次性付款')) {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  });
                } else if (forms.owner_type === 10) {
                  var _index4 = forms.children.findIndex(function (d) {
                    return d.id === forms.normal_result.value2;
                  });
                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改 首付比例
                        if (_forms_.owner_type === 12 && forms.children[_index4].owner_type === 11) {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  });
                }
              } else {
                forms.normal_result.value2 = '';
              }
            } else if (forms.form_type === 'popup') {
              var _item$forms;
              if (collect) {
                forms.normal_result.auto_value = JSON.parse(collect !== null && collect !== void 0 ? collect : '');
              }
              // 显示意向房源 隐藏回填项
              (_item$forms = item.forms) === null || _item$forms === void 0 || _item$forms.forEach(function (_item) {
                //   80 意向楼栋 81意向户型 82 意向面积 87 意向楼层
                if ([80, 81, 82, 87].includes(_item.owner_type)) {
                  _item.hidde = true;
                  _item.is_required = 1;
                }
              });
            } else if (forms.form_type === '意向户型') {
              if (collect_room_cate) {
                forms.normal_result.auto_value = JSON.parse(collect_room_cate !== null && collect_room_cate !== void 0 ? collect_room_cate : '');
              }
            }
          });
          //  产权人
        }
        //  产权人和银行资料
        else if (item.type === 1 || item.type === 2) {
          // 1为产权人 2为银行资料
          var ownerBankKey = item.type == 1 ? "owner" : "bank";
          item[ownerBankKey + "s"].forEach(function (owners) {
            owners.forms.map(function (forms, formsIndex) {
              if (forms.fill_in_type === 2) {
                item.typeNumber = item.typeNumber + 1;
              } else {
                item.typeUserNumber = item.typeUserNumber + 1;
              }
              if (forms.form_type === 'select') {
                if (forms[ownerBankKey + "_result"].value !== '') {
                  var array = [];
                  forms[ownerBankKey + "_result"].value.split(',').map(function (num) {
                    array.push(parseInt(num));
                  });
                  forms[ownerBankKey + "_result"].value2 = array;
                } else {
                  forms[ownerBankKey + "_result"].value2 = [];
                }
              } else if (forms.form_type === 'file') {
                if (forms[ownerBankKey + "_result"].value !== '') {
                  var _array2 = [];
                  forms[ownerBankKey + "_result"].value.split(',').map(function (img) {
                    _array2.push({
                      'url': _this4.replaceImgshow(img),
                      "name": img.split('/')[img.split('/').length - 1]
                    });
                  });
                  // 组装新数组
                  forms[ownerBankKey + "_result"].value2 = _array2;
                } else {
                  forms[ownerBankKey + "_result"].value2 = [];
                }
              } else if (forms.form_type === 'radio') {
                if (forms[ownerBankKey + "_result"].value !== '') {
                  forms[ownerBankKey + "_result"].value2 = parseInt(forms[ownerBankKey + "_result"].value);
                } else {
                  forms[ownerBankKey + "_result"].value2 = '';
                }
              }
              var formkes = forms[ownerBankKey + "_result"];
              // 如果产权人是建发数字案场传递过来的，则此产权人 不允许修改(只可能是产权人1才会出现此情况)
              // #1277 建发手机号不允许修改
              if (formkes.is_jf == 1 && formkes.owner_type === 2) {
                forms[ownerBankKey + "_result"].disabled = 1;
              }
            });
          });
        }
      });
      return data;
    },
    // 产权人传回的图片
    handleProperty: function handleProperty(url, index) {
      var _this5 = this;
      this.statusInfo[index[0]].owners[index[1]].forms[index[2]].owner_result.value = url;
      this.$nextTick(function () {
        _this5.removeUploadImage();
      });
    },
    // 其他类型传回的图片
    handleOtherImg: function handleOtherImg(url, index) {
      var _this6 = this;
      this.statusInfo[index[0]].forms[index[1]].normal_result.value = url;
      this.$nextTick(function () {
        _this6.removeUploadImage();
      });
    },
    handleSchedule: function handleSchedule() {
      this.$router.push({
        name: 'Schedule'
      });
    },
    // 处理数据 提交给后台
    postData: function postData() {
      var hideData = {};
      // 修改多选，图片格式
      this.statusInfo.forEach(function (item, index) {
        // 其他类型
        if (item.type === 0) {
          item.forms.map(function (forms) {
            if (forms.form_type === 'select') {
              if (typeof forms.normal_result.value2 !== 'string') {
                forms.normal_result.value = forms.normal_result.value2.join(',');
                forms.normal_result.form_id = forms.id;
              }
            } else if (forms.form_type === 'file') {
              if (typeof forms.normal_result.value !== 'string') {
                // 组装新数组
                forms.normal_result.value = forms.normal_result.value.join(',');
                forms.normal_result.form_id = forms.id;
              }
            } else if (forms.form_type === 'radio') {
              forms.normal_result.value = forms.normal_result.value2 + '';
              forms.normal_result.form_id = forms.id;
            } else {
              forms.normal_result.form_id = forms.id;
            }

            // 隐藏表单
            if (forms.hide_id) {
              if (!hideData[forms.hide_id]) hideData[forms.hide_id] = [];
              if (forms.form_type === "select" || forms.form_type === "radio") {
                var _hideData$forms$hide_;
                var ids = forms.normal_result.value.split(",");
                var value = [];
                for (var a = 0; a < forms.children.length; a++) {
                  if (ids.indexOf(forms.children[a].id + "") >= 0) {
                    value.push(forms.children[a].name);
                  }
                }
                (_hideData$forms$hide_ = hideData[forms.hide_id]).push.apply(_hideData$forms$hide_, value);
              } else if (forms.form_type === "置业顾问") {
                var _hideData$forms$hide_2;
                var _ids = forms.normal_result.value.split(",");
                var _value = [];
                for (var _a = 0; _a < forms.admins.length; _a++) {
                  if (_ids.indexOf(forms.admins[_a].id + "") >= 0) {
                    _value.push(forms.admins[_a].name);
                  }
                }
                (_hideData$forms$hide_2 = hideData[forms.hide_id]).push.apply(_hideData$forms$hide_2, _value);
              } else {
                hideData[forms.hide_id].push(forms.normal_result.value);
              }
            }
          });
        }
        //  产权人
        else if (item.type === 1 || item.type === 2) {
          // 1为产权人 2为银行资料
          var ownerBankKey = item.type == 1 ? "owner" : "bank";
          item[ownerBankKey + "s"].forEach(function (owners) {
            owners.forms.map(function (forms) {
              forms.normal_result.form_id = forms.id;
              if (forms.form_type === 'select') {
                if (typeof forms[ownerBankKey + "_result"].value2 !== 'string') {
                  forms[ownerBankKey + "_result"].value = forms[ownerBankKey + "_result"].value2.join(',');
                }
              } else if (forms.form_type === 'file') {
                if (typeof forms[ownerBankKey + "_result"].value !== 'string') {
                  // 组装新数组
                  forms[ownerBankKey + "_result"].value = forms[ownerBankKey + "_result"].value.join(',');
                }
              } else if (forms.form_type === 'radio') {
                forms[ownerBankKey + "_result"].value = forms[ownerBankKey + "_result"].value2 + '';
              }
            });
          });
        }
      });

      // 添加隐藏信息
      for (var i = 0; i < this.statusInfo.length; i++) {
        if (this.statusInfo[i].type == 1) {
          // 产权人
          for (var j = 0; j < this.statusInfo[i].owners.length; j++) {
            for (var k = 0; k < this.statusInfo[i].owners[j].forms.length; k++) {
              var form_item = this.statusInfo[i].owners[j].forms[k];
              if (form_item.form_type === "hide" && hideData[form_item.id]) {
                form_item.owner_result.value = hideData[form_item.id].join(",");
              }
            }
          }
        } else if (this.statusInfo[i].type == 2) {
          // 银行
          for (var _j3 = 0; _j3 < this.statusInfo[i].banks.length; _j3++) {
            for (var _k3 = 0; _k3 < this.statusInfo[i].banks[_j3].forms.length; _k3++) {
              var _form_item = this.statusInfo[i].banks[_j3].forms[_k3];
              if (_form_item.form_type === "hide" && hideData[_form_item.id]) {
                _form_item.bank_result.value = hideData[_form_item.id].join(",");
              }
            }
          }
        } else {
          for (var _j4 = 0; _j4 < this.statusInfo[i].forms.length; _j4++) {
            var _form_item2 = this.statusInfo[i].forms[_j4];
            if (_form_item2.form_type === "hide" && hideData[_form_item2.id]) {
              _form_item2.normal_result.value = hideData[_form_item2.id].join(",");
            }
          }
        }
      }
      return this.statusInfo;
    },
    // 字符串替换
    getString: function getString(str) {
      return str.replace(new RegExp(this.imgUrl, 'g'), '');
    },
    // 取消返回 详情页面
    handleCancel: function handleCancel() {
      var row = this.toolReviewRow;
      row.id = this.auditInfo.id;
      this.CHANGE_ROW(row);
      this.CHANGE_DRAWER_TYPE(1);
      // this.$router.push({
      //   name: 'ReviewDetails',
      //   query: { id: this.auditInfo.id, status: this.$route.query.status },
      // })
    },
    // 保存验证！ 是他填完
    getVerifyData: function getVerifyData() {
      var _this7 = this;
      var data = this.postData();
      var verifyType = true;
      var _loop2 = function _loop2() {
          var item = data[s];
          // 产权人类型
          if (item.type === 1 || item.type === 2) {
            // 1为产权人 2为银行资料
            var ownerBankKey = item.type == 1 ? "owner" : "bank";
            item[ownerBankKey + "s"].forEach(function (owners, ownInx) {
              var _loop3 = function _loop3() {
                  var ownersItem = owners.forms[i];
                  if (ownersItem.fill_in_type === 1 && ownersItem.parent_cause_show) {
                    // 多选
                    if (ownersItem.form_type === 'select') {
                      if (ownersItem[ownerBankKey + "_result"].value === '') {
                        _this7.$message({
                          type: 'error',
                          message: "\u7B2C".concat(ownInx + 1).concat(item.area_name + ownersItem.name, "\u672A\u9009\u62E9")
                        });
                        verifyType = false;
                        return {
                          v: void 0
                        };
                      }
                    }
                    // 单选
                    else if (ownersItem.form_type === 'radio') {
                      if (ownersItem[ownerBankKey + "_result"].value === '') {
                        _this7.$message({
                          type: 'error',
                          message: "\u7B2C".concat(ownInx + 1).concat(item.area_name + ownersItem.name, "\u672A\u9009\u62E9")
                        });
                        verifyType = false;
                        return {
                          v: void 0
                        };
                      } else {
                        // 证件类型
                        if (ownersItem.owner_type === 4) {
                          var index = ownersItem.children.findIndex(function (d) {
                            return d.id === ownersItem[ownerBankKey + "_result"].value2;
                          });
                          if (ownersItem.children[index].name === '身份证') {
                            var cardReg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/;
                            var HKReg = /[A-Za-z]{1}\d{6}\([0-9A-Za-z]\)/;
                            var TWReg = /[A-Z]{1}\d{9}/;
                            var AMReg = /[1|5|7]\d{6}\([0-9A-Za-z]\)/;

                            // 找出 证件号！ value
                            for (var n = 0; n < owners.forms.length; n++) {
                              if (owners.forms[n].owner_type === 3) {
                                if (!cardReg.test(owners.forms[n][ownerBankKey + "_result"].value) && !HKReg.test(owners.forms[n][ownerBankKey + "_result"].value) && !TWReg.test(owners.forms[n][ownerBankKey + "_result"].value) && !AMReg.test(owners.forms[n][ownerBankKey + "_result"].value)) {
                                  _this7.$message({
                                    type: 'error',
                                    message: "\u7B2C".concat(ownInx + 1).concat(item.area_name + owners.forms[n].name, "\u683C\u5F0F\u4E0D\u5BF9")
                                  });
                                  verifyType = false;
                                  return {
                                    v: void 0
                                  };
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    // 上传图片
                    else if (owners.forms[i].form_type === 'file') {
                      if (owners.forms[i][ownerBankKey + "_result"].value !== '') {
                        if (owners.forms[i][ownerBankKey + "_result"].value.split(',').length < owners.forms[i].img_number) {
                          _this7.$message({
                            type: 'error',
                            message: "\u7B2C".concat(ownInx + 1).concat(item.area_name + owners.forms[i].name, "\u56FE\u7247\u81F3\u5C11\u4F20").concat(owners.forms[i].img_number, "\u5F20")
                          });
                          verifyType = false;
                          return {
                            v: void 0
                          };
                        }
                      } else {
                        _this7.$message({
                          type: 'error',
                          message: "\u7B2C".concat(ownInx + 1).concat(item.area_name, "\u8BF7\u4E0A\u4F20").concat(owners.forms[i].name, "\u56FE\u7247\u81F3\u5C11\u4F20").concat(owners.forms[i].img_number, "\u5F20")
                        });
                        verifyType = false;
                        return {
                          v: void 0
                        };
                      }
                    }
                    // 输入框
                    else {
                      if (owners.forms[i][ownerBankKey + "_result"].value === '') {
                        _this7.$message({
                          type: 'error',
                          message: "\u7B2C".concat(ownInx + 1).concat(item.area_name + owners.forms[i].name, "\u4E0D\u80FD\u4E3A\u7A7A")
                        });
                        verifyType = false;
                        return {
                          v: void 0
                        };
                      } else {
                        var reg = /^1\d{10}$/;
                        // 手机号
                        if (ownersItem.owner_type === 2) {
                          if (!reg.test(ownersItem[ownerBankKey + "_result"].value)) {
                            _this7.$message({
                              type: 'error',
                              message: "\u7B2C".concat(ownInx + 1).concat(item.area_name + ownersItem.name, "\u683C\u5F0F\u4E0D\u5BF9")
                            });
                            verifyType = false;
                            return {
                              v: void 0
                            };
                          }
                        }
                      }
                    }
                  }
                },
                _ret2;
              for (var i = 0; i < owners.forms.length; i++) {
                _ret2 = _loop3();
                if (_ret2) return _ret2.v;
              }
            });
          } else {
            // 其他规则
            for (var i = 0; i < item.forms.length; i++) {
              if (item.forms[i].form_type === 'popup') {
                var newArr = item.forms[i].normal_result.auto_value;
                item.forms[i].normal_result.auto_value = typeof newArr === 'string' ? newArr : JSON.stringify(newArr);
              }
              if (item.forms[i].form_type === '意向户型') {
                var _newArr = item.forms[i].normal_result.auto_value;
                item.forms[i].normal_result.auto_value = typeof _newArr === 'string' ? _newArr : JSON.stringify(_newArr);
              }
              if (item.forms[i].fill_in_type === 1 && item.forms[i].parent_cause_show) {
                if (item.forms[i].form_type === 'select') {
                  if (item.forms[i].normal_result.value === '') {
                    _this7.$message({
                      type: 'error',
                      message: "".concat(item.forms[i].name, "\u672A\u9009\u62E9")
                    });
                    verifyType = false;
                    return {
                      v: void 0
                    };
                  }
                } else if (item.forms[i].form_type === 'radio') {
                  // 不显示就 不验证
                  if (!item.forms[i].is_show_tp) {
                    if (item.forms[i].normal_result.value === '') {
                      _this7.$message({
                        type: 'error',
                        message: "".concat(item.forms[i].name, "\u672A\u9009\u62E9")
                      });
                      verifyType = false;
                      return {
                        v: void 0
                      };
                    }
                  }
                } else if (item.forms[i].form_type === 'file') {
                  // 隐藏不判断 --- 不验证
                  if (!item.forms[i].is_show_tp) {
                    if (item.forms[i].normal_result.value !== '') {
                      if (item.forms[i].normal_result.value.split(',').length < item.forms[i].img_number) {
                        _this7.$message({
                          type: 'error',
                          message: "".concat(item.forms[i].name, "\u56FE\u7247\u81F3\u5C11\u4F20").concat(item.forms[i].img_number, "\u5F20")
                        });
                        verifyType = false;
                        return {
                          v: void 0
                        };
                      }
                    } else {
                      _this7.$message({
                        type: 'error',
                        message: "\u8BF7\u4E0A\u4F20".concat(item.forms[i].name, "\u56FE\u7247")
                      });
                      verifyType = false;
                      return {
                        v: void 0
                      };
                    }
                  }
                } else {
                  if (item.forms[i].normal_result.value === '' && item.forms[i].is_required === 0) {
                    _this7.$message({
                      type: 'error',
                      message: "".concat(item.forms[i].name, "\u4E0D\u80FD\u4E3A\u7A7A")
                    });
                    verifyType = false;
                    return {
                      v: void 0
                    };
                  }
                }
              }
            }
          }
        },
        _ret;
      for (var s = 0; s < data.length; s++) {
        _ret = _loop2();
        if (_ret) return _ret.v;
      }
      return verifyType;
    },
    // 保存数据
    handleSave: function handleSave() {
      var _this8 = this;
      if (!this.getVerifyData()) {
        return;
      }

      // 执行一下修改;
      // let types = this.$route.query.status
      var row = this.toolReviewRow;
      var types = this.toolReviewRow.types;
      if (types === 'status0') {
        AuditStatusEdit0({
          id: parseInt(row.id),
          data: this.postData(),
          collect: this.collect,
          collect_room_cate: this.collect_room_cate
        }).then(function (res) {
          if (res.status === 200) {
            _this8.$message({
              type: 'success',
              message: res.data.msg
            });
          }
        });
      } else if (types === 'status1') {
        AuditStatusEdit1({
          id: parseInt(row.id),
          data: this.postData(),
          collect: this.collect,
          collect_room_cate: this.collect_room_cate
        }).then(function (res) {
          if (res.status === 200) {
            _this8.$message({
              type: 'success',
              message: res.data.msg
            });
          }
        });
      } else if (types === 'status2') {
        AuditStatusEdit2({
          id: parseInt(row.id),
          data: this.postData(),
          collect: this.collect,
          collect_room_cate: this.collect_room_cate
        }).then(function (res) {
          if (res.status === 200) {
            _this8.$message({
              type: 'success',
              message: res.data.msg
            });
          }
        });
      }
    },
    /**
     * 父级影响显示隐藏
     * @param parent_id  当前id（父级）
     * @param parent_values 当前选项ids
     * @param isShowParent 是否开始判断父级隐藏
     * @param ownerBankIndex 产权人下标
     * @param fromItem 是否是单选传的所有数据
     */
    doShowParent: function doShowParent(isShowParent, parent_id, parent_values, ownerBankIndex, fromItem) {
      var _this9 = this;
      if (isShowParent) {
        var forms = JSON.parse(JSON.stringify(this.statusInfo));
        var ids = [];
        if (ownerBankIndex || ownerBankIndex === 0) {
          for (var i = 0; i < forms.length; i++) {
            // 产权人或者银行资料
            if (forms[i].type === 1) {
              // 1为产权人 2为银行资料
              var ownerBankKey = forms[i].type == 1 ? "owner" : "bank";
              for (var a = 0; a < forms[i][ownerBankKey + "s"][ownerBankIndex].forms.length; a++) {
                var item = forms[i][ownerBankKey + "s"][ownerBankIndex].forms[a];
                if (parent_id === item.parent_pid && item.parent_pid !== 0) {
                  var parent_ids = [];
                  if (item.parent_id) {
                    parent_ids = item.parent_id.split(",");
                  }
                  var parent_cause_show = false;
                  for (var c = 0; c < parent_ids.length; c++) {
                    if (parent_values.indexOf(parent_ids[c]) >= 0) {
                      parent_cause_show = true;
                    }
                  }
                  // 隐藏时清空之前的值
                  if (!parent_cause_show) {
                    item.normal_result.value = "";
                    item[ownerBankKey + "_result"].value = "";
                    item.normal_result.value2 = "";
                    item[ownerBankKey + "_result"].value2 = "";
                    ids.push(item.id);
                  }
                  item.parent_cause_show = parent_cause_show;
                } else if (item.parent_pid === 0) {
                  item.parent_cause_show = true;
                }
                forms[i][ownerBankKey + "s"][ownerBankIndex].forms[a] = item;
              }
            }
          }
        } else {
          for (var _i4 = 0; _i4 < forms.length; _i4++) {
            // 产权人或者银行资料
            if (forms[_i4].type === 1 || forms[_i4].type === 2) {
              // 1为产权人 2为银行资料
              var _ownerBankKey = forms[_i4].type == 1 ? "owner" : "bank";
              for (var l = 0; l < forms[_i4][_ownerBankKey + "s"].length; l++) {
                for (var _a2 = 0; _a2 < forms[_i4][_ownerBankKey + "s"][l].forms.length; _a2++) {
                  var _item2 = forms[_i4][_ownerBankKey + "s"][l].forms[_a2];
                  if (parent_id === _item2.parent_pid && _item2.parent_pid !== 0) {
                    var _parent_ids = [];
                    if (_item2.parent_id) {
                      _parent_ids = _item2.parent_id.split(",");
                    }
                    var _parent_cause_show = false;
                    for (var _c = 0; _c < _parent_ids.length; _c++) {
                      if (parent_values.indexOf(_parent_ids[_c]) >= 0) {
                        _parent_cause_show = true;
                      }
                    }
                    // 隐藏时清空之前的值
                    if (!_parent_cause_show) {
                      _item2.normal_result.value = "";
                      _item2[_ownerBankKey + "_result"].value = "";
                      _item2.normal_result.value2 = "";
                      _item2[_ownerBankKey + "_result"].value2 = "";
                      ids.push(_item2.id);
                    }
                    _item2.parent_cause_show = _parent_cause_show;
                  } else if (_item2.parent_pid === 0) {
                    _item2.parent_cause_show = true;
                  }
                  forms[_i4][_ownerBankKey + "s"][l].forms[_a2] = _item2;
                }
              }
            }
            // 其他类型
            else {
              for (var _a3 = 0; _a3 < forms[_i4].forms.length; _a3++) {
                var _item3 = forms[_i4].forms[_a3];
                if (parent_id === _item3.parent_pid && _item3.parent_pid !== 0) {
                  var _parent_ids2 = [];
                  if (_item3.parent_id) {
                    _parent_ids2 = _item3.parent_id.split(",");
                  }
                  var _parent_cause_show2 = false;
                  for (var _c2 = 0; _c2 < _parent_ids2.length; _c2++) {
                    if (parent_values.indexOf(_parent_ids2[_c2]) >= 0) {
                      _parent_cause_show2 = true;
                    }
                  }
                  // 隐藏时清空之前的值
                  if (!_parent_cause_show2) {
                    _item3.normal_result.value = "";
                    _item3.normal_result.value2 = "";
                    ids.push(_item3.id);
                  }
                  _item3.parent_cause_show = _parent_cause_show2;
                } else if (_item3.parent_pid === 0) {
                  _item3.parent_cause_show = true;
                }
                forms[_i4].forms[_a3] = _item3;
              }
            }
          }
        }
        this.statusInfo = JSON.parse(JSON.stringify(forms));
        if (ids.length) this.doShowParentChild(ids);
        if (fromItem) {
          this.handleRadio(fromItem.down_index, fromItem.owner_type, fromItem.normal_result.value2);
        }
      } else {
        var formData = JSON.parse(JSON.stringify(this.statusInfo));
        formData.forEach(function (item) {
          if (item.type != 1 && item.type != 2) {
            item.forms.forEach(function (_item) {
              var selectId = [];
              if (_item.form_type == '置业顾问') {
                // 置业顾问
                if (_item.normal_result.value) {
                  selectId = _item.normal_result.value.toString().split(',');
                } else if (_item.value) {
                  selectId = _item.value.toString().split(',');
                } else if (_item.select_value == 1) {
                  // 默认选择
                  selectId = _item.admins[0].id.toString().split(',');
                }
                _this9.doShowParent(true, _item.id, selectId);
              } else if (_item.form_type == 'radio' || _item.form_type == 'select') {
                // 单选 || 多选
                if (_item.normal_result.value) {
                  selectId = _item.normal_result.value.toString().split(',');
                } else if (_item.value) {
                  selectId = _item.value.toString().split(',');
                } else if (_item.select_value == 1) {
                  if (_item.form_type == 'radio') {
                    selectId = _item.children[0].id.toString().split(',');
                  } else {
                    _item.normal_result.value = selectId.join(',');
                  }
                }
                _this9.doShowParent(true, _item.id, selectId);
              }
            });
          } else if (item.type == 1) {
            // 1为产权人 2为银行资料
            var _ownerBankKey2 = item.type == 1 ? "owner" : "bank";
            // 产权人或者银行资料，多个
            for (var _l = 0; _l < item[_ownerBankKey2 + "s"].length; _l++) {
              // 问题
              for (var _a4 = 0; _a4 < item[_ownerBankKey2 + "s"][_l].forms.length; _a4++) {
                var _item = item[_ownerBankKey2 + "s"][_l].forms[_a4];
                var selectId = [];
                if (_item.form_type == '置业顾问') {
                  // 置业顾问
                  if (_item[_ownerBankKey2 + "_result"].value) {
                    selectId = _item[_ownerBankKey2 + "_result"].value.toString().split(',');
                  } else if (_item.value) {
                    selectId = _item.value.toString().split(',');
                  } else if (_item.select_value == 1) {
                    // 默认选择
                    selectId = _item.admins[0].id.toString().split(',');
                  }
                  _this9.doShowParent(true, _item.id, selectId, _l);
                } else if (_item.form_type == 'radio' || _item.form_type == 'select') {
                  // 单选 || 多选
                  if (_item[_ownerBankKey2 + "_result"].value) {
                    selectId = _item[_ownerBankKey2 + "_result"].value.toString().split(',');
                  } else if (_item.value) {
                    selectId = _item.value.toString().split(',');
                  } else if (_item.select_value == 1) {
                    if (_item.form_type == 'radio') {
                      selectId = _item.children[0].id.toString().split(',');
                    } else {
                      _item[_ownerBankKey2 + "_result"].value = selectId.join(',');
                    }
                  }
                  _this9.doShowParent(true, _item.id, selectId, _l);
                }
              }
            }
          }
        });
      }
    },
    onRoomList: function onRoomList(limit) {
      var forms = JSON.parse(JSON.stringify(this.statusInfo));
      forms === null || forms === void 0 || forms.forEach(function (form) {
        // 遍历表单
        if (form.type === 0) {
          var _form$forms;
          // 普通表单
          (_form$forms = form.forms) === null || _form$forms === void 0 || _form$forms.forEach(function (_item) {
            // 遍历表单问题项
            if (_item.form_type === 'popup') {
              // 意向房源
              _item.hidde = !limit;
              _item.is_required = 1;
            } else if (_item.form_type === '意向户型') {
              _item.hidde = !!limit;
              _item.is_required = 1;
            }
          });
        }
      });
      this.statusInfo = forms;
    },
    handlePopup: function handlePopup(id, data) {
      var _this10 = this;
      var forms = JSON.parse(JSON.stringify(this.statusInfo));
      forms === null || forms === void 0 || forms.forEach(function (form) {
        // 遍历表单
        if (form.type === 0) {
          var _form$forms2;
          // 普通表单
          (_form$forms2 = form.forms) === null || _form$forms2 === void 0 || _form$forms2.forEach(function (formItem) {
            // 遍历表单问题项
            if (formItem.form_type === 'popup') {
              // 意向房源
              if (formItem.id === id) {
                var _data$map;
                // 当前表单
                _this10.collect = JSON.stringify(data);
                formItem.normal_result.auto_value = JSON.stringify(data); // 修改
                formItem.normal_result.value = data === null || data === void 0 || (_data$map = data.map(function (item) {
                  return item.room;
                })) === null || _data$map === void 0 ? void 0 : _data$map.join(); // 修改
              }
            }
          });
        }
      });
      this.statusInfo = forms;
    },
    handleHouse: function handleHouse(id, data) {
      var _this11 = this;
      var forms = JSON.parse(JSON.stringify(this.statusInfo));
      forms === null || forms === void 0 || forms.forEach(function (form) {
        // 遍历表单
        if (form.type === 0) {
          var _form$forms3;
          // 普通表单
          (_form$forms3 = form.forms) === null || _form$forms3 === void 0 || _form$forms3.forEach(function (formItem) {
            // 遍历表单问题项
            if (formItem.form_type === '意向户型') {
              // 意向房源
              if (formItem.id === id) {
                var _data$map2;
                // 当前表单
                _this11.collect_room_cate = JSON.stringify(data);
                formItem.normal_result.auto_value = JSON.stringify(data); // 修改
                formItem.normal_result.value = data === null || data === void 0 || (_data$map2 = data.map(function (item) {
                  return item.name;
                })) === null || _data$map2 === void 0 ? void 0 : _data$map2.join(); // 修改
              }
            }
          });
        }
      });
      this.statusInfo = forms;
    },
    // 根据父级id找到旗下的所有子级，包括子级的子级
    doShowParentChild: function doShowParentChild(parent_ids) {
      var forms = JSON.parse(JSON.stringify(this.statusInfo));
      var ids = [];
      for (var i = 0; i < forms.length; i++) {
        // 产权人或者银行资料
        if (forms[i].type === 1 || forms[i].type === 2) {
          // 1为产权人 2为银行资料
          var ownerBankKey = forms[i].type == 1 ? "owner" : "bank";
          for (var l = 0; l < forms[i][ownerBankKey + "s"].length; l++) {
            for (var a = 0; a < forms[i][ownerBankKey + "s"][l].forms.length; a++) {
              var item = forms[i][ownerBankKey + "s"][l].forms[a];
              if (parent_ids.indexOf(item.parent_pid) >= 0 && item.parent_pid !== 0 && item.parent_cause_show) {
                item.parent_cause_show = false;
                item.normal_result.value = "";
                item[ownerBankKey + "_result"].value = "";
                item.normal_result.value2 = "";
                item[ownerBankKey + "_result"].value2 = "";
                ids.push(item.id);
                forms[i][ownerBankKey + "s"][l].forms[a] = item;
              }
            }
          }
        } else {
          for (var _a5 = 0; _a5 < forms[i].forms.length; _a5++) {
            var _item4 = forms[i].forms[_a5];
            if (parent_ids.indexOf(_item4.parent_pid) >= 0 && _item4.parent_pid !== 0 && _item4.parent_cause_show) {
              _item4.parent_cause_show = false;
              _item4.normal_result.value = "";
              _item4.normal_result.value2 = "";
              ids.push(_item4.id);
              forms[i].forms[_a5] = _item4;
            }
          }
        }
      }
      this.statusInfo = JSON.parse(JSON.stringify(forms));
      if (ids.length) this.doShowParentChild(ids);
    },
    // 返回文件名称
    fileName: function fileName(str) {
      var splits = str.split('/');
      var name = splits[splits.length - 1];
      var suffix = name.split('.');
      suffix = suffix[suffix.length - 1];
      var images = ["bmp", "jpg", "jpeg", "png", "tif", "gif", "pcx", "tga", "exif", "fpx", "svg", "dxf", "ufo", "eps", "raw", "WMF", "webp", "avif", "apng"];
      return images.indexOf(suffix) < 0 ? name : "";
    },
    // 删除因为上传的是文件而不显示的图片
    removeUploadImage: function removeUploadImage() {
      var images = document.getElementsByClassName("el-upload-list__item-thumbnail");
      for (var i = 0; i < images.length; i++) {
        if (this.fileName(images[i].src)) {
          images[i].parentNode.style["padding-left"] = "10px";
          images[i].remove();
        }
      }
    },
    /**
     * 配偶选择是产权人增加一个产权人
     * @param formIndex
     * @param ownerIndex
     */
    doSpouseAddOwner: function doSpouseAddOwner(formIndex, ownerIndex) {
      var owners = JSON.parse(JSON.stringify(this.statusInfo[formIndex].owners));
      var data = {};
      // 产权人信息
      var owner_type1 = [1, 2, 3, 4, 5, 29];
      // 配偶信息
      var owner_type2 = [41, 42, 43, 44, 45, 69];
      // 不被清空的信息
      var owner_type3 = [40, 46, 48];
      var owner = JSON.parse(JSON.stringify(this.statusInfo[formIndex].owners[ownerIndex]));
      owner.id = 0;
      // 清空其中的字段
      owner.forms = owner.forms.map(function (item) {
        delete item.select_text;
        delete item.verify_error;
        // delete item.owner_result.disabled
        item.normal_result.form_id = 0;
        item.normal_result.id = 0;
        // item.normal_result.value = ''
        item.owner_result.form_id = 0;
        // item.owner_result.value = ''
        item.owner_result.id = 0;
        if (item.admins && item.admins.length) {
          item.admins = item.admins.map(function (_item) {
            delete _item.checked;
            return _item;
          });
        } else if (item.children && item.children.length) {
          item.children = item.children.map(function (_item) {
            delete _item.checked;
            return _item;
          });
        }
        if (owner_type1.indexOf(item.owner_type) >= 0 || owner_type2.indexOf(item.owner_type) >= 0 || owner_type3.indexOf(item.owner_type) >= 0) {
          data[item.owner_type + "_1"] = JSON.parse(JSON.stringify(item));
        } else {
          data["item_id_" + item.id].push(JSON.parse(JSON.stringify(item)));
        }
        return item;
      });
      // 开始进行owner_type分类，1和41调换
      for (var k in data) {
        // 存在owner_type1
        if (owner_type1.indexOf(parseInt(k)) >= 0) {
          // 存在owner_type2
          if (data[parseInt(k) + 40 + "_1"]) {
            var newData1 = JSON.parse(JSON.stringify(data[k]));
            var newData2 = JSON.parse(JSON.stringify(data[parseInt(k) + 40 + "_1"]));
            // 如果是证件类型这种单选，先获取owner_type1中一个选中的值再和owner_type2一个中的children比较，并算出owner_type2一个中的值，以此类推
            if (data[parseInt(k) + 40 + "_1"].form_type === "radio") {
              var childrenName1 = "",
                childrenName2 = "";
              for (var i = 0; i < newData1.children.length; i++) {
                if (newData1.owner_result.value == newData1.children[i].id) {
                  childrenName1 = newData1.children[i].name;
                }
              }
              for (var _i5 = 0; _i5 < newData2.children.length; _i5++) {
                if (newData2.owner_result.value == newData2.children[_i5].id) {
                  childrenName2 = newData2.children[_i5].name;
                }
              }
              for (var _i6 = 0; _i6 < newData1.children.length; _i6++) {
                if (newData1.children[_i6].name == childrenName2) {
                  data[k].owner_result.value = newData1.children[_i6].id + "";
                  data[k].normal_result.value = newData1.children[_i6].id + "";
                  data[k].value = newData1.children[_i6].id + "";
                  data[k].children[_i6].checked = true;
                  data[k].select_text = newData1.children[_i6].name;
                }
              }
              for (var _i7 = 0; _i7 < newData2.children.length; _i7++) {
                if (newData2.children[_i7].name == childrenName1) {
                  data[parseInt(k) + 40 + "_1"].owner_result.value = newData2.children[_i7].id + "";
                  data[parseInt(k) + 40 + "_1"].normal_result.value = newData2.children[_i7].id + "";
                  data[parseInt(k) + 40 + "_1"].value = newData2.children[_i7].id + "";
                  data[parseInt(k) + 40 + "_1"].children[_i7].checked = true;
                  data[parseInt(k) + 40 + "_1"].select_text = newData2.children[_i7].name;
                }
              }
            }
            // 其他类型就直接赋值，将owner_type1中的值赋到owner_type2中，以此类推
            else {
              data[parseInt(k) + 40 + "_1"].owner_result.value = newData1.owner_result.value;
              data[parseInt(k) + 40 + "_1"].owner_result.auto_value = newData1.owner_result.auto_value;
              data[parseInt(k) + 40 + "_1"].normal_result.value = newData1.normal_result.value;
              data[parseInt(k) + 40 + "_1"].value = newData1.value;
              data[k].owner_result.value = newData2.owner_result.value;
              data[k].owner_result.auto_value = newData2.owner_result.auto_value;
              data[k].normal_result.value = newData2.normal_result.value;
              data[k].value = newData2.value;
            }
          }
          // 不存在owner_type2
          else {
            delete data[k].owner_result.disabled;
            data[k].normal_result.value = '';
            data[k].owner_result.value = '';
          }
        }
        // 存在owner_type2，不存在owner_type1
        else if (owner_type2.indexOf(parseInt(k)) >= 0 && !data[parseInt(k) - 40 + "_1"]) {
          delete data[k].owner_result.disabled;
          data[k].normal_result.value = '';
          data[k].owner_result.value = '';
        }
        // 是不被清空的owner_type
        else if (owner_type3.indexOf(parseInt(k)) >= 0) {
          // 如果是 是否是产权人或者婚姻状态
          if (k == "46_1" || k == "40_1") {
            for (var _i8 = 0; _i8 < data[k].children.length; _i8++) {
              if (data[k].owner_result.value == data[k].children[_i8].id) {
                data[k].children[_i8].checked = true;
                data[k].select_text = data[k].children[_i8].name;
              }
            }
          } else if (k == "48_1") {}
        }
        // 其他类型（不包括婚姻证明）
        else if (owner_type1.indexOf(parseInt(k)) === -1 && owner_type2.indexOf(parseInt(k)) === -1 && owner_type3.indexOf(parseInt(k)) === -1) {
          delete data[k].owner_result.disabled;
          data[k].normal_result.value = '';
          data[k].owner_result.value = '';
        }
      }
      // 将分类好的数据赋值到新的数据中
      owner.forms = [];
      for (var _k4 in data) {
        owner.forms.push(data[_k4]);
      }
      var isOk = true;
      for (var _i9 = 0; _i9 < owners.length; _i9++) {
        for (var l = 0; l < owners[_i9].forms.length; l++) {
          // 判断身份证号码是否一致，如果一致表示重复
          if (owners[_i9].forms[l].owner_type == 3 && owners[_i9].forms[l].owner_result.value == data["3_1"].owner_result.value) {
            isOk = false;
            break;
          }
        }
        if (!isOk) break;
      }
      if (!isOk) return;
      owners.push(owner);
      this.statusInfo[formIndex].owners = JSON.parse(JSON.stringify(owners));
    }
  }
};