import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { type, list } from './api';
var scrollTime = null;
var h = 0;
export default {
  name: 'IntentionRoom',
  props: {
    showPopup: Boolean,
    title: {
      default: '意向房源'
    },
    // 已选中数组
    selectData: {
      type: [Array, String],
      default: function _default() {
        return [];
      }
    },
    // 意向选择数量上限
    select_number: {
      type: Number,
      default: 1
    }
  },
  watch: {
    // 监听显示隐藏 可以减少页面通过ref获取dom对象
    showPopup: {
      handler: function handler(newVal, odlVal) {
        // 开启
        if (newVal) {
          this.show();
        }
        // 开启又关闭
        if (!newVal && odlVal) {
          this.close();
        }
      },
      immediate: true // 首次
    },
    selectData: {
      handler: function handler(newVal, odlVal) {
        var d = newVal;
        // 如果是字符串
        if (typeof newVal === 'string') {
          try {
            // 不去报错 影响操作
            d = JSON.parse(newVal);
          } catch (e) {
            d = [];
          }
        }
        if (!d) d = [];
        if (d && typeof d === 'string') {
          d = JSON.parse(d);
        }
        this.selectList = d;
        this.checkRoomList = d;
        if (d.length) {
          // 有选择数据通过提交回填
          this.toStr(d);
        }
      },
      immediate: true // 首次
    }
  },
  data: function data() {
    return {
      showIntention: false,
      buildTypeList: [],
      // 楼栋类型 车位 房源...
      buildTypeIndex: 1,
      buildList: [],
      // 楼栋列表
      unitList: [],
      // 单元列表
      roomList: [],
      // 房间列表
      areaList: [],
      // 区域列表
      checkRoomList: [],
      // 选中的房间列表
      selectList: [],
      // 选择显示列表
      page: 1,
      // 分页
      loading: false,
      count: false
    };
  },
  created: function created() {
    this.getBuildList();
  },
  methods: {
    toTop: function toTop() {
      h = 0;
      this.$refs.roomlist.scrollTop = 0;
    },
    // 调整位置
    adjust: function adjust(e) {
      var pos = e.pos,
        index = e.index;
      var index2 = pos === 'up' ? index - 1 : index + 1;

      // 拷贝一下数据 避免vue页面不更新
      var arr = JSON.parse(JSON.stringify(this.selectList));

      // 交换元素
      var temp = arr[index];
      arr[index] = arr[index2];
      arr[index2] = temp;
      this.selectList = arr; // 重设数据
      this.toStr(arr);
    },
    setData: function setData(data, callback) {
      var _this = this;
      Object.keys(data).forEach(function (key) {
        _this[key] = data[key];
      });
      callback && callback();
    },
    // 方法调用显示
    show: function show() {
      var _this2 = this;
      var l = this.$loading({
        lock: true,
        text: '加载房源数据中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getBuildList().then(function () {
        _this2.$nextTick(function () {
          _this2.showIntention = true;
          _this2.$emit('show');
        });
      }).finally(function () {
        l.close();
      });
    },
    // 方法调用关闭
    close: function close() {
      this.setData({
        showIntention: false
      });
      this.$emit('close');
    },
    /**
     * 获取楼栋列表
     * @param id 选中的楼栋类型id
     */
    getBuildList: function getBuildList() {
      var _this3 = this;
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      return new Promise(function (result, reject) {
        type({
          id: id
        }).then(function (result) {
          var _res$list;
          var res = result.data;
          _this3.$emit('limit', res.allCount);
          var intention = res.type || [];
          if (intention.length) {
            // 二次不返回 避免无数据时覆盖
            var activeIndex = _this3.buildTypeList.indexOf(function (item) {
              return item.active;
            });
            if (activeIndex === -1) activeIndex = 0; // 没有激活 默认激活第一个
            intention[activeIndex].active = true;
            _this3.setData({
              buildTypeList: intention,
              buildTypeIndex: intention[activeIndex].id
            });
          }

          // 没有列表 id为0没有数据 重新获取
          if (!((_res$list = res.list) !== null && _res$list !== void 0 && _res$list.length) && id === 0) {
            if (intention.length) _this3.getBuildList(intention[0].id);
            return;
          }
          _this3.handleBuile(res.list);
        }).finally(function () {
          result();
        });
      });
    },
    // 处理楼栋数据
    handleBuile: function handleBuile() {
      var _this4 = this;
      var list = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var _this$getBuildActive = this.getBuildActive(),
        buildIndex = _this$getBuildActive.buildIndex,
        childrenIndex = _this$getBuildActive.childrenIndex,
        childrenIndexs = _this$getBuildActive.childrenIndexs;
      var buildList = [],
        unitList = [],
        areaList = [];
      list.map(function (item, index) {
        var _item$list;
        // 没有单元数据 给个空 防报错
        if (!(item !== null && item !== void 0 && (_item$list = item.list) !== null && _item$list !== void 0 && _item$list.length)) item.list = [];
        // 不是空单元 但没名字 给默认
        if (!item.name) item.name = '无楼栋';
        item.active = buildIndex === index;
        // 单元处理
        if (buildIndex === index) {
          item.list.map(function (it, idx) {
            it.active = idx === childrenIndex;
            // 区域处理
            if (idx === childrenIndex) {
              // 不是空区域 但没名字
              if (!it.name) it.name = '无单元';
              it.list.map(function (i, idxs) {
                if (!i.name) i.name = '无区域';
                i.active = childrenIndexs === idxs;
              });
              var be2 = it.list.filter(function (f) {
                return f.name != '无区域';
              });
              areaList = it.list;
              // 全部没有名字即 空区域  置空数据
              if (!be2.length) areaList = [];
            }
          });
          var be = item.list.filter(function (f) {
            return f.name !== '无单元';
          });
          unitList = item.list;
          // 全部没有名字即 空单元  置空数据不显示
          if (!be.length) unitList = [];
        }
        // 空楼栋不添加
        buildList.push(item);
      });

      // 全部没有名字即 空楼栋  置空数据不显示
      var k = list.filter(function (f) {
        return f.name != '无楼栋';
      });
      if (!k.length) buildList = [];
      this.setData({
        buildList: buildList,
        unitList: unitList,
        areaList: areaList,
        page: 1
      }, function () {
        _this4.getRoomList();
      });
    },
    // 楼栋激活的数据
    getBuildActive: function getBuildActive() {
      var _b$buildIndex, _b$buildIndex2;
      var b = this.buildList,
        buildIndex = 0,
        childrenIndex = 0,
        childrenIndexs = 0;
      b.map(function (item, index) {
        if (item.active) buildIndex = index;
      });
      (_b$buildIndex = b[buildIndex]) === null || _b$buildIndex === void 0 || _b$buildIndex.list.map(function (item, index) {
        if (item.active) childrenIndex = index;
      });
      (_b$buildIndex2 = b[buildIndex]) === null || _b$buildIndex2 === void 0 || (_b$buildIndex2 = _b$buildIndex2.list[childrenIndex]) === null || _b$buildIndex2 === void 0 || _b$buildIndex2.list.map(function (item, index) {
        if (item.active) childrenIndexs = index;
      });
      // 返回找到的下标 默认0
      return {
        buildIndex: buildIndex,
        childrenIndex: childrenIndex,
        childrenIndexs: childrenIndexs
      };
    },
    // 获取房间列表
    getRoomList: function getRoomList() {
      var _this$buildList$build,
        _this$unitList$childr,
        _this5 = this;
      var _this$getBuildActive2 = this.getBuildActive(),
        buildIndex = _this$getBuildActive2.buildIndex,
        childrenIndex = _this$getBuildActive2.childrenIndex,
        childrenIndexs = _this$getBuildActive2.childrenIndexs;
      var params = {
        build_id: ((_this$buildList$build = this.buildList[buildIndex]) === null || _this$buildList$build === void 0 ? void 0 : _this$buildList$build.id) || '',
        unit_id: ((_this$unitList$childr = this.unitList[childrenIndex]) === null || _this$unitList$childr === void 0 ? void 0 : _this$unitList$childr.id) || '',
        page: this.page // 分页
      };
      if (this.buildTypeIndex !== 1) {
        var _this$areaList$childr;
        // 楼栋不加区域
        params.floor_id = ((_this$areaList$childr = this.areaList[childrenIndexs]) === null || _this$areaList$childr === void 0 ? void 0 : _this$areaList$childr.id) || '';
      }
      this.loading = true;
      list(params).then(function (result) {
        var res = result.data;
        var roomList = _this5.handleRoom(res.list ? JSON.parse(res.list) : []);
        _this5.setData({
          roomList: roomList
        });
      }).finally(function () {
        _this5.loading = false;
      });
    },
    // 处理房源数据
    handleRoom: function handleRoom() {
      var _this6 = this;
      var resRoomList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      // 没需要改的数据返回原数据
      if (resRoomList.length === 0) {
        this.count = true; // 到达总数 不请求了
        return this.roomList;
      } else {
        this.count = false;
      }
      var roomList = null;
      var area = this.areaList.length ? JSON.parse(JSON.stringify(this.areaList)) : [{
        id: 0
      }];

      // 分区
      roomList = area.map(function (item) {
        item.list = resRoomList.filter(function (f) {
          if (f.fid === item.id || item.id === 0) return f;
        });
        return item;
      });
      if (this.page > 1) {
        // 分页合并数据
        roomList = this.roomList.concat(roomList);
      }
      // 数据选中
      roomList.forEach(function (item) {
        item.list.forEach(function (it) {
          it.active = _this6.checkRoomList.findIndex(function (c) {
            return c.id === it.id;
          }) !== -1;
        });
      });
      return this.dataCheckIndex(roomList);
    },
    // 数据选中添加下标
    dataCheckIndex: function dataCheckIndex() {
      var _this7 = this;
      var roomList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      // 数据选中
      roomList === null || roomList === void 0 || roomList.forEach(function (item) {
        item.list.forEach(function (it) {
          var roomInfo = _this7.checkRoomList.findIndex(function (c) {
            return c.id === it.id;
          });
          if (roomInfo > -1) {
            it.index = roomInfo + 1;
          }
        });
      });
      return roomList;
    },
    scrollLoadingData: function scrollLoadingData(e) {
      var _this8 = this;
      // 往下滚动
      if (e.target.scrollHeight >= h && !this.count) {
        h = e.target.scrollHeight;
        clearTimeout(scrollTime);
        scrollTime = setTimeout(function () {
          _this8.setData({
            page: _this8.page + 1
          }, function () {
            return _this8.getRoomList();
          });
        }, 100);
      }
    },
    // 提交意向单
    submitIntention: function submitIntention() {
      this.toStr(this.checkRoomList);
      this.close();
    },
    toStr: function toStr() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      if (!data) return;
      data.forEach(function (item) {
        Object.keys(item).forEach(function (key) {
          // 不为不处理的
          if (!['id', 'active'].includes(key)) {
            // 强制转字符
            item[key] = String(item[key]);
          }
        });
      });
      this.$emit('submit', data);
    },
    // 设置建筑类型
    setBuildType: function setBuildType(e) {
      var index = e.index;
      var buildTypeList = this.buildTypeList.map(function (item, idx) {
        item.active = index === idx;
        return item;
      });
      this.setData({
        buildTypeList: buildTypeList,
        buildTypeIndex: this.buildTypeList[index].id,
        roomList: [],
        page: 1
      });
      this.toTop();
      this.getBuildList(this.buildTypeList[index].id);
    },
    // 设置楼栋
    setBuildData: function setBuildData(e) {
      var index = e.index,
        indexs = e.indexs,
        childindex = e.childindex;
      var b = this.buildList;

      // 设置楼栋 对单元、区域重置
      if (typeof index === 'number') {
        indexs = 0;
        childindex = 0;
      }
      // 设置单元 对区域重置
      if (typeof indexs === 'number') {
        childindex = 0;
      }
      b.forEach(function (item, i) {
        // 楼栋
        if (typeof index === 'number') item.active = i === index;
        if (typeof indexs === 'number') {
          // 单元
          item.list.forEach(function (items, idxs) {
            items.active = idxs === indexs;
            // 区域
            items.list.forEach(function (it, idx) {
              it.active = idx === indexs;
            });
          });
        }
        if (typeof childindex === 'number') {
          // 单元
          item.list.forEach(function (items, idxs) {
            // 区域
            items.list.forEach(function (it, idx) {
              it.active = idx === childindex;
            });
          });
        }
      });
      this.handleBuile(b);
    },
    // 选择房间
    checkRoom: function checkRoom(e) {
      var _this9 = this;
      var id = e.item.id;
      var roomList = JSON.parse(JSON.stringify(this.roomList)); // 拷贝
      var current = {};
      for (var i = 0; i < roomList.length; i++) {
        var _roomList$i$list;
        (_roomList$i$list = roomList[i].list) === null || _roomList$i$list === void 0 || _roomList$i$list.forEach(function (ai) {
          if (!ai.active) ai.active = false;
          if (ai.id === id) {
            ai.active = !ai.active;
            current = _objectSpread(_objectSpread({}, ai), {}, {
              type: _this9.buildTypeList.find(function (item) {
                return item.active;
              }).name // 激活类型
            });
          }
        });
      }
      var list = this.checkRoomList,
        checkRoomList = [];

      // 没数据直接添加
      if (!list.length && current.active) {
        checkRoomList.push(current);
      } else {
        if (current.active) {
          // 添加 直接合并
          checkRoomList = list.concat([current]);
        } else {
          // 删除就过滤掉数据
          checkRoomList = list.filter(function (item) {
            return id != item.id;
          });
        }
      }
      // 如果 选择数 大于 配置数 阻止选择
      if (checkRoomList.length > this.select_number) {
        this.$message.error('选择已达上限');
        return;
      }
      this.setData({
        checkRoomList: checkRoomList
      }, function () {
        _this9.setData({
          roomList: _this9.dataCheckIndex(roomList)
        });
      });
    }
  }
};