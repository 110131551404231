import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import screenfull from '@/components/my-screen';
import { orderQueryTurn, orderStartLottery, orderStartRand } from '@/api/lottery';
import { debounce } from '@/utils/debounce';
import editFormatMixin from '@/views/editFormat/editFormatMixin';
import lotteryMixin from '@/views/tool/lotteryMixin';
export default {
  name: 'StartLotteryOrder',
  components: {
    screenfull: screenfull
  },
  mixins: [editFormatMixin, lotteryMixin],
  data: function data() {
    return {
      not_user_list: [],
      // 定时器
      timeId: null,
      // 摇号 规则
      rand: {},
      // 摇号列表
      lottery: [],
      h: 0,
      // 轮次信息
      list: {},
      // 摇号按钮
      startButton: false,
      // 下一轮摇号
      is_next: false,
      // 获取当前轮次
      roundNumber: 0,
      // 剩余摇号人数
      laveNumber: 0,
      // 判断本组是否有下一轮
      is_rand_next: false,
      // 根据个数改变样式
      heightClass: 'height10',
      // 滚动文字
      next_round_list: [],
      styleObject: {},
      //  防止多次点击停止
      stopLotteryButton: false,
      is_stay_user: 0,
      classOptionC: {
        step: 0.2,
        // 数值越大速度滚动越快
        limitMoveNum: 3,
        // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false,
        // 是否开启鼠标悬停stop
        direction: 1,
        // 0向下 1向上 2向左 3向右
        openWatch: true,
        // 开启数据实时监控刷新dom
        singleHeight: 0,
        // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0,
        // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000,
        // 单步运动停止的时间(默认值1000ms)
        autoPlay: true // 自动播放
      }
    };
  },
  // 初始化
  created: function created() {
    this.initStartLottery();
  },
  watch: {
    // 监听轮次变化，刷新副屏轮次数据
    roundNumber: function roundNumber(newData, oldData) {
      if (this.rand.is_sub_screen === 1) {
        var round_nums = [];
        for (var i = 0; i < this.editFormatList.sub_screenDatas.length; i++) {
          for (var a = 0; a < this.editFormatList.sub_screenDatas[i].rounds.length; a++) {
            if (round_nums.indexOf(newData - this.editFormatList.sub_screenDatas[i].rounds[a]) === -1) {
              round_nums.push(newData - this.editFormatList.sub_screenDatas[i].rounds[a]);
            }
          }
        }
        if (round_nums.length) this.doGetRound(round_nums, newData);
      }
    }
  },
  methods: {
    // 身份证
    getCard: function getCard(card, show_card) {
      // 隐藏身份证中间
      if (show_card === 1) {
        if (card.length > 15) {
          return card.replace(/^(.{4})(?:\d+)(.{4})$/, '$1******$2');
        } else {
          return card.replace(/^(.{1})(?:\d+)(.{1})$/, '$1******$2');
        }
      }
      // 只显示最后六个号码
      else {
        return card.substring(card.length - 6);
      }
    },
    nickName: function nickName() {
      var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var names = str.split(';');
      var newName = '';
      names.forEach(function (name) {
        if (!!name) {
          if (name.length < 3) {
            newName = newName + name[0] + '*';
          }
          if (name.length > 2) {
            newName = newName + name[0] + new Array(name.length - 2).fill('*').join('') + name[name.length - 1];
          }
          if (names.length > 1) newName = newName + ';';
        }
      });
      return newName;
    },
    //  手机正则
    getTel: function getTel(tel) {
      if (tel != null) {
        var reg = /^(\d{3})\d{4}(\d{4})$/;
        return tel.replace(reg, '$1****$2');
      }
    },
    // 上一轮 跳转历史页面
    handleLastRound: function handleLastRound() {
      // 获取摇号结果
      var data = {
        id: this.rand.id,
        group_id: this.list.group_id,
        round: this.list.end ? this.list.max_round - 1 : this.list.max_round
      };
      this.$router.push({
        name: 'StartLotteryOrderHistory',
        query: data
      });
    },
    // 初始化摇号
    initStartLottery: function initStartLottery() {
      var _this = this;
      // 获取参数
      var query = JSON.parse(JSON.stringify(this.$route.query));
      var data = {
        id: query.id,
        group_id: query.group_id,
        round: query.round
      };

      // 查询摇号接口
      orderQueryTurn(data).then(function (res) {
        if (res.status === 200) {
          var _list$max_round_list;
          var _res$data = res.data,
            list = _res$data.list,
            rand = _res$data.list.rand;

          // 活动参数
          _this.list = list;

          // 摇号规则
          _this.rand = rand;
          _this.styleObject = {
            background: "url(".concat(_this.imgUrl + rand.logo, ")100%, no-repeat"),
            backgroundSize: 'cover',
            backgroundPosition: 'center, center',
            height: '100%'
          };

          // 本组是否结束
          _this.is_rand_next = list.end;

          // 获取未摇号人数
          _this.laveNumber = list.not_user_count;
          _this.is_stay_user = rand.is_stay_user;

          // 改变参数
          query.round = list.max_round === 0 ? list.max_round + 1 : list.max_round;

          // 更新路由
          _this.$router.push({
            path: _this.$route.path,
            query: query
          });

          // 下一轮客户
          _this.next_round_list = list.next_round_list;
          if (list.max_round === 0) {
            _this.getStartLottery();
          }
          if (query.round === list.max_round) {
            _this.is_next = true;
          } else {
            _this.is_next = false;
          }

          // 页面个数 高度
          if (rand.rand_count > 10) {
            _this.heightClass = 'height20';
          } else {
            _this.heightClass = 'height10';
          }

          // 获取当前轮次
          _this.roundNumber = list.max_round === 0 ? list.max_round + 1 : list.max_round;

          // 覆盖数据
          if (((_list$max_round_list = list.max_round_list) === null || _list$max_round_list === void 0 ? void 0 : _list$max_round_list.length) < rand.rand_count) {
            // 计算出来还差几个
            // let number = rand.rand_count -  list.max_round_list.length;
            //
            // for (let i = 0; i < number; i++) {
            // 	list.max_round_list.push({
            // 		name: '****',
            // 		code: '***',
            // 		tel: '***',
            // 		card: '***'
            // 	})
            // }
            _this.lottery = list.max_round_list;
          } else {
            _this.lottery = list.max_round_list;
          }
          _this.getRoundUser();
        }
      });
    },
    // 获取摇号用户
    getRoundUser: function getRoundUser() {
      var _this2 = this;
      // 没启用摇号滚动
      if (this.rand.row_rand_roll === 0) return;
      // 获取参数
      var query = JSON.parse(JSON.stringify(this.$route.query));
      var data = _objectSpread({}, query);
      orderStartRand(data).then(function (res) {
        var _res$data$list;
        _this2.not_user_list = ((_res$data$list = res.data.list) === null || _res$data$list === void 0 ? void 0 : _res$data$list.not_user_list) || [];
      });
    },
    handleStartLottery: debounce(function () {
      var _this3 = this,
        _this$not_user_list;
      if (this.getTimeOk > new Date().getTime() - 1000) return;
      this.getTimeOk = new Date().getTime();

      // 摇号结束
      if (this.list.end) {
        this.$confirm('本轮摇号已结束！', '提醒', {
          confirmButtonText: '确定',
          cancelButtonText: '返回首页',
          closeOnClickModal: false,
          closeOnPressEscape: false,
          showClose: false,
          type: 'warning'
        }).catch(function () {
          _this3.$router.push({
            name: 'Lottery',
            query: {
              id: _this3.$route.query.id
            }
          });
        });
        return;
      }
      // 关闭再开 避免多个定时器
      window.clearInterval(this.timeId);
      if (((_this$not_user_list = this.not_user_list) === null || _this$not_user_list === void 0 ? void 0 : _this$not_user_list.length) > 0) {
        this.timeId = setInterval(function () {
          _this3.lottery.forEach(function (item) {
            // 一个随机下标
            var randomIndex = Math.floor(Math.random() * _this3.not_user_list.length);
            var userData = _this3.nto_user_list[randomIndex];
            item.name = userData.name;
            item.tel = userData.tel;
            item.code = userData.code;
            item.card = userData.card;
          });
        }, 100);
      }

      // 点击以后就显示结束按钮
      this.startButton = true;
    }, 200, true),
    // 获取下一轮 客户
    getStartLottery: debounce(function () {
      var _this4 = this;
      if (this.getTimeOk > new Date().getTime() - 1000) {
        return false;
      } else {
        this.getTimeOk = new Date().getTime();
      }
      // 获取参数
      var query = JSON.parse(JSON.stringify(this.$route.query));
      this.stopLotteryButton = true;
      var data = {
        id: query.id,
        group_id: query.group_id,
        round: query.round
      };
      // 判断是否上一次完成
      if (this.lotterySetTimeout) {
        return;
      }
      var loading;
      // 超过800ms就显示遮罩层
      this.lotterySetTimeout = setTimeout(function () {
        loading = _this4.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      }, 800);
      orderStartLottery(data).then(function (res) {
        // 关闭定时器 停止摇号
        window.clearInterval(_this4.timeId);
        _this4.startButton = false;
        if (res.status === 200) {
          var _res$data2 = res.data,
            list = _res$data2.list,
            rand = _res$data2.list.rand;
          // 摇号规则
          _this4.rand = rand;

          // 活动参数
          _this4.list = list;

          //  当前组是否结束
          _this4.is_rand_next = list.end;

          // 摇号结束
          if (list.end) {
            _this4.$confirm('本轮摇号已结束！', '提醒', {
              confirmButtonText: '确定',
              cancelButtonText: '返回首页',
              closeOnClickModal: false,
              closeOnPressEscape: false,
              showClose: false,
              type: 'warning'
            }).then(function () {}).catch(function () {
              _this4.$router.push({
                name: 'Lottery',
                query: {
                  id: _this4.$route.query.id
                }
              });
            });
            return;
          }

          // 下一轮客户
          _this4.next_round_list = list.next_round_list;

          // 获取 未摇号人数
          _this4.laveNumber = list.not_user_count - list.this_round.length;
          _this4.is_stay_user = rand.is_stay_user;

          // 获取当前轮次  加+1
          _this4.roundNumber = list.max_round + 1;

          // 改变参数
          query.round = list.max_round + 1;

          // 更新路由
          _this4.$router.push({
            path: _this4.$route.path,
            query: query
          });

          // 更新路由
          _this4.$router.push({
            path: _this4.$route.path,
            query: query
          });

          // 覆盖数据
          if (list.this_round.length < rand.rand_count) {
            // 计算出来还差几个
            // let number = rand.rand_count -  list.this_round.length;
            //
            // for (let i = 0; i < number; i++) {
            // 	list.this_round.push({
            // 		name: '****',
            // 		code: '***',
            // 		tel: '***',
            // 		card: '***'
            // 	})
            // }
            _this4.lottery = list.this_round;
          } else {
            _this4.lottery = list.this_round;
          }
        }
        _this4.stopLotteryButton = false;
      }).finally(function (res) {
        var _loading;
        clearTimeout(_this4.lotterySetTimeout);
        _this4.lotterySetTimeout = null;
        (_loading = loading) === null || _loading === void 0 || _loading.close();
      });
    }, 200, true)
  }
};