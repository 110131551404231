var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", style: _vm.styleObject },
    [
      _c("screenfull"),
      _c(
        "div",
        { ref: "topHeight", staticClass: "topHeight" },
        [
          _c("project-title", {
            attrs: {
              innertext: _vm.rand.name,
              "p-color": _vm.editFormatList.name_color,
              "p-font": _vm.editFormatList.name_size,
              "p-position": _vm.editFormatList.name_position,
            },
          }),
          _c("project-rounds", {
            attrs: {
              innertext: "第" + _vm.roundNumber + "轮",
              "p-color": _vm.editFormatList.round_color,
              "p-font": _vm.editFormatList.round_size,
              "p-position": _vm.editFormatList.round_position,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "project-content",
            {
              attrs: {
                "s-position": _vm.editFormatList.card_position,
                "s-row-num": _vm.editFormatList.card_row_num,
                "top-height": _vm.tbHeight,
              },
            },
            _vm._l(_vm.lottery, function (item, i) {
              return _c(
                "item-box",
                {
                  key: i,
                  attrs: {
                    "is-show-num": _vm.rand.show_rand_code,
                    num: "第" + _vm.changeData(i) + "号",
                  },
                },
                [
                  _vm.rand.show_name
                    ? _c("span", { staticClass: "mes" }, [
                        _vm._v(_vm._s(item.name)),
                      ])
                    : _vm._e(),
                  _vm.rand.show_tel
                    ? _c("span", { staticClass: "mes" }, [
                        _vm._v(_vm._s(item.tel)),
                      ])
                    : _vm._e(),
                  _vm.rand.show_audit_order
                    ? _c("span", { staticClass: "mes" }, [
                        _vm._v(_vm._s(item.code)),
                      ])
                    : _vm._e(),
                  _vm.rand.show_card
                    ? _c("span", { staticClass: "mes" }, [
                        _vm._v(_vm._s(item.card)),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "bottomHeight", staticClass: "edit-btn-group" },
        [
          _c(
            "project-bottom",
            { attrs: { "is-show-tip": false, "flex-pos": "space-around" } },
            [
              _vm.list.max_round + 1 > 1
                ? _c("rounds-btn", {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "roomhot/roomlist"],
                        expression: "['admin','roomhot/roomlist']",
                      },
                    ],
                    attrs: { "btn-text": "上一轮记录" },
                    on: { btnClick: _vm.handleLastRound },
                  })
                : _vm._e(),
              _c("rounds-btn", {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "roomhot/roomlist"],
                    expression: "['admin','roomhot/roomlist']",
                  },
                ],
                attrs: { "btn-text": "下一轮客户" },
                on: { btnClick: _vm.getStartLottery },
              }),
              _vm.startButton
                ? _c("rounds-btn", {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "roomhot/roomlist"],
                        expression: "['admin','roomhot/roomlist']",
                      },
                    ],
                    attrs: { "btn-text": "回到当前轮" },
                    on: { btnClick: _vm.handleCancel },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      false
        ? _c(
            "div",
            { staticClass: "content" },
            [
              _c("screenfull"),
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.rand.name)),
              ]),
              _c("div", { staticClass: "lottery-box" }, [
                _c("div", { staticClass: "lottery-bg" }, [
                  _c("div", { staticClass: "lottery-num" }, [
                    _c("div", { staticClass: "num" }, [
                      _vm._v("第" + _vm._s(_vm.roundNumber) + "轮"),
                    ]),
                    _c("span", [
                      _vm._v("还有" + _vm._s(_vm.laveNumber) + "人未摇号"),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "lottery-list" },
                    _vm._l(_vm.lottery, function (item, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "list", class: _vm.heightClass },
                        [
                          _c("div", { staticClass: "number" }, [
                            _vm._v("第" + _vm._s(_vm.changeData(i)) + "号"),
                          ]),
                          _vm.rand.show_name
                            ? _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.name)),
                              ])
                            : _vm._e(),
                          _vm.rand.show_tel
                            ? _c("div", { staticClass: "card" }, [
                                _vm._v(_vm._s(item.tel)),
                              ])
                            : _vm._e(),
                          _vm.rand.show_audit_order
                            ? _c("div", { staticClass: "card" }, [
                                _vm._v(_vm._s(item.code)),
                              ])
                            : _vm._e(),
                          _vm.rand.show_card
                            ? _c("div", { staticClass: "card" }, [
                                _vm._v(_vm._s(item.card)),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c("div", { staticClass: "lottery-button" }, [
                _c(
                  "div",
                  { staticClass: "lottery-block" },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "text-center", attrs: { span: 8 } },
                          [
                            _c(
                              "div",
                              { staticClass: "w" },
                              [
                                _vm.list.max_round + 1 > 1
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: [
                                              "admin",
                                              "roomhot/roomlist",
                                            ],
                                            expression:
                                              "['admin','roomhot/roomlist']",
                                          },
                                        ],
                                        staticClass: "button-style",
                                        on: { click: _vm.handleLastRound },
                                      },
                                      [_vm._v("上一轮记录")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "text-center", attrs: { span: 8 } },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["admin", "roomhot/roomlist"],
                                    expression: "['admin','roomhot/roomlist']",
                                  },
                                ],
                                staticClass: "button-style",
                                on: { click: _vm.getStartLottery },
                              },
                              [_vm._v("下一轮客户")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { staticClass: "text-center", attrs: { span: 8 } },
                          [
                            _vm.startButton
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "roomhot/roomlist"],
                                        expression:
                                          "['admin','roomhot/roomlist']",
                                      },
                                    ],
                                    staticClass: "button-style",
                                    on: { click: _vm.handleCancel },
                                  },
                                  [_vm._v("回到当前轮")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }