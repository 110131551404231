export var defaultData = {
  title: {
    color: '#FFF',
    fontSize: 20,
    textAlign: 'left',
    top: 0
  },
  borderColor: 'rgba(255,255,255, 0.5)',
  locationXy: 'left',
  locationTb: 'top',
  width: 400,
  height: 400,
  rounds: [],
  row_num: 2,
  type: 'card',
  cardPosition: {
    left: 0,
    top: 0
  },
  background_color: 'rgba(255,255,255,.1)',
  code: {
    fontSize: 16,
    lineHeight: 16,
    color: '#fff'
  },
  name: {
    fontSize: 16,
    lineHeight: 16,
    color: '#fff'
  },
  tel: {
    fontSize: 16,
    lineHeight: 16,
    color: '#fff'
  },
  audit_order: {
    lineHeight: 16,
    fontSize: 16,
    color: '#fff'
  },
  card: {
    fontSize: 16,
    lineHeight: 16,
    color: '#fff'
  },
  other_remark: {
    fontSize: 16,
    lineHeight: 16,
    color: '#fff'
  },
  x: 0,
  y: 0
};
export var mainDefaultData = {
  // ****** 2023年9月6日 ******
  // 以后主屏样式添加到host_josn 这个字段内部
  host_json: {
    page_width: 0,
    page_height: 0,
    page_positionX: 0,
    page_positionY: 0,
    card_content_mode: 'column',
    num_width: 100,
    name_width: 100,
    code_width: 100,
    card_width: 100,
    card_info_code_positionButtom: 0,
    // 序号下边距
    other_remark_width: 100,
    await_round_size: 10,
    // 带摇号字号
    notice_duration: 10 // 滚动速度
  },
  // 卡片内部信息
  card_info_code_color: '#FFFFFF',
  // 卡片内序号颜色
  card_info_code_position: 0,
  // 序号上边距
  card_info_code_size: 30,
  // 卡片内序号字体大小
  card_info_user_color: '#FFFFFF',
  // 卡片内客户字体颜色
  card_info_user_position: 0,
  // 卡片内客户位置
  card_info_user_size: 20,
  // 卡片内客户字体大小
  card_info_user_spacing: 5,
  // 卡片内客户间距
  card_info_left_margin: 25,
  // 卡片左右边距
  card_info_top_margin: 50,
  // 卡片上下边距
  // 卡片
  card_background_color: 1,
  // 卡片背景颜色 1半透明 2透明
  card_position: 160,
  // 卡片位置
  card_margin: 140,
  // 卡片整体的左右的边距
  // card_position: 20, // 卡片位置
  card_row_num: 5,
  // 一行显示几个卡片
  topHeight: 0,
  // 背景图
  logo: '/rand/lottery.png',
  // 背景图
  // 项目名称
  name_color: '#FFFFFF',
  // 标题颜色
  name_show: 0,
  name_position: 50,
  // 标题位置
  name_size: 40,
  // 标题大小
  // 轮次
  round_color: '#FFFFFF',
  // 轮次颜色
  round_show: 0,
  round_position: 100,
  // 轮次位置
  // round_position: 10, // 轮次位置
  round_size: 40 // 轮次字体大小
};