var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "publicitySet",
      staticStyle: { width: "100vw", height: "100vh", overflow: "hidden" },
    },
    [
      _c(
        "div",
        {
          staticClass: "setting-control-icon",
          on: {
            click: function ($event) {
              _vm.isOpenDrawer = true
            },
          },
        },
        [
          _c("i", {
            staticClass: "el-icon-s-platform",
            staticStyle: { "font-size": "30px", color: "white" },
          }),
        ]
      ),
      _c("img", {
        staticStyle: {
          width: "100vw",
          height: "100vh",
          position: "absolute",
          "z-index": "0",
          top: "0",
          left: "0",
        },
        attrs: { src: _vm.replaceImgshow(_vm.style.logo) },
      }),
      _vm.style.title.show
        ? _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                position: "absolute",
                "text-align": "center",
                "font-weight": "bold",
              },
              style: {
                top: _vm.style.title.top + "px",
                color: _vm.style.title.color,
                fontSize: _vm.style.title.fontSize + "px",
              },
            },
            [_vm._v(_vm._s(_vm.rand_name))]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "overflowWidth0",
          staticStyle: {
            position: "relative",
            width: "100%",
            "overflow-y": "auto",
          },
          style: {
            height: "calc(100vh - " + _vm.style.title.bottom + "px)",
            top: _vm.style.title.bottom + "px",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                width: "calc(100% - 100px)",
                overflow: "hidden",
                display: "flex",
                margin: "0 50px",
                "justify-content": "space-between",
                "flex-wrap": "wrap",
                "overflow-y": "auto",
              },
            },
            _vm._l(_vm.round_length, function (item) {
              return _c(
                "div",
                {
                  key: item,
                  staticClass: "box",
                  staticStyle: { "border-radius": "20px" },
                  style: {
                    padding: _vm.style.box.round.top + "px 20px",
                    "background-color":
                      "rgba(255,255,255," + _vm.style.box.opacity + ")",
                    marginTop: _vm.style.box.top + "px",
                    "min-width":
                      "calc(100% / " + _vm.round_row_num + " - 20px)",
                  },
                },
                [
                  _vm.style.box.round.position === "top" &&
                  _vm.style.box.round.show
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            "text-align": "center",
                            top: "10px",
                            color: "black",
                            "font-size": "20px",
                          },
                          style: {
                            color: _vm.style.box.round.color,
                            fontSize: _vm.style.box.round.fontSize + "px",
                          },
                        },
                        [_vm._v("轮次")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        display: "flex",
                        overflow: "hidden",
                      },
                      style: {
                        height: _vm.style.box.itemBox.height + "px",
                        marginTop: _vm.style.box.itemBox.marginTop + "px",
                      },
                    },
                    [
                      _vm.style.box.round.position === "left" &&
                      _vm.style.box.round.show
                        ? _c(
                            "div",
                            {
                              staticClass: "round",
                              staticStyle: {
                                "margin-top": "auto",
                                "margin-bottom": "auto",
                              },
                              style: {
                                color: _vm.style.box.round.color,
                                fontSize: _vm.style.box.round.fontSize + "px",
                              },
                            },
                            [_vm._v("轮次")]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "box-content",
                          style: {
                            "--row_num": _vm.round_row_num,
                            "--width": _vm.style.box.itemBox.width,
                            "--left":
                              _vm.style.box.round.position === "left"
                                ? _vm.style.box.round.width + 10
                                : 0,
                            "--margin": _vm.style.box.itemBox.margin,
                            "--row": _vm.row_num,
                            "--leftMargin": _vm.style.box.item.leftMargin,
                            "--topMargin": _vm.style.box.item.topMargin,
                          },
                        },
                        _vm._l(_vm.row_num, function (item) {
                          return _c(
                            "div",
                            {
                              key: item,
                              staticClass: "item",
                              style: {
                                padding: _vm.style.box.item.topPadding + "px 0",
                                "background-color":
                                  _vm.style.box.item.backgroundColor,
                              },
                            },
                            [
                              _vm.rand.led_show_rand_code
                                ? _c(
                                    "div",
                                    {
                                      style: {
                                        "font-size":
                                          _vm.style.box.item.numFontSize + "px",
                                        color: _vm.style.box.item.numColor,
                                        marginTop:
                                          _vm.style.box.item.numTop + "px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item))]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  style: {
                                    marginTop:
                                      _vm.style.box.item.contentTop + "px",
                                    "line-height":
                                      _vm.style.box.item.lineHeight + "px",
                                  },
                                },
                                [
                                  _vm.rand.led_show_name
                                    ? _c(
                                        "div",
                                        {
                                          style: {
                                            "font-size":
                                              _vm.style.box.item.fontSize +
                                              "px",
                                            color: _vm.style.box.item.color,
                                          },
                                        },
                                        [_vm._v("姓名")]
                                      )
                                    : _vm._e(),
                                  _vm.rand.led_show_tel
                                    ? _c(
                                        "div",
                                        {
                                          style: {
                                            "font-size":
                                              _vm.style.box.item.fontSize +
                                              "px",
                                            color: _vm.style.box.item.color,
                                          },
                                        },
                                        [_vm._v("手机号")]
                                      )
                                    : _vm._e(),
                                  _vm.rand.led_show_audit_order
                                    ? _c(
                                        "div",
                                        {
                                          style: {
                                            "font-size":
                                              _vm.style.box.item.fontSize +
                                              "px",
                                            color: _vm.style.box.item.color,
                                          },
                                        },
                                        [_vm._v("诚意单号")]
                                      )
                                    : _vm._e(),
                                  _vm.rand.led_show_card
                                    ? _c(
                                        "div",
                                        {
                                          style: {
                                            "font-size":
                                              _vm.style.box.item.fontSize +
                                              "px",
                                            color: _vm.style.box.item.color,
                                          },
                                        },
                                        [_vm._v("身份证")]
                                      )
                                    : _vm._e(),
                                  _vm.rand.led_show_other_remark
                                    ? _c(
                                        "div",
                                        {
                                          style: {
                                            "font-size":
                                              _vm.style.box.item.fontSize +
                                              "px",
                                            color: _vm.style.box.item.color,
                                          },
                                        },
                                        [_vm._v("其他信息")]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            modal: false,
            visible: _vm.isOpenDrawer,
            "with-header": false,
            size: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isOpenDrawer = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer-body" },
            [
              _c("top-title", { attrs: { title: "页面配置" } }),
              _c(
                "div",
                [
                  _c("top-title", {
                    attrs: { "show-r": true },
                    on: { resetBeforeImg: _vm.resetBeforeImg },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "simple-setting" },
                [
                  _c("img-up", { on: { upSuccess: _vm.upSuccess } }),
                  _c("div", { staticClass: "setting-wrapper" }, [
                    _c("div", { staticClass: "left" }, [_vm._v("公示模式")]),
                    _c(
                      "div",
                      { staticClass: "right" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.publicity_type,
                              callback: function ($$v) {
                                _vm.publicity_type = $$v
                              },
                              expression: "publicity_type",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 0, value: 0 } }, [
                              _vm._v("全部"),
                            ]),
                            _c("el-radio", { attrs: { label: 1, value: 1 } }, [
                              _vm._v("最新模式"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.publicity_type === 1
                    ? _c("div", { staticClass: "setting-wrapper" }, [
                        _c("div", { staticClass: "left" }, [
                          _vm._v("展示最近X轮"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "right" },
                          [
                            _c("el-input-number", {
                              staticClass: "input-number__style",
                              model: {
                                value: _vm.max_round_length,
                                callback: function ($$v) {
                                  _vm.max_round_length = $$v
                                },
                                expression: "max_round_length",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "setting-wrapper" }, [
                    _c("div", { staticClass: "left" }, [
                      _vm._v("每页展示轮次"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "right" },
                      [
                        _c("el-input-number", {
                          staticClass: "input-number__style",
                          model: {
                            value: _vm.round_length,
                            callback: function ($$v) {
                              _vm.round_length = $$v
                            },
                            expression: "round_length",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "setting-wrapper" }, [
                    _c("div", { staticClass: "left" }, [
                      _vm._v("公示屏切换间隔 (秒)"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "right" },
                      [
                        _c("el-input-number", {
                          staticClass: "input-number__style",
                          model: {
                            value: _vm.carousel_interval,
                            callback: function ($$v) {
                              _vm.carousel_interval = $$v
                            },
                            expression: "carousel_interval",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c("top-title", {
                attrs: {
                  title: "摇号名称",
                  showSwitch: true,
                  show: _vm.style.title.show,
                },
                on: {
                  "update:show": function ($event) {
                    return _vm.$set(_vm.style.title, "show", $event)
                  },
                },
              }),
              _c("div", { staticClass: "simple-setting" }, [
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("字号")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.style.title.fontSize,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.title, "fontSize", $$v)
                          },
                          expression: "style.title.fontSize",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("颜色")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-color-picker", {
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.style.title.color,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.title, "color", $$v)
                          },
                          expression: "style.title.color",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("位置")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.style.title.top,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.title, "top", $$v)
                          },
                          expression: "style.title.top",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("top-title", {
                attrs: {
                  title: "轮次卡片",
                  showSwitch: true,
                  show: _vm.style.box.round.show,
                },
                on: {
                  "update:show": function ($event) {
                    return _vm.$set(_vm.style.box.round, "show", $event)
                  },
                },
              }),
              _c("div", { staticClass: "simple-setting" }, [
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("底色")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.style.box.opacity,
                            callback: function ($$v) {
                              _vm.$set(_vm.style.box, "opacity", $$v)
                            },
                            expression: "style.box.opacity",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { label: 0.1, value: 0.1 } },
                            [_vm._v("半透明")]
                          ),
                          _c("el-radio", { attrs: { label: 0, value: 0 } }, [
                            _vm._v("完全透明"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("字号")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.style.box.round.fontSize,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.box.round, "fontSize", $$v)
                          },
                          expression: "style.box.round.fontSize",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("颜色")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-color-picker", {
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.style.box.round.color,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.box.round, "color", $$v)
                          },
                          expression: "style.box.round.color",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("方位")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.style.box.round.position,
                            callback: function ($$v) {
                              _vm.$set(_vm.style.box.round, "position", $$v)
                            },
                            expression: "style.box.round.position",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { label: "top", value: "top" } },
                            [_vm._v("上方")]
                          ),
                          _c(
                            "el-radio",
                            { attrs: { label: "left", value: "left" } },
                            [_vm._v("左方")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("单行轮次数量")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        attrs: { min: 1 },
                        model: {
                          value: _vm.round_row_num,
                          callback: function ($$v) {
                            _vm.round_row_num = $$v
                          },
                          expression: "round_row_num",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("整体位置")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.style.title.bottom,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.title, "bottom", $$v)
                          },
                          expression: "style.title.bottom",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("卡片行间距")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.style.box.top,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.box, "top", $$v)
                          },
                          expression: "style.box.top",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("卡片高度")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.style.box.round.top,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.box.round, "top", $$v)
                          },
                          expression: "style.box.round.top",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("top-title", { attrs: { title: "内容卡片" } }),
              _c("div", { staticClass: "simple-setting" }, [
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("单行数量")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.row_num,
                          callback: function ($$v) {
                            _vm.row_num = $$v
                          },
                          expression: "row_num",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("整体位置")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.style.box.itemBox.marginTop,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.box.itemBox, "marginTop", $$v)
                          },
                          expression: "style.box.itemBox.marginTop",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("左右外边距")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.style.box.itemBox.leftMargin,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.box.itemBox, "leftMargin", $$v)
                          },
                          expression: "style.box.itemBox.leftMargin",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("卡片行间距")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.style.box.itemBox.topMargin,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.box.itemBox, "topMargin", $$v)
                          },
                          expression: "style.box.itemBox.topMargin",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("卡片高度")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.style.box.item.topPadding,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.box.item, "topPadding", $$v)
                          },
                          expression: "style.box.item.topPadding",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("卡片宽度")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.style.box.itemBox.width,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.box.itemBox, "width", $$v)
                          },
                          expression: "style.box.itemBox.width",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("top-title", { attrs: { title: "卡片内信息" } }),
              _c("div", { staticClass: "simple-setting" }, [
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("背景颜色")]),
                  _c(
                    "div",
                    { staticClass: "right", staticStyle: { display: "flex" } },
                    [
                      _c("el-color-picker", {
                        attrs: { "show-alpha": "", size: "mini" },
                        model: {
                          value: _vm.style.box.item.backgroundColor,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.box.item, "backgroundColor", $$v)
                          },
                          expression: "style.box.item.backgroundColor",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("序号位置")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.style.box.item.numTop,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.box.item, "numTop", $$v)
                          },
                          expression: "style.box.item.numTop",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("序号字号")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.style.box.item.numFontSize,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.box.item, "numFontSize", $$v)
                          },
                          expression: "style.box.item.numFontSize",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("序号颜色")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-color-picker", {
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.style.box.item.numColor,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.box.item, "numColor", $$v)
                          },
                          expression: "style.box.item.numColor ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("客户信息字号")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.style.box.item.fontSize,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.box.item, "fontSize", $$v)
                          },
                          expression: "style.box.item.fontSize",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("客户信息颜色")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-color-picker", {
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.style.box.item.color,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.box.item, "color", $$v)
                          },
                          expression: "style.box.item.color ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("信息位置")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.style.box.item.contentTop,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.box.item, "contentTop", $$v)
                          },
                          expression: "style.box.item.contentTop",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("信息间距")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.style.box.item.lineHeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.style.box.item, "lineHeight", $$v)
                          },
                          expression: "style.box.item.lineHeight",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "save-btn" }, [
                _c(
                  "div",
                  {
                    staticClass: "save-left",
                    on: {
                      click: function ($event) {
                        return _vm.onSetLedStyle(0)
                      },
                    },
                  },
                  [_vm._v("重置为默认值")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "save-right",
                    on: {
                      click: function ($event) {
                        return _vm.onSetLedStyle(1)
                      },
                    },
                  },
                  [_vm._v("保存当前设置")]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }