import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import utils from '/src/utils/utils';
export default {
  name: 'OperateTable',
  props: {
    tableData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    tableHeight: {
      type: Number,
      default: 0
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isPC: utils.isPC()
    };
  },
  methods: {
    rowClick: function rowClick(row) {
      this.$refs.refTable.toggleRowExpansion(row);
    }
  }
};