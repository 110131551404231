//
//
//
//
//

import WebOfficeSDK from '../../utils/web-office-sdk-solution-v2.0.4.es.js';
export default {
  data: function data() {
    return {
      instance: null,
      fileId: ''
    };
  },
  created: function created() {
    var option = this.$route.query;
    if (option.fileId) {
      this.int(option.fileId);
    }
  },
  mounted: function mounted() {
    this.instance.ApiEvent.AddApiEventListener('fileOpen', function (data) {
      console.log('fileOpen: ', data);
    });
  },
  methods: {
    int: function int(fileId) {
      this.instance = WebOfficeSDK.init({
        appId: process.env.VUE_APP_WPS_APPID || 'SX20230925MEDFIQ',
        // appId:'AK20230925FCGBWW',
        fileId: fileId,
        officeType: WebOfficeSDK.OfficeType.Writer,
        mode: 'normal',
        commandBars: [{
          attributes: {
            visible: false,
            enable: false
          }
        }]
      });
    }
  }
};