var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "switch-btn-wrapper" }, [
    _c("div", { staticClass: "btn-wrapper" }, [
      _c(
        "div",
        {
          class: ["btn", { active: _vm.index == 0 }],
          on: {
            click: function ($event) {
              return _vm.btnChange(0)
            },
          },
        },
        [_vm._v("客户认筹表")]
      ),
      _vm.isShowUser
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "identifyaudit/auditdetail"],
                  expression: "['admin','identifyaudit/auditdetail']",
                },
              ],
              class: ["btn", { active: _vm.index == 2 }],
              on: {
                click: function ($event) {
                  return _vm.btnChange(2)
                },
              },
            },
            [_vm._v("客户登记表")]
          )
        : _vm._e(),
      _vm.isShowSchedule
        ? _c(
            "div",
            {
              class: ["btn", { active: _vm.index == 1 }],
              on: {
                click: function ($event) {
                  return _vm.btnChange(1)
                },
              },
            },
            [_vm._v("审核附表")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }