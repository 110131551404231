import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import inputBox from '../components/input-box';
import { AuditStatusSave, exportAuditStatus0, exportAuditStatus1, exportAuditStatus2 } from '@/api/sincerityRegister';
import toolReviewMixin from '../toolReviewMixin';
export default {
  name: 'ReviewDetails',
  components: {
    inputBox: inputBox
  },
  mixins: [toolReviewMixin],
  props: {
    row: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      statusInfo: [],
      activeName: '0',
      form: {}
    };
  },
  // 填写附表
  created: function created() {
    var _this = this;
    var row = this.toolReviewRow;
    if (row.types === 'status0') {
      exportAuditStatus0({
        id: row.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
            statusInfo = _res$data.statusInfo,
            auditInfo = _res$data.auditInfo,
            info = _res$data.info;
          _this.statusInfo = _this.getInfoData(info.dataList);
          _this.auditInfo = auditInfo;
          _this.info = info.logList;
        }
      });
    } else if (row.types === 'status1') {
      exportAuditStatus1({
        id: row.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data2 = res.data,
            statusInfo = _res$data2.statusInfo,
            auditInfo = _res$data2.auditInfo,
            info = _res$data2.info;
          _this.statusInfo = _this.getInfoData(info.dataList);
          _this.auditInfo = auditInfo;
          _this.info = info.logList;
        }
      });
    } else {
      exportAuditStatus2({
        id: row.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data3 = res.data,
            statusInfo = _res$data3.statusInfo,
            auditInfo = _res$data3.auditInfo,
            info = _res$data3.info;
          _this.statusInfo = _this.getInfoData(info.dataList);
          _this.auditInfo = auditInfo;
          _this.info = info.logList;
          _this.doShowParent();
        }
      });
    }
  },
  mounted: function mounted() {
    // console.log(this.row)
  },
  methods: {
    // 其他类型传回的图片
    handleOtherImg: function handleOtherImg(url, index) {
      this.statusInfo[index[0]].forms[index[1]].normal_result.value = url;
    },
    // 处理数据
    getInfoData: function getInfoData(data) {
      var _this2 = this;
      // 修改多选，图片格式
      data.forEach(function (item, index) {
        item.typeNumber = 0;
        item.typeUserNumber = 0;

        // 其他类型
        if (item.type === 0) {
          item.forms.map(function (forms) {
            if (forms.fill_in_type === 2) {
              item.typeNumber = item.typeNumber + 1;
            } else if (forms.fill_in_type === 1) {
              item.typeUserNumber = item.typeUserNumber + 1;
            }
            if (forms.form_type === 'select') {
              if (forms.normal_result.value !== '') {
                var array = [];
                forms.normal_result.value.split(',').map(function (num) {
                  array.push(parseInt(num));
                });
                forms.normal_result.value2 = array;
              } else {
                forms.normal_result.value2 = [];
              }
            } else if (forms.form_type === 'file') {
              if (forms.normal_result.value !== '') {
                var _array = [];
                forms.normal_result.value.split(',').map(function (img) {
                  _array.push({
                    'url': _this2.imgUrl + img
                  });
                });
                // 组装新数组
                forms.normal_result.value2 = _array;
              } else {
                forms.normal_result.value2 = [];
              }
            } else if (forms.form_type === 'radio') {
              if (forms.normal_result.value !== '') {
                forms.normal_result.value2 = parseInt(forms.normal_result.value);

                // 婚姻状态
                if (forms.owner_type === 6) {
                  var _index = forms.children.findIndex(function (d) {
                    return d.id === forms.normal_result.value2;
                  });
                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改
                        if (_forms_.owner_type === 8 && forms.children[_index].name === '未婚') {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  });
                  //   付款方式
                } else if (forms.owner_type === 7) {
                  var _index2 = forms.children.findIndex(function (d) {
                    return d.id === forms.normal_result.value2;
                  });
                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改
                        if (_forms_.owner_type === 9 && (forms.children[_index2].name === '全款' || forms.children[_index2].name === '一次性付款')) {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  });
                } else if (forms.owner_type === 10) {
                  var _index3 = forms.children.findIndex(function (d) {
                    return d.id === forms.normal_result.value2;
                  });
                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改 首付比例
                        if (_forms_.owner_type === 12 && forms.children[_index3].owner_type === 11) {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  });
                }
              } else {
                forms.normal_result.value2 = '';
              }
            }
          });
          //  产权人
        } else if (item.type === 1 || item.type === 2) {
          // 1为产权人 2为银行资料
          var ownerBankKey = item.type == 1 ? "owner" : "bank";
          item[ownerBankKey + "s"].forEach(function (owners) {
            owners.forms.map(function (forms) {
              if (forms.fill_in_type === 2) {
                item.typeNumber = item.typeNumber + 1;
              } else {
                item.typeUserNumber = item.typeUserNumber + 1;
              }
              if (forms.form_type === 'select') {
                if (forms[ownerBankKey + "_result"].value !== '') {
                  var array = [];
                  forms[ownerBankKey + "_result"].value.split(',').map(function (num) {
                    array.push(parseInt(num));
                  });
                  forms[ownerBankKey + "_result"].value2 = array;
                } else {
                  forms[ownerBankKey + "_result"].value2 = [];
                }
              } else if (forms.form_type === 'file') {
                if (forms[ownerBankKey + "_result"].value !== '') {
                  var _array2 = [];
                  forms[ownerBankKey + "_result"].value.split(',').map(function (img) {
                    _array2.push({
                      'url': _this2.imgUrl + img
                    });
                  });
                  // 组装新数组
                  forms[ownerBankKey + "_result"].value2 = _array2;
                } else {
                  forms[ownerBankKey + "_result"].value2 = [];
                }
              } else if (forms.form_type === 'radio') {
                if (forms[ownerBankKey + "_result"].value !== '') {
                  forms[ownerBankKey + "_result"].value2 = parseInt(forms[ownerBankKey + "_result"].value);
                } else {
                  forms[ownerBankKey + "_result"].value2 = '';
                }
              }
            });
          });
        }
      });
      return data;
    },
    // 保存验证！ 是他填完
    getVerifyData: function getVerifyData() {
      var _this3 = this;
      var data = this.postData();
      var verifyType = true;
      var _loop = function _loop() {
          var item = data[s];
          // 产权人类型
          if (item.type === 1 || item.type === 2) {
            // 1为产权人 2为银行资料
            var ownerBankKey = item.type == 1 ? "owner" : "bank";
            item[ownerBankKey + "s"].forEach(function (owners, ownInx) {
              var _loop2 = function _loop2() {
                  var ownersItem = owners.forms[i];
                  if (ownersItem.fill_in_type === 2 && ownersItem.parent_cause_show) {
                    // 多选
                    if (ownersItem.form_type === 'select') {
                      if (ownersItem[ownerBankKey + "_result"].value === '') {
                        _this3.$message({
                          type: 'error',
                          message: "\u7B2C".concat(ownInx + 1).concat(item.area_name + ownersItem.name, "\u672A\u9009\u62E9")
                        });
                        verifyType = false;
                        return {
                          v: void 0
                        };
                      }
                      // 单选
                    } else if (ownersItem.form_type === 'radio') {
                      if (ownersItem[ownerBankKey + "_result"].value === '') {
                        _this3.$message({
                          type: 'error',
                          message: "\u7B2C".concat(ownInx + 1).concat(item.area_name + ownersItem.name, "\u672A\u9009\u62E9")
                        });
                        verifyType = false;
                        return {
                          v: void 0
                        };
                      } else {
                        // 证件类型
                        if (ownersItem.owner_type === 4) {
                          var index = ownersItem.children.findIndex(function (d) {
                            return d.id === ownersItem[ownerBankKey + "_result"].value2;
                          });
                          if (ownersItem.children[index].name === '身份证') {
                            var cardReg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/;
                            var HKReg = /[A-Za-z]{1}\d{6}\([0-9A-Za-z]\)/;
                            var TWReg = /[A-Z]{1}\d{9}/;
                            var AMReg = /[1|5|7]\d{6}\([0-9A-Za-z]\)/;

                            // 找出 证件号！ value
                            for (var n = 0; n < owners.forms.length; n++) {
                              if (owners.forms[n].owner_type === 3) {
                                if (!cardReg.test(owners.forms[n][ownerBankKey + "_result"].value) && !HKReg.test(owners.forms[n][ownerBankKey + "_result"].value) && !TWReg.test(owners.forms[n][ownerBankKey + "_result"].value) && !AMReg.test(owners.forms[n][ownerBankKey + "_result"].value)) {
                                  _this3.$message({
                                    type: 'error',
                                    message: "\u7B2C".concat(ownInx + 1).concat(item.area_name + owners.forms[n].name, "\u683C\u5F0F\u4E0D\u5BF9")
                                  });
                                  verifyType = false;
                                  return {
                                    v: void 0
                                  };
                                }
                              }
                            }
                          }
                        }
                      }
                      // 上传图片
                    } else if (owners.forms[i].form_type === 'file') {
                      if (owners.forms[i][ownerBankKey + "_result"].value.split(',').length < owners.forms[i].img_number) {
                        _this3.$message({
                          type: 'error',
                          message: "\u7B2C".concat(ownInx + 1).concat(item.area_name + owners.forms[i].name, "\u56FE\u7247\u81F3\u5C11\u4F20").concat(owners.forms[i].img_number, "\u5F20")
                        });
                        verifyType = false;
                        return {
                          v: void 0
                        };
                      }
                      // 输入框
                    } else {
                      if (owners.forms[i][ownerBankKey + "_result"].value === '') {
                        _this3.$message({
                          type: 'error',
                          message: "\u7B2C".concat(ownInx + 1).concat(item.area_name + owners.forms[i].name, "\u4E0D\u80FD\u4E3A\u7A7A")
                        });
                        verifyType = false;
                        return {
                          v: void 0
                        };
                      } else {
                        var reg = /^1\d{10}$/;
                        // 手机号
                        if (ownersItem.owner_type === 2) {
                          if (!reg.test(ownersItem[ownerBankKey + "_result"].value)) {
                            _this3.$message({
                              type: 'error',
                              message: "\u7B2C".concat(ownInx + 1).concat(item.area_name + ownersItem.name, "\u683C\u5F0F\u4E0D\u5BF9")
                            });
                            verifyType = false;
                            return {
                              v: void 0
                            };
                          }
                        }
                      }
                    }
                  }
                },
                _ret2;
              for (var i = 0; i < owners.forms.length; i++) {
                _ret2 = _loop2();
                if (_ret2) return _ret2.v;
              }
            });
          } else {
            // 其他规则
            for (var i = 0; i < item.forms.length; i++) {
              if (item.forms[i].fill_in_type === 2 && item.forms[i].parent_cause_show) {
                if (item.forms[i].form_type === 'select') {
                  if (item.forms[i].normal_result.value === '') {
                    _this3.$message({
                      type: 'error',
                      message: "".concat(item.forms[i].name, "\u672A\u9009\u62E9")
                    });
                    verifyType = false;
                    return {
                      v: void 0
                    };
                  }
                } else if (item.forms[i].form_type === 'radio') {
                  if (!item.forms[i].is_show_tp) {
                    if (item.forms[i].normal_result.value === '') {
                      _this3.$message({
                        type: 'error',
                        message: "".concat(item.forms[i].name, "\u672A\u9009\u62E9")
                      });
                      verifyType = false;
                      return {
                        v: void 0
                      };
                    }
                  }
                } else if (item.forms[i].form_type === 'file') {
                  if (!item.forms[i].is_show_tp) {
                    if (item.forms[i].normal_result.value !== '') {
                      if (item.forms[i].normal_result.value.split(',').length < item.forms[i].img_number) {
                        _this3.$message({
                          type: 'error',
                          message: "".concat(item.forms[i].name, "\u56FE\u7247\u81F3\u5C11\u4F20").concat(item.forms[i].img_number, "\u5F20")
                        });
                        verifyType = false;
                        return {
                          v: void 0
                        };
                      }
                    } else {
                      _this3.$message({
                        type: 'error',
                        message: "\u8BF7\u4E0A\u4F20".concat(item.forms[i].name, "\u56FE\u7247")
                      });
                      verifyType = false;
                      return {
                        v: void 0
                      };
                    }
                  }
                } else {
                  // debugger
                  if (item.forms[i].normal_result.value === '') {
                    _this3.$message({
                      type: 'error',
                      message: "".concat(item.forms[i].name, "\u4E0D\u80FD\u4E3A\u7A7A")
                    });
                    verifyType = false;
                    return {
                      v: void 0
                    };
                  }
                }
              }
            }
          }
        },
        _ret;
      for (var s = 0; s < data.length; s++) {
        _ret = _loop();
        if (_ret) return _ret.v;
      }
      return verifyType;
    },
    // 处理数据 提交给后台
    postData: function postData() {
      // 修改多选，图片格式
      this.statusInfo.forEach(function (item, index) {
        // 其他类型
        if (item.type === 0) {
          item.forms.map(function (forms) {
            forms.normal_result.form_id = forms.id;
            if (forms.form_type === 'select') {
              if (typeof forms.normal_result.value2 !== 'string') {
                forms.normal_result.value = forms.normal_result.value2.join(',');
              }
            } else if (forms.form_type === 'file') {
              if (typeof forms.normal_result.value !== 'string') {
                // 组装新数组
                forms.normal_result.value = forms.normal_result.value.join(',');
              }
            } else if (forms.form_type === 'radio') {
              forms.normal_result.value = forms.normal_result.value2 + '';
            }
          });
          //  产权人和银行资料
        } else if (item.type === 1 || item.type === 2) {
          // 1为产权人 2为银行资料
          var ownerBankKey = item.type == 1 ? "owner" : "bank";
          item[ownerBankKey + "s"].forEach(function (owners) {
            owners.forms.map(function (forms) {
              forms.normal_result.form_id = forms.id;
              if (forms.form_type === 'select') {
                if (typeof forms[ownerBankKey + "_result"].value2 !== 'string') {
                  forms[ownerBankKey + "_result"].value = forms[ownerBankKey + "_result"].value2.join(',');
                }
              } else if (forms.form_type === 'file') {
                if (typeof forms[ownerBankKey + "_result"].value !== 'string') {
                  // 组装新数组
                  forms[ownerBankKey + "_result"].value = forms[ownerBankKey + "_result"].value.join(',');
                }
              } else if (forms.form_type === 'radio') {
                forms[ownerBankKey + "_result"].value = forms[ownerBankKey + "_result"].value2 + '';
              }
            });
          });
        }
      });
      return this.statusInfo;
    },
    // 取消返回 详情页面
    handleCancel: function handleCancel() {
      // this.$router.push({
      //   name: 'ReviewDetails',
      //   query: { id: this.auditInfo.id, status: this.row.types },
      // })
      // this.$emit('openDrawer', true, 1, this.row)
      this.toolReviewRow.id = this.auditInfo.id;
      this.CHANGE_ROW(this.toolReviewRow);
      this.CHANGE_DRAWER_TYPE(1);
    },
    // 保存附表
    handleSave: function handleSave() {
      var _this4 = this;
      if (!this.getVerifyData()) {
        return;
      }

      // 执行一下修改;
      AuditStatusSave({
        id: parseInt(this.toolReviewRow.id),
        data: this.postData()
      }).then(function (res) {
        if (res.status === 200) {
          _this4.$message({
            type: 'success',
            message: res.data.msg
          });
          setTimeout(function () {
            _this4.toolReviewRow.id = _this4.auditInfo.id;
            // this.toolReviewRow.drawerType = 1
            _this4.change_row(_this4.toolReviewRow);
            _this4.CHANGE_DRAWER_TYPE(1);

            // this.$emit('openDrawer', true, 1, this.row)
            // this.$router.push({
            //   name: 'ReviewDetails',
            //   query: { id: this.auditInfo.id, status: this.row.types },
            // })
          }, 1000);
        }
      });
    },
    /**
     * 父级影响显示隐藏
     * @param parent_id  当前id（父级）
     * @param parent_values 当前选项ids
     * @param isShowParent 是否开始判断父级隐藏
     * @param fromItem 是否是单选传的所有数据
     */
    doShowParent: function doShowParent(isShowParent, parent_id, parent_values, fromItem) {
      var _this5 = this;
      if (isShowParent) {
        var forms = JSON.parse(JSON.stringify(this.statusInfo));
        for (var i = 0; i < forms.length; i++) {
          // 产权人或者银行资料
          if (forms[i].type === 1 || forms[i].type === 2) {
            // 1为产权人 2为银行资料
            var ownerBankKey = forms[i].type == 1 ? "owner" : "bank";
            for (var l = 0; l < forms[i][ownerBankKey + "s"].length; l++) {
              for (var a = 0; a < forms[i][ownerBankKey + "s"][l].forms.length; a++) {
                var item = forms[i][ownerBankKey + "s"][l].forms[a];
                if (parent_id === item.parent_pid && item.parent_pid !== 0) {
                  var parent_ids = [];
                  if (item.parent_id) {
                    parent_ids = item.parent_id.split(",");
                  }
                  var parent_cause_show = false;
                  for (var c = 0; c < parent_ids.length; c++) {
                    if (parent_values.indexOf(parent_ids[c]) >= 0) {
                      parent_cause_show = true;
                    }
                  }
                  // 隐藏时清空之前的值
                  if (!parent_cause_show) {
                    item.normal_result.value = "";
                    item[ownerBankKey + "_result"].value = "";
                    item.normal_result.value2 = "";
                    item[ownerBankKey + "_result"].value2 = "";
                  }
                  item.parent_cause_show = parent_cause_show;
                } else if (item.parent_pid === 0) {
                  item.parent_cause_show = true;
                }
                forms[i][ownerBankKey + "s"][l].forms[a] = item;
              }
            }
          }
          // 其他类型
          else {
            for (var _a = 0; _a < forms[i].forms.length; _a++) {
              var _item2 = forms[i].forms[_a];
              if (parent_id === _item2.parent_pid && _item2.parent_pid !== 0) {
                var _parent_ids = [];
                if (_item2.parent_id) {
                  _parent_ids = _item2.parent_id.split(",");
                }
                var _parent_cause_show = false;
                for (var _c = 0; _c < _parent_ids.length; _c++) {
                  if (parent_values.indexOf(_parent_ids[_c]) >= 0) {
                    _parent_cause_show = true;
                  }
                }
                // 隐藏时清空之前的值
                if (!_parent_cause_show) {
                  _item2.normal_result.value = "";
                  _item2.normal_result.value2 = "";
                }
                _item2.parent_cause_show = _parent_cause_show;
              } else if (_item2.parent_pid === 0) {
                _item2.parent_cause_show = true;
              }
              forms[i].forms[_a] = _item2;
            }
          }
        }
        this.statusInfo = JSON.parse(JSON.stringify(forms));
        if (fromItem) {
          this.handleRadio(fromItem.down_index, fromItem.owner_type, fromItem.normal_result.value2);
        }
      } else {
        var formData = JSON.parse(JSON.stringify(this.statusInfo));
        formData.forEach(function (item) {
          if (item.type != 1 && item.type != 2) {
            item.forms.forEach(function (_item) {
              var selectId = [];
              if (_item.form_type == '置业顾问') {
                // 置业顾问
                if (_item.normal_result.value) {
                  selectId = _item.normal_result.value.toString().split(',');
                } else if (_item.value) {
                  selectId = _item.value.toString().split(',');
                } else if (_item.select_value == 1) {
                  // 默认选择
                  selectId = _item.admins[0].id.toString().split(',');
                }
                _this5.doShowParent(true, _item.id, selectId);
              } else if (_item.form_type == 'radio' || _item.form_type == 'select') {
                // 单选 || 多选
                if (_item.normal_result.value) {
                  selectId = _item.normal_result.value.toString().split(',');
                } else if (_item.value) {
                  selectId = _item.value.toString().split(',');
                } else if (_item.select_value == 1) {
                  if (_item.form_type == 'radio') {
                    selectId = _item.children[0].id.toString().split(',');
                  } else {
                    _item.normal_result.value = selectId.join(',');
                  }
                }
                _this5.doShowParent(true, _item.id, selectId);
              }
            });
          }
        });
      }
    }
  }
};