var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "base-table" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "base-table-content" },
      _vm._l(_vm.data, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "base-table-list" },
          [
            item.name
              ? _c(
                  "div",
                  { staticClass: "base-table-item base-table-subtitle" },
                  [_c("div", [_vm._v(_vm._s(item.name))])]
                )
              : _vm._e(),
            _vm._m(1, true),
            _vm._l(item.list, function (items, idx) {
              return _c("div", { key: idx, staticClass: "base-table-item" }, [
                _c("div", { staticClass: "type-index" }, [
                  _vm._v(_vm._s(idx + 1)),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "type-copy",
                    on: {
                      click: function ($event) {
                        return _vm.copyText(items.name)
                      },
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(items.name) + " "),
                    _c("svg-icon", {
                      attrs: {
                        "class-name": "greens copy-icon",
                        "icon-class": "copy",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { domProps: { innerHTML: _vm._s(items.meaming) } }),
              ])
            }),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hand_hint" }, [
      _c("div", [
        _vm._v(" 说明："),
        _c("br"),
        _vm._v(" 1）点击复制变量名，将其粘贴至word模板中需要取值的位置；"),
        _c("br"),
        _vm._v(
          " 2）如右图示例，认购方处填写姓名拼接的变量名${real_name_str}。"
        ),
        _c("br"),
      ]),
      _c("img", {
        attrs: {
          src: require("../../../assets/table/z_table_img.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "base-table-title" }, [
      _c("div", [_vm._v("序号")]),
      _c("div", [_vm._v("变量名")]),
      _c("div", [_vm._v("含义")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }