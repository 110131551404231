import _toConsumableArray from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//

import '@public/kindeditor/themes/default/default.css';
import '@public/kindeditor/kindeditor-all.js';
import '@public/kindeditor/lang/zh-CN.js';

// 以下四个配置文件见下文

import items from './config/items';
import htmlTags from './config/htmlTags';
import fontSizeTable from './config/fontSizeTable';
import otherConfig from './config/otherConfig';
export default {
  name: 'KindeditorComponent',
  props: _objectSpread({
    // 编辑器内容
    content: {
      type: String,
      default: ''
    },
    // 编辑器id
    id: {
      type: String,
      // required: true,
      default: 'kindeditor-id'
    },
    // 宽
    width: {
      type: String,
      default: "100%"
    },
    // 高
    height: {
      type: String,
      default: '400'
    },
    // 最小宽
    minWidth: {
      type: Number,
      default: 650
    },
    // 最小高
    minHeight: {
      type: Number,
      default: 200
    },
    // toolbar 工具栏配置
    items: {
      type: Array,
      default: function _default() {
        return _toConsumableArray(items);
      }
    },
    // 标签配置
    htmlTags: {
      type: Object,
      default: function _default() {
        return _objectSpread({}, htmlTags);
      }
    },
    // 字号配置
    fontSizeTable: {
      type: Array,
      default: function _default() {
        return _toConsumableArray(fontSizeTable);
      }
    },
    // 语言配置
    langType: {
      type: String,
      default: 'zh-CN'
    },
    // 主题配置
    themeType: {
      type: String,
      default: 'default'
    },
    // body 的样式
    bodyClass: {
      type: String,
      default: 'ke-content'
    }
  }, otherConfig),
  data: function data() {
    return {
      editor: null,
      outContent: this.content
    };
  },
  watch: {
    readonly: {
      handler: function handler(bool) {
        if (!this.editor) return;
        this.setEditor();
      }
    },
    content: {
      handler: function handler(content) {
        this.editor && this.editor.html(content);
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    // 初始访问时创建
    this.initEditor();
    if (!this.editor) return;
    this.setEditor();
    this.editor.statusbar.hide(); // 隐藏底部状态栏
  },
  activated: function activated() {
    // 初始访问时创建
    this.initEditor();
    if (!this.editor) return;
    this.setEditor();
    this.editor.statusbar.hide(); // 隐藏底部状态栏
  },
  deactivated: function deactivated() {
    // 实例销毁之前移除
    this.removeEditor();
  },
  beforeDestroy: function beforeDestroy() {
    // 实例销毁之前移除
    this.removeEditor();
  },
  methods: {
    // 根据只读、编辑状态设置编辑器
    setEditor: function setEditor() {
      this.editor.readonly(this.readonly);
      if (this.readonly) {
        this.editor.toolbar.hide(); // 隐藏工具栏
      } else {
        this.editor.focus();
        this.editor.toolbar.show(); // 编辑显示工具栏
      }
    },
    // 移除编辑器实例
    removeEditor: function removeEditor() {
      window.KindEditor.remove("#".concat(this.id));
    },
    // 初始化编辑器
    initEditor: function initEditor() {
      var _this = this;
      this.removeEditor();
      this.editor = window.KindEditor.create('#' + this.id, {
        width: this.width,
        height: this.height,
        minWidth: this.minWidth,
        minHeight: this.minHeight,
        items: this.items,
        noDisableItems: this.noDisableItems,
        filterMode: this.filterMode,
        htmlTags: this.htmlTags,
        wellFormatMode: this.wellFormatMode,
        resizeType: this.resizeType,
        themeType: this.themeType,
        langType: this.langType,
        designMode: this.designMode,
        fullscreenMode: this.fullscreenMode,
        basePath: this.basePath,
        themesPath: this.themesPath,
        pluginsPath: this.pluginsPath,
        langPath: this.langPath,
        minChangeSize: this.minChangeSize,
        loadStyleMode: this.loadStyleMode,
        urlType: this.urlType,
        newlineTag: this.newlineTag,
        pasteType: this.pasteType,
        dialogAlignType: this.dialogAlignType,
        shadowMode: this.shadowMode,
        zIndex: this.zIndex,
        useContextmenu: this.useContextmenu,
        syncType: this.syncType,
        indentChar: this.indentChar,
        cssPath: this.cssPath,
        cssData: this.cssData,
        bodyClass: this.bodyClass,
        colorTable: this.colorTable,
        afterCreate: this.afterCreate,
        // 编辑器内容改变回调
        afterChange: function afterChange() {
          _this.editor ? _this.outContent = _this.editor.html() : '';
        },
        afterTab: this.afterTab,
        afterFocus: this.afterFocus,
        afterBlur: this.afterBlur,
        afterUpload: this.afterUpload,
        uploadJson: this.uploadJson,
        fileManagerJson: this.fileManagerJson,
        allowPreviewEmoticons: this.allowPreviewEmoticons,
        allowImageUpload: this.allowImageUpload,
        allowFlashUpload: this.allowFlashUpload,
        allowMediaUpload: this.allowMediaUpload,
        allowFileUpload: this.allowFileUpload,
        allowFileManager: this.allowFileManager,
        fontSizeTable: this.fontSizeTable,
        imageTabIndex: this.imageTabIndex,
        formatUploadUrl: this.formatUploadUrl,
        fullscreenShortcut: this.fullscreenShortcut,
        extraFileUploadParams: this.extraFileUploadParams,
        filePostName: this.filePostName,
        fillDescAfterUploadImage: this.fillDescAfterUploadImage,
        afterSelectFile: this.afterSelectFile,
        pagebreakHtml: this.pagebreakHtml,
        allowImageRemote: this.allowImageRemote,
        autoHeightMode: this.autoHeightMode,
        fixToolBar: this.fixToolBar,
        tabIndex: this.tabIndex
      });
    }
  }
};