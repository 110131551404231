import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import { getWsToken } from '@/utils/auth';
export default {
  data: function data() {
    var _this = this;
    return {
      socket: '',
      socketConnect: false,
      // socket是否连接
      closeSelf: false,
      // 主动关闭
      lockReconnect: false,
      // 重连锁
      reconnect_timer: '',
      // 重连定时器
      hearCheck: {
        data: '{"type":"ping"}',
        timeout: 10000,
        timeoutObj: null,
        serverTimeoutObj: null,
        reset: function reset() {
          clearTimeout(_this.hearCheck.timeoutObj);
          clearTimeout(_this.hearCheck.serverTimeoutObj);
          return _this.hearCheck;
        },
        start: function start() {
          _this.hearCheck.timeoutObj = setTimeout(function () {
            _this.socket.send(_this.hearCheck.data);
            _this.hearCheck.serverTimeoutObj = setTimeout(function () {
              _this.socket.close();
            }, _this.hearCheck.timeout);
          }, _this.hearCheck.timeout);
        }
      }
    };
  },
  methods: {
    wsConnect: function wsConnect() {
      var _this2 = this;
      // 连接socket是被动连接
      this.closeSelf = false;
      // 创建socket连接
      this.socket = new WebSocket("".concat(this.wsUrl, "?token=").concat(getWsToken()));
      // 打开连接
      this.socket.onopen = function (res) {
        console.log(res, '建立连接');
        _this2.socketConnect = true;
        _this2.hearCheck.reset().start();
      };
      // 连接失败
      this.socket.onerror = function (res) {
        console.log(res, '失败');
        _this2.socketConnect = false;
        // 如果是主动关闭, 不重连
        if (_this2.closeSelf) return;
        _this2.reconnect();
      };
      // 连接断开
      this.socket.onclose = function (res) {
        console.log(res, '断开');
        _this2.socketConnect = false;
        // 如果是主动关闭, 不重连
        if (_this2.closeSelf) return;
        _this2.reconnect();
      };
      // 接收消息
      this.socket.onmessage = function (res) {
        var msg = JSON.parse(res.data);
        var data = msg.data || {};
        if (data.type === 'reload') {
          _this2.socketReloadHandle();
        } else if (data.type === 'data') {
          _this2.socketDataHandle(data.Room);
        }
        _this2.hearCheck.reset().start();
      };
    },
    // socket重连
    reconnect: function reconnect() {
      var _this3 = this;
      if (this.lockReconnect) return;
      this.lockReconnect = true;
      clearTimeout(this.reconnect_timer);
      this.reconnect_timer = setTimeout(function () {
        _this3.wsConnect();
        _this3.lockReconnect = false;
      }, 1000);
    },
    // 主动关闭连接
    close: function close() {
      if (!this.socket) return;
      this.closeSelf = true;
      this.hearCheck.reset();
      this.socket.close();
    }
  }
};