import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import inputText from '@/components/inputText';
import { adminParameter } from '@/api/public';
import { userAddList, userAdmin } from '@/api/clientSet';
import { getProject } from '@/utils/auth';
import utils from '@/utils/utils';
export default {
  name: 'EditUser',
  components: {
    inputText: inputText
  },
  data: function data() {
    var _this = this;
    var checkPhone = function checkPhone(rule, value, callback) {
      if (!value) {
        utils.jumpFun('tel');
        return callback(new Error('手机号不能为空'));
      } else {
        var reg = /^1\d{10}$/;
        if (reg.test(value)) {
          callback();
        } else {
          // 跳转去填写
          utils.jumpFun('tel');
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };

    // 产权人 姓名
    var checkTrendName = function checkTrendName(rule, value, callback) {
      if (_this.is_sign) {
        if (!value) {
          utils.jumpFun('renName');
          return callback(new Error('姓名不能为空'));
        } else if (value.length > 50) {
          return callback(new Error('姓名长度不能超过50个字'));
        } else if (/(\\)|(\/)|(&)|(\|)/.test(value)) {
          return callback(new Error('姓名不能包含 \\ / & | 符号'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    // 产权人 手机验证
    var checkTrendTel = function checkTrendTel(rule, value, callback) {
      if (_this.is_sign) {
        if (!value) {
          utils.jumpFun('renTel');
          return callback(new Error('手机号不能为空'));
        } else {
          var reg = /^1\d{10}$/;
          if (reg.test(value)) {
            callback();
          } else {
            utils.jumpFun('renTel');
            return callback(new Error('请输入正确的手机号'));
          }
        }
      } else {
        callback();
      }
    };

    // 产权人  身份证
    var checkTrendCard = function checkTrendCard(rule, value, callback) {
      if (_this.is_sign) {
        var cardReg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/;
        var HKReg = /[A-Za-z]{1}\d{6}\([0-9A-Za-z]\)/;
        var TWReg = /[A-Z]{1}\d{9}/;
        var AMReg = /[1|5|7]\d{6}\([0-9A-Za-z]\)/;
        if (cardReg.test(value) || HKReg.test(value) || TWReg.test(value) || AMReg.test(value) || !value) {
          callback();
        } else {
          utils.jumpFun('renCard');
          return callback(new Error('请输入正确的证件号码'));
        }
      } else {
        callback();
      }
    };

    // 姓名校验
    var checkName = function checkName(rule, value, callback) {
      if (!value) {
        return callback(new Error('请输入姓名'));
      } else if (value.length > 50) {
        return callback(new Error('姓名长度不能超过50个字'));
      } else if (/(\\)|(\/)|(&)|(\|)/.test(value)) {
        return callback(new Error('姓名不能包含\ / & | 符号'));
      } else {
        callback();
      }
    };
    return {
      // 页面全部数据
      isPC: utils.isPC(),
      // 页面全部数据
      dataForm: {
        name: ''
      },
      form: {
        user: {
          status: 1,
          buy_count: 1,
          buy_min_car: 0,
          buy_max_car: 0,
          tel: '',
          card: '',
          rand_sort: 0
        },
        user_audit_owner: [],
        price: {}
      },
      admin_id: '',
      //置业顾问id
      // 增加产权人button
      is_addButton: true,
      // 表单验证
      rules: {
        'user.name': [{
          required: true,
          trigger: 'blur',
          validator: checkName
        }],
        'user.tel': [{
          validator: checkPhone,
          trigger: 'blur',
          required: true
        }],
        'user.buy_count': [{
          required: true,
          message: '请输入限购数量',
          trigger: 'blur'
        }, {
          pattern: /^[0-9]*$/,
          message: '请录入大于或等于0的整数',
          trigger: 'blur'
        }, {
          trigger: 'change',
          validator: function validator(rule, value, callback) {
            if (parseInt(value) > 30) return callback("最大限购数量30");
            callback();
          }
        }],
        "user.buy_min_car": [{
          required: true,
          message: '请输入车位最小限制数量',
          trigger: 'blur'
        }, {
          pattern: /^[0-9]{1}[0-9]*$/,
          message: '范围在0大的整数',
          trigger: 'blur'
        }],
        "user.buy_max_car": [{
          required: true,
          message: '请输入车位最大限制数量',
          trigger: 'blur'
        }, {
          pattern: /^[0-9]{1}[0-9]*$/,
          message: '范围在0大的整数',
          trigger: 'blur'
        }]
      },
      // 产权人
      trendRules: {
        real_name: [{
          validator: checkTrendName,
          trigger: 'blur',
          required: true
        }],
        tel: [{
          validator: checkTrendTel,
          trigger: 'blur',
          required: true
        }],
        card: [{
          validator: checkTrendCard,
          trigger: 'blur'
        }]
      },
      // 置业顾问 数据
      userList: [],
      card_types: [],
      // 公共权限
      projectList: '',
      isInput: true,
      is_sign: false,
      show_is_buy_rand: false,
      // 0不开启 1以客户配比 2以房源配比
      matching_order: 0,
      is_must_buy_room: 0 // 1 启用 0 不
    };
  },
  created: function created() {
    this.getAdminList();
  },
  methods: {
    // 同步产权人
    handleSynchro: function handleSynchro(i) {
      if (this.form.user.name === '' || this.form.user.tel === '') {
        this.$message({
          type: 'warning',
          message: '客户信息为空不能同步'
        });
        return;
      }
      this.form.user_audit_owner[i].real_name = this.form.user.name;
      this.form.user_audit_owner[i].tel = this.form.user.tel;
      this.form.user_audit_owner[i].card = this.form.user.card;
    },
    //  获取分钟
    handleMin: function handleMin(value) {
      this.form.user.min = value;
    },
    // 获取的秒
    handleSec: function handleSec(value) {
      var _this2 = this;
      if (value > 59) {
        this.form.user.sec = '';
        this.isInput = false;
        this.$nextTick(function () {
          _this2.isInput = true;
        });
      } else {
        this.form.user.sec = value;
      }
    },
    // 获取置业顾问
    getAdminList: function getAdminList() {
      var _this3 = this;
      // 获取列表
      userAdmin().then(function (res) {
        var _res$data = res.data,
          list = _res$data.list,
          card_type = _res$data.card_type;
        _this3.userList = list;
        _this3.card_types = card_type;
      });

      // 获取公共权限
      adminParameter().then(function (res) {
        if (res.status === 200) {
          var _res$data2 = res.data,
            list = _res$data2.list,
            matching_order = _res$data2.matching_order,
            is_must_buy_room = _res$data2.is_must_buy_room;
          _this3.projectList = list;
          // 只要SAAS端开启了协助选房模式，或者开启了签约环节（无论在线还是线下）
          _this3.is_sign = list.buy_type === 2 || list.is_display.includes('sign_up');
          // 是否显示 测试摇号结果
          _this3.show_is_buy_rand = list.is_display.includes('buy_rand');
          // 是否开启配比认购
          _this3.matching_order = matching_order;
          _this3.is_must_buy_room = is_must_buy_room;
          // 不填签约
          if (_this3.is_sign) {
            _this3.form.user_audit_owner.push({
              real_name: '',
              tel: '',
              card: '',
              address: '',
              tx_address: '',
              card_type: _this3.card_types.length ? _this3.card_types[0].card_type : ""
            });
          }
        }
      });
    },
    // 提交新增
    handleSave: function handleSave() {
      var _this4 = this;
      // 表单验证 是否通过
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          // 提交数据
          if (_this4.form.user.price !== undefined) {
            _this4.form.user.price = parseFloat(_this4.form.user.price);
          }
          var auditList = _this4.form.user_audit_owner;
          _this4.form.user.admin_id = _this4.admin_id === '' ? 0 : _this4.admin_id; //置业顾问的值不能为空字符串
          // SaaS 端开启签约
          if (_this4.is_sign) {
            if (auditList.length === 0 || auditList[0].real_name === '' || auditList[0].tel === '') {
              _this4.$message({
                type: 'warning',
                message: '产权人1，不能为空，可以点击同步补充'
              });
              // 跳转去填写
              var checkCard = document.querySelectorAll('.checkTrend');
              checkCard[0].scrollIntoView({
                block: 'start',
                behavior: 'smooth'
              });
              return;
            }
            _this4.$refs['trendRules'].validate(function (valid) {
              if (valid) {
                _this4.getSaveData();
              }
            });

            // SaaS 未开启签约
          } else {
            // 循环过滤 没填的空数组
            auditList.forEach(function (item, index) {
              if (item.real_name === '' && item.tel === '' && item.card === '' && item.address === '') {
                auditList.splice(index, 1);
              }
            });
            _this4.getSaveData();
          }
        } else {
          return false;
        }
      });
    },
    // 封装提交
    getSaveData: function getSaveData() {
      var _this5 = this;
      this.form.user.num = this.form.user.num == '' ? 0 : this.form.user.num;
      if (this.projectList.cate_room && this.form.price.room_price > 0) {
        if (this.form.price.room_number === '' || this.form.price.room_number == undefined) {
          this.$message({
            type: 'error',
            message: '请填写住宅诚意金份数'
          });
          utils.jumpFun('jumpRoom');
          return;
        }
      } else if (this.projectList.cate_room && (this.form.price.room_price <= 0 || !this.form.price.room_price)) {
        this.form.price.room_price = 0;
        this.form.price.room_number = 0;
      }
      if (this.projectList.cate_villa && this.form.price.villa_price > 0) {
        if (this.form.price.villa_number === '' || this.form.price.villa_number == undefined) {
          this.$message({
            type: 'error',
            message: '请填写别墅诚意金份数'
          });
          utils.jumpFun('jumpVilla');
          return;
        }
      } else if (this.projectList.cate_villa && (this.form.price.villa_price <= 0 || !this.form.price.villa_price)) {
        this.form.price.villa_price = 0;
        this.form.price.villa_number = 0;
      }
      if (this.projectList.cate_car && this.form.price.car_price > 0) {
        if (this.form.price.car_number === '' || this.form.price.car_number == undefined) {
          this.$message({
            type: 'error',
            message: '请填写车位诚意金份数'
          });
          utils.jumpFun('jumpCar');
          return;
        }
      } else if (this.projectList.cate_car && (this.form.price.car_price <= 0 || !this.form.price.car_price)) {
        this.form.price.car_price = 0;
        this.form.price.car_number = 0;
      }
      if (this.projectList.cate_shops && this.form.price.shops_price > 0) {
        if (this.form.price.shops_number === '' || this.form.price.shops_number == undefined) {
          this.$message({
            type: 'error',
            message: '请填写商铺诚意金份数'
          });
          utils.jumpFun('jumpShop');
          return;
        }
      } else if (this.projectList.cate_shops && (this.form.price.shops_price <= 0 || !this.form.price.shops_price)) {
        this.form.price.shops_price = 0;
        this.form.price.shops_number = 0;
      }
      this.form.user.rand_sort = this.form.user.rand_sort ? parseInt(this.form.user.rand_sort) : 0;
      userAddList(this.form).then(function (res) {
        if (res.status === 200) {
          _this5.$message({
            type: 'success',
            message: res.data.msg
          });
          if (_this5.form.user_audit_owner.length < 1) {
            var listData = {
              real_name: '',
              tel: '',
              card: '',
              address: '',
              tx_address: '',
              card_type: _this5.card_types.length ? _this5.card_types[0].card_type : ""
            };
            _this5.form.user_audit_owner.push(listData);
          }

          // 修改成功跳详情
          _this5.$router.push({
            name: 'UserDetails',
            query: {
              id: res.data.data,
              round: _this5.$route.query.round
            }
          });
        }
      }).catch(function (err) {
        if (_this5.form.user_audit_owner.length < 1) {
          var listData = {
            real_name: '',
            tel: '',
            card: '',
            address: '',
            tx_address: '',
            card_type: _this5.card_types.length ? _this5.card_types[0].card_type : ""
          };
          _this5.form.user_audit_owner.push(listData);
        }
      });
    },
    // 删除 产权人
    handleDelList: function handleDelList(inx) {
      var _this6 = this;
      this.$confirm('此操作不可复原，确认删除吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        _this6.form.user_audit_owner.splice(inx, 1);
        _this6.$message({
          message: '删除成功',
          type: 'success'
        });
        if (_this6.form.user_audit_owner.length == 5) {
          _this6.is_addButton = false;
          return;
        } else {
          _this6.is_addButton = true;
        }
      });
    },
    // 新增 产权人
    handleAddList: function handleAddList() {
      var listData = {
        real_name: '',
        tel: '',
        card: '',
        address: '',
        tx_address: '',
        card_type: this.card_types.length ? this.card_types[0].card_type : ""
      };
      this.form.user_audit_owner.push(listData);
      if (this.form.user_audit_owner.length == 5) {
        this.is_addButton = false;
        return;
      } else {
        this.is_addButton = true;
      }
    },
    // 车位最小限制数量blur
    onInputUserBlur: function onInputUserBlur(key) {
      if (key === "buy_min_car") {
        if (this.form.user.buy_min_car > this.form.user.buy_max_car) {
          this.form.user.buy_min_car = this.form.user.buy_max_car > 0 ? this.form.user.buy_max_car : 0;
          this.$message({
            message: '车位最小限制数量不能大于最大限制',
            type: 'info'
          });
        } else if (this.form.user.buy_min_car < 0) {
          this.form.user.buy_min_car = 0;
        }
      } else if (key === "buy_max_car") {
        if (this.form.user.buy_max_car < this.form.user.buy_min_car) {
          this.form.user.buy_max_car = this.form.user.buy_min_car > 0 ? this.form.user.buy_min_car : 0;
          this.$message({
            message: '车位最大限制数量不能小于最小限制',
            type: 'info'
          });
        } else if (this.form.user.buy_max_car < 0) {
          this.form.user.buy_max_car = 0;
        }
      }
    }
  }
};