var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "title" }, [
    _c("span", { staticClass: "title-left" }, [_vm._v(_vm._s(_vm.title))]),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showR,
            expression: "showR",
          },
        ],
        staticClass: "title-right",
        on: {
          click: function ($event) {
            return _vm.$emit("resetBeforeImg")
          },
        },
      },
      [_vm._v(_vm._s(_vm.titleR))]
    ),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showSwitch,
            expression: "showSwitch",
          },
        ],
        staticClass: "title-right",
      },
      [
        _c(
          "el-tooltip",
          {
            staticClass: "item",
            attrs: {
              effect: "dark",
              content: _vm.show !== 0 ? "不显示" : "显示",
              placement: "top",
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "redEye",
                class: _vm.show !== 0 ? "eyeBg" : "eyeOpenBg",
                on: { click: _vm.onSetShow },
              },
              [
                _c("svg-icon", {
                  staticStyle: { "font-size": "22px" },
                  attrs: {
                    "class-name": "color-hui",
                    "icon-class": _vm.show !== 0 ? "eye" : "eye-open",
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }