import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import showCardVue from '../component/showCard.vue';
import noticeBar from '../component/notice.vue';
import api from '../../../api/lotteryEntry';
import { getWsToken } from '@/utils/auth';
export default {
  components: {
    showCardVue: showCardVue,
    noticeBar: noticeBar
  },
  data: function data() {
    return {
      config: {
        is_max_round: true,
        // 是否有最新一轮 如果是false 就表示一轮记录都没有 页面呈初始化阶段
        log_code: [],
        // 此轮摇号记录详情
        rand: {},
        // 录入式摇号规则
        rand_log: {},
        // 生成记录
        repeat_msg: '',
        // 提示与几轮重复
        repeat_num: [],
        // 最新一轮与那一轮重复 重复轮轮都要高亮 （这是个数组）
        round: {} // 轮次记录
      },
      digitization: function digitization(num) {
        return num.match(/\d+(.\d+)?/g)[0];
      },
      bgc: function bgc(is_repeat, is_waste) {
        // if (is_waste) return 'rgba(38, 43, 41, 0.4)'
        // if (is_repeat) return 'rgba(255, 191, 0, 0.3)'
        // return 'rgba(255,255,255, 0.1)'
        if (is_waste) return 'rgb(38, 43, 41)';
        if (is_repeat) return 'rgb(255, 191, 0)';
        return 'rgb(255,255,255)';
      }
    };
  },
  created: function created() {
    document.title = '摇号大屏';
    this.main();
    this.getData();
  },
  methods: {
    main: function main() {
      var _this = this;
      // ----------------websocket------------------------
      var ws;
      var tt;
      var lockReconnect = false; // 避免重复连接
      var self = this;
      var websocket = {
        Init: function Init() {
          ws = new WebSocket("".concat(_this.wsUrl, "?token=").concat(getWsToken()));
          ws.onmessage = function (e) {
            var msg = JSON.parse(e.data);
            self.socketListen(msg); // 处理数据
            heart.start();
          };
          ws.onclose = function () {
            retry();
          };
          ws.onopen = function () {
            heart.start();
          };
          ws.onerror = function () {
            retry();
          };
        }
      };

      // 重连
      function retry() {
        if (lockReconnect) return;
        lockReconnect = true;
        tt && clearTimeout(tt);
        tt = setTimeout(function () {
          websocket.Init();
          lockReconnect = false;
        }, 4000);
      }
      // 心跳
      var heart = {
        timeout: 1000 * 100,
        timeoutObj: null,
        serverTimeoutObj: null,
        start: function start() {
          var _this2 = this;
          this.timeoutObj && clearTimeout(this.timeoutObj);
          this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
          this.timeoutObj = setTimeout(function () {
            ws.send('{"type":"ping"}');
            _this2.serverTimeoutObj = setTimeout(function () {
              if (ws.readyState != 1) ws.close();
            }, _this2.timeout);
          }, this.timeout);
        }
      };
      websocket.Init();
    },
    // socket 信息监听
    socketListen: function socketListen(msg) {
      var round = this.config.round;
      var type = msg.data ? msg.data.type : msg.mag;
      if (type == 'entry_help' || type == 'entry_reset') {
        if (round.num == 0) round.num = 1;else round.num += 1;
        this.getData();
      }
    },
    // 获取数据
    getData: function getData() {
      var _this3 = this;
      api.getLast().then(function (result) {
        var data = result.data.data;
        // data.round.chinese = this.convertToChinaNum(data.round.num)
        _this3.config = data;
      }).finally(function () {
        if (!_this3.config.round.num) _this3.config.round.num = '1';
      });
    },
    // 数字转汉字数字
    convertToChinaNum: function convertToChinaNum(num) {
      var chinese = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
      var unit = ['', '十', '百', '千', '万', '十', '百', '千', '亿']; // 可继续追加更高位转换值
      if (!num || isNaN(num)) return '零';
      var english = num.toString().split('');
      var result = '';
      for (var i = 0; i < english.length; i++) {
        var des_i = english.length - 1 - i; // 倒序排列设值
        result = unit[i] + result;
        var chinese_index = english[des_i];
        result = chinese[chinese_index] + result;
      }
      // 将【零千、零百】换成【零】 【十零】换成【十】
      result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');
      // 合并中间多个零为一个零
      result = result.replace(/零+/g, '零');
      // 将【零亿】换成【亿】【零万】换成【万】
      result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');
      // 将【亿万】换成【亿】
      result = result.replace(/亿万/g, '亿');
      // 移除末尾的零
      result = result.replace(/零+$/, '');
      // 将【一十】换成【十】
      result = result.replace(/^一十/g, '十');
      return result;
    }
  }
};