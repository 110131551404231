import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import screenfull from '@/components/my-screen';
import Api from '../../../api/rowNumber';
import { debounce } from '@/utils/debounce';
import editFormatMixin from '@/views/editFormat/editFormatMixin';
export default {
  name: 'Lottery',
  components: {
    screenfull: screenfull
  },
  mixins: [editFormatMixin],
  data: function data() {
    return {
      styleObject: {},
      // 基本数据
      rand: {},
      // 摇号组
      group: [],
      list: {},
      start_name: '开始排号'
    };
  },
  created: function created() {
    this.getDataIndex();
  },
  methods: {
    // 去摇号
    handleStartLottery: debounce(function () {
      // 获取摇号结果
      var data = {
        id: this.rand.id,
        round: 1,
        type: 'row'
      };
      this.$router.push({
        name: 'NumberOrder',
        query: data
      });
    }, 200, true),
    //    获取主页 基础数据
    getDataIndex: function getDataIndex() {
      var _this = this;
      Api.numberRandIndex({
        id: this.$route.query.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data$list = res.data.list,
            group = _res$data$list.group,
            rand = _res$data$list.rand,
            group_id = _res$data$list.group_id,
            start_name = _res$data$list.start_name;
          _this.rand = rand;
          // this.styleObject = {
          // 	background: `url(${rand.logo})`,
          // };
          _this.group = group;
          _this.start_name = start_name;
          _this.group_id = group_id;
          _this.list = res.data.list;
        }
      });
    }
  }
};