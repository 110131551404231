import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vueSeamlessScroll from 'vue-seamless-scroll';
import { pingPongPublicity } from "@/api/formulaLottery";
import { getWsToken } from "@/utils/auth";
export default {
  name: 'formulaPublicity',
  components: {
    vueSeamlessScroll: vueSeamlessScroll
  },
  data: function data() {
    return {
      imgUrl: 'https://oss-cn-xm-jfcloud-d01-a.ops.jfcloud.chinacdc.com/',
      scroll: 'vueSeamlessScroll',
      // 滚动配置
      classOption: {
        step: 1,
        // 数值越大速度滚动越快
        limitMoveNum: 60,
        // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false,
        // 是否开启鼠标悬停stop
        direction: 1,
        // 0向下 1向上 2向左 3向右
        openWatch: true,
        // 开启数据实时监控刷新dom
        singleHeight: 0,
        // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0,
        // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000,
        // 单步运动停止的时间(默认值1000ms)
        autoPlay: true,
        // 自动播放
        speed: 1
      },
      lotteryLog: [],
      window_list: [],
      lottery: {},
      limit: 50,
      max_code: '001',
      isPoll: false
    };
  },
  created: function created() {
    var _this = this;
    if (this.$route.query.roll_speed) {
      this.classOption.step = this.$route.query.roll_speed;
    }
    this.getPublicity().then(function () {
      _this.Pollinglist();
    });
    this.main();
  },
  mounted: function mounted() {
    this.classOption.limitMoveNum = Math.ceil(this.$refs.scrollRef.clientHeight / 90); //计算多少条数据开始滚动
  },
  methods: {
    Pollinglist: function Pollinglist() {
      var _this2 = this;
      setInterval(function () {
        if (_this2.lotteryLog.length > _this2.classOption.limitMoveNum && _this2.isPoll) {
          _this2.getPublicity2();
        }
      }, 3500);
    },
    getPublicity2: function getPublicity2() {
      var _this3 = this;
      pingPongPublicity({
        limit: this.limit,
        max_code: this.max_code
      }).then(function (res) {
        var _res$data$info = res.data.info,
          log_list = _res$data$info.log_list,
          window_list = _res$data$info.window_list,
          lottery = _res$data$info.lottery;
        if (log_list && log_list.length) {
          _this3.lotteryLog = _this3.lotteryLog.concat(log_list);
          _this3.max_code = log_list[log_list.length - 1].code;
        }
      });
    },
    // 滚动完毕,加载新的数据
    rollComplete: function rollComplete() {
      console.log('滚动完成');
      // this.max_code = this.lotteryLog[this.lotteryLog.length  - 1].code
      this.max_code = '001';
      this.getPublicity();
    },
    // socket 信息监听
    socketListen: function socketListen(msg) {
      var type = msg.data ? msg.data.type : msg.mag;
      var rand_status = msg.data.rand_status;
      if (type == 'ping_pang_lottery') {
        if (rand_status == "reset") {
          //重置摇号
          this.max_code = '001';
          this.getPublicity();
        } else {
          //录入推送
          this.lefData(msg.data);
          if (this.lotteryLog.length < this.classOption.limitMoveNum) {
            this.getPublicity(false);
          }
        }
      }
    },
    main: function main() {
      var _this4 = this;
      var ws;
      var tt;
      var lockReconnect = false; // 避免重复连接
      var self = this;
      var websocket = {
        Init: function Init() {
          ws = new WebSocket("".concat(_this4.wsUrl, "?token=").concat(getWsToken()));
          ws.onmessage = function (e) {
            var msg = JSON.parse(e.data);
            self.socketListen(msg); // 处理数据
            heart.start();
          };
          ws.onclose = function () {
            retry();
          };
          ws.onopen = function () {
            heart.start();
          };
          ws.onerror = function () {
            retry();
          };
        }
      };
      // 重连
      function retry() {
        if (lockReconnect) return;
        lockReconnect = true;
        tt && clearTimeout(tt);
        tt = setTimeout(function () {
          websocket.Init();
          lockReconnect = false;
        }, 4000);
      }
      // 心跳
      var heart = {
        timeout: 1000 * 100,
        timeoutObj: null,
        serverTimeoutObj: null,
        start: function start() {
          var _this5 = this;
          this.timeoutObj && clearTimeout(this.timeoutObj);
          this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
          this.timeoutObj = setTimeout(function () {
            ws.send('{"type":"ping"}');
            _this5.serverTimeoutObj = setTimeout(function () {
              if (ws.readyState != 1) ws.close();
            }, _this5.timeout);
          }, this.timeout);
        }
      };
      websocket.Init();
    },
    lefData: function lefData(msg) {
      //左侧窗口处理 左侧窗口不走接口
      for (var i = 0; i < this.window_list.length; i++) {
        if (this.window_list[i].id == msg.admin_id) {
          this.window_list[i].new_code_info.code = msg.code;
          this.window_list[i].new_code_info.status = msg.status;
        }
      }
      this.$forceUpdate();
    },
    // 获取右侧排序汇总
    getPublicity: function getPublicity() {
      var _this6 = this;
      var iswindow = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var msg = arguments.length > 1 ? arguments[1] : undefined;
      this.isPoll = false;
      return new Promise(function (resolve) {
        pingPongPublicity({
          limit: _this6.limit,
          max_code: _this6.max_code
        }).then(function (res) {
          var _res$data$info2 = res.data.info,
            log_list = _res$data$info2.log_list,
            window_list = _res$data$info2.window_list,
            lottery = _res$data$info2.lottery;
          if (log_list && log_list.length) {
            _this6.lotteryLog = log_list;
            _this6.max_code = log_list[log_list.length - 1].code;
          } else {
            _this6.lotteryLog = [];
            _this6.max_code = '001';
          }
          //  for(let i = 0 ;i<2000;i++){
          //   let obj = {user_code:'002',code:'122222',other_remark:'123'}
          //   this.lotteryLog.push(obj)
          //  }

          if (iswindow) {
            _this6.window_list = window_list || [];
          }
          _this6.lottery = lottery;
          _this6.isPoll = true;
          resolve();
        });
      });
    }
  }
};