import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getRepeat, generate } from "@/api/formulaLottery";
export default {
  name: 'enterThenNumber',
  props: {
    number: {
      type: Number,
      default: 6
    },
    userInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      code_status: 0,
      disabledInput: false,
      bfb: 0,
      value: {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        7: '',
        8: '',
        9: ''
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.bfb = "width: calc(".concat(Math.floor(100 / this.number), "% - 20px)");
    console.log(this.bfb);
    this.$refs["input".concat(1)][0].focus();
    if (this.userInfo.rand_time > 0 && this.userInfo.rand_name) {
      this.disabledInput = true;
      for (var i = 0; i < this.userInfo.rand_name.length; i++) {
        this.value[i + 1] = this.userInfo.rand_name[i];
      }
    }
  },
  methods: {
    inputValue: function inputValue(val, item) {
      console.log(val);
      var nextIndex;
      if (val.length == 1) {
        nextIndex = item + 1;
        if (item == this.number) {
          //最后一个输入框
          this.$refs["input".concat(item)][0].blur();
          return;
        }
        this.$refs["input".concat(nextIndex)][0].focus();
      } else if (!val.length) {
        nextIndex = item - 1;
        if (item == 1) {
          return;
        }
        this.$refs["input".concat(nextIndex)][0].focus();
      } else {
        this.value[item] = val.split('')[1];
      }
    },
    confirm: function confirm() {
      var _this = this;
      var val = '';
      var flag = true;
      for (var key in this.value) {
        val += this.value[key];
        if (key <= this.number && this.value[key] === '') {
          flag = false;
        }
      }
      if (flag) {
        getRepeat({
          code: val,
          user_id: this.userInfo.id
        }).then(function (res) {
          var code_status = res.data.data.code_status;
          _this.code_status = code_status;
          if (code_status === 0) {
            _this.generateResult({
              code: val,
              user_id: _this.userInfo.id
            });
          } else {
            _this.generateResult({
              code: val,
              user_id: _this.userInfo.id
            }, true);
            _this.value = {
              1: '',
              2: '',
              3: '',
              4: '',
              5: '',
              6: '',
              7: '',
              8: '',
              9: ''
            };
            // this.$confirm('重复号码，是否继续?', '提示',{ confirmButtonText: '继续录入',
            //   cancelButtonText: '重新录入',type: 'warning'}).then(() => {
            //   this.generateResult({code:val,user_id:this.userInfo.id})
            // }).catch(() => {
            //   this.code_status = 0
            //   this.value = {
            //     1:'',
            //     2:'',
            //     3:'',
            //     4:'',
            //     5:'',
            //     6:'',
            //     7:'',
            //     8:'',
            //     9:'',
            //   }
            // });
          }
        });
        this.$emit('confirm', val);
      } else {
        this.$message({
          type: 'warning',
          message: '请填写完整'
        });
      }
    },
    generateResult: function generateResult(params, isdup) {
      var _this2 = this;
      return new Promise(function (resolve) {
        generate(params).then(function (res) {
          if (isdup) {
            _this2.disabledInput = false;
          } else {
            _this2.disabledInput = true;
            _this2.$message({
              type: 'success',
              message: '摇号成功'
            });
          }
        });
      });
    }
  }
};