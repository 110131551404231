var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "top-fixed" }, [
      _c(
        "div",
        { staticClass: "title-top-box" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(
              "修改" +
                _vm._s(_vm.customSetting.villa_setting.house_res) +
                "详情"
            ),
          ]),
          _c(
            "el-button",
            {
              staticClass: "greens-btn",
              attrs: { size: "small" },
              on: { click: _vm.handleSave },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "detail-box mT16" },
      [
        _c(
          "el-form",
          { ref: "form", attrs: { model: _vm.form, "label-width": "120px" } },
          [
            _c("div", { staticClass: "block" }, [
              _c("div", { staticClass: "title-h2" }, [
                _vm._v(
                  _vm._s(_vm.customSetting.villa_setting.house_res) + "信息 "
                ),
                _c("span", { staticClass: "orange" }, [
                  _vm._v(_vm._s(_vm.form.room.room)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "model" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "是否非售：" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      staticStyle: { width: "100%" },
                                      model: {
                                        value: _vm.form.room.status,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form.room, "status", $$v)
                                        },
                                        expression: "form.room.status",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 0 } },
                                            [_vm._v("否")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: -1 } },
                                            [_vm._v("是")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.form.room.status === -1
                            ? _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "非售原因" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          size: "small",
                                          maxlength: "6",
                                          "show-word-limit": "",
                                        },
                                        model: {
                                          value: _vm.form.room.not_desc,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.room,
                                              "not_desc",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "form.room.not_desc",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      "*" +
                                      _vm.customSetting.villa_setting
                                        .house_type +
                                      "：",
                                    prop: "room.room_cate_id",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        size: "small",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.form.room.room_cate_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.room,
                                            "room_cate_id",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "form.room.room_cate_id",
                                      },
                                    },
                                    _vm._l(_vm.carCate, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "建筑面积：" } },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        size: "small",
                                        max: 5,
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.form.room.build_area,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.room,
                                            "build_area",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "form.room.build_area",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("㎡"),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "套内面积：" } },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        size: "small",
                                        max: 5,
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.form.room.room_area,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.room,
                                            "room_area",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "form.room.room_area",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("㎡"),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 16 } }),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: { label: "*总价：" },
                                },
                                [
                                  _vm.projectList.room_info_price_type === 1
                                    ? [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "jump1",
                                            attrs: { span: 8 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "现价",
                                                  "label-width": "55px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    attrs: {
                                                      type: "number",
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.room
                                                          .old_total_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.room,
                                                          "old_total_price",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "form.room.old_total_price",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm.projectList.room_info_price_type === 2
                                    ? [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "jump3",
                                            attrs: { span: 8 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "原价",
                                                  "label-width": "55px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    attrs: {
                                                      type: "number",
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.room
                                                          .old_total_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.room,
                                                          "old_total_price",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "form.room.old_total_price",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "jump4",
                                            attrs: { span: 8 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "优惠价",
                                                  "label-width": "55px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    attrs: {
                                                      type: "number",
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.room
                                                          .discount_total_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.room,
                                                          "discount_total_price",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "form.room.discount_total_price",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "jump7",
                                            attrs: { span: 8 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "标准价",
                                                  "label-width": "55px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    attrs: {
                                                      type: "number",
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.room
                                                          .old_total_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.room,
                                                          "old_total_price",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "form.room.old_total_price",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "jump9",
                                            attrs: { span: 8 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "一次性",
                                                  "label-width": "55px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    attrs: {
                                                      type: "number",
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.room
                                                          .one_off_total_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.room,
                                                          "one_off_total_price",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "form.room.one_off_total_price",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "jump8",
                                            attrs: { span: 8 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "按揭",
                                                  "label-width": "55px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    attrs: {
                                                      type: "number",
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.room
                                                          .pin_an_jie_total_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.room,
                                                          "pin_an_jie_total_price",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "form.room.pin_an_jie_total_price",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: { label: "*单价：" },
                                },
                                [
                                  _vm.projectList.room_info_price_type === 1
                                    ? [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "jump2",
                                            attrs: { span: 8 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "现价",
                                                  "label-width": "55px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    attrs: {
                                                      type: "number",
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.room
                                                          .old_unit_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.room,
                                                          "old_unit_price",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "form.room.old_unit_price",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm.projectList.room_info_price_type === 2
                                    ? [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "jump5",
                                            attrs: { span: 8 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "原价",
                                                  "label-width": "55px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    attrs: {
                                                      type: "number",
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.room
                                                          .old_unit_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.room,
                                                          "old_unit_price",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "form.room.old_unit_price",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "jump6",
                                            attrs: { span: 8 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "优惠价",
                                                  "label-width": "55px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    attrs: {
                                                      type: "number",
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.room
                                                          .discount_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.room,
                                                          "discount_price",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "form.room.discount_price",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "jump10",
                                            attrs: { span: 8 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "标准价",
                                                  "label-width": "55px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    attrs: {
                                                      type: "number",
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.room
                                                          .old_unit_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.room,
                                                          "old_unit_price",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "form.room.old_unit_price",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "jump12",
                                            attrs: { span: 8 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "一次性",
                                                  "label-width": "55px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    attrs: {
                                                      type: "number",
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.room
                                                          .one_off_unit_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.room,
                                                          "one_off_unit_price",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "form.room.one_off_unit_price",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "jump11",
                                            attrs: { span: 8 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "按揭",
                                                  "label-width": "55px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    attrs: {
                                                      type: "number",
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.room
                                                          .pin_an_jie_unit_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.room,
                                                          "pin_an_jie_unit_price",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "form.room.pin_an_jie_unit_price",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "分类：" } },
                                [
                                  _c("el-input", {
                                    attrs: { size: "small" },
                                    model: {
                                      value: _vm.form.room.room_tag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.room,
                                          "room_tag",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.room.room_tag",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      _vm.projectList.pin_fu_zen +
                                      (_vm.projectList.pin_fu_zen_product ||
                                        "信息") +
                                      "：",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { size: "small", type: "textarea" },
                                    model: {
                                      value: _vm.form.room.gift_desc,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.room,
                                          "gift_desc",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.room.gift_desc",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      _vm.projectList.pin_fu_zen +
                                      (_vm.projectList.pin_fu_zen_price ||
                                        "价值") +
                                      "：",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { size: "small" },
                                    model: {
                                      value: _vm.form.room.gift_price,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.room,
                                          "gift_price",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.room.gift_price",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "其他系统FID：" } },
                                [
                                  _c("el-input", {
                                    attrs: { size: "small" },
                                    model: {
                                      value: _vm.form.room.other_f_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.room,
                                          "other_f_id",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.room.other_f_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 16 } }),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "描述：" } },
                                [
                                  _c("el-input", {
                                    attrs: { size: "small", type: "textarea" },
                                    model: {
                                      value: _vm.form.room.desc,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.room,
                                          "desc",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.room.desc",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "其他信息" } },
                                [
                                  _c("el-input", {
                                    attrs: { size: "small", type: "textarea" },
                                    model: {
                                      value: _vm.form.room.other,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.room,
                                          "other",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.room.other",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm.$route.query.round
              ? _c("div", { staticClass: "block mT15" }, [
                  _c("div", { staticClass: "title-h2" }, [
                    _vm._v("关联场次 "),
                    _c("span", [_vm._v("请在【开盘客户】页面，点击关联场次")]),
                  ]),
                ])
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }