import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vanSwitch from '@/components/switch';
import customTable from './components/customTable';
import schedule from './review/schedule';
import reviewDetails from './review/reviewDetails.vue';
import editReviewDetails from './review/editReviewDetails.vue';
import toolReviewMixin from './toolReviewMixin';
import { auditListAlone, auditListFirst, auditListSecond, transferToCustomer, exportAuditUser, adminSetting, batchReview, auditDelAll } from '@/api/pledge';
import { sign } from '@/api/sincerityRegister';
import store from '@/store';
import { mapState } from 'vuex';
import utils from "@/utils/utils";
export default {
  name: 'Review',
  components: {
    vanSwitch: vanSwitch,
    customTable: customTable,
    schedule: schedule,
    reviewDetails: reviewDetails,
    editReviewDetails: editReviewDetails
  },
  mixins: [toolReviewMixin],
  data: function data() {
    return {
      // switch 按钮数据
      switchList: [{
        title: '单点',
        id: 2,
        num: ''
      }, {
        title: '初审',
        id: 0,
        num: ''
      }, {
        title: '复审',
        id: 1,
        num: ''
      }],
      // 表格高度
      appHeight: '',
      topHeight: '',
      // 总数
      total: 0,
      // 页数 page
      page: 1,
      // 条数限制
      limit: 10,
      // 二级菜单切换
      chooseRadio: 1,
      // 转入状态 配置
      selectOption: [{
        id: 0,
        label: '全部'
      }, {
        id: 1,
        label: '未转入'
      }, {
        id: 2,
        label: '已转入'
      }],
      // 转入状态 选中值
      selectValue: 0,
      // 附表填写下拉选择配置
      fillInTable: [{
        id: 2,
        label: '全部'
      }, {
        id: 1,
        label: '填写完成'
      }, {
        id: 0,
        label: '填写未完成'
      }],
      // 附表填写下拉选择 选中值
      fillInTableValue: 2,
      // 搜索关键字
      searchText: '',
      // 初审0 复审1
      type: 0,
      // table 数据
      tableData: [],
      // 已选中 多少条数据
      choosedNum: 0,
      // 选中的数据
      choosedItem: [],
      // 驳回弹框
      rejectDialogVisible: false,
      // 驳回理由
      rejectText: '',
      // saas端配置
      saasSetting: {
        audit: {}
      },
      // 是否隐藏初审 复审按钮
      isHiddenBtn: true,
      // 待审核 通过 驳回 数量统计
      count_data: {},
      // 驳回类型 0 批量驳回 1批量拒绝
      rejectType: 0,
      // ----------table配置------------
      // column1 column2 column3 初审 (待审核 已通过 已驳回)
      column1: [{
        label: '姓名',
        prop: 'name'
      }, {
        label: '登录手机号',
        prop: 'tel'
      }, {
        label: '审核状态',
        prop: 'status',
        type: 'status'
      }, {
        label: '提交时间',
        prop: 'created_at',
        type: 'time'
      }
      // { label: '附表', prop: 'is_over', type: 'isOver' }
      ],
      column2: [{
        label: '姓名',
        prop: 'name'
      }, {
        label: '登录手机号',
        prop: 'tel'
      }, {
        label: '审核状态',
        prop: 'status',
        type: 'status'
      }, {
        label: '审核时间',
        prop: 'audit_at',
        type: 'time'
      }, {
        label: '审核人',
        prop: 'audit_name'
      }
      // { label: '转入成开盘客户', prop: 'is_into', type: 'custom' }
      ],
      column3: [{
        label: '姓名',
        prop: 'name'
      }, {
        label: '登录手机号',
        prop: 'tel'
      }, {
        label: '审核状态',
        prop: 'status',
        type: 'status'
      }, {
        label: '审核时间',
        prop: 'audit_at',
        type: 'time'
      }, {
        label: '审核人',
        prop: 'audit_name'
      }, {
        label: '驳回原因',
        prop: 'err_note'
      }],
      // column4 column5 column6 复审 (待审核 已通过 已驳回)
      column4: [{
        label: '姓名',
        prop: 'name'
      }, {
        label: '登录手机号',
        prop: 'tel'
      }, {
        label: '审核状态',
        prop: 'status',
        type: 'status'
      }, {
        label: '客户提交时间',
        prop: 'first_time',
        type: 'time'
      }, {
        label: '初审通过时间',
        prop: 'created_at',
        type: 'time'
      }, {
        label: '初审审核人',
        prop: 'create_name'
      }
      // { label: '附表', prop: 'is_over', type: 'isOver' }
      ],
      column5: [{
        label: '姓名',
        prop: 'name'
      }, {
        label: '登录手机号',
        prop: 'tel'
      }, {
        label: '审核状态',
        prop: 'status',
        type: 'status'
      }, {
        label: '审核时间',
        prop: 'audit_at',
        type: 'time'
      }, {
        label: '审核人',
        prop: 'audit_name'
      }
      // { label: '转入成开盘客户', prop: 'is_into', type: 'custom' }
      ],
      column6: [{
        label: '姓名',
        prop: 'name'
      }, {
        label: '登录手机号',
        prop: 'tel'
      }, {
        label: '审核状态',
        prop: 'status',
        type: 'status'
      }, {
        label: '审核时间',
        prop: 'audit_at',
        type: 'time'
      }, {
        label: '审核人',
        prop: 'audit_name'
      }, {
        label: '驳回原因',
        prop: 'err_note'
      }]
    };
  },
  computed: {
    // 此功能按钮只能对未开启在线认筹签约的数据生效，否则不显示
    // 同时此功能按钮只在已完成审核的已审核列表中显示：
    // a、如果是单审，则在初审已通过列表中显示
    // b、如果是复审，则在复审已通过列表中显示
    showConfirm: function showConfirm() {
      var _this$saasSetting = this.saasSetting,
        list = _this$saasSetting.list,
        audit = _this$saasSetting.audit;
      // 诚意签约未开启
      if (!(list !== null && list !== void 0 && list.is_identify_sign)) {
        // 单审
        if ((audit === null || audit === void 0 ? void 0 : audit.identify_examine_rule) === 1) {
          return this.type === 0 && this.chooseRadio === 2;
        }
        // 复审
        else if ((audit === null || audit === void 0 ? void 0 : audit.identify_examine_rule) === 2) {
          return this.type === 1 && this.chooseRadio === 2;
        }
        // 自动审核
        else if (!(audit !== null && audit !== void 0 && audit.identify_examine_rule)) {
          return false;
        }
      }
      // 开启不显示
      else {
        return false;
      }
    },
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 166;
    }
  },
  destroyed: function destroyed() {
    window.onresize = null;
  },
  mounted: function mounted() {
    var _this = this;
    this.appHeight = this.$refs.app.offsetHeight;
    this.topHeight = this.$refs.apptop.offsetHeight;
    window.onresize = function () {
      _this.appHeight = _this.$refs.app.offsetHeight;
      _this.topHeight = _this.$refs.apptop.offsetHeight;
    };
    this.getAdminSetting();
    // this.getAuditListFirst()
  },
  methods: {
    // 保存后刷新页面
    handleRefresh: function handleRefresh() {
      switch (this.type) {
        case 0:
          this.getAuditListFirst();
          break;
        case 1:
          this.getAuditListSecond();
          break;
        case 2:
          this.getAuditListAlone();
          break;
      }
    },
    // 批量操作
    handleCommand: function handleCommand(type) {
      switch (type) {
        case 'All':
          this.batchThrough();
          break;
        case 'All1':
          this.batchRejected(1);
          break;
        case 'All2':
          this.batchRejected(0);
          break;
        case 'All3':
          this.batchDelAll();
          break;
        case 'All4':
          this.batchTransfer();
          break;
        case 'confirm':
          this.signConfirm();
          break;
      }
    },
    // 签约确认
    signConfirm: function signConfirm() {
      var _this2 = this;
      if (this.choosedNum === 0) {
        this.$message({
          type: 'warning',
          message: '请勾选需要确认的客户'
        });
      } else {
        var _this$choosedItem;
        var list = (_this$choosedItem = this.choosedItem) !== null && _this$choosedItem !== void 0 ? _this$choosedItem : [];
        var ids = list.map(function (item) {
          return item.ar_id;
        });
        var params = {
          ids: ids,
          "types": 2 //1报名 2认筹
        };
        sign(params).then(function (res) {
          var _res$data$msg, _res$data;
          _this2.$message.success((_res$data$msg = (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.msg) !== null && _res$data$msg !== void 0 ? _res$data$msg : '');
        });
      }
    },
    // switch change
    bindSwitch: function bindSwitch(e) {
      this.type = e;
      // 二级菜单默认选中第一项
      this.chooseRadio = 1;
      // 初始化下拉选项的值
      this.selectValue = 0;
      // 初始化填写附表下拉框选项的值
      this.fillInTableValue = 2;
      // 初始化page
      this.page = 1;
      // 批量删除的数据
      this.choosedNum = 0;
      switch (this.type) {
        case 0:
          this.getAuditListFirst();
          break;
        case 1:
          this.getAuditListSecond();
          break;
        case 2:
          this.getAuditListAlone();
          break;
      }
    },
    // 二级按钮切换
    chooseRadioChange: function chooseRadioChange(e) {
      // 初始化下拉选项的值
      this.selectValue = 0;
      // 初始化填写附表下拉框选项的值
      this.fillInTableValue = 2;
      // 初始化page
      this.page = 1;
      // 批量删除的数据
      this.choosedNum = 0;
      if (this.type == 0) {
        this.getAuditListFirst();
      }
      if (this.type == 1) {
        this.getAuditListSecond();
      }
      if (this.type == 2) {
        this.getAuditListAlone();
      }
    },
    // 转入状态 下拉
    selectChange: function selectChange(e) {},
    // 批量通过 驳回 删除
    batchThrough: function batchThrough() {
      var _this3 = this;
      // saas端配置
      var audit = this.saasSetting.audit;
      if (this.choosedNum === 0) {
        this.$message({
          type: 'warning',
          message: '请勾选需要通过的客户'
        });
      } else {
        var list = this.choosedItem;
        var ids = [];
        list && list.forEach(function (item) {
          ids.push(item.id);
        });

        // 附表是否填写完成
        var isOver = true;
        if (audit.register_schedule == 1) {
          if (list[0].types == 'ide0' || audit.register_schedule_admin == 1 && list[0].types == 'ide1' || audit.register_schedule_admin == 2 && list[0].types == 'ide2') {
            list && list.forEach(function (item) {
              ids.push(item.id);
              if (item.is_over == 0) {
                isOver = false;
              }
            });
          }
        }
        var params = {
          ids: ids,
          type: 'commit',
          audit_types: list[0].types // 审核类型
        };
        if (!isOver) {
          // 如果有附表没有填
          this.$confirm('通过审核前请填写附表', '提示', {
            confirmButtonText: '去填附表',
            cancelButtonText: '取 消',
            type: 'warning'
          }).then(function () {
            var row = {};
            row.id = list[0].id;
            row.types = list[0].types;
            _this3.open_close_drawer(true);
            _this3.CHANGE_DRAWER_TYPE(0);
            _this3.CHANGE_ROW(row);
          }).catch(function () {
            return;
          });
        } else {
          this.$confirm('是否确认通过审核？', '提示', {
            confirmButtonText: '通 过',
            cancelButtonText: '取 消',
            type: 'warning'
          }).then(function () {
            batchReview(params).then(function (res) {
              _this3.$message({
                type: 'success',
                message: res.data.msg
              });
              _this3.reset();
            });
          }).catch(function () {
            return;
          });
        }
      }
    },
    batchRejected: function batchRejected(type) {
      var _this4 = this;
      this.rejectType = type;
      if (this.choosedNum === 0) {
        this.$message({
          type: 'warning',
          message: type ? '请勾选需要拒绝的客户' : '请勾选需要驳回的客户'
        });
      } else {
        if (type == 1) {
          // 批量拒绝
          this.$confirm('是否确认驳回，并拒绝客户再次提交？', {
            cancelButtonText: '取 消',
            confirmButtonText: '确 认',
            type: 'warning'
          }).then(function () {
            _this4.rejectDialogVisible = true;
          }).catch(function () {
            return;
          });
        } else {
          this.rejectDialogVisible = true;
        }
      }
    },
    batchDelAll: function batchDelAll() {
      var _this5 = this;
      if (this.choosedNum === 0) {
        this.$message({
          type: 'warning',
          message: '请勾选需要删除的客户'
        });
      } else {
        var ids = [];
        this.choosedItem && this.choosedItem.forEach(function (item) {
          ids.push(item.id);
        });
        var params = {
          ids: ids
        };
        this.$confirm('是否确认删除当前用户', '提示', {
          confirmButtonText: '确 认',
          cancelButtonText: '取 消',
          type: 'warning'
        }).then(function () {
          auditDelAll(params).then(function (res) {
            _this5.$message({
              type: 'success',
              message: res.data.msg
            });
            _this5.search();
          });
        }).catch(function () {
          return;
        });
      }
    },
    // 提交驳回理由
    submitRejectReason: function submitRejectReason() {
      var _this6 = this;
      var text = this.rejectText.trim();
      if (text == '') {
        this.$message({
          type: 'warning',
          message: '驳回理由不能为空'
        });
        return;
      }
      var list = this.choosedItem;
      var ids = [];
      list && list.forEach(function (item) {
        ids.push(item.id);
      });
      var params = {
        ids: ids,
        type: this.rejectType == 0 ? 'return' : 'refuse',
        audit_types: list[0].types,
        // 审核类型
        err_note: text
      };
      batchReview(params).then(function (res) {
        _this6.$message({
          type: 'success',
          message: res.data.msg
        });
        _this6.rejectText = '';
        _this6.reset();
        _this6.rejectDialogVisible = false;
      });
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(e) {
      this.page = e;
      if (this.type == 0) {
        this.getAuditListFirst();
      }
      if (this.type == 1) {
        this.getAuditListSecond();
      }
      if (this.type == 2) {
        this.getAuditListAlone();
      }
    },
    handleSizeChange: function handleSizeChange(e) {
      this.limit = e;
      if (this.type == 0) {
        this.getAuditListFirst();
      }
      if (this.type == 1) {
        this.getAuditListSecond();
      }
      if (this.type == 2) {
        this.getAuditListAlone();
      }
    },
    // 查询与重置
    search: function search() {
      this.page = 1;
      this.limit = 10;
      if (this.type == 0) {
        this.getAuditListFirst();
      }
      if (this.type == 1) {
        this.getAuditListSecond();
      }
      if (this.type == 2) {
        this.getAuditListAlone();
      }
    },
    reset: function reset() {
      this.page = 1;
      this.limit = 10;
      // 转入状态 下拉
      this.selectValue = 0;
      // 填写附表 下拉
      this.fillInTableValue = 2;
      this.searchText = '';
      if (this.type == 0) {
        this.getAuditListFirst();
      }
      if (this.type == 1) {
        this.getAuditListSecond();
      }
      if (this.type == 2) {
        this.getAuditListAlone();
      }
    },
    // 批量转入客户
    batchTransfer: function batchTransfer() {
      var _this7 = this;
      var self = this;
      if (this.choosedNum === 0) {
        this.$message({
          type: 'warning',
          message: '请勾选需要转入的客户'
        });
      } else {
        var list = this.choosedItem;
        var ids = [];
        list && list.forEach(function (item) {
          ids.push(item.id);
        });
        var params = {
          ids: ids
        };
        this.$confirm('是否确认把所选客户转成开盘客户？', '提示', {
          confirmButtonText: '确 认',
          cancelButtonText: '取 消',
          type: 'warning'
        }).then(function () {
          transferToCustomer(params).then(function (res) {
            if (res.data.data.err_count > 0) {
              var url = res.data.data.path;
              self.downloadFile("".concat(_this7.host).concat(url));
            }
            _this7.$message({
              type: 'success',
              message: res.data.msg
            });
            _this7.search();
          });
        }).catch(function () {
          return;
        });
      }
    },
    // 导出数据
    exportThrough: function exportThrough() {
      var loading = this.$loading({
        lock: true,
        text: '努力导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      exportAuditUser().then(function (res) {
        // let url = res.data.path
        // this.downloadFile(`${this.host}${url}`)
        utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
        loading.close();
      }).catch(function () {
        loading.close();
      });
    },
    // 已选中多少条数据
    checkedNum: function checkedNum(e) {
      this.choosedNum = e.length;
      this.choosedItem = e;
    },
    // ----------接口API----------------
    // 单点审核 列表
    getAuditListAlone: function getAuditListAlone() {
      var _this8 = this;
      var params = {
        page: this.page,
        limit: this.limit,
        name: this.searchText,
        is_into: this.selectValue,
        type: this.chooseRadio,
        is_over: this.fillInTableValue
      };
      auditListAlone(params).then(function (res) {
        if (res.data.list != null) {
          res.data.list.forEach(function (item) {
            item.tel = _this8.getTel(item.tel);
          });
        }
        _this8.tableData = res.data.list;
        _this8.total = res.data.count;
        _this8.count_data = res.data.count_data;
      });
    },
    // 初审 列表
    getAuditListFirst: function getAuditListFirst() {
      var _this9 = this;
      var params = {
        page: this.page,
        limit: this.limit,
        name: this.searchText,
        is_into: this.selectValue,
        type: this.chooseRadio,
        is_over: this.fillInTableValue
      };
      auditListFirst(params).then(function (res) {
        if (res.data.list != null) {
          res.data.list.forEach(function (item) {
            item.tel = _this9.getTel(item.tel);
          });
        }
        _this9.tableData = res.data.list;
        _this9.total = res.data.count;
        _this9.count_data = res.data.count_data;
      });
    },
    // 复审 列表
    getAuditListSecond: function getAuditListSecond() {
      var _this10 = this;
      var params = {
        page: this.page,
        limit: this.limit,
        name: this.searchText,
        is_into: this.selectValue,
        type: this.chooseRadio,
        is_over: this.fillInTableValue
      };
      auditListSecond(params).then(function (res) {
        if (res.data.list != null) {
          res.data.list.forEach(function (item) {
            item.tel = _this10.getTel(item.tel);
          });
        }
        _this10.tableData = res.data.list;
        _this10.total = res.data.count;
        _this10.count_data = res.data.count_data;
      });
    },
    // 文件下载
    downloadFile: function downloadFile(url) {
      var iframe = document.createElement('iframe');
      iframe.style.display = 'none'; // 防止影响页面
      iframe.style.height = 0; // 防止影响页面
      iframe.src = url;
      document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
      // 5分钟之后删除（onload方法对于下载链接不起作用）
      setTimeout(function () {
        iframe.remove();
      }, 5 * 60 * 1000);
    },
    // saas项目配置
    getAdminSetting: function getAdminSetting() {
      var _this11 = this;
      adminSetting().then(function (res) {
        var saasSetting = res.data;
        _this11.saasSetting = saasSetting;
        var roles = store.getters.roles;
        // audit/auditfirstlist  audit/auditcomplexlist audit/auditalonelist
        var switchList = [];
        // if (roles.includes('identifyaudit/auditalonelist')) {
        //   switchList.push({ title: '单点', id: 2, num: '' })
        // }

        if (roles.includes('identifyaudit/auditfirstlist')) {
          switchList.push({
            title: '初审',
            id: 0,
            num: ''
          });
        }
        if (roles.includes('identifyaudit/auditcomplexlist')) {
          switchList.push({
            title: '复审',
            id: 1,
            num: ''
          });
        }
        if (roles.includes('admin')) {
          switchList = [
          // { title: '单点', id: 2, num: '' },
          {
            title: '初审',
            id: 0,
            num: ''
          }, {
            title: '复审',
            id: 1,
            num: ''
          }];
        }

        // 如果只有单点则隐藏按钮
        if (switchList.length == 1 && switchList[0].id == 0) {
          switchList = [];
          _this11.type = 0;
          _this11.getAuditListFirst();
        }
        _this11.switchList = switchList;
        if (switchList[0] && switchList[0].id == 0) {
          _this11.type = 0;
          _this11.getAuditListFirst();
        } else if (switchList[0] && switchList[0].id == 1) {
          _this11.type = 1;
          _this11.getAuditListSecond();
        } else if (switchList[0] && switchList[0].id == 2) {
          _this11.type = 2;
          _this11.getAuditListAlone();
        }
      });
    }
  }
};