import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { AuditStatus0, AuditStatus1, AuditStatus2, exportAuditStatus0, exportAuditStatus1, exportAuditStatus2, AgainRejected, RejectAgain } from '@/api/sincerityRegister';
import moment from 'moment';
import { adminParameter } from '@/api/public';
import userRegister from '../components/user-register.vue';
import { mapGetters } from 'vuex';
import toolReviewMixin from '../toolReviewMixin';
import switchBtn from './components/switchBtn';
export default {
  name: 'ReviewDetails',
  components: {
    userRegister: userRegister,
    switchBtn: switchBtn
  },
  mixins: [toolReviewMixin],
  props: {
    row: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      activeName: '0',
      // 审核内容
      statusInfo: [],
      // 审核信息
      auditInfo: [],
      index: 1,
      info: {},
      //  是否显示填写附表按钮
      is_button: true,
      // 是否填写附表
      showChangeSchedule: false,
      // 打开弹窗
      isTips: false,
      // 驳回原因
      textarea: '',
      // 上次驳回原因
      isReason: false,
      // 判断 审核方式
      types: 'status1',
      // 驳回原因数据
      turnInfo: '',
      // 顶部div高度
      divHeight: 0,
      // 顶部button 判断
      intoInfo: {},
      // 日志info
      infoTips: [],
      checked: true,
      // saasSetting
      saasSetting: {
        audit: {}
      }
      // // 审核附表图片
      // attach_img_arr: [],
      // // 客户登记
      // img_arr: []
    };
  },
  computed: _objectSpread({}, mapGetters(['roles'])),
  created: function created() {
    this.getDataInfo();
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.divHeight = _this.$refs.topDiv.offsetHeight;
    });
  },
  methods: {
    // 打开驳回弹窗
    handleShowDialog: function handleShowDialog(type) {
      this.retrunType = type;
      this.isTips = true;
    },
    // 确定驳回
    handleChange: function handleChange() {
      this.handleTurnDown(this.retrunType);
    },
    // 获取页面数据
    getDataInfo: function getDataInfo() {
      var _this2 = this;
      // let types = this.$route.query.status;
      var row = this.toolReviewRow;
      var types = row.types;
      this.types = types;
      adminParameter().then(function (res) {
        if (res.status === 200) {
          var audit = res.data.audit;
          _this2.saasSetting = res.data;
          if (types === 'status0') {
            exportAuditStatus0({
              id: row.id
            }).then(function (res) {
              if (res.status === 200) {
                var _res$data = res.data,
                  auditInfo = _res$data.auditInfo,
                  info = _res$data.info;
                _this2.statusInfo = _this2.getInfoData(info.dataList);
                _this2.auditInfo = auditInfo;
                _this2.info = info.logList;
                _this2.turnInfo = info.turnInfo;
                _this2.intoInfo = info.intoInfo;
                _this2.adminInfo = info.adminInfo;
                _this2.createInfo = info.createInfo;
                _this2.is_button = _this2.getScheduleType(info.dataList);
                // 客户登记表 审核附表 图片
                _this2.CHANGE_ALL_IMG([info.attach_img_arr, info.img_arr]);
                _this2.getList();
                _this2.doShowParent();
                // 判断是否显示填写附表
                if (audit.register_schedule == 1) {
                  _this2.showChangeSchedule = true;
                }
              }
            });
          } else if (types === 'status1') {
            exportAuditStatus1({
              id: row.id
            }).then(function (res) {
              if (res.status === 200) {
                var _res$data2 = res.data,
                  auditInfo = _res$data2.auditInfo,
                  info = _res$data2.info;
                _this2.statusInfo = _this2.getInfoData(info.dataList);
                _this2.auditInfo = auditInfo;
                _this2.info = info.logList;
                _this2.turnInfo = info.turnInfo;
                _this2.intoInfo = info.intoInfo;
                _this2.createInfo = info.createInfo;
                _this2.adminInfo = info.adminInfo;
                _this2.is_button = _this2.getScheduleType(info.dataList);
                // 客户登记表 审核附表 图片
                _this2.CHANGE_ALL_IMG([info.attach_img_arr, info.img_arr]);
                _this2.getList();
                _this2.doShowParent();
                // 判断是否显示填写附表
                if (audit.register_schedule == 1 && audit.register_schedule_admin == 1) {
                  _this2.showChangeSchedule = true;
                }
              }
            });
          } else if (types === 'status2') {
            exportAuditStatus2({
              id: row.id
            }).then(function (res) {
              if (res.status === 200) {
                var _res$data3 = res.data,
                  auditInfo = _res$data3.auditInfo,
                  info = _res$data3.info;
                _this2.statusInfo = _this2.getInfoData(info.dataList);
                _this2.auditInfo = auditInfo;
                _this2.info = info.logList;
                _this2.turnInfo = info.turnInfo;
                _this2.intoInfo = info.intoInfo;
                _this2.createInfo = info.createInfo;
                _this2.userInfo = info.userInfo;
                _this2.adminInfo = info.adminInfo;
                _this2.is_button = _this2.getScheduleType(info.dataList);
                // 客户登记表 审核附表 图片
                _this2.CHANGE_ALL_IMG([info.attach_img_arr, info.img_arr]);
                _this2.getList();
                _this2.doShowParent();
                // 判断是否显示填写附表
                if (audit.register_schedule == 1 && audit.register_schedule_admin == 2) {
                  _this2.showChangeSchedule = true;
                } else if (audit.register_schedule_admin === 1) {
                  _this2.showChangeSchedule = true;
                }
              }
            });
          }
        }
      });
    },
    // 获取
    getList: function getList() {
      var infoTips = [];
      var _this$auditInfo = this.auditInfo,
        status = _this$auditInfo.status,
        types = _this$auditInfo.types;
      var createInfo = this.createInfo;
      var userInfo = this.userInfo;
      var adminInfo = this.adminInfo;
      var intoInfo = this.intoInfo;

      // 待审核
      if (status === 1) {
        infoTips = [[{
          name: '客户提交时间',
          value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm')
        }, {
          name: '客户姓名',
          value: createInfo.name
        }, {
          name: '登录手机号',
          value: createInfo.tel
        }]];
      }
      // 已驳回、已通过
      if (status === -2 || status === 2) {
        infoTips = [[{
          name: '客户提交时间',
          value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm')
        }, {
          name: '客户姓名',
          value: createInfo.name
        }, {
          name: '登录手机号',
          value: createInfo.tel
        }], [{
          name: '审核时间',
          value: moment(adminInfo.audit_at * 1000).format('YYYY-MM-DD HH:mm')
        }, {
          name: '审核人',
          value: adminInfo.name
        }]];
      }
      // 复审
      if (types === 'status2') {
        // 待审核
        if (status === 1) {
          // infoTips = [
          //   [
          //     { name: '客户提交时间', value: moment(userInfo.created_at * 1000).format('YYYY-MM-DD HH:mm') },
          //     { name: '客户姓名', value: userInfo.name },
          //     { name: '登录手机号', value: userInfo.tel }
          //   ],
          //   [
          //     { name: '初审通过时间', value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm') },
          //     { name: '审核人', value: createInfo.name }
          //   ]
          // ]
          infoTips = [[{
            name: '客户提交时间',
            value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm')
          }, {
            name: '客户姓名',
            value: createInfo.name
          }, {
            name: '登录手机号',
            value: createInfo.tel
          }]];
        }
        // 已驳回、已通过
        if (status === -2 || status === 2) {
          // infoTips = [
          //   [
          //     { name: '客户姓名', value: userInfo.name },
          //     { name: '客户提交时间', value: moment(userInfo.created_at * 1000).format('YYYY-MM-DD HH:mm') },
          //     { name: '登录手机号', value: userInfo.tel }
          //   ],
          //   [
          //     { name: '初审通过时间', value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm') },
          //     { name: '审核人', value: createInfo.name }
          //   ],
          //   [
          //     { name: '复审审核时间', value: moment(adminInfo.audit_at * 1000).format('YYYY-MM-DD HH:mm') },
          //     { name: '审核人', value: adminInfo.name }
          //   ]
          // ]
          infoTips = [[{
            name: '客户姓名',
            value: createInfo.name
          }, {
            name: '客户提交时间',
            value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm')
          }, {
            name: '登录手机号',
            value: createInfo.tel
          }], [{
            name: '审核时间',
            value: moment(adminInfo.audit_at * 1000).format('YYYY-MM-DD HH:mm')
          }, {
            name: '审核人',
            value: adminInfo.name
          }]];
        }
      }
      if (this.intoInfo != undefined) {
        if (this.intoInfo.is_into) {
          infoTips.push([
          // { name: '转入开盘客户时间', value: moment(intoInfo.into_time * 1000).format('YYYY-MM-DD HH:mm') },
          {
            name: '操作人',
            value: intoInfo.name
          }]);
        }
      }
      this.infoTips = infoTips;
    },
    // 处理数据
    getInfoData: function getInfoData(data) {
      data.forEach(function (item) {
        item.typeNumber = 0;
        item.typeUserNumber = 0;

        // 其他类型
        if (item.type === 0) {
          item.forms.forEach(function (forms) {
            if (forms.fill_in_type === 2) {
              item.typeNumber = item.typeNumber + 1;
            } else if (forms.fill_in_type === 1) {
              item.typeUserNumber = item.typeUserNumber + 1;
            }
            if (forms.form_type === 'radio') {
              if (forms.normal_result.value !== '') {
                // 婚姻状态
                if (forms.owner_type === 6) {
                  var index = forms.children.findIndex(function (d) {
                    return d.id == forms.normal_result.value;
                  });
                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改
                        if (_forms_.owner_type === 8 && forms.children[index].name === '未婚') {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  });
                  //   付款方式
                } else if (forms.owner_type === 7) {
                  var _index2 = forms.children.findIndex(function (d) {
                    return d.id == forms.normal_result.value;
                  });
                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改
                        if (_forms_.owner_type === 9 && (forms.children[_index2].name === '全款' || forms.children[_index2].name === '一次性付款')) {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  });
                } else if (forms.owner_type === 10) {
                  var _index3 = forms.children.findIndex(function (d) {
                    return d.id == forms.normal_result.value;
                  });
                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改
                        if (_forms_.owner_type === 12 && forms.children[_index3].owner_type === 11) {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  });
                }
              }
            }
          });
        } else if (item.type === 1 || item.type === 2) {
          // 1为产权人 2为银行资料
          var ownerBankKey = item.type == 1 ? "owner" : "bank";
          item[ownerBankKey + "s"].forEach(function (owners, indexs) {
            owners.forms.map(function (forms, index) {
              if (forms.fill_in_type === 2) {
                item.typeNumber = item.typeNumber + 1;
              } else {
                item.typeUserNumber = item.typeUserNumber + 1;
              }
              if (forms.owner_type === 46) {
                // 是否产权人
                var current = '',
                  ta = '',
                  // 配偶
                  n = 0;
                // 遍历其他产权人
                item[ownerBankKey + "s"].forEach(function (_owners, _indexs) {
                  _owners.forms.map(function (_forms, _index) {
                    if (_forms.owner_type === 46) {
                      // 是否产权人
                      if (_indexs !== indexs) {
                        // 不为当前的遍历
                        _owners.forms.map(function (__forms, __indexs) {
                          if (__forms.owner_type === 3) {
                            // 证件号
                            ta = __forms.owner_result.value;
                            n = _indexs;
                          }
                        });
                      }
                    } else if (_forms.owner_type === 43) {
                      // 配偶证件号
                      if (_indexs === indexs) {
                        // 当前用户
                        current = _forms.owner_result.value; // 当前用户证件号
                      }
                    }
                  });
                });
                forms.inconformity = current === ta; // 是否不一致
                forms.userNumber = n + 1;
              }
            });
          });
        }
      });
      return data;
    },
    // 查看驳回原因
    checkReason: function checkReason() {
      this.isReason = true;
    },
    // 判断是否出现填写附表
    getScheduleType: function getScheduleType(data) {
      var type = true;
      data.forEach(function (item) {
        // 其他类型
        if (item.type === 0) {
          item.forms.map(function (forms) {
            if (forms.fill_in_type === 2) {
              if (forms.normal_result.value !== '') {
                type = false;
              }
            }
          });
          //  产权人
        } else if (item.type === 1 || item.type === 2) {
          // 1为产权人 2为银行资料
          var ownerBankKey = item.type == 1 ? "owner" : "bank";
          item[ownerBankKey + "s"].forEach(function (owners) {
            owners.forms.map(function (forms) {
              if (forms.fill_in_type === 2) {
                if (forms[ownerBankKey + "_result"].value !== '') {
                  type = false;
                }
              }
            });
          });
        }
      });
      return true;
    },
    tabClick: function tabClick(tab) {
      this.CHANGE_BTN_TYPE(tab);
      if (tab == 0) {
        this.index = 1;
      } else if (tab == 1) {
        this.index = 2;
      }
      this.getInfoData(this.statusInfo);
    },
    // 跳转去填写附表
    handleSchedule: function handleSchedule() {
      var row = this.toolReviewRow;
      row.id = this.auditInfo.id;
      this.CHANGE_ROW(row);
      this.CHANGE_DRAWER_TYPE(0);

      // this.$router.push({
      //   name: 'Schedule',
      //   query: { id: this.auditInfo.id, status: this.row.types }
      // })
    },
    // 修改登记变信息
    handleEdit: function handleEdit() {
      var row = this.toolReviewRow;
      row.id = this.auditInfo.id;
      row.index = this.index;
      this.CHANGE_ROW(row);
      this.CHANGE_DRAWER_TYPE(2);
      // this.$router.push({
      //   name: 'EditReviewDetails',
      //   query: { id: this.auditInfo.id, status: this.row.types, types: this.index },
      // })
    },
    // 驳回审核,提交审核
    handleTurnDown: function handleTurnDown(type) {
      var _this3 = this;
      console.log(type);
      var types = this.toolReviewRow.types;
      console.log(types);
      console.log(type === 'repulse');
      if (this.textarea === '' && (type === 'return' || type === 'refuse' || type === 'repulse' || type === 'return2' || type === 'refuse2')) {
        this.$message({
          type: 'error',
          message: '驳回原因必填'
        });
        return;
        // this.postSave(type)
      } else if (this.textarea !== '' && type === 'return') {
        this.postSave(type);
      } else if (this.textarea !== '' && type === 'refuse') {
        this.postSave(type);
      } else if (this.textarea !== '' && type === 'repulse') {
        this.postSave(type);
      } else if (this.textarea !== '' && type === 'return2') {
        this.doReturnRefuse2("return");
      } else if (this.textarea !== '' && type === 'refuse2') {
        this.doReturnRefuse2("refuse");
      } else {
        var text = '';
        if (this.roles.includes('audit/auditcomplexlist')) {
          text = types === 'status1' ? '是否确认通过审核？' : '是否确认通过审核？';
        } else {
          text = '是否确认通过审核？';
        }
        this.$confirm(text, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this3.postSave(type);
        }).catch(function () {});
      }
    },
    postSave: function postSave(type) {
      var _this4 = this;
      var data = {
        id: parseInt(this.toolReviewRow.id),
        type: type,
        err_note: this.textarea,
        next: this.checked ? 1 : 0
      };
      var types = this.toolReviewRow.types;
      if (types === 'status0') {
        AuditStatus0(data).then(function (res) {
          if (res.status === 200) {
            var next = res.data.next;
            _this4.$message({
              type: 'success',
              message: res.data.msg
            });
            if (_this4.checked && JSON.stringify(next) !== '{}') {
              var row = _this4.toolReviewRow;
              row.id = next.id;
              _this4.CHANGE_ROW(row);
              _this4.CHANGE_DRAWER_TYPE(3);
              setTimeout(function () {
                _this4.CHANGE_DRAWER_TYPE(1);
              }, 300);

              // this.$router.push({
              //   name: 'ReviewDetails',
              //   query: { id: next.id, status: next.types },
              // })
            } else {
              if (_this4.checked && _this4.type === 'commit') {
                setTimeout(function () {
                  _this4.$message({
                    type: 'warning',
                    message: '已经是最后一条了'
                  });
                }, 1000);
              }
            }
            _this4.isTips = false;
            _this4.getDataInfo();
            // 刷新列表页面
            _this4.$emit('handleRefresh');
          }
        });
      } else if (types === 'status1') {
        AuditStatus1(data).then(function (res) {
          if (res.status === 200) {
            var next = res.data.next;
            _this4.$message({
              type: 'success',
              message: res.data.msg
            });
            if (_this4.checked && JSON.stringify(next) !== '{}') {
              var row = _this4.toolReviewRow;
              row.id = next.id;
              _this4.CHANGE_ROW(row);
              _this4.CHANGE_DRAWER_TYPE(3);
              setTimeout(function () {
                _this4.CHANGE_DRAWER_TYPE(1);
              }, 300);
              // this.$router.push({
              //   name: 'ReviewDetails',
              //   query: { id: next.id, status: next.types },
              // })
            } else {
              if (_this4.checked && _this4.type === 'commit') {
                setTimeout(function () {
                  _this4.$message({
                    type: 'warning',
                    message: '已经是最后一条了'
                  });
                }, 1000);
              }
            }
            _this4.isTips = false;
            _this4.getDataInfo();
            // 刷新列表页面
            _this4.$emit('handleRefresh');
          }
        });
      } else if (types === 'status2') {
        AuditStatus2(data).then(function (res) {
          if (res.status === 200) {
            var next = res.data.next;
            _this4.$message({
              type: 'success',
              message: res.data.msg
            });
            if (_this4.checked && JSON.stringify(next) !== '{}') {
              var row = _this4.toolReviewRow;
              row.id = next.id;
              _this4.CHANGE_ROW(row);
              _this4.CHANGE_DRAWER_TYPE(3);
              setTimeout(function () {
                _this4.CHANGE_DRAWER_TYPE(1);
              }, 300);
              // this.$router.push({
              //   name: 'ReviewDetails',
              //   query: { id: next.id, status: next.types },
              // })
            } else {
              if (_this4.checked && _this4.type === 'commit') {
                setTimeout(function () {
                  _this4.$message({
                    type: 'warning',
                    message: '已经是最后一条了'
                  });
                }, 1000);
              }
            }
            _this4.isTips = false;
            _this4.getDataInfo();
            // 刷新列表页面
            _this4.$emit('handleRefresh');
          }
        });
      }
    },
    // 审核通过后再次驳回和再次驳回并拒绝提交
    doReturnRefuse2: function doReturnRefuse2(type) {
      var _this5 = this;
      var data = {
        id: parseInt(this.toolReviewRow.id),
        type: type,
        err_note: this.textarea
      };
      if (type === "return") {
        AgainRejected(data).then(function (res) {
          if (res.status === 200) {
            var next = res.data.next;
            _this5.$message({
              type: 'success',
              message: res.data.msg
            });
            _this5.isTips = false;
            _this5.getDataInfo();
            // 刷新列表页面
            _this5.$emit('handleRefresh');
          }
        });
      } else if (type === "refuse") {
        RejectAgain(data).then(function (res) {
          if (res.status === 200) {
            var next = res.data.next;
            _this5.$message({
              type: 'success',
              message: res.data.msg
            });
            _this5.isTips = false;
            _this5.getDataInfo();
            // 刷新列表页面
            _this5.$emit('handleRefresh');
          }
        });
      }
    },
    /**
     * 父级影响显示隐藏
     * @param parent_id  当前id（父级）
     * @param parent_values 当前选项ids
     * @param isShowParent 是否开始判断父级隐藏
     * @param ownerBankIndex 产权人下标
     */
    doShowParent: function doShowParent(isShowParent, parent_id, parent_values, ownerBankIndex) {
      var _this6 = this;
      if (isShowParent) {
        var forms = JSON.parse(JSON.stringify(this.statusInfo));
        if (ownerBankIndex || ownerBankIndex === 0) {
          for (var i = 0; i < forms.length; i++) {
            // 产权人或者银行资料
            if (forms[i].type === 1) {
              // 1为产权人 2为银行资料
              var ownerBankKey = forms[i].type == 1 ? "owner" : "bank";
              for (var a = 0; a < forms[i][ownerBankKey + "s"][ownerBankIndex].forms.length; a++) {
                var item = forms[i][ownerBankKey + "s"][ownerBankIndex].forms[a];
                if (parent_id === item.parent_pid && item.parent_pid !== 0) {
                  var parent_ids = [];
                  if (item.parent_id) {
                    parent_ids = item.parent_id.split(",");
                  }
                  var parent_cause_show = false;
                  for (var c = 0; c < parent_ids.length; c++) {
                    if (parent_values.indexOf(parent_ids[c]) >= 0) {
                      parent_cause_show = true;
                    }
                  }
                  // 隐藏时清空之前的值
                  if (!parent_cause_show) {
                    item.normal_result.value = "";
                    item[ownerBankKey + "_result"].value = "";
                    item.normal_result.value2 = "";
                    item[ownerBankKey + "_result"].value2 = "";
                  }
                  item.parent_cause_show = parent_cause_show;
                } else if (item.parent_pid === 0) {
                  item.parent_cause_show = true;
                }
                forms[i][ownerBankKey + "s"][ownerBankIndex].forms[a] = item;
              }
            }
          }
        } else {
          for (var _i = 0; _i < forms.length; _i++) {
            // 产权人或者银行资料
            if (forms[_i].type === 1 || forms[_i].type === 2) {
              // 1为产权人 2为银行资料
              var _ownerBankKey = forms[_i].type == 1 ? "owner" : "bank";
              for (var l = 0; l < forms[_i][_ownerBankKey + "s"].length; l++) {
                for (var _a = 0; _a < forms[_i][_ownerBankKey + "s"][l].forms.length; _a++) {
                  var _item2 = forms[_i][_ownerBankKey + "s"][l].forms[_a];
                  if (parent_id === _item2.parent_pid && _item2.parent_pid !== 0) {
                    var _parent_ids = [];
                    if (_item2.parent_id) {
                      _parent_ids = _item2.parent_id.split(",");
                    }
                    var _parent_cause_show = false;
                    for (var _c = 0; _c < _parent_ids.length; _c++) {
                      if (parent_values.indexOf(_parent_ids[_c]) >= 0) {
                        _parent_cause_show = true;
                      }
                    }
                    // 隐藏时清空之前的值
                    if (!_parent_cause_show) {
                      _item2.normal_result.value = "";
                      _item2[_ownerBankKey + "_result"].value = "";
                      _item2.normal_result.value2 = "";
                      _item2[_ownerBankKey + "_result"].value2 = "";
                    }
                    _item2.parent_cause_show = _parent_cause_show;
                  } else if (_item2.parent_pid === 0) {
                    _item2.parent_cause_show = true;
                  }
                  forms[_i][_ownerBankKey + "s"][l].forms[_a] = _item2;
                }
              }
            }
            // 其他类型
            else {
              for (var _a2 = 0; _a2 < forms[_i].forms.length; _a2++) {
                var _item3 = forms[_i].forms[_a2];
                if (parent_id === _item3.parent_pid && _item3.parent_pid !== 0) {
                  var _parent_ids2 = [];
                  if (_item3.parent_id) {
                    _parent_ids2 = _item3.parent_id.split(",");
                  }
                  var _parent_cause_show2 = false;
                  for (var _c2 = 0; _c2 < _parent_ids2.length; _c2++) {
                    if (parent_values.indexOf(_parent_ids2[_c2]) >= 0) {
                      _parent_cause_show2 = true;
                    }
                  }
                  // 隐藏时清空之前的值
                  if (!_parent_cause_show2) {
                    _item3.normal_result.value = "";
                    _item3.normal_result.value2 = "";
                  }
                  _item3.parent_cause_show = _parent_cause_show2;
                } else if (_item3.parent_pid === 0) {
                  _item3.parent_cause_show = true;
                }
                forms[_i].forms[_a2] = _item3;
              }
            }
          }
        }
        this.statusInfo = JSON.parse(JSON.stringify(forms));
      } else {
        var formData = JSON.parse(JSON.stringify(this.statusInfo));
        formData.forEach(function (item) {
          if (item.type != 1 && item.type != 2) {
            item.forms.forEach(function (_item) {
              var selectId = [];
              if (_item.form_type == '置业顾问') {
                // 置业顾问
                if (_item.normal_result.value) {
                  selectId = _item.normal_result.value.toString().split(',');
                } else if (_item.value) {
                  selectId = _item.value.toString().split(',');
                } else if (_item.select_value == 1) {
                  // 默认选择
                  selectId = _item.admins[0].id.toString().split(',');
                }
                _this6.doShowParent(true, _item.id, selectId);
              } else if (_item.form_type == 'radio' || _item.form_type == 'select') {
                // 单选 || 多选
                if (_item.normal_result.value) {
                  selectId = _item.normal_result.value.toString().split(',');
                } else if (_item.value) {
                  selectId = _item.value.toString().split(',');
                } else if (_item.select_value == 1) {
                  if (_item.form_type == 'radio') {
                    selectId = _item.children[0].id.toString().split(',');
                  } else {
                    _item.normal_result.value = selectId.join(',');
                  }
                }
                _this6.doShowParent(true, _item.id, selectId);
              }
            });
          } else if (item.type == 1) {
            // 1为产权人 2为银行资料
            var _ownerBankKey2 = item.type == 1 ? "owner" : "bank";
            // 产权人或者银行资料，多个
            for (var _l = 0; _l < item[_ownerBankKey2 + "s"].length; _l++) {
              // 问题
              for (var _a3 = 0; _a3 < item[_ownerBankKey2 + "s"][_l].forms.length; _a3++) {
                var _item = item[_ownerBankKey2 + "s"][_l].forms[_a3];
                var selectId = [];
                if (_item.form_type == '置业顾问') {
                  // 置业顾问
                  if (_item[_ownerBankKey2 + "_result"].value) {
                    selectId = _item[_ownerBankKey2 + "_result"].value.toString().split(',');
                  } else if (_item.value) {
                    selectId = _item.value.toString().split(',');
                  } else if (_item.select_value == 1) {
                    // 默认选择
                    selectId = _item.admins[0].id.toString().split(',');
                  }
                  _this6.doShowParent(true, _item.id, selectId, _l);
                } else if (_item.form_type == 'radio' || _item.form_type == 'select') {
                  // 单选 || 多选
                  if (_item[_ownerBankKey2 + "_result"].value) {
                    selectId = _item[_ownerBankKey2 + "_result"].value.toString().split(',');
                  } else if (_item.value) {
                    selectId = _item.value.toString().split(',');
                  } else if (_item.select_value == 1) {
                    if (_item.form_type == 'radio') {
                      selectId = _item.children[0].id.toString().split(',');
                    } else {
                      _item[_ownerBankKey2 + "_result"].value = selectId.join(',');
                    }
                  }
                  _this6.doShowParent(true, _item.id, selectId, _l);
                }
              }
            }
          }
        });
      }
    }
  }
};