var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "app", staticClass: "container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 9 } }, [
            _c(
              "div",
              {
                staticClass: "block-floor",
                style: { height: _vm.divHeight + "px" },
              },
              [
                _c("div", { staticClass: "room-title" }, [_vm._v("终端显示")]),
                _vm.areaData.length > 0
                  ? _c("div", { staticClass: "option" }, [
                      _c("div", [
                        _c("div", { staticClass: "titleName" }, [
                          _vm._v("楼栋"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "floorList" },
                          _vm._l(_vm.areaData, function (item, index) {
                            return _c(
                              "span",
                              {
                                key: item.id,
                                staticClass: "list",
                                class: index === _vm.areaTab ? "on" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleArea(index, item)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(item.name == "" ? "无" : item.name)
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("div", [
                        _c("div", { staticClass: "titleName" }, [
                          _vm._v("楼层"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "floorList" },
                          _vm._l(_vm.unitList, function (item, index) {
                            return _c(
                              "span",
                              {
                                key: item.id,
                                staticClass: "list",
                                class: index === _vm.unitTab ? "on" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUnit(index, item)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(item.name == "" ? "无" : item.name)
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("div", [
                        _c("div", { staticClass: "titleName" }, [
                          _vm._v("区域"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "floorList" },
                          _vm._l(_vm.floorList, function (item, index) {
                            return _c(
                              "span",
                              {
                                key: item.id,
                                staticClass: "list",
                                class: index === _vm.floorTab ? "on" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleFloor(index, item)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(item.name == "" ? "无" : item.name)
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
          _c(
            "el-col",
            { attrs: { span: 15 } },
            [
              _c("phone", {
                ref: "phone",
                attrs: {
                  type: "villa",
                  "name-table": _vm.nameTable,
                  "is-revision": _vm.isRevision,
                  "phone-data": _vm.phoneData,
                  "sort-table": _vm.sortTable,
                  "tab-data": [
                    { name: "楼栋" },
                    { name: "楼层" },
                    { name: "区域", id: 1 },
                    { name: "车位号", id: 2 },
                  ],
                  "div-height": _vm.divHeight,
                },
                on: {
                  handleClose: _vm.handleClose,
                  handleBottom: _vm.handleBottom,
                  handleTop: _vm.handleTop,
                  handleSave: _vm.handleSave,
                  handleLeftRight: _vm.handleLeftRight,
                  handleTab: _vm.handleTab,
                  handleUpDownSort: _vm.handleUpDownSort,
                  onEdit: _vm.onEdit,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }