import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array-buffer.constructor.js";
import "core-js/modules/es.array-buffer.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.to-string.js";
import "core-js/modules/es.typed-array.with.js";
import "core-js/modules/esnext.array-buffer.detached.js";
import "core-js/modules/esnext.array-buffer.transfer.js";
import "core-js/modules/esnext.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/web.atob.js";
import "core-js/modules/web.dom-exception.constructor.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.dom-exception.to-string-tag.js";
import { AwesomeQR } from 'awesome-qr';
import { createQR, qrCode } from '@/api/qrCodeGeneration';

/**.
 * 上传二维码
 * @function uploadQR
 * @param {string} base64
 * @param {Number} user_id
 */
function uploadQR(base64, user_id) {
  return new Promise(function (reslove) {
    var f = convertBase64UrlToBlob(base64);
    var fd = new FormData();
    fd.append('file', f, "uid".concat(user_id, "-").concat(new Date().getTime(), "-image.png"));
    fd.append('user_id', user_id);
    qrCode(fd).then(function (response) {
      console.log(response);
      reslove();
    });
  });
}

// base64转Blob
function convertBase64UrlToBlob(urlData) {
  // 去掉url的头，并转换为byte
  var split = urlData.split(',');
  var bytes = window.atob(split[1]);
  // 处理异常,将ascii码小于0的转换为大于0
  var ab = new ArrayBuffer(bytes.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i);
  }
  return new Blob([ab], {
    type: split[0]
  });
}
export default {
  namespaced: true,
  actions: {
    /**
     * 创建用户二维码并上传
     * @function createUserQRCode
     * @param {Object} params { user_id, event_id, round_id }
     */
    createUserQRCode: function createUserQRCode(c) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var not_up = params.not_up;
      return new Promise(function (reslove) {
        createQR(params).then(function (res) {
          var text = res.data.QrCode;
          new AwesomeQR({
            text: text,
            size: 260,
            margin: 10,
            correctLevel: 3
          }).draw().then(function (base64Image) {
            if (not_up) {
              reslove(base64Image);
            } else {
              uploadQR(base64Image, params.user_id).then(function () {
                reslove();
              });
            }
          });
        });
      });
    }
  }
};