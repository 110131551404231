//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import auditState from "./auditState";
export default {
  name: "userAudit",
  components: {
    auditState: auditState
  },
  props: {
    nowUser: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    project: {
      type: Object,
      default: function _default() {}
    },
    showAudit: {
      type: Boolean,
      default: true
    },
    showOwner: {
      type: Boolean,
      default: false
    }
  }
};