import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Api from '@/api/staff';
import { debounce } from '@/utils/debounce';
import treetable from '@/components/myTreetableCopy';
import basePopup from './popup.vue';
import utils from '@/utils/utils';
export default {
  name: 'PostList',
  components: {
    treetable: treetable,
    basePopup: basePopup
  },
  props: ['tableHeight'],
  data: function data() {
    return {
      adminNumber: 0,
      // 是否手机端
      isPC: utils.isPC(),
      kpAdminNumber: 0,
      //  默认选中开盘助手
      tabText: 'kpAdmin',
      // 新增岗位
      dialogPost: false,
      // 新增人员
      addStaff: false,
      // 新增修改岗位提交内容
      addEditPost: {
        name: ''
      },
      // 新增修改岗位弹框名
      addEditPostText: '增加一个岗位',
      // 新增修改岗位弹框 验证
      addEditPostRules: {
        name: [{
          required: true,
          message: '请输入岗位名称',
          trigger: 'blur'
        }]
      },
      // 添加人员搜索
      addStaffVal: '',
      // 人员列表 选中数量
      selectedUserNum: 0,
      // 添加人员 选中数量
      selectedNum: 0,
      // 添加人员列表 总页数
      total: 0,
      // 添加人员列表 页数
      page: 1,
      // 添加人员列表 每页条数
      pageSize: 10,
      // 添加人员列表
      memberList: [],
      // 岗位列表
      postTable: [],
      // 人员列表
      staffTable: [],
      staffLoading: false,
      // 表单加载控制
      counselor: false,
      // 置业顾问
      // 功能授权弹框
      features: false,
      // 后台权限列表
      adminRuleList: [],
      // 助手端权限列表
      helperRuleList: [],
      // 获取选中id
      selectRuleList: [],
      //  后台权限列表取值
      ruleProps: {
        children: 'List',
        label: 'name'
      },
      funName: '',
      //  权限
      auth: [],
      // 新增岗位列表提示
      temList: []
    };
  },
  // 初始化
  created: function created() {
    this.getPostList();
  },
  methods: {
    handleNumber: function handleNumber(number, type) {
      this.number = number;
      if (type === 'admin') {
        this.adminNumber = number;
      } else {
        this.kpAdminNumber = number;
      }
    },
    // 保存
    handleSaveFeatures: function handleSaveFeatures() {
      var _this = this;
      this.adminNumber = 0;
      this.kpAdminNumber = 0;
      var admin = this.getCheckedData(this.adminRuleList, 'admin');
      var helper = this.getCheckedData(this.helperRuleList, 'kpAdmin');
      var data = {
        id: this.postId,
        ids: admin.concat(helper)
      };

      // 提交数据
      Api.postRoleSetRole(data).then(function (res) {
        if (res.status === 200) {
          _this.$message({
            type: 'success',
            message: res.data.msg
          });
          _this.features = false;
        }
      });
    },
    // 打开功能授权弹框
    handleFeatures: function handleFeatures(row) {
      var _this2 = this;
      this.adminRuleList = [];
      this.helperRuleList = [];
      this.selectRuleList = [];
      this.features = true;
      this.postId = row.id;
      this.funName = row.name;
      Api.getRoleSetRole({
        id: row.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
            adminRule = _res$data.adminRule,
            selectRule = _res$data.selectRule,
            helperRule = _res$data.helperRule,
            selectRuleList = _res$data.selectRuleList;

          //  递归 数据 加 isChecked
          _this2.getForData(adminRule, selectRuleList);
          //   添加 禁用状态
          _this2.addIsDisabledStatus(adminRule);
          _this2.adminRuleList = adminRule;
          _this2.adminNumber = 0;
          _this2.kpAdminNumber = 0;
          _this2.getForData(helperRule == null ? [] : helperRule, selectRuleList);
          //   添加 禁用状态
          _this2.addIsDisabledStatus(helperRule);
          _this2.helperRuleList = helperRule == null ? [] : helperRule;
          // 处理选中的id
          // this.findFunc(
          // 	selectRuleList,
          // 	adminRuleList.concat(helperRuleList)
          // )

          // 递归处理
          // function addIsDisabledStatus(data) {
          //     data && data.forEach(item => {
          //         item && item.AuthList && this.addDisabledStatus(item.AuthList)
          //         item && item.Children && addIsDisabledStatus(item.Children)
          //     })
          // }

          // 判断查看权限是否添加🈲用状态
          // function addDisabledStatus(data) {
          //   let booleanArr = new Set()
          //   data && data.forEach(item => {
          //       if (item.IsMenu != 2) {
          //           booleanArr.add(item.isChecked)
          //       }
          //   })
          //   if (booleanArr.size > 1) {
          //       data && data.forEach(item => {
          //           if (item.IsMenu == 2) {
          //               item.isDisabled = true
          //           }
          //       })
          //   } else {
          //       if (booleanArr.has(true)) {
          //           data && data.forEach(item => {
          //               if (item.IsMenu == 2) {
          //                   item.isDisabled = true
          //               }
          //           })
          //       } else {
          //           data && data.forEach(item => {
          //               if (item.IsMenu == 2) {
          //                   item.isDisabled = false
          //               }
          //           })
          //       }
          //   }
          // }

          _this2.getCheckedData(adminRule, 'admin');
          _this2.getCheckedData(helperRule, 'kpAdmin');
        }
      });
    },
    addIsDisabledStatus: function addIsDisabledStatus(data) {
      var _this3 = this;
      data && data.forEach(function (item) {
        item && item.AuthList && _this3.addDisabledStatus(item.AuthList);
        item && item.Children && _this3.addIsDisabledStatus(item.Children);
      });
    },
    addDisabledStatus: function addDisabledStatus(data) {
      var booleanArr = new Set();
      data && data.forEach(function (item) {
        if (item.IsMenu != 2) {
          booleanArr.add(item.isChecked);
        }
      });
      if (booleanArr.size > 1) {
        data && data.forEach(function (item) {
          if (item.IsMenu == 2) {
            item.isDisabled = true;
          }
        });
      } else {
        if (booleanArr.has(true)) {
          data && data.forEach(function (item) {
            if (item.IsMenu == 2) {
              item.isDisabled = true;
            }
          });
        } else {
          data && data.forEach(function (item) {
            if (item.IsMenu == 2) {
              item.isDisabled = false;
            }
          });
        }
      }
    },
    //  递归数据 加 isChecked
    getForData: function getForData(data, idList) {
      var _this4 = this;
      data && data.forEach(function (item) {
        if (idList.includes(Number(item.RuleID))) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
        item.AuthList && item.AuthList.forEach(function (authItem) {
          if (idList.includes(authItem.RuleID)) {
            authItem.isChecked = true;
          } else {
            authItem.isChecked = false;
          }
        });
        item.Children && _this4.getForData(item.Children, idList);
      });
    },
    // @ 递归数据获取选中的数据
    getCheckedData: function getCheckedData(data, type) {
      var rule = [];
      var that = this;
      getRules(data, type);
      function getRules(data, type) {
        data && data.forEach(function (item) {
          if (item.isChecked) {
            rule.push(item.RuleID);
          }
          item.AuthList && item.AuthList.forEach(function (authItem) {
            if (authItem.isChecked) {
              rule.push(authItem.RuleID);
              if (type === 'admin') {
                that.adminNumber++;
              } else {
                that.kpAdminNumber++;
              }
            }
          });
          item.Children && getRules(item.Children, type);
        });
      }
      return rule;
    },
    // 人员列表 点击行 选中
    handleUserRow: debounce(function (row) {
      this.$refs.userTable.toggleRowSelection(row); // 设置复选框为未选状态
    }, 200, true),
    // 批量删除
    handleBatchDel: debounce(function () {
      this.delUserMethod(this.staffIds);
    }, 200, true),
    // 修改人员列表账号状态
    handleUserStatus: function handleUserStatus(row) {
      var _this5 = this;
      // 管理员不予许修改
      if (row.is_saas) {
        return;
      }
      Api.roleMemberStatus({
        id: row.id,
        status: row.status,
        role_id: this.postId
      }).then(function (res) {
        if (res.status === 200) {
          _this5.$message({
            type: 'success',
            message: res.data.msg
          });
        }
      });
    },
    // 保存选中人员
    handleSaveStaff: function handleSaveStaff() {
      var _this6 = this;
      Api.postRoleAddMember({
        id: this.postId,
        ids: this.userIds
      }).then(function (res) {
        if (res.status === 200) {
          _this6.$message({
            type: 'success',
            message: res.data.msg
          });
          _this6.addStaff = false;
          _this6.getStaffList(_this6.postId);
        }
      });
    },
    // 添加人员清空
    addStaffReset: function addStaffReset() {
      this.page = 1;
      this.addStaffVal = '';
      this.handleAddStaff({
        id: this.postId
      });
    },
    // 添加人员搜索事件
    addStaffSearch: function addStaffSearch() {
      this.page = 1;
      this.handleAddStaff({
        id: this.postId
      });
    },
    // 添加人员点击行选中
    handleMemberList: debounce(function (row) {
      this.$refs.memberListTable.toggleRowSelection(row); // 设置复选框为未选状态
    }, 200, true),
    // 人员列表删除 方法
    delUserMethod: function delUserMethod(ids) {
      var _this7 = this;
      this.$confirm('此操作不可复原，确认删除吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        var data = {
          id: _this7.postId,
          ids: ids
        };

        // 删除 接口
        Api.roleMemberDel(data).then(function (res) {
          if (res.status === 200) {
            _this7.getStaffList(_this7.postId);
            _this7.$message({
              type: 'success',
              message: res.data.msg
            });
          }
        });
      });
    },
    /**
     * 置业顾问 排序处理
     * @param {Number} index 当前数据下标
     * @param {Number} digit 要交换的位数
     * */
    handleSort: function handleSort(index, digit) {
      var _this8 = this;
      var list = this.staffTable;
      var current = list[index];
      var newIndex = index + digit;
      var newSort = list[newIndex];
      var params = {
        role_id: this.postId,
        id1: current.role_admin_id,
        id2: newSort.role_admin_id
      };
      Api.MemberSort(params).then(function (resultSort) {
        _this8.$message.success('调整成功');
        _this8.getStaffList(_this8.postId);
      }).catch(function (err) {
        _this8.$message.error('调整失败');
      });
    },
    // 移除人员列表
    handleDelUser: function handleDelUser(row) {
      this.delUserMethod([row.id]);
    },
    // 修改岗位状态
    handleStatus: function handleStatus(row) {
      var _this9 = this;
      Api.roleStatus({
        id: row.id,
        status: row.status
      }).then(function (res) {
        if (res.status === 200) {
          _this9.$message({
            type: 'success',
            message: res.data.msg
          });
          _this9.getStaffList(row.id);
        }
      });
    },
    // 删除岗位
    handleDelStaff: function handleDelStaff(row, index) {
      var _this10 = this;
      this.$confirm('此操作不可复原，确认删除吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        Api.roleDel({
          id: row.id
        }).then(function (res) {
          if (res.status === 200) {
            //	前端删除
            _this10.postTable.splice(index, 1);
            _this10.$message({
              type: 'success',
              message: res.data.msg
            });
          }
        });
      });
    },
    // 修改岗位
    handleEditInfo: function handleEditInfo(row) {
      if (row.name == '置业顾问' || row.name == '销售经理') {
        this.$message({
          message: '该岗位为系统级岗位，不允许修改！',
          type: 'warning'
        });
        return;
      }
      this.dialogPost = true;
      this.addEditPostText = '编辑岗位名称';
      this.getPostInfo(row.id);

      // 点击的当前行id
      this.postId = row.id;
    },
    // 新增岗位，修改岗位
    handlePostSave: function handlePostSave() {
      var _this11 = this;
      if (this.addEditPostText === '编辑岗位名称') {
        Api.roleEdit(this.addEditPost).then(function (res) {
          if (res.status === 200) {
            _this11.$message({
              type: 'success',
              message: res.data.msg
            });

            // 获取修改数据的下标
            var index = _this11.postTable.findIndex(function (item) {
              return item.id === _this11.postId;
            });

            // 前端修改
            _this11.postTable[index].name = _this11.addEditPost.name;

            // 清空
            _this11.$refs['addEditPost'].resetFields();
          }
        });
      } else {
        Api.roleAdd(this.addEditPost).then(function (res) {
          if (res.status === 200) {
            _this11.$message({
              type: 'success',
              message: res.data.msg
            });
            var id = res.data.id;

            // 前端添加
            _this11.postTable.unshift({
              id: id,
              name: _this11.addEditPost.name,
              status: 1,
              is_system: 0
            });
            _this11.handleFeatures({
              id: id,
              name: _this11.addEditPost.name,
              status: 1,
              is_system: 0
            });

            // 清空
            _this11.$refs['addEditPost'].resetFields();
          }
        });
      }

      // 关闭弹框
      this.dialogPost = false;
    },
    // 获取岗位信息
    getPostInfo: function getPostInfo(id) {
      var _this12 = this;
      Api.roleInfo({
        id: id
      }).then(function (res) {
        if (res.status === 200) {
          var role = res.data.role;
          _this12.addEditPost = role;
        }
      });
    },
    // 点击table 触发事件
    handleRow: debounce(function (row) {
      this.getStaffList(row.id);

      // 点击的当前行id
      this.postId = row.id;
    }, 200, true),
    // 获取岗位下人员
    getStaffList: function getStaffList(id) {
      var _this13 = this;
      this.staffLoading = true;
      Api.roleMember({
        id: id,
        limit: 9999
      }).then(function (res) {
        if (res.status === 200) {
          var memberList = res.data.memberList;
          var isc = _this13.postTable.find(function (role) {
            return role.id === id;
          });
          _this13.counselor = (isc === null || isc === void 0 ? void 0 : isc.name) === '置业顾问';
          _this13.staffTable = memberList;
        }
      }).finally(function () {
        _this13.staffLoading = false;
      });
    },
    // 获取岗位列表
    getPostList: function getPostList() {
      var _this14 = this;
      Api.roleList().then(function (res) {
        if (res.status === 200) {
          var _res$data2 = res.data,
            roleList = _res$data2.roleList,
            temList = _res$data2.temList;
          _this14.temList = [];
          if (roleList !== null && roleList.length > 0) {
            _this14.postTable = roleList;
            var nameList = [];
            for (var i = 0; i < roleList.length; i++) {
              nameList.push(roleList[i].name);
            }
            if (temList !== null && temList !== void 0 && temList.length) {
              for (var _i = 0; _i < temList.length; _i++) {
                if (nameList.indexOf(temList[_i]) < 0) {
                  _this14.temList.push({
                    value: temList[_i]
                  });
                }
              }
            }
            // 默认选中 第一行
            _this14.$nextTick(function () {
              this.$refs.postTable.setCurrentRow(this.postTable[0]);
              // 第一次获取 人员
              this.getStaffList(roleList[0].id);
              // 岗位id
              this.postId = roleList[0].id;
            });
          } else if (temList !== null && temList !== void 0 && temList.length) {
            for (var _i2 = 0; _i2 < temList.length; _i2++) {
              _this14.temList.push({
                value: temList[_i2]
              });
            }
          }
        }
      });
    },
    // 新增 修改岗位
    handleAddPost: debounce(function () {
      this.dialogPost = true;
      this.addEditPostText = '增加一个岗位';
      this.addEditPost.name = '';
    }, 200, true),
    // 新增弹框 获取人员
    handleAddStaff: function handleAddStaff(row) {
      var _this15 = this;
      this.addStaff = true;
      var data = {
        id: row.id,
        search: this.addStaffVal,
        null_role: 0,
        null_team: 0,
        limit: this.pageSize,
        page: this.page
      };

      // 岗位id
      this.postId = row.id;
      Api.getRoleAddMember(data).then(function (res) {
        if (res.status === 200) {
          var _res$data3 = res.data,
            memberList = _res$data3.memberList,
            count = _res$data3.count;
          _this15.memberList = memberList;
          _this15.total = count;
        }
      });
    },
    // 表格选择
    handleSelectionChange: function handleSelectionChange(row) {
      var ids = [];
      row.forEach(function (item) {
        ids.push(item.id);
      });
      this.userIds = ids;
      this.selectedNum = row.length;
    },
    // 获取选人员列表
    handleUserSelection: function handleUserSelection(row) {
      var ids = [];
      row.forEach(function (item) {
        ids.push(item.id);
      });
      this.staffIds = ids;
      this.selectedUserNum = row.length;
    },
    // 添加人员 切换每页条数
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.handleAddStaff({
        id: this.postId
      });
    },
    // 添加人员 切换页数
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.handleAddStaff({
        id: this.postId
      });
    },
    onTemListSelect: function onTemListSelect(queryString, cb) {
      var temList = this.temList;
      var results = queryString ? temList.filter(function (restaurant) {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      }) : temList;
      // 调用 callback 返回建议列表的数据
      cb(results);
    }
  }
};