import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
// 异步获取图片信息
export function getImageInfo(el) {
  return new Promise(function (r, e) {
    if (!el) return e(new Error("el is necessary"));
    if (typeof el === "string") el = document.querySelector(el);
    if (!el) return e(new Error("error el"));
    if (!el.localName) el = el[0];
    var _el = el,
      width = _el.naturalWidth,
      height = _el.naturalHeight,
      src = _el.src,
      x = _el.x,
      y = _el.y;
    r({
      width: width,
      height: height,
      src: src,
      x: x,
      y: y
    });
  });
}
/**
 * 取出数字
 * str 字符串
 * **/
export function removeSuffix(str) {
  if (!str) return 0;
  var reg = /(-|\+)?[0-9]+\.?[0-9]+/;
  var arr = reg.exec(str);
  return arr[0];
}