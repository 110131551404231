var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "publicitySet",
      staticStyle: { width: "100vw", height: "100vh", overflow: "hidden" },
    },
    [
      _c("img", {
        staticStyle: {
          width: "100vw",
          height: "100vh",
          position: "absolute",
          "z-index": "0",
          top: "0",
          left: "0",
        },
        attrs: { src: _vm.replaceImgshow(_vm.style.logo) },
      }),
      _vm.style.title.show
        ? _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                position: "absolute",
                "text-align": "center",
                "font-weight": "bold",
              },
              style: {
                top: _vm.style.title.top + "px",
                color: _vm.style.title.color,
                fontSize: _vm.style.title.fontSize + "px",
              },
            },
            [_vm._v(_vm._s(_vm.rand_name))]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "overflowWidth0",
          staticStyle: {
            position: "relative",
            width: "100%",
            "overflow-y": "auto",
          },
          style: {
            height: "calc(100vh - " + _vm.style.title.bottom + "px)",
            top: _vm.style.title.bottom + "px",
          },
        },
        [
          _c(
            "el-carousel",
            {
              staticClass: "overflowWidth0",
              staticStyle: { "overflow-y": "auto" },
              style: {
                height: "calc(100vh - " + _vm.style.title.bottom + "px)",
              },
              attrs: {
                "indicator-position": "none",
                interval: _vm.carousel_interval * 1000,
                arrow: "hover",
              },
            },
            _vm._l(_vm.data, function (item, index) {
              return _c(
                "el-carousel-item",
                {
                  key: index,
                  staticClass: "overflowWidth0",
                  staticStyle: { "overflow-y": "auto" },
                  style: {
                    height: "calc(100vh - " + _vm.style.title.bottom + "px)",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "overflowWidth0",
                      staticStyle: {
                        position: "absolute",
                        width: "calc(100% - 100px)",
                        overflow: "hidden",
                        display: "flex",
                        margin: "0 50px",
                        "justify-content": "space-between",
                        "flex-wrap": "wrap",
                        "overflow-y": "auto",
                      },
                    },
                    _vm._l(item, function (_item, _index) {
                      return _c(
                        "div",
                        {
                          key: _index,
                          staticClass: "box",
                          staticStyle: { "border-radius": "20px" },
                          style: {
                            padding: _vm.style.box.round.top + "px 20px",
                            "background-color":
                              "rgba(255,255,255," + _vm.style.box.opacity + ")",
                            marginTop: _vm.style.box.top + "px",
                            "min-width":
                              "calc(100% / " + _vm.round_row_num + " - 20px)",
                          },
                        },
                        [
                          _vm.style.box.round.position === "top" &&
                          _vm.style.box.round.show
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "text-align": "center",
                                    top: "10px",
                                    color: "black",
                                    "font-size": "20px",
                                  },
                                  style: {
                                    color: _vm.style.box.round.color,
                                    fontSize:
                                      _vm.style.box.round.fontSize + "px",
                                  },
                                },
                                [
                                  _item.level
                                    ? _c("span", [
                                        _vm._v(_vm._s(_item.level) + "-"),
                                      ])
                                    : _vm._e(),
                                  _vm._v("第" + _vm._s(_item.num) + "轮"),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                display: "flex",
                                overflow: "hidden",
                              },
                              style: {
                                height:
                                  (_vm.style.box.itemBox.height[_index] || 0) +
                                  "px",
                                marginTop:
                                  _vm.style.box.itemBox.marginTop + "px",
                              },
                            },
                            [
                              _vm.style.box.round.position === "left" &&
                              _vm.style.box.round.show
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "round",
                                      staticStyle: {
                                        "margin-top": "auto",
                                        "margin-bottom": "auto",
                                      },
                                      style: {
                                        color: _vm.style.box.round.color,
                                        fontSize:
                                          _vm.style.box.round.fontSize + "px",
                                      },
                                    },
                                    [_vm._v("第" + _vm._s(_item.num) + "轮")]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "box-content",
                                  style: {
                                    "--row_num": _vm.round_row_num,
                                    "--width": _vm.style.box.itemBox.width,
                                    "--left":
                                      _vm.style.box.round.position === "left"
                                        ? _vm.style.box.round.width + 10
                                        : 0,
                                    "--margin": _vm.style.box.itemBox.margin,
                                    "--row": _vm.row_num,
                                    "--leftMargin":
                                      _vm.style.box.item.leftMargin,
                                    "--topMargin": _vm.style.box.item.topMargin,
                                  },
                                },
                                _vm._l(
                                  _item.rand_list,
                                  function (_item_, _index_) {
                                    return _c(
                                      "div",
                                      {
                                        key: _index_,
                                        staticClass: "item",
                                        style: {
                                          padding:
                                            _vm.style.box.item.topPadding +
                                            "px 0",
                                          "background-color":
                                            _vm.style.box.item.backgroundColor,
                                        },
                                      },
                                      [
                                        _vm.rand.led_show_rand_code
                                          ? _c(
                                              "div",
                                              {
                                                style: {
                                                  "font-size":
                                                    _vm.style.box.item
                                                      .numFontSize + "px",
                                                  color:
                                                    _vm.style.box.item.numColor,
                                                  marginTop:
                                                    _vm.style.box.item.numTop +
                                                    "px",
                                                },
                                              },
                                              [_vm._v(_vm._s(_item_.rand_code))]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            style: {
                                              marginTop:
                                                _vm.style.box.item.contentTop +
                                                "px",
                                              "line-height":
                                                _vm.style.box.item.lineHeight +
                                                "px",
                                            },
                                          },
                                          [
                                            _vm.rand.led_show_name
                                              ? _c(
                                                  "div",
                                                  {
                                                    style: {
                                                      "font-size":
                                                        _vm.style.box.item
                                                          .fontSize + "px",
                                                      color:
                                                        _vm.style.box.item
                                                          .color,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.rand.show_name === 2
                                                          ? _item_.name
                                                          : _vm.nickName(
                                                              _item_.name
                                                            )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.rand.led_show_tel
                                              ? _c(
                                                  "div",
                                                  {
                                                    style: {
                                                      "font-size":
                                                        _vm.style.box.item
                                                          .fontSize + "px",
                                                      color:
                                                        _vm.style.box.item
                                                          .color,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getTel(_item_.tel)
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.rand.led_show_audit_order
                                              ? _c(
                                                  "div",
                                                  {
                                                    style: {
                                                      "font-size":
                                                        _vm.style.box.item
                                                          .fontSize + "px",
                                                      color:
                                                        _vm.style.box.item
                                                          .color,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(_item_.code))]
                                                )
                                              : _vm._e(),
                                            _vm.rand.led_show_card
                                              ? _c(
                                                  "div",
                                                  {
                                                    style: {
                                                      "font-size":
                                                        _vm.style.box.item
                                                          .fontSize + "px",
                                                      color:
                                                        _vm.style.box.item
                                                          .color,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getCard(
                                                          _item_.card,
                                                          _vm.rand.show_card
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.rand.led_show_other_remark
                                              ? _c(
                                                  "div",
                                                  {
                                                    style: {
                                                      "font-size":
                                                        _vm.style.box.item
                                                          .fontSize + "px",
                                                      color:
                                                        _vm.style.box.item
                                                          .color,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _item_.other_remark
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }