import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getToken, getProject } from '@/utils/auth';
import Oss from "@/utils/oss";
export default {
  name: 'ImgUp',
  props: {
    showText: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      action: '',
      header: {
        token: ''
      },
      // 图片大小限制
      imgSizeLimit: 4
    };
  },
  mounted: function mounted() {
    this.header.token = getToken();
    var token = getToken();
    var project = JSON.parse(getProject());
    this.header = {
      token: token,
      project: project.project_id
    };
  },
  methods: {
    // 文件上传前
    uploadBefore: function uploadBefore(e) {
      var size = e.size;
      var limit_M = this.imgSizeLimit;
      if (size / 1024 / 1024 > limit_M) {
        this.$message({
          type: 'error',
          message: "\u4E0A\u4F20\u56FE\u7247\u4E0D\u80FD\u5927\u4E8E".concat(limit_M, "M")
        });
        return false;
      } else {
        return true;
      }
    },
    // 上传失败
    uploadFail: function uploadFail(e) {
      this.$message({
        type: 'error',
        message: '图片上传失败！'
      });
    },
    // 上传成功
    uploadSucess: function uploadSucess(e) {
      this.$emit('upSuccess', e);
    },
    // 处理文件上传
    handleFileUploadOss: function handleFileUploadOss(file) {
      Oss.onUploadFile(file, "public/pcimg/$id/".concat(new Date().getTime(), "-").concat(file.file.name), file.file.name);
    }
  }
};