/**
 * @author: Rrq,
 * @mailbox: 15280973293@163.com,
 * @date: 2021-07-28 11:10,
 */

import request from '@/utils/request';
// import qs from 'qs'

export default {
  //	定金列表
  depositList: function depositList(data) {
    return request({
      url: '/pay/list',
      method: 'post',
      data: data
    });
  },
  //	退款接口
  depositRefund: function depositRefund(params) {
    return request({
      url: '/pay/refund',
      method: 'get',
      params: params
    });
  },
  //	标记退款
  depositSignRefund: function depositSignRefund(params) {
    return request({
      url: '/pay/signRefund',
      method: 'get',
      params: params
    });
  },
  //	查看收据
  smallTicket: function smallTicket(params) {
    return request({
      url: '/pay/small_ticket',
      method: 'get',
      params: params
    });
  },
  // 导出定金记录
  getPayExport: function getPayExport(data) {
    return request({
      url: '/pay/export',
      method: 'post',
      data: data
    });
  },
  //	查找用户列表
  getPayUserList: function getPayUserList(data) {
    return request({
      url: '/pay/Search',
      method: 'post',
      data: data
    });
  },
  //	认购签约记录列表
  signList: function signList(data) {
    return request({
      url: '/sign/list',
      method: 'post',
      data: data
    });
  },
  //	批量删除签约
  signdelAll: function signdelAll(data) {
    return request({
      url: '/sign/delAll',
      method: 'post',
      data: data
    });
  },
  //	认购签约记录列表
  getContract: function getContract(params) {
    return request({
      url: '/sign/getContract',
      method: 'get',
      params: params
    });
  },
  //	签约归档
  doArchive: function doArchive(params) {
    return request({
      url: '/sign/archive',
      method: 'get',
      params: params
    });
  },
  //	认购记录导出数据
  getExport: function getExport(data) {
    return request({
      url: '/sign/export',
      method: 'post',
      data: data
    });
  },
  //	认购记录 作废记录
  getCancel: function getCancel(params) {
    return request({
      url: '/sign/cancel',
      method: 'get',
      params: params
    });
  },
  //	查找用户列表
  getSignUserList: function getSignUserList(data) {
    return request({
      url: '/sign/Search',
      method: 'post',
      data: data
    });
  },
  // 获取签约
  signDetails: function signDetails(data) {
    return request({
      url: '/pay/signDetails',
      method: 'POST',
      data: data
    });
  }
};