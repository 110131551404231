import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import utils from "@/utils/utils";
export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: '导入'
    },
    size: {
      type: String,
      default: 'small'
    },
    type: {
      type: String,
      default: 'text'
    },
    // 导入 数据判断
    dataType: {
      type: String,
      default: 'room'
    }
  },
  data: function data() {
    return {
      // 请求头
      headers: {
        token: this.getToken(),
        project: JSON.parse(this.getProject()).project_id
      },
      isDialog: false,
      info: {},
      // 800毫秒过后开始出现 loading
      hm: 800
    };
  },
  methods: {
    // 上传格式 大小限制
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      // 倒计时 800 毫秒 出现 loading
      this.loading = this.$loading({
        lock: true,
        text: '努力上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      // this.time = setInterval(() => {
      // 	this.hm = this.hm - 10
      // 	if (this.hm === 0) {
      // 		this.loading = this.$loading({
      // 			lock: true,
      // 			text: '努力上传中...',
      // 			spinner: 'el-icon-loading',
      // 			background: 'rgba(0, 0, 0, 0.7)'
      // 		})
      // 		clearInterval(this.time)
      // 	}
      // }, 10)

      // excel
      var isJPG = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel';
      var isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        var _this$loading;
        (_this$loading = this.loading) === null || _this$loading === void 0 || _this$loading.close();
        this.$message.error('上传只能是 excel 格式!');
      }
      if (!isLt2M) {
        var _this$loading2;
        (_this$loading2 = this.loading) === null || _this$loading2 === void 0 || _this$loading2.close();
        this.$message.error('上传大小不能超过 10MB!');
      }
      return isJPG && isLt2M;
    },
    // 上传成回调
    handelSuccess: function handelSuccess(response, file, fileList) {
      var _this$loading3;
      if (this.url === "/admins/Import") {
        var _response$successCoun, _response$errCount;
        // this.$message({
        //     type: 'success',
        //     message: "成功：" + (response.successCount) + "条，失败：" + (response.errCount) + "条"
        // })
        this.info = {
          suc: (_response$successCoun = response.successCount) !== null && _response$successCoun !== void 0 ? _response$successCoun : 0,
          err: (_response$errCount = response.errCount) !== null && _response$errCount !== void 0 ? _response$errCount : 0
        };
        this.isDialog = true;
      } else {
        this.$message({
          type: 'success',
          message: response.msg
        });
      }
      if (response.info !== undefined) {
        this.info = response.info;
        this.isDialog = true;
      } else {
        this.$emit('uploadSuccess');
      }
      if (response.path !== '' && response.path !== null && response.path !== "/") {
        // window.location.href = this.host + response.path
        utils.download_files_base64(response.data, response.name, response.suffix);
      }
      (_this$loading3 = this.loading) === null || _this$loading3 === void 0 || _this$loading3.close();
    },
    // 上传失败
    handelError: function handelError(err, file, fileList) {
      var _this$loading4;
      var myError = err.toString(); // 转字符串
      myError = myError.replace('Error: ', ''); // 去掉前面的" Error: "
      myError = JSON.parse(myError); // 转对象

      this.$message({
        type: 'error',
        message: myError.msg
      });

      //  是否超过 800毫秒， 没超过 直接终止定时器

      (_this$loading4 = this.loading) === null || _this$loading4 === void 0 || _this$loading4.close();
      this.$emit('uploadSuccess');
      if (myError.msg !== '项目已失效无权操作~') {
        if (myError.path && myError.path !== '' && myError.path !== null) {
          window.location.href = this.host + myError.path;
        }
      }
    },
    isDialogClose: function isDialogClose() {
      this.isDialog = false;
      this.$emit('uploadSuccess');
    }
  }
};