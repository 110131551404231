var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "edit-format-bg" }, [
      _c("img", { attrs: { src: _vm.replaceImgshow(_vm.rand.logo) } }),
    ]),
    _c("div", { staticClass: "box" }, [
      _c("div", { staticClass: "box-title" }, [
        _vm._v(" " + _vm._s(_vm.rand.rand_name) + " "),
      ]),
      _c("div", { staticClass: "box-content" }, [
        _c("div", { staticClass: "box-left" }, [
          _c("div", { staticClass: "left-title" }, [_vm._v("查找客户")]),
          _c(
            "div",
            { staticClass: "left-content" },
            [
              _vm.type == 1
                ? [
                    _vm._m(0),
                    _c("p", { staticClass: "prompt" }, [
                      _vm._v("可通过入场编号\\姓名\\房号查询"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "seek-input-wrapper" },
                      [
                        _c("el-input", {
                          ref: "userInput",
                          staticClass: "input-style",
                          attrs: {
                            clearable: "",
                            placeholder: "入场编号\\姓名\\房号查询",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.getUserList($event)
                            },
                          },
                          model: {
                            value: _vm.searchParams.tel,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "tel", $$v)
                            },
                            expression: "searchParams.tel",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm.type == 2
                ? [
                    _c(
                      "div",
                      { staticClass: "box-content-left-userList" },
                      _vm._l(_vm.userList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            class: {
                              "box-content-left-userList-item": true,
                              "select-userList-item": index == 1,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onCheckedUser(index)
                              },
                            },
                          },
                          [
                            item.checked
                              ? _c("img", {
                                  staticStyle: {
                                    width: "34px",
                                    height: "30px",
                                    position: "absolute",
                                    bottom: "0",
                                    right: "0",
                                  },
                                  attrs: {
                                    src: require("@/assets/image/randRoom-select.png"),
                                  },
                                })
                              : _vm._e(),
                            _c("img", {
                              staticStyle: { width: "60px", height: "60px" },
                              attrs: {
                                src: require("@/assets/image/avatar_icon.png"),
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "info-r",
                                staticStyle: {
                                  "margin-left": "20px",
                                  width: "200px",
                                  color: "white",
                                },
                              },
                              [
                                item.code
                                  ? _c(
                                      "div",
                                      { staticStyle: { padding: "5px 0" } },
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 10 } },
                                              [_vm._v("入场编号：")]
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 13 } },
                                              [_vm._v(_vm._s(item.code))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticStyle: { padding: "5px 0" } },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c("el-col", { attrs: { span: 10 } }, [
                                          _vm._v("姓名："),
                                        ]),
                                        _c("el-col", { attrs: { span: 13 } }, [
                                          _vm._v(_vm._s(item.name)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                item.other_remark
                                  ? _c(
                                      "div",
                                      { staticStyle: { padding: "5px 0" } },
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 10 } },
                                              [_vm._v("原房号：")]
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 13 } },
                                              _vm._l(
                                                item.houseall,
                                                function (house_name, indexs) {
                                                  return _c(
                                                    "p",
                                                    {
                                                      key: indexs,
                                                      staticClass: "houses",
                                                    },
                                                    [_vm._v(_vm._s(house_name))]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                : _vm.type == 3
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "box-content-left-userList",
                        staticStyle: { padding: "30px 15px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "info_cell",
                            staticStyle: {
                              "margin-top": "50px",
                              color: "white",
                              transform: "scale(1.5)",
                            },
                          },
                          [
                            _c("div", { staticClass: "info-val" }, [
                              _vm._v(_vm._s(_vm.userInfo.code)),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "info_cell",
                            staticStyle: {
                              "margin-top": "60px",
                              color: "white",
                              transform: "scale(1.5)",
                            },
                          },
                          [
                            _c("div", { staticClass: "info-val" }, [
                              _vm._v(_vm._s(_vm.userInfo.name)),
                            ]),
                          ]
                        ),
                        _vm.userInfo.other_remark
                          ? _c(
                              "div",
                              {
                                staticClass: "info_cell",
                                staticStyle: {
                                  "margin-top": "60px",
                                  color: "white",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "info-val" },
                                  _vm._l(
                                    _vm.userInfo.houseall,
                                    function (house_name, indexs) {
                                      return _c(
                                        "p",
                                        {
                                          key: indexs,
                                          staticClass: "houses",
                                          staticStyle: { "font-size": "39px" },
                                        },
                                        [_vm._v(_vm._s(house_name))]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "box-content-left-bottom",
              on: { click: _vm.customConfirm },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.type == 1
                      ? "查询"
                      : _vm.type == 2
                      ? "确定"
                      : "换一个客户"
                  ) +
                  " "
              ),
            ]
          ),
        ]),
        _vm.type == 3
          ? _c(
              "div",
              { staticClass: "box-right" },
              [
                _c("enter-then-number", {
                  attrs: { number: _vm.entry_num, userInfo: _vm.userInfo },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "seek-img-wrapper" }, [
      _c("img", {
        staticClass: "dialog-3-content-img",
        attrs: { src: require("@/assets/image/IDCard-img.png"), alt: "" },
      }),
      _c("div", { staticClass: "search-img" }, [
        _c("img", {
          staticClass: "search-img",
          attrs: { alt: "", src: require("@/assets/image/searching-img.png") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }