//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Card',
  props: {
    isBottom: {
      type: Boolean,
      default: true
    },
    showTop: {
      //top是否显示 默认显示
      type: Boolean,
      default: true
    }
  }
};