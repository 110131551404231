import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Print } from '@/api/lottery';
import utils from "@/utils/utils";
export default {
  name: "index",
  props: {
    template_ID: {
      type: Number
    }
  },
  data: function data() {
    return {
      dataList: [],
      rand: {},
      selectIds: [],
      rand_id: 0,
      project_name: "",
      template_id: 0
    };
  },
  created: function created() {
    console.log(this.$route.query.template_id);
    if (this.$route.query.ids && this.$route.query.rand_id) {
      this.template_id = this.$route.query.template_id ? this.$route.query.template_id : 0;
      var selectIds = this.$route.query.ids ? this.$route.query.ids.split(",") : [];
      this.rand_id = +this.$route.query.rand_id;
      for (var i = 0; i < selectIds.length; i++) {
        this.selectIds.push(+selectIds[i]);
      }
      this.doGetPrint(this.selectIds);
    } else if (this.template_ID === 0 || this.$route.query.template_ID == 0) {
      var log_list = [];
      for (var _i = 1; _i < 11; _i++) {
        log_list.push({
          code: _i,
          name: "名称" + _i,
          user_code: "诚意单号" + _i,
          card: "证件号码" + _i
        });
      }
      this.dataList = [{
        num: 1,
        created_at: new Date().getTime() / 1000,
        print_time: new Date().getTime() / 1000,
        log_list: log_list
      }];
      this.project_name = "项目名称";
      this.template_id = 0;
    } else if (this.template_ID === 1 || this.$route.query.template_ID == 1) {
      var _log_list = [];
      for (var _i2 = 1; _i2 < 11; _i2++) {
        _log_list.push({
          code: _i2,
          name: "名称" + _i2,
          user_code: "收据号" + _i2
        });
      }
      this.dataList = [{
        num: 1,
        created_at: new Date().getTime() / 1000,
        print_time: new Date().getTime() / 1000,
        log_list: _log_list
      }];
      this.project_name = "项目名称";
      this.template_id = 1;
    } else {}
  },
  mounted: function mounted() {},
  methods: {
    doGetPrint: function doGetPrint() {
      var _this = this;
      if (this.printTimeOut) return;
      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      Print({
        "rand_id": this.rand_id,
        "ids": this.selectIds
      }).then(function (res) {
        _this.rand = res.data.info.rand;
        _this.project_name = res.data.info.name;
        _this.dataList = res.data.info.list || [];
        _this.$nextTick(function () {
          _this.printTimeOut = setTimeout(function () {
            window.print();
            clearTimeout(_this.printTimeOut);
          }, 500);
        });
        _this.loading.close();
      }).catch(function (res) {
        _this.loading.close();
      });
    },
    // 改变时间格式
    doGetTime: function doGetTime(time) {
      return utils.formatDate(new Date(time), "yyyy年MM月dd日 hh:mm:ss");
    }
  }
};