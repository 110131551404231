import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import store from '@/store';
function checkPermission(el, binding) {
  var value = binding.value;
  var roles = store.getters && store.getters.roles;
  if (value && value instanceof Array) {
    if (value.length > 0) {
      var permissionRoles = value;
      var hasPermission = roles.some(function (role) {
        return permissionRoles.includes(role);
      });
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    }
  } else {
    throw new Error("need roles! Like v-permission=\"['admin','editor']\"");
  }
}
export default {
  inserted: function inserted(el, binding) {
    checkPermission(el, binding);
  },
  update: function update(el, binding) {
    checkPermission(el, binding);
  }
};