var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-progress" },
    _vm._l(_vm.progressList, function (item, i) {
      return _c("div", { key: i, staticClass: "progress-wrapper" }, [
        _c("div", { staticClass: "progress" }, [
          _c("div", { staticClass: "p-top" }, [
            _c("div", { class: item.status ? "circle" : "circle-fail" }, [
              item.status
                ? _c("i", { staticClass: "iconfont icondui" })
                : _c("span", [_vm._v(_vm._s(i + 1))]),
            ]),
            _vm._m(0, true),
          ]),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "progress-text" }, [
              _c("div", { staticClass: "text-title" }, [
                _vm._v(_vm._s(item.title)),
              ]),
              item.status
                ? _c("div", { staticClass: "sucess-text" }, [_vm._v("已完成")])
                : _c(
                    "div",
                    {
                      staticClass: "fail-text",
                      on: {
                        click: function ($event) {
                          return _vm.setting(item)
                        },
                      },
                    },
                    [_vm._v("去设置")]
                  ),
            ]),
            _c("div", { staticClass: "text-sub-title" }, [
              _vm._v(_vm._s(item.subTitle)),
            ]),
            false
              ? _c("div", { staticClass: "content-box" }, [
                  i === _vm.progressList.length - 1 && item.is_sms
                    ? _c("div", { staticClass: "bottom-box" }, [
                        _c("div", { staticClass: "tip-text" }, [
                          _vm._v(
                            "还有" + _vm._s(item.sms_count) + "人未发送初始密码"
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["admin", "index/send"],
                                expression: "['admin','index/send']",
                              },
                            ],
                            staticClass: "pr-btn-wrapper",
                            attrs: { loading: _vm.sendLoading },
                            on: { click: _vm.sendMes },
                          },
                          [_vm._v("立即发送")]
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-wrapper" }, [
      _c("div", { staticClass: "line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }