import _typeof from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/typeof.js";
import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import topTitle from './components/title';
import imgUp from './components/imgUp';
import simpleSetting from './components/simpleSetting';
import roundSetting from './components/roundSetting';
import customBtn from './components/customBtn.vue';
import simpleCard from './components/simpleCard';
import complexCard from './components/complexCard';
import complexInfoCard from './components/complexInfoCard';
import mainSetting from './components/mainSetting';
import projectTitle from './components/projectTitle.vue';
import projectRounds from './components/projectRounds.vue';
import projectBottom from './components/projectBottom.vue';
import projectContent from './components/projectContent.vue';
import itemBox from './components/itemBox.vue';
import roundsBtn from './components/roundsBtn.vue';
import { mapMutations, mapState } from 'vuex';
import { getRoundStyle, setRoundStyle, getRowStyle, setRowStyle, setSubStyle } from '@/api/projectRound';
import { unit } from './handleStyle';

// 默认数据
import { defaultData, mainDefaultData } from "./defaultSettingData";
import NoticeCard from '@/views/editFormat/components/noticeCard.vue';
export default {
  name: 'EditFormat',
  components: {
    NoticeCard: NoticeCard,
    topTitle: topTitle,
    imgUp: imgUp,
    simpleSetting: simpleSetting,
    roundSetting: roundSetting,
    customBtn: customBtn,
    mainSetting: mainSetting,
    simpleCard: simpleCard,
    complexCard: complexCard,
    complexInfoCard: complexInfoCard,
    projectTitle: projectTitle,
    projectRounds: projectRounds,
    projectBottom: projectBottom,
    projectContent: projectContent,
    itemBox: itemBox,
    roundsBtn: roundsBtn
  },
  data: function data() {
    return {
      // 是否打开弹框
      isOpenDrawer: false,
      // 自适应的顶部高度
      tbHeight: 0,
      // 参数 配置
      options: {},
      // 定时器
      timer: null,
      // 个数 item
      itemNum: 10,
      // rand
      rand: {},
      // 副屏
      sub_screen: {
        show: false,
        index: 0,
        data: [],
        selectData: {}
      },
      // 假数据
      next_round_list: [],
      roundNumber: 1,
      is_stay_user: 1
    };
  },
  computed: _objectSpread({}, mapState({
    editFormatList: function editFormatList(state) {
      return state.editFormat.list;
    }
  })),
  watch: {
    // 监听数据实时变化实时预览 比较耗性能
    // # 为啥还这样写 因为不想动这屎山
    "sub_screen.selectData": {
      deep: true,
      handler: function handler(state) {
        if (JSON.stringify(state) !== "{}") {
          this.sub_screen.data[this.sub_screen.index] = JSON.parse(JSON.stringify(this.sub_screen.selectData));
        }
      }
    }
  },
  mounted: function mounted() {
    var options = this.$route.query;
    this.options = options;
    this.getAllOptions();
  },
  updated: function updated() {
    var _this = this;
    // 数据更新重新计算高度
    this.$nextTick(function () {
      clearTimeout(_this.timer);
      _this.timer = setTimeout(function () {
        _this.doGetTbHeight();
      }, 1000);
    });
  },
  methods: _objectSpread(_objectSpread({}, mapMutations('editFormat', ['SET_LIST'])), {}, {
    /**
     * @function getSequenceNumber 获取顺序号
     * @param {number} index 当前摇号下标
     * @param {number} roundNumber 轮
     */
    getSequenceNumber: function getSequenceNumber() {
      var index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var roundNumber = arguments.length > 1 ? arguments[1] : undefined;
      var num = index;
      // 继承轮顺序号
      if (this.rand.type_round_code === 1) {
        var _roundNumber;
        // 避免错误数据
        roundNumber = ((_roundNumber = roundNumber) !== null && _roundNumber !== void 0 ? _roundNumber : 1) - 1;
        num = roundNumber * this.rand.rand_count + index;
      }

      // 继承分组顺序号 && 随机 && 分组
      if (this.rand.type_group_code === 1 && this.rand.type === 1 && this.rand.type_group === 1) {
        num += this.list.max_code;
      }

      // 开启顺序号ABC样式
      if (this.rand.code_style === 1) {
        //  将顺序号转换成ABC
        num = this.getExcelColumn(num);
      } else {
        num = "\u7B2C".concat(num).concat(this.rand.code_unit || '号');
      }
      return num;
    },
    // 获取类Excel列表
    getExcelColumn: function getExcelColumn(columnNumber) {
      var column = '';
      while (columnNumber > 0) {
        columnNumber--;
        var remainder = columnNumber % 26;
        column = String.fromCharCode(65 + remainder) + column;
        columnNumber = Math.floor(columnNumber / 26);
      }
      return column;
    },
    //  获取配置
    getAllOptions: function getAllOptions() {
      var _this2 = this;
      if (this.options.type == 'round') {
        getRoundStyle().then(function (res) {
          var _this2$sub_screen$dat;
          var editFormatList = res.data.list;
          _this2.rand = editFormatList.rand;
          _this2.is_stay_user = editFormatList.rand.is_stay_user;
          _this2.itemNum = editFormatList.rand.rand_count;
          var arr = [];
          for (var i = 0; i < _this2.rand.last_round_num; i++) {
            var list = [];
            for (var j = 0; j < _this2.itemNum; j++) {
              list.push({
                name: '用户姓名',
                code: '诚意单号',
                tel: '12345678901',
                card: '123456789012345678'
              });
            }
            arr.push({
              round: i + 1,
              list: list
            });
          }
          _this2.next_round_list = arr;
          var styleData = [];
          if ((editFormatList === null || editFormatList === void 0 ? void 0 : editFormatList.sub_json) !== "{}") {
            var d = true;
            try {
              styleData = JSON.parse(editFormatList.sub_json);
            } catch (error) {
              // 解析失败 不知道什么原因数据丢失或不是一个正常的JSON格式 使用默认数据
              styleData = [defaultData];
              d = false;
            }
            if (d) {
              // 如果是旧数据跟默认数据对比一下
              // 用默认数据补全旧数据
              // 防止因旧数据无新添加的样式报错
              _this2.handleData(styleData, [defaultData]);
            }
          } else {
            styleData = [defaultData];
          }
          _this2.sub_screen = {
            show: false,
            index: 0,
            data: styleData,
            selectData: {}
          };
          if (!((_this2$sub_screen$dat = _this2.sub_screen.data[0]) !== null && _this2$sub_screen$dat !== void 0 && _this2$sub_screen$dat.other_remark)) {
            for (var _i = 0; _i < _this2.sub_screen.data.length; _i++) {
              _this2.sub_screen.data[_i].other_remark = {
                fontSize: 16,
                color: "#fff"
              };
            }
          }
          if (!editFormatList.content_height) editFormatList.content_height = 0;
          _this2.SET_LIST(editFormatList);
        });
      } else {
        getRowStyle().then(function (res) {
          var editFormatList = res.data.list;
          _this2.rand = editFormatList.row;
          _this2.is_stay_user = editFormatList.row.is_stay_user;
          _this2.itemNum = editFormatList.row.rand_count;
          if (!editFormatList.content_height) editFormatList.content_height = 0;
          _this2.SET_LIST(editFormatList);
        });
      }
    },
    /**
     * 旧数据缺失新配置处理
     */
    handleData: function handleData() {
      var _this3 = this;
      var newStyle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var defaultData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      // 数组处理
      if (newStyle instanceof Array) {
        newStyle.forEach(function (item) {
          defaultData.forEach(function (oldItem) {
            var newArr = Object.keys(item);
            Object.keys(oldItem).forEach(function (olditemKey) {
              // 新数据无默认数据中的字段
              if (!newArr.includes(olditemKey)) {
                // 新数据直接添加默认里的数据
                item[olditemKey] = oldItem[olditemKey];
              }
              // 有就接着往下递归
              else return _this3.handleData(item[olditemKey], oldItem[olditemKey]);
            });
          });
        });
      }
      // 对象处理
      else if (newStyle instanceof Object) {
        Object.keys(defaultData).forEach(function (key) {
          // 无数据 使用默认
          if (['undefiend', 'null'].includes(_typeof(newStyle[key]))) {
            newStyle[key] = defaultData[key];
          }
          // 有数据 接着往下处理
          else return _this3.handleData(newStyle[key], defaultData[key]);
        });
      }
      // 其他类型数据直接返回
      else return newStyle;
    },
    // 保存恢复默认
    saveOption: function saveOption(type) {
      var _this4 = this;
      // 恢复默认
      if (type == 0) {
        this.$confirm('是否重置为默认值?', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确定'
        }).then(function () {
          var params = _objectSpread(_objectSpread({}, mainDefaultData), {}, {
            host_json: mainDefaultData.host_json
          });
          if (_this4.options.type == 'round') {
            setRoundStyle(params).then(function (res) {
              _this4.SET_LIST(JSON.parse(JSON.stringify(mainDefaultData)));
              _this4.$message({
                type: 'success',
                message: res.data.msg
              });
              _this4.ro();
            });
          } else {
            setRowStyle(params).then(function (res) {
              _this4.SET_LIST(JSON.parse(JSON.stringify(mainDefaultData)));
              _this4.$message({
                type: 'success',
                message: res.data.msg
              });
              _this4.ro();
            });
          }
        }).catch(function () {
          return;
        });
      }
      // 保存
      if (type == 1) {
        var params = _objectSpread(_objectSpread({}, this.editFormatList), {}, {
          host_json: this.editFormatList.host_json
        });
        if (this.options.type == 'round') {
          setRoundStyle(params).then(function (res) {
            _this4.$message({
              type: 'success',
              message: res.data.msg
            });
            _this4.ro();
          });
        } else {
          setRowStyle(params).then(function (res) {
            _this4.$message({
              type: 'success',
              message: res.data.msg
            });
            _this4.ro();
          });
        }
      }
    },
    ro: function ro() {
      var _this5 = this;
      if (this.rand.type === 2 && this.rand.is_last_round_show == 0) {
        // 解决通知滚动条不重新渲染
        this.rand.type = 1;
        this.$nextTick(function () {
          _this5.rand.type = 2;
        });
      }
    },
    // 保存成功
    upSuccess: function upSuccess(e) {
      var _e$Data,
        _this6 = this;
      if (e !== null && e !== void 0 && (_e$Data = e.Data) !== null && _e$Data !== void 0 && _e$Data.url) {
        this.editFormatList.logo = e.Data.url;
      } else {
        this.editFormatList.logo = mainDefaultData.logo;
      }
      var params = _objectSpread(_objectSpread({}, this.editFormatList), {}, {
        host_json: this.editFormatList.host_json
      });
      if (this.options.type == 'round') {
        setRoundStyle(params).then(function (res) {
          _this6.$message({
            type: 'success',
            message: res.data.msg
          });
        });
      } else {
        setRowStyle(params).then(function (res) {
          _this6.$message({
            type: 'success',
            message: res.data.msg
          });
        });
      }
    },
    // 获取tbHeight的值
    doGetTbHeight: function doGetTbHeight() {
      var t_el = this.$refs['topHeight'];
      var b_el = this.$refs['bottomHeight'];
      // 之前使用相对位置，所以父级高度存在，目前改为绝对高度，所以使用轮次的上边距和字体大小判断当前元素占多高
      // let titleHeight = parseInt(this.editFormatList.round_position + this.editFormatList.round_size)
      var titleHeight = parseInt(this.editFormatList.card_position);
      this.tbHeight = titleHeight + parseInt(b_el.offsetHeight);
    },
    mainStyle: function mainStyle() {
      var currentStyle = {},
        currentDOM = Object.assign({}, mainDefaultData, this.editFormatList.host_json);
      currentStyle.top = currentDOM.page_positionX;
      currentStyle.left = currentDOM.page_positionY;
      currentStyle.width = "calc(100vw - ".concat(currentDOM.page_width, "px)");
      currentStyle.height = "calc(100vh - ".concat(currentDOM.page_height, "px)");
      // 样式
      return unit(currentStyle);
    },
    // 计算样式
    subStyle: function subStyle(index) {
      if (this.rand.is_sub_screen !== 1) return;
      var current = this.sub_screen.data[index];
      var currentDOM = Object.assign({}, defaultData, current),
        currentStyle = {},
        // 样式
        xAxis = currentDOM.locationXy,
        // x坐标
        yAxis = currentDOM.locationTb; // y坐标

      // 定位
      currentStyle[xAxis] = currentDOM.x;
      currentStyle[yAxis] = currentDOM.y;
      currentStyle.width = "calc(100vw - ".concat(currentDOM.width, "px)");
      currentStyle.height = "calc(100vh - ".concat(currentDOM.height, "px)");
      currentStyle.borderColor = currentDOM.borderColor;
      return unit(currentStyle);
    },
    // 副屏操作
    onSub_screenOper: function onSub_screenOper(key) {
      var _this7 = this;
      // 打开弹框
      if (key === "drawer") {
        this.sub_screen.show = true;
        this.sub_screen.selectData = JSON.parse(JSON.stringify(this.sub_screen.data[this.sub_screen.index]));
      }
      // 删除
      else if (key === "del") {
        if (this.sub_screen.data.length <= 1) {
          this.$message({
            type: 'error',
            message: "当前副屏不可删除！"
          });
          return;
        }
        this.$confirm('该操作不可复原，删除成功后需保存生效，是否确定删除当前副屏?', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确定'
        }).then(function () {
          _this7.sub_screen.data.splice(_this7.sub_screen.index, 1);
          _this7.sub_screen.show = false;
          _this7.sub_screen.index = 0;
          _this7.$message({
            type: 'success',
            message: "删除成功！"
          });
          _this7.doGetTbHeight();
        });
      }
      // 增加副屏
      else if (key === "add") {
        this.sub_screen.data.push(this.sub_screen.data[this.sub_screen.data.length - 1]);
        this.sub_screen.index = this.sub_screen.data.length - 1;
        this.sub_screen.selectData = JSON.parse(JSON.stringify(this.sub_screen.data[this.sub_screen.index]));
        this.$message({
          type: 'success',
          message: "添加成功！需保存生效！"
        });
        this.doGetTbHeight();
      }
      // 同步数据
      else if (key === "sync") {
        this.$confirm('确定要将卡片内信息同步至其他副屏吗?', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确定'
        }).then(function () {
          var data = JSON.parse(JSON.stringify(_this7.sub_screen.data)),
            selectData = _this7.sub_screen.selectData;
          var keys = ["background_color", "name", "tel", "audit_order", "card", "other_remark", "interval"];
          for (var i = 0; i < data.length; i++) {
            for (var k in selectData) {
              if (keys.indexOf(k) >= 0) {
                data[i][k] = selectData[k];
              }
            }
          }
          _this7.sub_screen.data = JSON.parse(JSON.stringify(data));
          _this7.sub_screen.show = false;
          _this7.$message({
            type: 'success',
            message: "同步成功！"
          });
        });
      }
      // 保存
      else if (key === "save") {
        this.sub_screen.data[this.sub_screen.index] = JSON.parse(JSON.stringify(this.sub_screen.selectData));
        var loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        setSubStyle({
          sub_json: this.sub_screen.data
        }).then(function (res) {
          _this7.$message({
            type: 'success',
            message: "保存成功！"
          });
          _this7.getAllOptions();
          loading.close();
        }).catch(function (res) {
          loading.close();
        });
      }
    },
    // 副屏切换屏幕
    onSub_screenIndexChange: function onSub_screenIndexChange(index) {
      this.sub_screen.index = index;
      this.sub_screen.selectData = JSON.parse(JSON.stringify(this.sub_screen.data[index]));
    },
    // 修改fixe
    changeFlex: function changeFlex(e) {
      if (e === 'column') {
        this.editFormatList.host_json.num_width = 100;
        this.editFormatList.host_json.name_width = 100;
        this.editFormatList.host_json.tel_width = 100;
        this.editFormatList.host_json.code_width = 100;
        this.editFormatList.host_json.card_width = 100;
        this.editFormatList.host_json.other_remark_width = 100;
      } else {
        this.editFormatList.host_json.card_info_code_positionButtom = 0;
      }
    },
    changeShowModel: function changeShowModel(e) {
      if (e === 'list') {
        this.sub_screen.selectData.row_num = 1;
      }
    }
  })
};