/**
 * @author: Rrq,
 * @mailbox: 15280973293@163.com,
 * @date: 2021-06-17 09:33,
 */

import request from '@/utils/request';
export default {
  // 获取房源列表
  roomIndex: function roomIndex(data) {
    return request({
      url: '/identifySign/index',
      method: 'post',
      data: data
    });
  },
  export: function _export(data) {
    return request({
      url: '/identifySign/export',
      method: 'post',
      data: data
    });
  },
  getContract: function getContract(params) {
    return request({
      url: '/identifySign/getUrl',
      method: 'get',
      params: params
    });
  },
  downUrl: function downUrl(params) {
    return request({
      url: 'identifySign/downUrl',
      method: 'GET',
      params: params
    });
  },
  cancel: function cancel(params) {
    return request({
      url: '/identifySign/cancel',
      method: 'get',
      params: params
    });
  },
  eCancel: function eCancel(params) {
    return request({
      url: '/identifySign/eCancel',
      method: 'get',
      params: params
    });
  },
  del: function del(data) {
    return request({
      url: '/identifySign/del',
      method: 'POST',
      data: data
    });
  },
  signConfirm: function signConfirm(data) {
    return request({
      url: 'identifySign/signConfirm',
      method: 'POST',
      data: data
    });
  }
};