// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".el-table .cellClass td:nth-child(1) {\n  background-color: #f5f7fa;\n}\n.el-table .cellClass td:nth-child(2) {\n  background-color: #f5f7fa;\n}\n.tableStyle .el-table th.is-leaf,\n.tableStyle .el-table td {\n  border-right: none !important;\n}\n.title-flex {\n  width: 100%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.title-flex span {\n    -webkit-box-flex: 1;\n        -ms-flex: 1;\n            flex: 1;\n    text-align: center;\n}\n.title-style {\n  width: 100%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n.title-style .title-number {\n    padding-right: 8px;\n}\n.title-style .title-number span {\n      display: inline-block;\n      width: 120px;\n      text-align: right;\n}\n.el-collapse-item__header {\n  background: #F8F8F8;\n  padding-left: 5px;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n}\n.box-list {\n  max-height: 200px;\n  overflow-x: auto;\n}\n.coll-list .el-checkbox {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  padding: 15px 0;\n}\n.coll-list .el-checkbox .el-checkbox__label {\n    -webkit-box-flex: 1;\n        -ms-flex: 1;\n            flex: 1;\n}\n.title-p {\n  padding: 15px 0;\n}\n", ""]);
// Exports
exports.locals = {
	"menuText": "#475852",
	"menuActiveText": "#FFFFFF",
	"subMenuActiveText": "#019B81",
	"menusonhoverBg": "#CFEAE1",
	"menusonhoverText": "#019B81",
	"menuBg": "#FFFFFF",
	"menuHoverText": "#019B81",
	"menuHoverBg": "rgba(34, 162, 113, 0.2)",
	"subMenuBg": "#FFFFFF",
	"subMenuHover": "#019B81",
	"sideBarWidth": "210px",
	"sonHoverText": "#019B81",
	"sonHoverBg": "rgba(34, 162, 113, 0.2)"
};
module.exports = exports;
