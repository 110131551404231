import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import screenfull from '@/components/my-screen';
import Api from '../../../api/rowNumber';
import editFormatMixin from '@/views/editFormat/editFormatMixin';
export default {
  name: 'NumberOrderHistory',
  components: {
    screenfull: screenfull
  },
  mixins: [editFormatMixin],
  data: function data() {
    return {
      // 定时器
      timeId: null,
      // 摇号 规则
      rand: {},
      // 摇号列表
      lottery: [],
      h: 0,
      // 轮次信息
      list: {},
      // 根据个数改变样式
      heightClass: 'height10'
    };
  },
  // 初始化
  created: function created() {
    // 路由传参数据
    var _this$$route$query = this.$route.query,
      id = _this$$route$query.id,
      group_id = _this$$route$query.group_id,
      round = _this$$route$query.round;

    // 组装数据 给后台
    var data = {
      id: id,
      group_id: group_id,
      round: round
    };

    // 获取历史  数据
    this.getStartLottery(data);
  },
  methods: {
    // 回到当前轮
    handleStartLottery: function handleStartLottery() {
      // 路由传参数据
      var _this$$route$query2 = this.$route.query,
        id = _this$$route$query2.id,
        round = _this$$route$query2.round;

      // 组装数据 给后台
      var data = {
        id: id,
        group_id: 0,
        round: round,
        type: 'row'
      };
      this.$router.push({
        name: 'NumberOrder',
        query: data
      });
    },
    // 获取下一轮 客户
    handleUpDown: function handleUpDown(num) {
      // 路由传参  获取路由参数信息
      var query = JSON.parse(JSON.stringify(this.$route.query));

      // 组装数据 给后台
      var data = {
        id: query.id,
        group_id: query.group_id,
        round: query.round - num,
        type: 'row'
      };
      query.round = query.round - num; // 改变参数

      this.$router.push({
        path: this.$route.path,
        query: query
      }); // 更新路由

      // 刷新页面数据
      this.getStartLottery(data);
    },
    // 初始化数据 获取历史摇号数据
    getStartLottery: function getStartLottery(data) {
      var _this = this;
      Api.numberSelOrderRow(data).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
            list = _res$data.list,
            _res$data$list = _res$data.list,
            round_list = _res$data$list.round_list,
            row = _res$data$list.row;

          // 摇号规则
          _this.rand = row;
          _this.roundNumber = 3;
          // 摇号基本数据
          _this.list = list;
          _this.lottery = round_list;

          // 页面个数 高度
          if (row.rand_count > 10) {
            _this.heightClass = 'height20';
          } else {
            _this.heightClass = 'height10';
          }
        }
      });
    }
  }
};