//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    bg: {
      type: String,
      default: '#fff'
    },
    labelWidth: {
      type: String,
      default: ''
    },
    labelAlign: {
      type: String,
      default: ''
    }
  },
  provide: function provide() {
    var groupWidth = this.labelWidth;
    var groupAlign = this.labelAlign;
    return {
      groupWidth: groupWidth,
      groupAlign: groupAlign
    };
  },
  data: function data() {
    return {};
  }
};