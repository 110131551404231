import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { roomCatelist } from './api';
export default {
  name: 'HouseType',
  props: {
    showPopup: Boolean,
    title: {
      default: '意向户型'
    },
    // 已选中数组
    selectData: {
      type: [Array, String],
      default: function _default() {
        return [];
      }
    }
  },
  watch: {
    // 监听显示隐藏 可以减少页面通过ref获取dom对象
    showPopup: {
      handler: function handler(newVal, odlVal) {
        // 开启
        if (newVal) {
          this.show();
        }
        // 开启又关闭
        if (!newVal && odlVal) {
          this.close();
        }
      },
      immediate: true // 首次
    },
    selectData: {
      handler: function handler(newVal, odlVal) {
        var d = newVal;
        // 如果是字符串
        if (typeof newVal === 'string') {
          try {
            // 不去报错 影响操作
            d = JSON.parse(newVal);
          } catch (e) {
            d = [];
          }
        }
        if (!d) d = [];
        if (d && typeof d === 'string') {
          d = JSON.parse(d);
        }
        this.selectList = d;
        this.checkRoomCateList = d;
        if (d.length) {
          // 有选择数据通过提交回填
          this.toStr(d);
        }
      },
      immediate: true // 首次
    }
  },
  data: function data() {
    return {
      cateList: [],
      checkRoomCateList: [],
      showRoomCate: false,
      buildTypeList: [],
      // 楼栋类型 车位 房源...
      buildTypeIndex: 1,
      selectList: [] // 选择显示列表
    };
  },
  methods: {
    setData: function setData(data, callback) {
      var _this = this;
      Object.keys(data).forEach(function (key) {
        _this[key] = data[key];
      });
      callback && callback();
    },
    toStr: function toStr() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      if (!data) return;
      data.forEach(function (item) {
        Object.keys(item).forEach(function (key) {
          // 不为不处理的
          if (!['id', 'checked'].includes(key)) {
            // 强制转字符
            item[key] = String(item[key]);
          }
        });
      });
      this.$emit('submit', data);
    },
    // 方法调用显示
    show: function show() {
      var _this2 = this;
      var l = this.$loading({
        lock: true,
        text: '加载房源数据中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getRoomCateList().then(function () {
        _this2.$nextTick(function () {
          _this2.showRoomCate = true;
          _this2.$emit('show');
        });
      }).finally(function () {
        l.close();
      });
    },
    // 方法调用关闭
    close: function close() {
      this.setData({
        showRoomCate: false,
        cateList: [],
        checkRoomCateList: [] // 确定后清空
      });
      this.$emit('close');
    },
    /**
     * 获取楼栋列表
     * @param id 选中的楼栋类型id
     */
    getRoomCateList: function getRoomCateList() {
      var _this3 = this;
      return new Promise(function (resolve, reject) {
        roomCatelist().then(function (res) {
          var _res$data;
          _this3.handleRoomCate((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.list);
          resolve();
        }).catch(function () {
          return reject;
        });
      });
    },
    handleRoomCate: function handleRoomCate() {
      var cateList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      if (!cateList) cateList = [];
      this.setData({
        cateList: this.typeDataCheckIndex(cateList)
      });
    },
    // 调整位置
    adjust: function adjust(e) {
      var pos = e.pos,
        index = e.index;
      var index2 = pos === 'up' ? index - 1 : index + 1;

      // 拷贝一下数据 避免vue页面不更新
      var arr = JSON.parse(JSON.stringify(this.selectList));

      // 交换元素
      var temp = arr[index];
      arr[index] = arr[index2];
      arr[index2] = temp;
      this.selectList = arr; // 重设数据
      this.toStr(arr);
    },
    // 数据选中添加下标
    typeDataCheckIndex: function typeDataCheckIndex() {
      var _this4 = this;
      var roomList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      // 数据选中
      roomList === null || roomList === void 0 || roomList.forEach(function (item) {
        var roomInfo = _this4.checkRoomCateList.findIndex(function (c) {
          return c.id === item.id;
        });
        if (roomInfo > -1) {
          item.checked = true;
          item.index = roomInfo + 1;
        }
      });
      return roomList;
    },
    // 选择户型
    selectCate: function selectCate(e) {
      var _this5 = this;
      var id = e.id;
      var cateList = this.cateList;
      var current = {};
      for (var i = 0; i < cateList.length; i++) {
        var ci = cateList[i];
        if (ci.id === id) {
          ci.checked = !ci.checked;
          current = ci;
        }
      }
      var list = this.checkRoomCateList,
        checkRoomCateList = [];

      // 没数据直接添加
      if (!list.length && current.checked) {
        checkRoomCateList.push(current);
      } else {
        if (current.checked) {
          // 添加 直接合并
          checkRoomCateList = list.concat([current]);
        } else {
          // 删除就过滤掉数据
          checkRoomCateList = list.filter(function (item) {
            return id != item.id;
          });
        }
      }
      this.setData({
        checkRoomCateList: checkRoomCateList
      }, function () {
        _this5.setData({
          cateList: _this5.typeDataCheckIndex(cateList)
        }, _this5.$forceUpdate());
      });
    },
    submitRoomCate: function submitRoomCate(e) {
      this.toStr(this.checkRoomCateList);
      this.close();
    }
  }
};