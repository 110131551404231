import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { areaList, formList, areaPleadgeList, addPleadgeTitle, editTitle, delTitle, editPleadgeTitle, listSortIdentify, adminSetting, editPleadgeSave, identifyGetParent, identifyGetHide } from '@/api/sincerityRegister';
export default {
  name: 'Formedit',
  props: {
    formData: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    // 修改还是保存
    type: {
      type: Boolean,
      default: true
    },
    // 修改还是保存
    ide_selectType: {
      type: Number,
      default: 0
    },
    // 区域id
    areaId: {
      type: [Number, String],
      default: 0
    },
    // 认筹区域 数据
    pleadge: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    isM: {
      type: [Boolean],
      default: false
    }
  },
  data: function data() {
    return {
      // saas配置
      saasSetting: {
        audit: {}
      },
      // 二级标题校验
      newAreaForm: {
        area: []
      },
      // 编辑板块弹框
      isEditPlate: false,
      // 表单配置二级标题
      area: [],
      // 表格切换
      chooseRadio: 0,
      hiddenProperty: '',
      // 增加 修改题目 文字
      addNewTitleText: '增加题目',
      // 增加题目form
      addTitleForm: {
        // 名称
        name: '',
        // 表单类型
        form_type: 'radio',
        // 区域id
        display_area_id: 0,
        // 提示语句
        placeholder: '单选/多选',
        // 图片最少限制
        img_number: 1,
        // 是否需要限制
        is_verification: 0,
        // 配置选项
        children: [{
          name: '',
          select_value: 0
        }, {
          name: '',
          select_value: 0
        }],
        // 父级题目id
        parent_pid: 0,
        // 父级题目选项集id
        parent_id: [],
        is_parent_pid: false,
        parent_cause_show: 1,
        is_document: 0,
        // 是否必填
        is_required: 0,
        // 特殊说明
        special_value: ""
        // is_special:0
      },
      addTitleFormRules: {
        name: [{
          required: true,
          message: '题目名称为必填项',
          trigger: 'blur'
        }]
      },
      // 是否打开新增弹窗
      addNewTitleDialogVisible: false,
      // 父级表单
      rarentList: {
        form: [],
        option: []
      },
      hideList: []
    };
  },
  //  页面初始化
  created: function created() {
    this.getAreaList();
    // 获取saas端配置
    this.getSaasSetting();
  },
  methods: {
    // 移动板块
    plateMove: function plateMove(index, item, type) {
      var arr = this.newAreaForm.area;
      if (type === 0) {
        // 上
        if (index == 0) {
          this.$message({
            type: 'warning',
            message: '已经是第一个了'
          });
          return;
        } else {
          var index1 = index;
          var index2 = index - 1;
          move.call(this, index1, index2, arr);
        }
      }
      if (type === 1) {
        // 下
        if (index == arr.length - 1) {
          this.$message({
            type: 'warning',
            message: '已经是最后一个了'
          });
          return;
        } else {
          var _index = index;
          var _index2 = index + 1;
          move.call(this, _index, _index2, arr);
        }
      }
      function move(index1, index2, arr) {
        arr.splice.apply(arr, [index1, 1].concat(_toConsumableArray(arr.splice(index2, 1, arr[index1]))));
      }
    },
    // 删除区域
    delPlate: function delPlate(index, item) {
      var area = this.newAreaForm.area;
      area.splice(index, 1);
    },
    // 获取区域列表
    getAreaList: function getAreaList() {
      var _this = this;
      // 获取 认筹区域
      areaPleadgeList().then(function (res) {
        var area = res.data.list.area;
        if (area != null) {
          _this.chooseRadio = area[0].id;
          _this.areaIds = area[0].id;
          _this.area = area;
          _this.addTitleForm.display_area_id = area[0].id;
          _this.newAreaForm.area = JSON.parse(JSON.stringify(area));
        }
      });
    },
    // 提交板块修改
    editPlateSubmit: function editPlateSubmit() {
      var _this2 = this;
      var params = {
        area: this.newAreaForm.area
      };
      params.area.forEach(function (item, i) {
        item.sort = i + 1;
      });
      this.$refs['newAreaForm'].validate(function (valid) {
        if (valid) {
          editPleadgeSave(params).then(function (res) {
            _this2.$message({
              type: 'success',
              message: res.data.msg
            });

            // 修改成功去  父组件更新
            _this2.$emit('handleEditPlate');
            _this2.isEditPlate = false;
            _this2.getAreaList();
          });
        } else {
          return;
        }
      });
    },
    // 添加板块
    addNewPlate: function addNewPlate() {
      var area = this.newAreaForm.area;
      area.push({
        area_name: '未命名',
        // 区域名称
        sort: 1 // 排序
      });
    },
    // 二级按钮切换
    chooseRadioChange: function chooseRadioChange(e) {
      this.addTitleForm.display_area_id = e;
      this.areaIds = e;
      // 刷新页面
      this.$emit('handleTabs', e);
    },
    // 删除 table
    delTitleItem: function delTitleItem(id) {
      this.$emit('delTable', id);
    },
    // 移动
    moveItem: function moveItem(index, row, type) {
      var _this3 = this;
      // index 当前位置 row 当前数据
      switch (type) {
        case 0:
          // 上
          if (index == 0) {
            this.$message({
              type: 'warning',
              message: '已经是第一个了'
            });
            return;
          } else {
            var index1 = index;
            var index2 = index - 1;
            move.call(this, index1, index2);
          }
          break;
        case 1:
          // 下
          if (index == this.formData.length - 1) {
            this.$message({
              type: 'warning',
              message: '已经是最后一个了'
            });
            return;
          } else {
            var _index3 = index;
            var _index4 = index + 1;
            move.call(this, _index3, _index4);
          }
          break;
        case 2:
          // 移到最前面
          if (index == 0) {
            this.$message({
              type: 'warning',
              message: '已经是第一个了'
            });
            return;
          } else {
            var arr = this.formData;
            var _index5 = index;
            var _index6 = 0;
            arr.unshift.apply(arr, _toConsumableArray(arr.splice(_index5, 1)));
          }
          break;
        case 3:
          // 移到最后面
          if (index == this.formData.length - 1) {
            this.$message({
              type: 'warning',
              message: '已经是最后一个了'
            });
            return;
          } else {
            var _index7 = index;
            var _index8 = this.formData.length;
            move.call(this, _index7, _index8);
          }
          break;
        default:
          break;
      }

      // 2个元素交换位置
      function move(index1, index2) {
        var arr = this.formData;
        arr.splice.apply(arr, [index1, 1].concat(_toConsumableArray(arr.splice(index2, 1, arr[index1]))));
        this.formData = arr;
      }
      var sortParams = {
        form: []
      };
      this.formData && this.formData.forEach(function (item, index) {
        sortParams.form.push({
          id: item.id,
          sort: index + 1
        });
      });
      listSortIdentify(sortParams).then(function (res) {
        _this3.$message({
          type: 'success',
          message: '保存成功'
        });
      }).catch(function () {});
    },
    // 删除选项
    delNewOptions: function delNewOptions(item) {
      var _this4 = this;
      if (this.addType == 1) {
        var params = {
          id: item.id
        };
        delTitle(params).then(function (res) {
          _this4.$message({
            type: 'success',
            message: res.data.msg
          });
          var index = _this4.addTitleForm.children.indexOf(item);
          if (index !== -1) {
            _this4.addTitleForm.children.splice(index, 1);
          }
          _this4.getFormList(_this4.areaIds);
        });
      } else {
        var index = this.addTitleForm.children.indexOf(item);
        if (index !== -1) {
          this.addTitleForm.children.splice(index, 1);
        }
      }
    },
    // 是否显示表格
    tableCheckboxChange: function tableCheckboxChange(e, row) {
      var _this5 = this;
      var params = _objectSpread({}, row);
      editPleadgeTitle(params).then(function (res) {
        _this5.$message({
          type: 'success',
          message: res.data.msg
        });
      });
    },
    // 添加题目保存
    addNewTitleSave: function addNewTitleSave() {
      var _this6 = this;
      var params = _objectSpread({}, this.addTitleForm);

      // 不关联父级字段
      if (!params.is_parent_pid) {
        params.parent_pid = 0;
        params.parent_id = [];
      }
      if (params.parent_pid && (!params.parent_id || !params.parent_id.length)) {
        this.$message({
          type: 'warning',
          message: '请选择父级题目选项！'
        });
        return;
      }
      if (params.form_type == 'radio' || params.form_type == 'select') {
        if (params.children && params.children.length < 2) {
          this.$message({
            type: 'warning',
            message: '至少有两个选项'
          });
          return;
        } else if (!params.children) {
          this.$message({
            type: 'warning',
            message: '至少有两个选项'
          });
          return;
        } else {
          params.children.forEach(function (item, i) {
            item.sort = i + 1;
          });
        }
      } else {
        if (params.children) {
          delete params.children;
        }
      }
      if (params.form_type === 'hide') {
        params.is_required = 1;
      }
      var p1 = new Promise(function (resolve, reject) {
        _this6.$refs['addTitleForm'].validate(function (valid) {
          if (valid) {
            resolve();
          } else {
            reject();
          }
        });
      });
      var p2 = new Promise(function (resolve, reject) {
        if (params.form_type == 'radio' || params.form_type == 'select') {
          _this6.$refs['addTitleFormChildren'].validate(function (valid) {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        } else {
          resolve();
        }
      });
      Promise.all([p1, p2]).then(function (res) {
        if (_this6.addType == 0) {
          // 增加题目
          params.display_area_id = _this6.areaIds;
          addPleadgeTitle(params).then(function (res) {
            _this6.$message({
              type: 'success',
              message: res.data.msg
            });
            _this6.$emit('formList', _this6.areaIds);
            _this6.addNewTitleDialogVisible = false;
          });
        } else {
          // 修改题目
          editPleadgeTitle(params).then(function (res) {
            _this6.$message({
              type: 'success',
              message: res.data.msg
            });
            _this6.$emit('formList', _this6.areaIds);
            _this6.addNewTitleDialogVisible = false;
          });
        }
      });
    },
    // 添加选项
    addNewOptions: function addNewOptions() {
      if (this.addTitleForm.children) {
        this.addTitleForm.children.push({
          name: '',
          select_value: 0
        });
      } else {
        var data = _objectSpread({}, this.addTitleForm);
        data.children = [{
          name: '',
          select_value: 0
        }];
        this.addTitleForm = data;
      }
    },
    // 题目类型改变
    titleTypeChange: function titleTypeChange(e) {
      if (this.addType === 0) {
        switch (e) {
          case 'radio':
            this.addTitleForm.placeholder = '单选/多选';
            break;
          case 'select':
            this.addTitleForm.placeholder = '单选/多选';
            break;
          case 'text':
            this.addTitleForm.placeholder = '';
            break;
          case 'number':
            this.addTitleForm.placeholder = '';
            break;
          case 'file':
            this.addTitleForm.placeholder = '请上传图片';
            break;
        }
      }
    },
    // 选项排序
    btnArrow: function btnArrow(index, item, type) {
      var arr = this.addTitleForm;
      if (type === 0) {
        // 上
        if (index == 0) {
          this.$message({
            type: 'warning',
            message: '已经是第一个了'
          });
          return;
        } else {
          var index1 = index;
          var index2 = index - 1;
          move.call(this, index1, index2, arr.children);
        }
      }
      if (type === 1) {
        // 下
        if (index == arr.children.length - 1) {
          this.$message({
            type: 'warning',
            message: '已经是最后一个了'
          });
          return;
        } else {
          var _index9 = index;
          var _index10 = index + 1;
          move.call(this, _index9, _index10, arr.children);
        }
      }
      function move(index1, index2, arr) {
        arr.splice.apply(arr, [index1, 1].concat(_toConsumableArray(arr.splice(index2, 1, arr[index1]))));
        this.addTitleForm.children = arr;
      }
    },
    // 隐藏无子集的icon
    getRowClassName: function getRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (!row.children) {
        return 'row-expand-cover';
      }
    },
    // 改变类型
    textTypeChange: function textTypeChange(str) {
      // 表单类型 (text)文本 (file)图片 (number)数字 (radio)单选 (select)多选
      switch (str) {
        case 'text':
          return '文本';
        case 'file':
          return '图片';
        case 'number':
          return '数字';
        case 'radio':
          return '单选';
        case 'select':
          return '多选';
        case 'map':
          return '地图选点';
        case 'hide':
          return '隐藏表单';
        case 'popup':
          return '弹窗';
        default:
          return str;
      }
    },
    // 判断客户登记表是否禁用
    disableType: function disableType(row) {
      if (this.type) {
        return this.type;
      } else {
        if (row.form_type == '置业顾问') {
          if (this.saasSetting.audit.complex_audit_first_rule == 1 || this.saasSetting.audit.complex_audit_complex_rule == 1) {
            return true;
          }
        } else {
          if (row.owner_type == 1) {
            return true;
          } else {
            return this.type;
          }
        }
      }
    },
    verifyMinNumber: function verifyMinNumber(e) {
      var _this$addTitleForm = this.addTitleForm,
        img_number = _this$addTitleForm.img_number,
        min_number = _this$addTitleForm.min_number;
      if (img_number < min_number) {
        this.addTitleForm.img_number = img_number + 1;
        this.queueMsg('最大意向不能小于最小意向');
        return;
      }
    },
    // 队列消息 防止消息一起出现 出现遮盖的问题
    queueMsg: function queueMsg(msg) {
      var _this7 = this;
      var status = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
      var d = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
      setTimeout(function () {
        return _this7.$message[status](msg);
      }, d);
    },
    // 增加题目
    addNewTitle: function addNewTitle(type, row) {
      this.addType = type;
      if (row != 0) {
        this.addNewTitleText = '修改题目';
        this.doGetParent();
        row.is_parent_pid = row.parent_pid !== 0;
        this.addTitleForm = JSON.parse(JSON.stringify(row));
      } else {
        this.addNewTitleText = '增加题目';
        this.doGetParent();
        this.resetForm();
      }
      this.addNewTitleDialogVisible = true;
    },
    // 获取父级题目
    doGetParent: function doGetParent() {
      var _this8 = this;
      this.rarentList = {
        form: [],
        option: []
      };
      // let type = 0;
      // for(let i = 0; i < this.area.length; i++){
      //   if(this.chooseRadio === this.area[i].id){
      //     type = this.area[i].type === 2 ? 1 : 0;
      //   }
      // }
      identifyGetHide().then(function (res) {
        _this8.hideList = res.data.list || [];
      });
      identifyGetParent({
        is_bank: 0
      }).then(function (res) {
        _this8.rarentList = {
          form: res.data.list || [],
          option: []
        };
        for (var i = 0; i < _this8.rarentList.form.length; i++) {
          if (_this8.addTitleForm.parent_pid === _this8.rarentList.form[i].id) {
            _this8.rarentList.option = _this8.rarentList.form[i].son;
            break;
          }
        }
      });
    },
    onParent_pidChange: function onParent_pidChange(e) {
      for (var i = 0; i < this.rarentList.form.length; i++) {
        if (this.addTitleForm.parent_pid === this.rarentList.form[i].id) {
          this.rarentList.option = this.rarentList.form[i].son;
          this.addTitleForm.parent_id = [];
          break;
        }
      }
    },
    // resetForm 重置表单
    resetForm: function resetForm() {
      this.addTitleForm = {
        // 名称
        name: '',
        // 表单类型
        form_type: 'radio',
        // 区域id
        display_area_id: 0,
        // 提示语句
        placeholder: '单选/多选',
        // 图片最少限制
        img_number: 1,
        is_document: 0,
        // 是否需要限制
        is_verification: 0,
        // 配置选项
        children: [{
          name: '',
          select_value: 0
        }, {
          name: '',
          select_value: 0
        }],
        // 父级题目id
        parent_pid: 0,
        is_parent_pid: false,
        // 父级题目选项集id
        parent_id: [],
        parent_cause_show: 1,
        // 是否必填
        is_required: 0,
        // 特殊说明
        special_value: ""
        // is_special:0
      };
    },
    // 弹框关闭
    addNewTitleDialogClose: function addNewTitleDialogClose() {
      // 重置表单
      this.$refs['addTitleFormChildren'].resetFields();
      this.$refs['addTitleForm'].resetFields();
    },
    // saas 配置
    getSaasSetting: function getSaasSetting() {
      var _this9 = this;
      adminSetting().then(function (res) {
        _this9.saasSetting = res.data;
      });
    }
  }
};