import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import item from '@/components/screenItem/index';
export default {
  name: 'ScreenNobanner',
  components: {
    item: item
  },
  props: {
    houseList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    ledConfInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    projectRoomInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    allSize: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    allColor: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    carousels: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    theCarousel: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      position: "static"
    };
  },
  created: function created() {
    for (var i = 0; i < this.houseList.length; i++) {
      if (this.houseList[i].x || this.houseList[i].y) {
        this.position = "relative";
        break;
      }
    }
  }
};