import request from '@/utils/request';
// import qs from 'qs'

export default {
  // 排号列表接口
  rowNumberList: function rowNumberList(params) {
    return request({
      url: '/rowNumber/index',
      method: 'get',
      params: params
    });
  },
  // 查看排号规则
  numberSelRule: function numberSelRule(params) {
    return request({
      url: '/rowNumber/selRule',
      method: 'get',
      params: params
    });
  },
  // 保存摇排号规则
  numberSetRule: function numberSetRule(data) {
    return request({
      url: '/rowNumber/setRule',
      method: 'post',
      data: data
    });
  },
  // 重置排号
  numberReset: function numberReset(params) {
    return request({
      url: '/rowNumber/reset',
      method: 'get',
      params: params
    });
  },
  // 获取排号记录列表
  numberRandList: function numberRandList(params) {
    return request({
      url: '/rowNumber/randList',
      method: 'get',
      params: params
    });
  },
  // 排号主页面
  numberRandIndex: function numberRandIndex(params) {
    return request({
      url: '/rowNumber/randIndex',
      method: 'get',
      params: params
    });
  },
  // 开始排号
  numberStartRow: function numberStartRow(params) {
    return request({
      url: '/rowNumber/startRow',
      method: 'get',
      params: params
    });
  },
  //	查询轮次
  numberSelOrderRow: function numberSelOrderRow(params) {
    return request({
      url: '/rowNumber/selOrderRow',
      method: 'get',
      params: params
    });
  },
  //	查询轮次
  numberExport: function numberExport(params) {
    return request({
      url: '/rowNumber/export',
      method: 'get',
      params: params
    });
  }
};