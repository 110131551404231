//
//
//
//
//
//
//
//

export default {
  name: 'Footer',
  props: {
    title: {
      type: String,
      default: '云销控，10年成就专业品质，让开盘变得更简单'
    }
  }
};