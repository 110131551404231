require("core-js/modules/es.number.to-fixed.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
/**
 * @author: Rrq,
 * @mailbox: 15280973293@163.com,
 * @date: 2021-04-12 11:55,
 */

exports.install = function (Vue, options) {
  Vue.prototype.changeData = function (textIndex) {
    var newString = '';
    var newTextIndex = textIndex + 1 + '';
    function sum(value, index) {
      var newValue = '';
      if (textIndex === 9) {
        return !index ? '十' : '';
      }
      var isSeat = ~~textIndex > 9 && ~~textIndex < 19;
      switch (~~value) {
        case 1:
          newValue = !index ? isSeat ? '' : '一' : '十一';
          break;
        case 2:
          newValue = !index ? isSeat ? '' : '二' : '十二';
          break;
        case 3:
          newValue = !index ? isSeat ? '' : '三' : '十三';
          break;
        case 4:
          newValue = !index ? isSeat ? '' : '四' : '十四';
          break;
        case 5:
          newValue = !index ? isSeat ? '' : '五' : '十五';
          break;
        case 6:
          newValue = !index ? isSeat ? '' : '六' : '十六';
          break;
        case 7:
          newValue = !index ? isSeat ? '' : '七' : '十七';
          break;
        case 8:
          newValue = !index ? isSeat ? '' : '八' : '十八';
          break;
        case 9:
          newValue = !index ? isSeat ? '' : '九' : '九十';
          break;
        case 0:
          newValue = '十';
          break;
        default:
          break;
      }
      return newValue;
    }
    for (var i = 0; i < newTextIndex.length; i++) {
      newString += sum(newTextIndex.substring(i, i + 1), i);
    }
    return newString;
  };

  // 金额千分位
  Vue.prototype.moneyData = function (price, preStatus, decimal) {
    // 取整
    if (preStatus === 2) {
      return Math.round(price || 0).toFixed().replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
    } else {
      // 保留两位小数
      if (decimal === 1) {
        // 精确到元
        return Math.round(price || 0).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
      } else if (decimal === 2) {
        // 精确到角
        var pricess = (+price || 0).toFixed(1).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
        return pricess + 0;
      } else {
        // 精确到分
        return (+price || 0).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
      }
    }
  };

  // 处理手机号 隐藏中间 4位
  Vue.prototype.getTel = function (tel) {
    var reg = /^(\d{3})\d{4}(\d{4})$/;
    return tel.replace(reg, '$1****$2');
  };
};