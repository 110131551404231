import _objectSpread from "D:/\u94FE\u55462/pc-new-jf/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import { mapGetters } from 'vuex';
import Api from '@/api/index';
import utils from '@/utils/utils';
import { adminParameter } from '@/api/public';
import defaultSettings from '@/settings';
import customProgress from '@/components/customProgress';
import { getProject, getProjectName, getpName } from '@/utils/auth';
import Mixins from './mixins/mixin';
export default {
  name: 'Dashboard',
  components: {
    customProgress: customProgress
  },
  mixins: [Mixins],
  data: function data() {
    return {
      checkList: ['01开盘概况', '02销售情况', '03客户情况', '04销售排名', '05开盘流程', '06落位转化', '07总结及建议'],
      reportShow: false,
      //打开开盘总结报告自定义内容弹窗 03客户情况
      advance: false,
      iteration: false,
      flowpath: false,
      xtRecommend: 1,
      reportform: {
        advance: '',
        //推进过程小结
        iteration: '',
        //系统迭代建议
        flowpath: '' //开盘流程建议,
      },
      verviewform: {
        summarize: '' //开盘总结
      },
      downloadIndex: 0,
      buildStatusRadio: 0,
      isBuildStatus: false,
      renStatusRadio: 0,
      // 认购信息
      isRenStatus: false,
      canvasImg: '',
      // moment工具
      moment: moment,
      // 用户信息
      userInfo: {},
      // 时间提示
      timeTip: '',
      // 选房端二维码
      xfdQR: '',
      // 助手端二维码
      assistantQR: '',
      // 资格审核二维码
      examinationQR: '',
      // 导出复核
      exportReview: false,
      exportKeyword: false,
      // 导出复核 全选
      ReviewCheckAll: false,
      // 导出复核 全选列表
      reviewCheckList: [],
      // 导出复核 选项列表
      reviewOptions: [{
        id: 5,
        name: '文本资料'
      }, {
        id: 1,
        name: '房源信息'
      }, {
        id: 3,
        name: '客户信息'
      }, {
        id: 2,
        name: '工作人员'
      }, {
        id: 4,
        name: '预留名单'
      }, {
        id: 6,
        name: '摇号预设名单'
      }
      // { id: 7, name: '关键参数' }
      ],
      //   -----------------------
      // 导出模板包
      exportTemp: false,
      sp: {},
      // 导出模板包 全选
      tempCheckAll: false,
      // 导出模板包 全选列表
      tempCheckList: [],
      // 导出模板包 选项列表
      tempOptions: [{
        id: 1,
        name: '房源信息'
      }, {
        id: 2,
        name: '工作人员'
      }, {
        id: 3,
        name: '客户信息'
      }, {
        id: 4,
        name: '预留名单'
      }, {
        id: 5,
        name: '摇号预设名单'
      }],
      // 导出loading
      exportLoading: false,
      // 待办
      stayDoList: [],
      // 异常提醒列表
      abnormalList: [],
      // 房源状态表弹框
      isShowHouseStatus: false,
      // 活动列表
      lists: [],
      // 活动列表 选中
      houseStatusradio: 0,
      dType: '',
      // 开盘配置
      openConfigList: '',
      sendLoading: false,
      defaultSettings: {},
      isNoUSer: false,
      infoList: [],
      infoListId: '',
      textTitle: '生成文件',
      userLoading: false,
      isNoUSerDown: false,
      canvasStyle: {
        // canvas样式
        width: 305,
        height: 490,
        scale: 2
      },
      // 客户状态表 下载
      isUserStatus: false,
      // 客户状态表默认选中
      userStatusRadio: 0,
      // 活动列表
      event_list: [],
      // 诚意登记saas端数据
      auditData: {},
      // 关键参数
      keysList: [],
      keysDownload: {},
      projectData: {},
      admin_tel: '**********',
      selectEventVisbled: false,
      // 选择控制
      selectRadio: 0,
      // 选择
      salesRadio: 0,
      //销售清单选择活动id
      salesDialogShow: false,
      salesListType: 1,
      //销售清单类型
      order_type: '',
      //
      type_show: 2,
      //消控图展示
      sign_up: null,
      //是否开启了签约
      verviewDialogShow: false,
      //开盘报告概述弹窗选择
      summarizeShow: true,
      //是否显示总结建议输入框
      end_event_id: 0,
      kpRadio: '0',
      yxclass: false,
      xsclass: false,
      kpclass: false,
      gxclass: false,
      classValue: []
    };
  },
  computed: _objectSpread({}, mapGetters(['roles', 'customSetting'])),
  created: function created() {
    this.defaultSettings = defaultSettings;
  },
  mounted: function mounted() {
    var _this = this;
    var projectData = JSON.parse(getProject());
    this.$set(this, 'userInfo', projectData || {});
    this.timeTip = utils.timeTips();
    this.getIndexData();

    // 开盘配置引导
    if (this.roles.includes('index/index') || this.roles.includes('admin')) {
      this.getOpenMes();
    }

    // 获取公共参数
    adminParameter().then(function (res) {
      var _res$data$gly;
      console.log(res.data);
      _this.admin_tel = (_res$data$gly = res.data.gly) === null || _res$data$gly === void 0 ? void 0 : _res$data$gly.admin_tel;
      if (res.status === 200) {
        var _res$data$end_event_i;
        var _res$data = res.data,
          list = _res$data.list,
          audit = _res$data.audit,
          data = _res$data.data,
          sp = _res$data.sp;
        _this.sign_up = list.is_display.includes('sign_up'); //是否开启签约
        _this.sp = sp || {};
        _this.adminList = list;
        _this.auditData = audit || {};
        _this.projectData = data;
        var index = _this.reviewOptions.findIndex(function (i) {
          return i.id === 6;
        });
        var index2 = _this.tempOptions.findIndex(function (i) {
          return i.id === 5;
        });
        _this.reviewOptions[index].buy_rand = list.buy_rand;
        // console.log(list.buy_rand, this.reviewOptions[index])
        _this.tempOptions[index2].buy_rand = list.buy_rand;
        // 获取sass端参数
        _this.end_event_id = (_res$data$end_event_i = res.data.end_event_id) !== null && _res$data$end_event_i !== void 0 ? _res$data$end_event_i : 0;
        // settingCustom(res.data)
      }
    });
  },
  methods: {
    // 销售清单下载打开选择弹窗
    salesListDownload: function salesListDownload() {
      var _this2 = this;
      if (this.customSetting.cate_car === 1) this.salesListType = 2;
      if (this.customSetting.cate_room === 1) this.salesListType = 1;
      this.$nextTick(function () {
        _this2.salesDialogShow = true;
      });
    },
    // 开盘报告概述弹窗打开
    openVerviewDialog: function openVerviewDialog() {
      this.verviewDialogShow = true;
    },
    // 确认下载
    salesDownload: function salesDownload() {
      var _this3 = this;
      var activeIndex = this.salesRadio;
      var parameter = {
        event_id: this.event_list[activeIndex].id,
        round_id: 0
      };
      var alesapi = this.salesListType == 1 ? 'roomSaleExport' : 'carSaleExport';
      var loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      Api[alesapi](parameter).then(function (res) {
        utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
      }).finally(function () {
        loading.close();
        _this3.salesDialogShow = false;
      });
    },
    // 下载报表
    downLoadStatement: function downLoadStatement() {
      var _this4 = this;
      var loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var params = {
        event_id: this.event_list[this.selectRadio].id,
        handled: '0',
        type: this.event_list[this.selectRadio].type !== 1 ? 1 : 2
      };
      Api.orderExport(params).then(function (res) {
        if (res.data.check.bool) {
          _this4.$message({
            type: 'error',
            message: '订单数据异常！'
          });
        }
        // window.location.href = this.host + res.data.msg
        utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
      }).finally(function () {
        loading.close();
        _this4.selectEventVisbled = false;
      });
    },
    downloadLog: function downloadLog(postId) {
      var _this5 = this;
      this.userLoading = true;
      this.textTitle = '生成中...';
      Api.generateLog({
        id: postId
      }).then(function (res) {
        if (res.status === 200) {
          if (res.data.is_download) {
            _this5.userLoading = true;
            _this5.textTitle = '下载中...';
            // 下载文件
            Api.downloadLog({
              id: postId
            }).then(function (res) {
              if (res.status === 200) {
                var path = res.data.path;
                _this5.userLoading = false;
                _this5.textTitle = '生成文件';
                // window.location.href = this.host + path
                utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
                _this5.isNoUSer = false;
                _this5.isNoUSerDown = false;
              }
            });
          }
        }
      });
    },
    generateLog: function generateLog(postId) {
      var _this6 = this;
      if (postId === '') {
        this.$message({
          type: 'warning',
          message: '先选择活动在下载报表'
        });
        return;
      }
      this.doAutCheck(postId);
      // 下载文件
      Api.downloadLog({
        id: postId
      }).then(function (res) {
        if (res.status === 200) {
          // window.location.href = this.host + path
          // 下载文件
          utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
          _this6.isNoUSer = false;
          _this6.$message({
            type: 'success',
            message: '下载成功~'
          });
        }
      }).catch(function (err) {
        _this6.isNoUSerDown = true;
        _this6.isNoUSer = false;
      });
    },
    // 获取首页数据
    getIndexData: function getIndexData() {
      var _this7 = this;
      // 无权限不掉接口
      if (this.roles.includes('index/getassistant') || this.roles.includes('admin')) {
        // 助手端二维码
        Api.getAssistant().then(function (res) {
          if (res.status === 200) {
            if (res.data.image) {
              _this7.assistantQR = 'data:image/png;base64,' + res.data.image;
            }
          }
        });
      }

      // 无权限不掉接口
      if (this.roles.includes('index/getaudit') || this.roles.includes('admin')) {
        // 资格审核二维码
        Api.getExamination().then(function (res) {
          if (res.status === 200) {
            if (res.data.image) {
              _this7.examinationQR = 'data:image/png;base64,' + res.data.image;
            }
          }
        });
      }
    },
    // 二维码下载
    downloadPicture: function downloadPicture(url, type) {
      var _this8 = this;
      // if (!url) return
      var _this$canvasStyle = this.canvasStyle,
        width = _this$canvasStyle.width,
        height = _this$canvasStyle.height,
        scale = _this$canvasStyle.scale;
      var picture_name;
      var img = new Image();
      img.width = width * scale;
      img.height = height * scale;
      img.onload = function () {
        var canvas = document.getElementById('qr_canvas');
        var ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        // 背景图片
        _this8.drawBgImg(ctx, canvas, scale, img);
        // 中间白色区域
        _this8.drawContent(ctx, canvas, scale);
        // // 项目名称
        _this8.drawName(ctx, canvas, scale);
        // // 小程序端标签
        _this8.drawTips_1(ctx, canvas, scale, type, _this8.sp.project_suffix);
        // // 提示文本
        _this8.drawTips_2(ctx, canvas, scale);
        // // 下载日期
        _this8.drawDownloadTime(ctx, canvas, scale);
        // // 项目邀请码
        _this8.drawQRImg(ctx, canvas, scale, url).then(function () {
          // 存储canvas图片
          _this8.exportCanvasQr(canvas.toDataURL('image/png', 0.92), picture_name);
        });
      };
      switch (type) {
        case 1:
          picture_name = '客户端小程序码';
          img.src = require('@/assets/image/qr_bg_img_x.png');
          break;
        case 2:
          picture_name = '助手端小程序码';
          img.src = require('@/assets/image/qr_bg_img_z.png');
          break;
        case 3:
          picture_name = "".concat(this.auditData.audit_suffix, "\u5C0F\u7A0B\u5E8F\u7801");
          img.src = require('@/assets/image/qr_bg_img_b.png');
          break;
        default:
          picture_name = '客户端小程序码';
          img.src = require('@/assets/image/qr_bg_img_x.png');
      }
    },
    // 导出canvas二维码
    exportCanvasQr: function exportCanvasQr(url, picture_name) {
      utils.download_files(url, "".concat(this.adminList.name, "-").concat(picture_name, "-").concat(moment().format('YYYYMMDDHHmmss')), {
        showMessage: false
      });
    },
    // 导出
    exportHandle: function exportHandle(type) {
      var _this9 = this;
      this.exportLoading = true;
      var params = {};
      if (type === 1) {
        // 导出模板包
        var templates = [];
        this.tempCheckList.forEach(function (item) {
          if (item.buy_rand === 0) {} else {
            templates.push(item.id);
          }
        });
        params.templates = templates.sort().join(',');
        this.loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        Api.exportTemplate(params).then(function (res) {
          _this9.exportLoading = false;
          if (res.status === 200 && res.data.msg) {
            //  11月17号改成不是对象存储了（5吴）
            var url = _this9.host + '/' + res.data.msg;
            utils.download_files(url, "".concat(getpName(), "-\u5BFC\u5165\u6A21\u677F-").concat(moment().format('YYYYMMDDHHmmss'))).then(function () {
              // 导出成功关闭弹窗
              _this9.cancelExport(type);
            });
          }
          _this9.loading.close();
        });
      } else if (type === 2) {
        // 导出复核
        var _templates = [];
        this.reviewCheckList.forEach(function (item) {
          if (item.buy_rand === 0) {} else {
            _templates.push(item.id);
          }
        });
        params.templates = _templates.sort().join(',');
        this.loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        Api.exportCheck(params).then(function (res) {
          _this9.exportLoading = false;
          if (res.status === 200 && res.data.msg) {
            // const url = this.host + '/' + res.data.msg
            // utils
            //     .download_files(
            //         url,
            //         `${getpName()}-导出复核-${moment().format('YYYYMMDDHHmmss')}`
            //     )
            //     .then(() => {
            //         // 导出成功关闭弹窗
            //         this.cancelExport(type)
            //     })
            utils.download_files_base64(res.data.data, "".concat(getpName(), "-\u5BFC\u51FA\u590D\u6838-").concat(moment().format('YYYYMMDDHHmmss')), res.data.suffix).then(function () {
              // 导出成功关闭弹窗
              _this9.cancelExport(type);
            });
          }
          _this9.loading.close();
        });
      }
    },
    // 取消导出
    cancelExport: function cancelExport(type) {
      if (type === 1) {
        // 导出模板包
        this.exportTemp = false;
        // 导出模板包 全选
        this.tempCheckAll = false;
        // 导出模板包 全选列表
        this.tempCheckList = [];
      } else if (type === 2) {
        // 导出复核
        this.exportReview = false;
        // 导出复核 全选
        this.ReviewCheckAll = false;
        // 导出复核 全选列表
        this.reviewCheckList = [];
      }
    },
    // 导出复核 弹框 全选
    reviewCheckAllChange: function reviewCheckAllChange(val) {
      this.reviewCheckList = val ? this.reviewOptions : [];
    },
    // 导出复核点选
    reviewCheckListChange: function reviewCheckListChange(val) {
      // 全选按钮是否选中
      this.ReviewCheckAll = val.length === this.reviewOptions.length;
    },
    // 导出模板包 弹框 全选
    tempCheckAllChange: function tempCheckAllChange(val) {
      this.tempCheckList = val ? this.tempOptions : [];
    },
    // 导出模板包点选
    tempCheckListChange: function tempCheckListChange(val) {
      // 全选按钮是否选中
      this.tempCheckAll = val.length === this.tempOptions.length;
    },
    // 导出复核 模板包
    exportTempBtn: function exportTempBtn(index) {
      if (index === 0) {
        this.exportTemp = true;
      } else {
        this.exportReview = true;
      }
    },
    // 下载关键字
    downloadKeys: function downloadKeys() {
      utils.download_files_base64(this.keysDownload.data, this.keysDownload.name, this.keysDownload.suffix);
      // utils.download_files(this.keysUrl, `${getpName()}-导入模板-${moment().format('YYYYMMDDHHmmss')}`)
      this.exportKeyword = false;
    },
    // 跳转审核
    goToExamine: function goToExamine(info) {
      if (info.is_audit) {
        // 跳审批详情
        this.$router.push({
          name: 'ApprovalDetail'
        });
      } else {
        this.$router.push({
          name: 'Reserved',
          query: {}
        });
      }
    },
    // 异常提醒跳转
    goToAbnormal: function goToAbnormal(info) {
      var name = 'Dashboard';
      var query = {};
      if (info.url === 'user') {
        // 开盘客户
        name = 'Index';
      } else if (info.url === 'room') {
        // 住宅设置
        name = 'House';
      } else if (info.url === 'villa') {
        // 别墅设置
        name = 'Building';
      }
      query[info.data.key] = info.data.value;
      this.$router.push({
        name: name,
        query: query
      });
    },
    showClassDiadlog: function showClassDiadlog(show) {
      if (show === 'kpclass') {
        // 一起开
        if (this.customSetting.cate_room === 1 && this.customSetting.cate_car === 1) {
          this.type_show = 2;
        } else if (this.customSetting.cate_room === 1) {
          // 单独开
          this.type_show = 0;
        } else if (this.customSetting.cate_car === 1) {
          // 单独开
          this.type_show = 1;
        }
      }
      this[show] = true;
    },
    close: function close(show) {
      this[show] = false;
      this.classValue = [];
    },
    downCheck: function downCheck() {
      var _this10 = this;
      console.log(this.classValue);
      if (!this.classValue.length) {
        this.$message.error('请选择报表再进行下载!');
        return;
      }
      this.classValue.forEach(function (item) {
        if (item == 1) {
          _this10.exportDetail(_this10.houseStatusradio);
        } else if (item == 5) {
          _this10.exportUserStatusCopy();
        } else if (item == 6) {
          _this10.handleRenDownload();
        } else if (item == 7) {
          _this10.buildSaleFun(_this10.event_list[_this10.buildStatusRadio].id);
        } else if (item == 8) {
          _this10.doAnalyse(1);
        } else if (item == 9) {
          _this10.downLoadStatement();
        } else if (item == 10) {
          _this10.salesDownload();
        } else if (item == 11) {
          // 	TODO
        } else {
          console.log(item);
          _this10.download(parseInt(item));
        }
      });
    },
    // 活动报告 下载
    download: function download(index) {
      this.downloadIndex = index;
      switch (index) {
        case 0:
          // 房源意向统计
          this.exportHouseIntention();
          break;
        case 1:
          // 房源状态表
          this.exportHouseStatus();
          break;
        case 2:
          // 不在线客户
          this.exportCustomOnline();
          break;
        case 3:
          // 登录过但未认购客户
          this.exportnoOrderCustom();
          break;
        case 4:
          // 客户意向统计
          this.exportUserCollet();
          break;
        case 5:
          // 客户状态表
          this.exportUserStatus();
          break;
        case 6:
          // 认购信息导出
          this.userOrderExport();
          break;
        case 7:
          // 楼栋去化率
          this.buildSale();
          break;
        case 8:
          // 装户综合分析
          this.doAnalyse(0);
          break;
        case 9:
          // 订单记录
          this.selectEventVisbled = true;
          break;
        default:
          return;
      }
    },
    /**
     * 装户综合分析
     * @param type  0：主页点击下载，1：弹框中选择了活动下载，2：开始下载
     * @param event_id
     * @param round_id
     */
    doAnalyse: function doAnalyse(type, event_id, round_id) {
      var _this11 = this;
      if (type === 0) {
        var lists = this.event_list;
        if (lists.length > 1) {
          this.isBuildStatus = true;
        } else {
          this.doAnalyse(2, this.event_list[0].id, this.lists[0].round);
        }
      } else if (type === 1) {
        this.doAnalyse(2, this.event_list[this.buildStatusRadio].id, this.lists[this.buildStatusRadio].round);
      } else if (type === 2) {
        var data = {
          event_id: event_id,
          round_id: round_id
        };
        this.loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        var isOK = 0;
        var urls = [];
        var download = null;
        if (this.customSetting.cate_room === 1) {
          Api.analyse(data).then(function (res) {
            // urls.push(this.host + res.data.url)
            urls.push(res.data);
            if (isOK === 1 || _this11.customSetting.cate_car !== 1) {
              // window.location.href = urls[0]
              // 下载文件
              utils.download_files_base64(urls[0].data, urls[0].name, urls[0].suffix);
              if (urls[1]) {
                download = setTimeout(function () {
                  // window.location.href = urls[1]
                  utils.download_files_base64(urls[1].data, urls[1].name, urls[1].suffix);
                  clearTimeout(download);
                }, 500);
              }
              _this11.isBuildStatus = false;
              _this11.loading.close();
            } else {
              isOK = 2;
            }
          });
        }
        if (this.customSetting.cate_car === 1) {
          Api.carAnalyse(data).then(function (res) {
            urls.push(res.data);
            if (isOK === 2 || _this11.customSetting.cate_room !== 1) {
              // window.location.href = urls[0]
              // 下载文件
              utils.download_files_base64(urls[0].data, urls[0].name, urls[0].suffix);
              if (urls[1]) {
                download = setTimeout(function () {
                  // window.location.href = urls[1]
                  utils.download_files_base64(urls[1].data, urls[1].name, urls[1].suffix);
                  clearTimeout(download);
                }, 500);
              }
              _this11.isBuildStatus = false;
              _this11.loading.close();
            } else {
              isOK = 1;
            }
          });
        }
      }
    },
    /**
     * 开盘报告下载时请求，查询是否订单数据异常
     * @param event_id
     */
    doAutCheck: function doAutCheck(event_id) {
      var _this12 = this;
      Api.check({
        event_id: event_id
      }).then(function (res) {
        if (res.data.data.bool) {
          _this12.$message({
            type: 'error',
            message: '订单数据异常！'
          });
        }
      });
    },
    // 楼栋去化率
    buildSale: function buildSale() {
      var lists = this.event_list;
      if (lists.length > 1) {
        this.isBuildStatus = true;
      } else {
        this.buildSaleFun(this.event_list[0].id);
      }
    },
    // 多个活动下载 认购信息导出
    handleBuildDownload: function handleBuildDownload() {
      var id = this.buildStatusRadio;
      this.buildSaleFun(this.event_list[id].id, this.lists[id].round);
    },
    // 楼栋去化率 封装下载
    buildSaleFun: function buildSaleFun(event_id, round_id) {
      var _this13 = this;
      var data = {
        event_id: event_id,
        round_id: round_id
      };
      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.doAutCheck(event_id);
      Api.buildSale(data).then(function (res) {
        // const url = this.host + res.data.url
        // this.isBuildStatus = false
        // window.location.href = url
        // 下载文件
        utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
        _this13.loading.close();
      });
    },
    // 多个活动下载 认购信息导出
    handleRenDownload: function handleRenDownload() {
      var id = this.renStatusRadio;
      this.userOrderExportFun(this.event_list[id].id);
    },
    // 认购信息导出 封装下载
    userOrderExportFun: function userOrderExportFun(id) {
      var _this14 = this;
      var params = {
        event_id: id
      };
      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.doAutCheck(id);
      Api.userOrderExport(params).then(function (res) {
        // const url = this.host + res.data.msg
        // this.isRenStatus = false
        // window.location.href = url
        // 下载文件
        utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
        _this14.loading.close();
      });
    },
    // 认购信息导出
    userOrderExport: function userOrderExport() {
      var lists = this.event_list;
      if (lists.length > 1) {
        this.isRenStatus = true;
      } else {
        this.userOrderExportFun(this.event_list[0].id);
      }
    },
    // 房源意向统计
    exportHouseIntention: function exportHouseIntention() {
      var _this15 = this;
      // Api.houseIntention
      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      Api.houseIntention().then(function (res) {
        // const url = this.host + res.data.path
        // window.location.href = url
        // 下载文件
        utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
        _this15.loading.close();
      });
    },
    // 房源状态表
    exportHouseStatus: function exportHouseStatus() {
      // Api.houseStatus
      // const lists = this.lists
      var lists = this.event_list;
      var len = lists.length;
      if (len == 1) {
        var event_id = lists[0].id;
        var round_id = lists[0].round;
        this.houseStatusDetail(event_id, round_id);
      } else {
        // 显示弹框
        this.dType = 0;
        this.isShowHouseStatus = true;
      }
    },
    // 不在线客户
    exportCustomOnline: function exportCustomOnline() {
      var _this16 = this;
      // Api.customOnline
      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      Api.startEvent().then(function (res) {
        if (res.status === 200) {
          var info = res.data.info;
          _this16.infoList = info;
          // 有活动
          if (info.length > 0) {
            _this16.isNoUSer = true;
          }
          // 无活动
          else {
            _this16.$message({
              type: 'warning',
              message: '暂无活动~'
            });
          }
        }
        _this16.loading.close();
      });
    },
    // 登录过但未认购客户
    exportnoOrderCustom: function exportnoOrderCustom() {
      // Api.noOrderCustom
      var lists = this.lists;
      var len = lists.length;
      if (len == 1) {
        var event_id = lists[0].id;
        this.noOrderCustomDetail(event_id);
      } else {
        // 显示弹框
        this.dType = 1;
        this.isShowHouseStatus = true;
      }
    },
    // 客户意向统计
    exportUserCollet: function exportUserCollet() {
      var _this17 = this;
      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      Api.userCollect().then(function (res) {
        // const url = this.host + res.data.path
        // window.location.href = url
        // 下载文件
        utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
        _this17.loading.close();
      });
    },
    // 客户状态表
    exportUserStatus: function exportUserStatus() {
      var _this18 = this;
      var lists = this.event_list;
      if (lists.length > 1) {
        this.isUserStatus = true;
      } else {
        var params = {
          event_id: lists[0].id,
          round_id: lists[0].round
        };
        this.loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.doAutCheck(lists[0].id);
        Api.userStatus(params).then(function (res) {
          // const url = this.host + res.data.path
          // window.location.href = url
          // 下载文件
          utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
          _this18.loading.close();
        });
      }
    },
    // 客户状态表 多个活动下载
    exportUserStatusCopy: function exportUserStatusCopy() {
      var _this19 = this;
      var index = this.userStatusRadio;
      var lists = this.event_list;
      var params = {
        event_id: lists[index].id,
        round_id: lists[index].round
      };
      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.doAutCheck(lists[index].id);
      Api.userStatus(params).then(function (res) {
        // const url = this.host + res.data.path
        // window.location.href = url
        utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
        _this19.isUserStatus = false;
        _this19.loading.close();
      });
    },
    // 活动总结报告
    exportActiveReport: function exportActiveReport() {
      console.log(33);
    },
    // 房源状态表详情
    houseStatusDetail: function houseStatusDetail(id, round_id) {
      var _this20 = this;
      var params = {
        event_id: id,
        round_id: round_id
      };
      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.doAutCheck(id);
      Api.houseStatus(params).then(function (res) {
        _this20.isShowHouseStatus = false;
        _this20.loading.close();
        // const url = this.host + res.data.path
        // window.location.href = url
        // 下载文件
        utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
      });
    },
    // 登录过但未认购客户详情
    noOrderCustomDetail: function noOrderCustomDetail(id) {
      var _this21 = this;
      var event_id = id;
      var params = {
        event_id: event_id
      };
      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.doAutCheck(event_id);
      Api.noOrderCustom(params).then(function (res) {
        // const url = this.host + res.data.path
        // window.location.href = url
        // 下载文件
        utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
        _this21.loading.close();
      });
    },
    // 房源状态表 登录过但未认购客户
    exportDetail: function exportDetail(index) {
      var event_id = this.event_list[index].id;
      var round_id = this.event_list[index].round_id;
      var type = this.dType;
      if (type == 0) {
        // 房源状态表
        this.houseStatusDetail(event_id, round_id);
      }
      if (type == 1) {
        // 登录过但未认购客户
        this.noOrderCustomDetail(event_id);
      }
    },
    // 发送
    sendMes: function sendMes() {
      var _this22 = this;
      this.sendLoading = true;
      Api.smsPassord().then(function (res) {
        _this22.sendLoading = false;
        if (res.status === 200) {
          _this22.$message({
            message: "已发送短信，成功：" + res.data.successCount + "条，失败：" + res.data.errCount + "条",
            type: 'success'
          });
        }
        _this22.$refs.customProgress.getOpenConfig();
        _this22.getOpenMes();
      });
    },
    // 获取开盘引导
    getOpenMes: function getOpenMes() {
      var _this23 = this;
      Api.getOpenConfig().then(function (res) {
        var list = res.data.list;
        _this23.openConfigList = list;
      });
    },
    //打开开盘盘总结报告增加自定义总结内容弹窗
    openReportDialog: function openReportDialog() {
      this.reportShow = true;
    },
    opendownloadverview: function opendownloadverview() {
      if (!this.order_type && this.sign_up && this.order_type !== 0) {
        //开启签约必须选择一个统计维度
        this.$message.error('请先选择认购统计维度');
        return;
      }
      var info = {};
      for (var key in this.verviewform) {
        if (this.verviewform[key] && !this[key]) {
          info[key] = this.verviewform[key].split('@').filter(function (item) {
            return item;
          });
        }
      }
      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var path = this.$router.resolve({
        path: '/activeoverview',
        query: {
          id: JSON.parse(getProject()).project_id,
          info: Object.keys(info).length ? JSON.stringify(info) : '',
          order_type: this.order_type,
          type_show: this.type_show
        }
      });
      var win = window.open("".concat(path.href), '_blank');
      this.loading.close();
    },
    handleCheckedCitiesChange: function handleCheckedCitiesChange(value) {
      this.summarizeShow = false;
      for (var i = 0; i < value.length; i++) {
        if (value[i] == '07总结及建议') {
          this.summarizeShow = true;
        }
        for (var j = i + 1; j < value.length; j++) {
          if (value[i].slice(1, 2) > value[j].slice(1, 2)) {
            var thvalue = value[i];
            value[i] = value[j];
            value[j] = thvalue;
          }
        }
      }
      this.checkList = value;
    },
    // 正式开盘活动总结报告
    openAcitveReport: function openAcitveReport() {
      //获取弹窗里面的值 传递到pdf页面
      if (!this.order_type && this.sign_up && this.order_type !== 0) {
        //开启签约必须选择一个统计维度
        this.$message.error('请先选择认购统计维度');
        return;
      }
      var info = {};
      for (var key in this.reportform) {
        if (this.reportform[key] && !this[key]) {
          info[key] = this.reportform[key].split('@').filter(function (item) {
            return item;
          });
        }
      }
      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var path = this.$router.resolve({
        path: '/activeReport',
        query: {
          id: JSON.parse(getProject()).project_id,
          info: Object.keys(info).length ? JSON.stringify(info) : '',
          order_type: this.order_type,
          xtRecommend: this.xtRecommend,
          showDimension: JSON.stringify(this.checkList)
        }
      });
      var win = window.open("".concat(path.href), '_blank');
      this.loading.close();
    }
  }
};