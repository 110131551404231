var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        "background-color": "white",
        padding: "20px 0",
        "overflow-x": "hidden",
      },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: _vm.isPC ? 16 : 24 } },
            [
              _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                _c("div", { staticClass: "detail-item" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("姓名：")]),
                  _c("div", {
                    staticClass: "text",
                    domProps: { textContent: _vm._s(_vm.data.name) },
                  }),
                ]),
              ]),
              _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                _c("div", { staticClass: "detail-item" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("手机号：")]),
                  _c("div", {
                    staticClass: "text",
                    domProps: { textContent: _vm._s(_vm.data.tel) },
                  }),
                ]),
              ]),
              _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                _c("div", { staticClass: "detail-item" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("认筹派号：")]),
                  _c("div", {
                    staticClass: "text",
                    domProps: {
                      textContent: _vm._s(_vm.data.code || "未派号"),
                    },
                  }),
                ]),
              ]),
              _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                _c("div", { staticClass: "detail-item" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("创建方式：")]),
                  _c("div", {
                    staticClass: "text",
                    domProps: {
                      textContent: _vm._s(
                        _vm.data.reg_user_status == 1 ? "后台导入" : "自行创建"
                      ),
                    },
                  }),
                ]),
              ]),
              _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                _c("div", { staticClass: "detail-item" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v("首次登陆时间："),
                  ]),
                  _c("div", {
                    staticClass: "text",
                    domProps: {
                      textContent: _vm._s(
                        _vm.data.login_time
                          ? _vm.doformatDate(
                              _vm.data.login_time * 1000,
                              "yyyy-MM-dd hh:mm:ss"
                            )
                          : ""
                      ),
                    },
                  }),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }