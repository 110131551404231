var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "padding-container" }, [
        _c("div", { staticClass: "top-fixed" }, [
          _vm.type === 1
            ? _c("div", { staticClass: "title" }, [_vm._v("修改登记表")])
            : _c("div", { staticClass: "title" }, [_vm._v("修改附表")]),
        ]),
        _c("div", { staticClass: "block-box" }, [
          _c(
            "div",
            { staticClass: "scroll-box mT10" },
            [
              _c("input-box", {
                attrs: {
                  userCreateInfoId: _vm.userCreateInfoId,
                  "status-info": _vm.statusInfo,
                  type: _vm.type,
                },
                on: {
                  addPropertyOwner: _vm.addPropertyOwner,
                  handleDelete: _vm.handleDelete,
                  handleOtherImg: _vm.handleOtherImg,
                  handleProperty: _vm.handleProperty,
                  handleRadio: _vm.handleRadio,
                  onOwnerSort: _vm.onOwnerSort,
                  doShowParent: _vm.doShowParent,
                  handlePopup: _vm.handlePopup,
                  handleHouse: _vm.handleHouse,
                  limit: _vm.onRoomList,
                  doSpouseAddOwner: _vm.doSpouseAddOwner,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "block-bottom-box" },
        [
          _c(
            "el-button",
            { staticClass: "pink-btn2", on: { click: _vm.handleCancel } },
            [_vm._v("关闭")]
          ),
          _c(
            "el-button",
            { staticClass: "greens-btn", on: { click: _vm.handleSave } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            "custom-class": "exportDialog",
            visible: _vm.ownerSort.dialogShow,
            title: "产权人排序",
            width: "40vw",
            top: "20vh",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.ownerSort, "dialogShow", $event)
            },
          },
        },
        [
          _vm.ownerSort.dialogShow
            ? _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.ownerSort.data,
                    "header-cell-style": { background: "#fafafa" },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", prop: "date", label: "姓名" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return _vm._l(
                              scope.row.forms,
                              function (item, index) {
                                return _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.owner_type === 1,
                                        expression: "item.owner_type === 1",
                                      },
                                    ],
                                    key: index,
                                  },
                                  [_vm._v(_vm._s(item.owner_result.value))]
                                )
                              }
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      2111841467
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", prop: "date", label: "手机号" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return _vm._l(
                              scope.row.forms,
                              function (item, index) {
                                return _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.owner_type === 2,
                                        expression: "item.owner_type === 2",
                                      },
                                    ],
                                    key: index,
                                  },
                                  [_vm._v(_vm._s(item.owner_result.value))]
                                )
                              }
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      1827163611
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "date",
                      label: "身份证",
                      width: "180",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return _vm._l(
                              scope.row.forms,
                              function (item, index) {
                                return _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.owner_type === 3,
                                        expression: "item.owner_type === 3",
                                      },
                                    ],
                                    key: index,
                                  },
                                  [_vm._v(_vm._s(item.owner_result.value))]
                                )
                              }
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      2429414907
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "name",
                      label: "排序",
                      width: "100",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.$index !== 0
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "向上换位",
                                        placement: "top-end",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-top",
                                        staticStyle: {
                                          "margin-right": "15px",
                                          color: "#22A271",
                                          "font-size": "18px",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onOwnerSort(
                                              scope.$index,
                                              "top"
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                : _c("span", {
                                    staticStyle: { padding: "15px" },
                                  }),
                              scope.$index !== _vm.ownerSort.data.length - 1
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "向下换位",
                                        placement: "top-end",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-bottom",
                                        staticStyle: {
                                          "margin-right": "15px",
                                          color: "#22A271",
                                          "font-size": "18px",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onOwnerSort(
                                              scope.$index,
                                              "bottom"
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3484787514
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { padding: "8px 20px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onOwnerSort(0, "submit")
                    },
                  },
                },
                [_vm._v("确定修改")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }